import { Form, Input, InputNumber, Popconfirm, Table, Typography } from "antd";
import moment from "moment";
import React, { useEffect, useState } from "react";

const originData = [];
for (let i = 0; i < 100; i++) {
  originData.push({
    key: i.toString(),
    name: `Edrward ${i}`,
    age: 32,
    address: `London Park no. ${i}`,
  });
}
const EditableCell = ({
  editing,
  dataIndex,
  title,
  inputType,
  record,
  index,
  children,
  ...restProps
}) => {
  const inputNode =
    inputType === "number" ? (
      <InputNumber
      // formatter={(value) =>
      //   `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
      // }
      // parser={(value) => value?.replace(/\$\s?|(,*)/g, "")}
      />
    ) : (
      <Input />
    );
  return (
    <td {...restProps}>
      {editing ? (
        <Form.Item
          name={dataIndex}
          style={{
            margin: 0,
          }}
          // rules={[
          //   {
          //     required: true,
          //     message: `Please Input ${title}!`,
          //   },
          // ]}
        >
          {inputNode}
        </Form.Item>
      ) : (
        children
      )}
    </td>
  );
};
const EditableTableO = (props) => {
  const [form] = Form.useForm();
  const [data, setData] = useState([]);
  const [ongoingP, setOngoingP] = useState(originData);
  const [editingKey, setEditingKey] = useState("");
  const isEditing = (record) => record.key === editingKey;

  function numberWithCommas(x) {
    return x?.toString()?.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  }

  const edit = (record) => {
    form.setFieldsValue({
      deptBudgetId: "3fa85f64-5717-4562-b3fc-2c963f66afa6",
      economicCode: "string",
      economicCodeDetails: "string",
      fundCodeMain: "string",
      fundCodeDetails: "string",
      adminCode: "string",
      details: "string",
      proposedRevenue: 0,
      previousApprovedRevenue: 0,
      currentRevenueAsAt: 0,
      forecastOfCurrentRevenue: 0,
      previousGeneratedRevenue: 0,

      ...record,
    });
    setEditingKey(record.key);
  };
  const cancel = () => {
    setEditingKey("");
  };
  const save = async (key, record) => {
    console.log(key);
    try {
      let row = await form.validateFields();
      row = {
        ...row,
        editedAmount: +row.availableBudget - +record?.proposedRevenue,
        availableBudget: +row.availableBudget,
      };
      const newData = [...data];
      const index = newData.findIndex((item) => key === item.key);
      if (index > -1) {
        const item = newData[index];
        newData.splice(index, 1, {
          ...item,
          ...row,
        });
        // props.setEnvelopeData(newData);
        console.log(newData);
        let kkk = { ...props.propzApproved, recurrentRevenues: newData };
        delete kkk["budgetDeptApprovers"];
        delete kkk["approvers"];
        props.saveAndRefreshApprovedMDAs(kkk);

        setData(newData);
        setEditingKey("");
      } else {
        newData.push(row);
        setData(newData);
        setEditingKey("");
        console.log(newData);
      }
    } catch (errInfo) {
      console.log("Validate Failed:", errInfo);
    }
  };

  const ongoingProjectsColumn = [
    {
      title: "Administrative Code",
      dataIndex: "adminCode",
      key: "name",
      render: (text) => <a>{text}</a>,
    },
    {
      title: "Economic Code",
      dataIndex: "economicCode",
      key: "name",
      render: (text) => <a>{text}</a>,
    },
    // {
    //   title: "Fund Code",
    //   dataIndex: "fundCode",
    //   key: "fundCode",
    //   editable: true,
    // },
    {
      title: "Details of Revenue",
      dataIndex: "details",
      key: "age",
      editable: true,
    },
    {
      title: "Proposed Revenue Estimates",
      dataIndex: "proposedRevenue",
      key: "proposedRevenue",
      editable: false,
      render: (text) => <a>{numberWithCommas(+text)}</a>,
      // editable: true,

      //  width: "20%",
    },
    {
      title: "Available Revenue Budget",
      dataIndex: "availableBudget",
      key: "availableBudget",
      editable: true,
      render: (text) => <a>{numberWithCommas(+text)}</a>,
      // editable: true,

      //  width: "20%",
    },
    {
      title: "Edited Amount",
      dataIndex: "editedAmount",
      key: "editedAmount",
      //  width: "20%",
    },

    {
      title: "Approved Revenue Estimates",
      dataIndex: "previousApprovedRevenue",
      editable: true,
      key: "previousApprovedRevenue",
      render: (text) => <a>{numberWithCommas(+text)}</a>,
      // editable: true,

      //  width: "20%",
    },
    // {
    //   title: "Fund Code Main",
    //   dataIndex: "fundCodeMain",
    //   editable: true,
    //   key: "address",
    //   //  width: "20%",
    // },
    // {
    //   title: "Fund Code Details",
    //   dataIndex: "fundCodeDetails",
    //   editable: true,
    //   key: "address",
    //   //  width: "20%",
    // },
    // {
    //   title: "Admin Code",
    //   dataIndex: "adminCode",
    //   editable: true,
    //   key: "address",
    //   //  width: "20%",
    // },
    {
      title: "Actual Revenue as at 30th September, 2023",
      dataIndex: "currentRevenueAsAt",
      editable: true,
      key: "currentRevenueAsAt",
      render: (text) => <a>{numberWithCommas(+text)}</a>,
      // editable: true,

      //  width: "20%",
    },
    {
      title: "Forecast of Actual Revenue up to 31st December (Annualised)",
      dataIndex: "forecastOfCurrentRevenue",
      key: "forecastOfCurrentRevenue",
      editable: true,
      render: (text) => <a>{numberWithCommas(+text)}</a>,
      // editable: true,

      //  width: "20%",
    },

    {
      title: "Actual Revenue 2021",
      dataIndex: "previousGeneratedRevenue",
      editable: true,
      key: "previousGeneratedRevenue",
      render: (text) => <a>{numberWithCommas(+text)}</a>,
      // editable: true,

      //  width: "20%",
    },
    {
      title: "operation",
      dataIndex: "operation",
      render: (_, record) => {
        const editable = isEditing(record);
        return editable ? (
          <span>
            <Typography.Link
              onClick={() => save(record.key)}
              style={{
                marginRight: 8,
              }}
            >
              Save
            </Typography.Link>
            <Popconfirm title="Sure to cancel?" onConfirm={cancel}>
              <a>Cancel</a>
            </Popconfirm>
          </span>
        ) : (
          <Typography.Link
            disabled={editingKey !== ""}
            onClick={() =>  props.isAdminEdit &&edit(record)}
          >
            Edit
          </Typography.Link>
        );
      },
    },
  ];

  useEffect(() => {
    createData();
  }, [props.propzApproved]);

  const createData = () => {
    // console.log(props.propzApproved[0]?.ongoingProjects);
    const ongoingProjectsData = props.propzApproved?.recurrentRevenues?.map(
      (e, idx) => ({
        key: idx,
        id: idx,
        deptBudgetId: e.deptBudgetId,
        economicCode: e.economicCode,
        economicCodeDetails: e.economicCodeDetails,
        fundCodeMain: e.fundCodeMain,
        fundCodeDetails: e.fundCodeDetails,
        adminCode: e.adminCode,
        details: e.details,
        proposedRevenue: e.proposedRevenue,
        availableBudget: e.proposedRevenue,
        previousApprovedRevenue: e.previousApprovedRevenue,
        currentRevenueAsAt: e.currentRevenueAsAt,
        forecastOfCurrentRevenue: e.forecastOfCurrentRevenue,
        previousGeneratedRevenue: e.previousGeneratedRevenue,
        editedAmount: +e?.editedAmount,

        ...e,
      })
    );

    console.log(ongoingProjectsData);
    setData(ongoingProjectsData);
  };

  //  console.log(props.propzApproved);
  const columns = [
    {
      title: "Project",
      dataIndex: "name",
      width: "25%",
      editable: true,
    },
    {
      title: "age",
      dataIndex: "age",
      width: "15%",
      editable: true,
    },
    {
      title: "address",
      dataIndex: "address",
      width: "40%",
      editable: true,
    },
    {
      title: "operation",
      dataIndex: "operation",
      render: (_, record) => {
        const editable = isEditing(record);
        return editable ? (
          <span>
            <Typography.Link
              onClick={() => save(record.key, record)}
              style={{
                marginRight: 8,
              }}
            >
              Save
            </Typography.Link>
            <Popconfirm title="Sure to cancel?" onConfirm={cancel}>
              <a>Cancel</a>
            </Popconfirm>
          </span>
        ) : (
          <Typography.Link
            disabled={editingKey !== ""}
            // onClick={() => edit(record)}
          >
            Edit
          </Typography.Link>
        );
      },
    },
  ];
  const mergedColumns = ongoingProjectsColumn
  .map((item) => {
    if (!props.switching && item?.dataIndex == "proposedRevenue") {
      console.log({ ...item, editable: true })
      return { ...item, editable: true };
    } else return item;
  })
  ?.map((col) => {
    if (!col.editable) {
      return col;
    }
    return {
      ...col,
      onCell: (record) => ({
        record,
        inputType: col.dataIndex === "age" ? "number" : "text",
        dataIndex: col.dataIndex,
        title: col.title,
        editing: isEditing(record),
      }),
    };
  });
  return (
    <Form form={form} component={false}>
      <Table
        components={{
          body: {
            cell: EditableCell,
          },
        }}
        bordered
        dataSource={data}
        columns={mergedColumns}
        rowClassName="editable-row"
        pagination={{
          onChange: cancel,
        }}
      />
    </Form>
  );
};
export default EditableTableO;
