import { Form, Input, InputNumber, Popconfirm, Table, Typography } from "antd";
import moment from "moment";
import React, { useEffect, useState } from "react";
import notification from "../../utility/notification";

const originData = [];
for (let i = 0; i < 100; i++) {
  originData.push({
    key: i.toString(),
    name: `Edrward ${i}`,
    age: 32,
    address: `London Park no. ${i}`,
  });
}

const { TextArea } = Input;
const EditableCell = ({
  editing,
  dataIndex,
  title,
  inputType,
  record,
  index,
  children,
  ...restProps
}) => {
  const inputNode =
    inputType === "number" ? (
      <InputNumber
        formatter={(value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
        parser={(value) => value?.replace(/\$\s?|(,*)/g, "")}
      />
    ) : (
      <TextArea rows={4} />
    );
  return (
    <td {...restProps}>
      {editing ? (
        <Form.Item
          name={dataIndex}
          style={{
            margin: 0,
          }}
          rules={[
            {
              required: true,
              message: `Please Input ${title}!`,
            },
          ]}
        >
          {inputNode}
        </Form.Item>
      ) : (
        children
      )}
    </td>
  );
};
const EnvelopeTable = (props) => {
  const [form] = Form.useForm();
  const [data, setData] = useState([]);
  const [ongoingP, setOngoingP] = useState(originData);
  const [editingKey, setEditingKey] = useState("");
  const isEditing = (record) => record.key === editingKey;

  function numberWithCommas(x) {
    return x?.toString()?.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  }

  const edit = (record) => {
    form.setFieldsValue({
      label: "",
      comment: "",

      ...record,
      amount: 0,
    });
    setEditingKey(record.key);
  };
  const cancel = () => {
    setEditingKey("");
  };
  const save = async (key) => {
    try {
      const row = await form.getFieldsValue();
      console.log(row);
      if (
        (!row.capitalProjects && row.capitalProjects !== 0) ||
        (!row.capitalReceipt && row.capitalReceipt !== 0) ||
        // (!row.ongoingProjects && row.ongoingProjects !== 0) ||
        (!row.overheadCost && row.overheadCost !== 0) ||
        (!row.personnelCost && row.personnelCost !== 0) ||
        (!row.recurrentRev && row.recurrentRev !== 0)
      ) {
        notification({
          title: "Input Value in every field",
          message: "If field not applicable to your MDA input 0",
          type: "danger",
        });
        return;
      }
      console.log(row);
      row.amount =
        +row.capitalProjects +
        +row.capitalReceipt +
        // +row.ongoingProjects +
        +row.overheadCost +
        +row.personnelCost +
        row.recurrentRev;

      console.log(row.amount);

      const newData = [...data];
      const index = newData.findIndex((item) => key === item.key);
      console.log(index);
      if (index > -1) {
        const item = newData[index];
        console.log(newData[index]);
        newData.splice(index, 1, {
          ...item,
          ...row,
        });
        props.setEnvelopeData(newData);
        console.log(newData);

        setData(newData);
        setEditingKey("");
      } else {
        newData.push(row);
        setData(newData);
        setEditingKey("");
        console.log(newData);
      }
    } catch (errInfo) {
      console.log("Validate Failed:", errInfo);
    }
  };

  const ongoingProjectsColumn = [
    {
      title: "MDA",
      dataIndex: "label",
      editable: false,
      key: "mda",
      render: (text) => <a>{text}</a>,
    },
    // {
    //   title: "Ongoing Project-Ceiling",
    //   dataIndex: "ongoingProjects",
    //   editable: true,
    //   key: "ongoingProjects",
    //   // render: (text) => <a>{text}</a>,
    //   render: (text) => <a>₦ {numberWithCommas(text)}</a>,
    // },
    {
      title: "Personel Cost",
      dataIndex: "personnelCost",
      editable: true,
      key: "personnelCost",
      render: (text) => <a>₦ {numberWithCommas(text)}</a>,
    },
    {
      title: "Capital Expenditure",
      dataIndex: "capitalProjects",
      editable: true,
      render: (text) => <a>₦ {numberWithCommas(text)}</a>,
      key: "capitalProjects",
    },
    {
      title: "Capital receipt",
      dataIndex: "capitalReceipt",
      editable: true,
      key: "capitalReceipt",
      render: (text) => <a>₦ {numberWithCommas(text)}</a>,
    },
    {
      title: "Overhead Cost-Monitor",
      dataIndex: "overheadCost",
      editable: true,
      key: "overheadCost",
      render: (text) => <a>₦ {numberWithCommas(text)}</a>,
    },
    {
      title: "Revenue",
      dataIndex: "recurrentRev",
      editable: true,
      render: (text) => <a>₦ {numberWithCommas(text)}</a>,
      key: "recurrentRev",
    },

    {
      title: "Total Amount",
      dataIndex: "amount",
      // editable: true,
      key: "amount",
      render: (text) => <a>₦ {numberWithCommas(text)}</a>,
    },

    // {
    //   title: "Comment",
    //   dataIndex: "comment",
    //   editable: true,
    //   key: "comment",
    // },
    {
      title: "operation",
      dataIndex: "operation",
      render: (_, record) => {
        const editable = isEditing(record);
        return editable ? (
          <span>
            <Typography.Link
              onClick={() => {
                save(record.key);
              }}
              style={{
                marginRight: 8,
              }}
            >
              Save
            </Typography.Link>
            <Popconfirm title="Sure to cancel?" onConfirm={cancel}>
              <a>Cancel</a>
            </Popconfirm>
          </span>
        ) : (
          <Typography.Link
            disabled={editingKey !== ""}
            onClick={() => edit(record)}
          >
            Edit
          </Typography.Link>
        );
      },
    },
  ];

  useEffect(() => {
    createData();
  }, [props.propzApproved]);

  useEffect(() => {
    if (props.toClearTable) {
      setData([]);
    }
  }, [props.toClearTable]);

  const createData = () => {
    // console.log(props.propzApproved[0]?.ongoingProjects);
    const ongoingProjectsData = props.propzApproved;

    // ?.ongoingProjects?.map(
    //   (e) => ({
    //     deptBudgetId: "fd9de34e-86f6-43c1-98ce-998a4812c289",
    //     project: e.project,
    //     status: e.status,
    //     location: e.location,
    //     startDate: moment("2023-10-11T17:08:24.133").format("ll"),
    //     amountSpent: e.amountSpent,
    //     conclusionEstimate: e.conclusionEstimate,
    //     completionTime: e.completionTime,
    //     mdaId: 11,
    //   })
    // );

    console.log(ongoingProjectsData);
    setData(ongoingProjectsData);
  };

  //  console.log(props.propzApproved);

  const mergedColumns = ongoingProjectsColumn.map((col) => {
    if (!col.editable) {
      return col;
    }
    return {
      ...col,
      onCell: (record) => ({
        record,
        inputType: col.dataIndex === "comment" ? "text" : "number",
        dataIndex: col.dataIndex,
        title: col.title,
        editing: isEditing(record),
      }),
    };
  });
  return (
    <Form form={form} component={false}>
      <Table
        components={{
          body: {
            cell: EditableCell,
          },
        }}
        bordered
        dataSource={data}
        columns={mergedColumns}
        rowClassName="editable-row"
        pagination={{
          onChange: cancel,
        }}
      />
    </Form>
  );
};
export default EnvelopeTable;
