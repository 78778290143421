import {
  Form,
  Input,
  InputNumber,
  Modal,
  Popconfirm,
  Table,
  Typography,
  Select,
  Button,
} from "antd";
import moment from "moment";
import React, { useEffect, useState } from "react";
import notification from "../../utility/notification";
import { CloseOutlined } from "@ant-design/icons";
import { get, post, put } from "../../api-services/fetch";

const originData = [];
for (let i = 0; i < 100; i++) {
  originData.push({
    key: i.toString(),
    name: `Edrward ${i}`,
    age: 32,
    address: `London Park no. ${i}`,
  });
}

const { TextArea } = Input;
const { Option } = Select;
const EditableCell = ({
  editing,
  dataIndex,
  title,
  inputType,
  record,
  index,
  children,
  ...restProps
}) => {
  const inputNode =
    inputType === "number" ? (
      <InputNumber
        formatter={(value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
        parser={(value) => value?.replace(/\$\s?|(,*)/g, "")}
      />
    ) : (
      <TextArea rows={4} />
    );
  return (
    <td {...restProps}>
      {editing ? (
        <Form.Item
          name={dataIndex}
          style={{
            margin: 0,
          }}
          rules={[
            {
              required: true,
              message: `Please Input ${title}!`,
            },
          ]}
        >
          {inputNode}
        </Form.Item>
      ) : (
        children
      )}
    </td>
  );
};
const EnvelopeTable = (props) => {
  const [form] = Form.useForm();
  const [data, setData] = useState([]);
  const [approver, setApprover] = useState();
  const [mdaDetails, setmdaDetails] = useState({});
  const [employeeModal, setEmployeeModal] = useState(false);
  const [isLoading, setisLoading] = useState(false);
  const [listOfEmployees, setListOfEmployees] = useState([]);

  const [editingKey, setEditingKey] = useState("");
  const isEditing = (record) => record.key === editingKey;

  function numberWithCommas(x) {
    return x?.toString()?.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  }

  const edit = (record) => {
    form.setFieldsValue({
      label: "",
      comment: "",

      ...record,
      // totalAmount: 0,
    });
    setEditingKey(record.key);
  };
  const cancel = () => {
    setEditingKey("");
  };
  const save = async (key, record) => {
    try {
      const row = await form.getFieldsValue();
      console.log(row);
      if (
        (!row.capitalCost && row.capitalCost !== 0) ||
        // (!row.capitalReceiptCost && row.capitalReceiptCost !== 0) ||
        // (!row.ongoingProjects && row.ongoingProjects !== 0) ||
        (!row.overheadCost && row.overheadCost !== 0) ||
        (!row.personelCost && row.personelCost !== 0) ||
        (!row.totalAmount && row.totalAmount !== 0)
      ) {
        notification({
          title: "Input Value in every field",
          message: "If field not applicable to your MDA input 0",
          type: "danger",
        });
        return;
      }

      updateRecord(row, record);
      // console.log(row);
      // row.totalAmount =
      //   +row.capitalCost +
      //   // +row.capitalReceiptCost +
      //   // +row.ongoingProjects +
      //   +row.overheadCost +
      //   +row.personelCost +
      //   // row.recurrentRevenueCost;

      //   console.log(row.totalAmount);

      const newData = [...data];
      const index = newData.findIndex((item) => key === item.key);
      console.log(index);
      if (index > -1) {
        const item = newData[index];
        console.log(newData[index]);
        newData.splice(index, 1, {
          ...item,
          ...row,
        });
        props.setEnvelopeData(newData);
        console.log(newData);

        setData(newData);
        setEditingKey("");
      } else {
        newData.push(row);
        setData(newData);
        setEditingKey("");
        console.log(newData);
      }
    } catch (errInfo) {
      console.log("Validate Failed:", errInfo);
    }
  };

  const returnStatus = (status) => {
    if (status == 2) return "Pending";
    if (status == 7) return "Approved";
  };

  const handleCancel = () => {
    setEmployeeModal(!employeeModal);
  };

  const ongoingProjectsColumn = [
    {
      title: "MDA",
      dataIndex: "mdaId",
      editable: false,
      key: "mdaId",
      render: (text) => (
        <a>{props.listOfMdas?.find((e) => e.id == +text)?.name}</a>
      ),
    },
    // {
    //   title: "Ongoing Project-Ceiling",
    //   dataIndex: "ongoingProjects",
    //   editable: true,
    //   key: "ongoingProjects",
    //   // render: (text) => <a>{text}</a>,
    //   render: (text) => <a>₦ {numberWithCommas(text)}</a>,
    // },
    {
      title: "Personel Cost",
      dataIndex: "personelCost",
      editable: true,
      key: "personelCost",
      render: (text) => <a>₦ {numberWithCommas(text)}</a>,
    },
    {
      title: "Capital Expenditure",
      dataIndex: "capitalCost",
      editable: true,
      render: (text) => <a>₦ {numberWithCommas(text)}</a>,
      key: "capitalCost",
    },
    // {
    //   title: "Capital receipt",
    //   dataIndex: "capitalReceiptCost",
    //   editable: true,
    //   key: "capitalReceiptCost",
    //   render: (text) => <a>₦ {numberWithCommas(text)}</a>,
    // },
    {
      title: "Overhead Cost",
      dataIndex: "overheadCost",
      editable: true,
      key: "overheadCost",
      render: (text) => <a>₦ {numberWithCommas(text)}</a>,
    },
    // {
    //   title: "Revenue",
    //   dataIndex: "recurrentRevenueCost",
    //   editable: true,
    //   render: (text) => <a>₦ {numberWithCommas(text)}</a>,
    //   key: "recurrentRevenueCost",
    // },

    {
      title: "Total Amount",
      dataIndex: "totalAmount",
      editable: true,
      key: "totalAmount",
      render: (text) => <a>₦ {numberWithCommas(+text)}</a>,
    },

    {
      title: "Status",
      dataIndex: "status",
      // editable: true,
      key: "status",
      render: (text) => <a> {returnStatus(+text)}</a>,
    },
    props.noButton && {
      title: "operation",
      dataIndex: "operation",
      render: (_, record) => {
        const editable = isEditing(record);
        console.log(props.listOfMdas?.find((e) => e.id == record.mdaId)?.name);
        return editable ? (
          <span>
            <Typography.Link
              onClick={() => {
                save(record.key, record);
              }}
              style={{
                marginRight: 8,
              }}
            >
              Save
            </Typography.Link>
            <Popconfirm title="Sure to cancel?" onConfirm={cancel}>
              <a>Cancel</a>
            </Popconfirm>
          </span>
        ) : (
          <div style={{ display: "flex", gap: 4, alignItems: "center" }}>
            <Typography.Link
              disabled={editingKey !== ""}
              onClick={() => edit(record)}
            >
              Edit
            </Typography.Link>
            <Button
              disabled={editingKey !== ""}
              style={{ backgroundColor: "#1C811C", color: "white" }}
              onClick={() => {
                handleCancel();
                setmdaDetails(record);
              }}
            >
              Add PS/Head Of Unit
            </Button>
          </div>
        );
        // <Button
        //   style={{ backgroundColor: "#1C811C", color: "white" }}
        //   onClick={() => {
        //     handleCancel()
        //     setmdaDetails(record);
        //   }}
        // >
        //   Add PS/Head Of Unit
        // </Button>
      },
    },
  ].filter(Boolean);

  useEffect(() => {
    createData();
    getEmployeeList();
    console.log(props.listOfMdas);
  }, [props.envelpeTableData]);

  useEffect(() => {
    if (props.toClearTable) {
      setData([]);
    }
  }, [props.toClearTable]);

  const updateRecord = async (row, record) => {
    setisLoading(true);

    const payload = {
      ...record,
      ...row,
      // overheadCost:'',
      // personelCost:'',
      // totalAmount:''
    };

    console.log(payload);
    console.log(row);

    const res = await put({
      endpoint: `/api/BudgetEnvelope`,
      auth: true,
      body: payload,
    });

    if (res && res.status == 200) {
      notification({
        title: "Success",
        message: "Updated Successfully",
        type: "success",
      });
      setisLoading(false);
      props?.getPendingConfirmationBudgetFunction();
      // getBudgetPeriod();
    } else {
      notification({
        title: "Success",
        message: "Something went wrong",
        type: "success",
      });
    }
  };

  const getEmployeeList = async () => {
    const res = await get({
      endpoint: `employee`,
      auth: true,
      pQuery: { q: "a" },
    });
    console.log(res);

    if (res && res.status === 200) {
      const { data } = res;
      if (res.status === 200) {
        console.log(data);
        setListOfEmployees(data);
      } else {
        notification({
          title: "Error Getting List of Employees",
          message: data.message,
          type: "danger",
        });
      }
    } else {
      notification({
        title: "Network",
        message: "Something has gone wrong. Please, try again.",
        type: "danger",
      });
    }
  };

  const handleEnployeeSearch = async (text) => {
    const res = await get({
      endpoint: "employee",
      auth: true,
      pQuery: { q: text || "e" },
    });
    console.log(res);

    if (res && res.status === 200) {
      const { data } = res;
      if (
        data
        // data.code === 1
      ) {
        console.log(data);
        setListOfEmployees(data);
      } else {
        // notification({
        //   title: "Error Getting List of Employees",
        //   message: data.message,
        //   type: "danger",
        // });
      }
    } else {
      // notification({
      //   title: "Network",
      //   message: "Something has gone wrong. Please, try again.",
      //   type: "danger",
      // });
    }
  };

  const toSendToPSBudget = async () => {
    setisLoading(true);
    let payload = {
      mdaId: mdaDetails?.mdaId,
      periodId: mdaDetails?.projectBatchId,
      approvalStatus: 1,
      approverId: approver.value,
    };

    const res = await post({
      endpoint: "/api/BudgetEnvelope/ConfirmEnvelopeByMda",
      auth: true,
      body: payload,
    });

    if (res && res.status == 200) {
      setisLoading(false);
      notification({
        title: "Success",
        message: "Successfully Sent to PS Budget",
        type: "success",
      });
      props?.getPendingConfirmationBudgetFunction();
      handleCancel();
    } else {
      setisLoading(false);
      notification({
        title: "Error",
        message: "Something went Wrong",
        // message: data.message,
        type: "danger",
      });
    }
  };

  const createData = () => {
    // console.log(props.propzApproved[0]?.ongoingProjects);
    const ongoingProjectsData =
      props?.envelpeTableData &&
      props.envelpeTableData?.map((e, idx) => ({
        key: idx + 1,
        ...e,
      }));

    // ?.ongoingProjects?.map(
    //   (e) => ({
    //     deptBudgetId: "fd9de34e-86f6-43c1-98ce-998a4812c289",
    //     project: e.project,
    //     status: e.status,
    //     location: e.location,
    //     startDate: moment("2023-10-11T17:08:24.133").format("ll"),
    //     totalAmountSpent: e.totalAmountSpent,
    //     conclusionEstimate: e.conclusionEstimate,
    //     completionTime: e.completionTime,
    //     mdaId: 11,
    //   })
    // );

    console.log(ongoingProjectsData);
    setData(ongoingProjectsData);
  };

  //  console.log(props.propzApproved);

  const mergedColumns = ongoingProjectsColumn.map((col) => {
    if (!col.editable) {
      return col;
    }
    return {
      ...col,
      onCell: (record) => ({
        record,
        inputType: col.dataIndex === "comment" ? "text" : "number",
        dataIndex: col.dataIndex,
        title: col.title,
        editing: isEditing(record),
      }),
    };
  });
  return (
    <Form form={form} component={false}>
      <Table
        components={{
          body: {
            cell: EditableCell,
          },
        }}
        bordered
        dataSource={data}
        columns={mergedColumns}
        rowClassName="editable-row"
        pagination={{
          onChange: cancel,
        }}
      />
      <Modal
        width="40%"
        title={props.listOfMdas?.find((e) => e.id == mdaDetails.mdaId)?.name}
        footer={false}
        // okText={<p style={{ maxWidth: "100px" }}>Boss</p>}
        closeIcon={
          <CloseOutlined
            style={{
              fontSize: "25px",
              backgroundColor: "red",
              color: "white",
              padding: "10px",
              marginLeft: "60px",
            }}
          />
        }
        visible={employeeModal}
        onCancel={handleCancel}
      >
        <div>
          {" "}
          <Select
            onChange={(text, index) => {
              setApprover(index);
            }}
            // mode="multiple"
            showSearch
            filterOption={(input, option) =>
              option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
            }
            filterSort={(optionA, optionB) =>
              optionA.children
                .toLowerCase()
                .localeCompare(optionB.children.toLowerCase())
            }
            //   mode="multiple"
            labelInValue
            allowClear
            style={{ width: "100%", border: "" }}
            //   onChange={e => this.setState({ details: e.target.value })}
            placeholder="Please select"
            //   defaultValue={['a10', 'c12']}
            //   onChange={handleChange}
            onSearch={(text, index) => handleEnployeeSearch(text)}
          >
            {Array.isArray(listOfEmployees) &&
              listOfEmployees?.map((obj) => (
                <Option value={obj?.id} key={obj?.id}>{`${obj?.firstName} ${
                  obj?.lastName
                } (${
                  props.listOfMdas?.find((e) => e?.id == obj.mdaId)?.name
                })`}</Option>
              ))}
            {/* {children} */}
          </Select>
          <Button
            loading={isLoading}
            style={{
              backgroundColor: "#1C811C",
              color: "white",
              marginTop: "20px",
            }}
            onClick={toSendToPSBudget}
          >
            Send for PS Budget Approval
          </Button>
        </div>
      </Modal>
    </Form>
  );
};
export default EnvelopeTable;
