// import React from 'react'
// import { Collapse, Form, Input, Button, Checkbox } from 'antd';
// import Tablez from '../layouts/Tablez';
// import BudgetReviewTable from '../layouts/BudgetReviewTable';
// import BudgetDetailsSummary from '../layouts/BudgetDetailsSummary';
// import ItemSetUpTable from '../layouts/ItemSetUpTable';

// const { Panel } = Collapse;

// const BudgetReview = ()=>{

//     const onFinish = (values) => {
//         console.log('Success:', values);
//       };

//       const onFinishFailed = (errorInfo) => {
//         console.log('Failed:', errorInfo);
//       };

// function callback(key) {
//     console.log(key);
//   }
//     return(
//         <div style={{width:'100%'}}>

// <div>2021 Aggregated Budget Review</div>

//       <div className='flex-between'>
//       <Input value={'Select MDA'}/>
//       <div className='flex w-100'>
//           <Button>Excel Export</Button>
//           <Button>Print Document</Button>
//       <Input placeholder={'Select MDA'}/>

//       </div>
//       </div>
//       <BudgetReviewTable/>

// <div className='m-t-10'>
//     Approval Officers comments
// </div>
// <Tablez approvalComments={true}/>

//         </div>
//     )
// }
// export default BudgetReview

import React, { Component } from "react";
// import { Link } from "react-router-dom";
// import { Nav } from "../Components/Nav";
// import { BudgetReviewC } from "../Components/BudgetReviewC";
import cookies from "js-cookie";
// import BudgetReviewC from "../layouts/BudgetReviewC";
import Greeting from "../common/Greeting";
import LocationDetails from "../layouts/LocationDetails";

const BASE_API_URL = "https://edogoverp.com/budget/api/v1";

export default class BudgetReview extends Component {
  constructor(props) {
    super(props);

    this.state = {
      fullName: "",
      role: "",
      time: "",
    };
  }
  componentDidMount() {
    this.getAuthorisedUser();
  }

  getAuthorisedUser = () => {
    // const { cookies } = this.props;
    if (typeof cookies.get("userId") !== "undefined") {
      let authUser = cookies.get("userId");
      // let time = authUser.lastLoginTime.split("T")[1]
      this.setState({
        fullName: authUser.firstName + " " + authUser.lastName,
        role: authUser.role,
        // time: time
      });
    }
  };

  render() {
    return (
      <React.Fragment>
        <main
          class="w-100 relative overflow-y-auto focus:outline-none"
          tabindex="0"
          style={{ outline: "none" }}
        >
          <div className="w-100 flex space-between flex-v-baseline">
            <Greeting />
            <LocationDetails />
          </div>
          <div class=" m-t-20 py-10  max-w-full mx-auto px-2 sm:px-4 md:px-8">
            <div class="flex-between flex-align w-100">
              <div className="flex-1 flex">
                <h1 class=" text-base font-medium text-color mr-10">
                  2021 Aggregated Budget
                </h1>
              </div>
            </div>
            <div className="py-6">
              {/* <BudgetReviewC {...this.props} /> */}
            </div>
          </div>
        </main>
      </React.Fragment>
    );
  }
}
