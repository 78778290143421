/* eslint-disable operator-linebreak */
/* eslint-disable object-curly-newline */
/* eslint-disable comma-dangle */
/* eslint-disable quotes */
/* eslint-disable indent */
/* eslint-disable linebreak-style */
/* eslint-disable array-bracket-spacing */
/* eslint-disable no-empty */
/* eslint-disable keyword-spacing */
/* eslint-disable brace-style */
/* eslint-disable import/order */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable max-len */
/* eslint-disable no-unused-vars */
import React, { useEffect, useState, useRef, useCallback } from "react";
import "../../assets/css/Dashboard.css";
// import AutoComplete from "../inputs/AutoComplete";
import CommentTable from "../layouts/CommentTable";
import { TiPencil } from "react-icons/ti";

// import { AiOutlineRedEnvelope } from 'react-icons/ai';
// import { BiCalendar } from 'react-icons/bi';
// import { HiOutlineUserGroup } from 'react-icons/hi';
// import Calendar from "react-calendar";
// import CardGiftcardIcon from "@material-ui/icons/CardGiftcard";
// import EventNoteIcon from "@material-ui/icons/EventNote";
// import SearchInput from "../inputs/SearchInput";
// import RequestDetails from "../layouts/RequestDetails";
import { get } from "../../api-services/fetch";
// import { newget } from "../../api-services/newfetch";
// import IsLoading from "../common/IsLoading";
import {
  formatSimpleDate,
  formatDate,
  formatDateforPicker,
} from "../../utility/dateTime";
import axios from "axios";

// import BudgetTeamReviewuestForm from "../layouts/BudgetTeamReviewuestForm";
import Greeting from "../common/Greeting";
import LocationDetails from "../layouts/LocationDetails";
// import { formatStatus } from "../../utility/general";
import notification from "../../utility/notification";
import "../../assets/css/calendar.css";
// import useClickOutsideBox from "../hooks/useClickOutsideBox";
// import SelectInputColored from "../inputs/SelectInputColored";
// // import CreateIcon from "@material-ui/icons/Create";
// import DeleteIcon from "@material-ui/icons/Delete";
// import { decodeToken } from "../../utility/auth";
// import RequestQueryForm from "../layouts/QueryForm";
// import QueryThread from "../layouts/QueryThread";
// import Massive from "../layouts/massive";
import GovernorsVIew from "./GovernorsView";
import {
  Table,
  Button,
  Input,
  Modal,
  Select,
  Tooltip,
  Form,
  Card,
  Typography,
} from "antd";

const BudgetTeamReview = ({ history }) => {
  const [name, setName] = useState("");
  const [amount, setAmount] = useState("");
  const [status, setStatus] = useState("");
  const [arrayHolder, setArrayHolder] = useState([]);
  const [envelopHolder, setenvelopHolder] = useState([]);
  const [id, setId] = useState(1);
  const [title, setTile] = useState("");
  const [totalCost, setTotalCost] = useState("");
  const [dateCreated, setDateCreated] = useState("");
  const [onShow, setOnShow] = useState(false);

  const [isLoading, setIsLoading] = useState(false);
  const [details, setDetails] = useState([]);
  const [requestCounts, setRequestCounts] = useState({});
  const [meetingCounts, setMeetingCounts] = useState({});
  const [errorMsg, setErrorMsg] = useState("No Request Yet");
  const [Mda, setMda] = useState([]);
  const [mdaId, setMdaId] = useState([]);
  const [employees, setEmployees] = useState([]);
  const [employeesId, setEmployeesId] = useState([]);
  const [ApproverId, setApproverId] = useState([]);
  const [mailDisplay, setMailDisplay] = useState(true);
  const [mdaDisplay, setMdaDisplay] = useState(false);
  const [category, setCategory] = useState([]);
  const [searchText, setSearchText] = useState("");
  //   const [status, setStatus] = useState("actioned");
  const [ourUrl, setOurUrl] = useState("");
  const [username, setUsername] = useState("");
  const [userId, setUserId] = useState("");
  const [summary, setSummaary] = useState("");
  const [batchId, setBatchId] = useState("");
  const [projectInitiative, setProjectInitiative] = useState("");
  const [onAction, setOnAction] = useState(false);
  const [sectorId, setSectorId] = useState();

  const [envelopeHolder, setEnvelopeHolder] = useState([]);
  const [commentsBudget, setCommentsBudget] = useState([]);
  const [commentsBudgetA, setCommentsBudgetA] = useState([]);
  const [budgetPending, setBudgetPending] = useState([]);

  const [showCalendar, setShowCalendar] = useState(false);
  const [calendarFor, setCalendarFor] = useState("");
  const [calendar, setCalendar] = useState(null);
  const [requestCalendar, setRequestCalendar] = useState(null);
  const [meetingCalendar, setMeetingCalendar] = useState(null);
  const [showQueryForm, setShowQueryForm] = useState(false);
  const [showQueryThread, setShowQueryThread] = useState(false);
  const token =
    "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJVc2VySUQiOiIxIiwiRmlyc3ROYW1lIjoiRWRvIEdvdnYiLCJMYXN0TmFtZSI6IkFkbWluIiwiZXhwIjoxNjIxNzg3NTQ1LCJpc3MiOiJzZXJ2ZXIiLCJhdWQiOiJjbGllbnQifQ.JpkTA-250nWIkIxrOyaLZcbw33fJNXuV7z1tPE9zz0w";
  const wrapperRef = useRef(null);
  const focusRef = useRef();
  const [current, setCurrent] = useState("");
  const [currentId, setCurrentId] = useState("");
  console.log(details);
  console.log(errorMsg);

  const BASE_API_URL = "https://edogoverp.com/budget/api/v1";
  // const BASE_API_URL = 'http://localhost:5000/api/v1';

  useEffect(() => {
    //   getMdas()
    //   getEmployees()

    const r = localStorage.getItem("r");
    let fn = localStorage.getItem("fn");
    let ln = localStorage.getItem("ln");
    let ui = localStorage.getItem("ui");
    let mi = localStorage.getItem("mi");

    // r && setRole(decodeToken('r').r);
    // if (fn && ln) {
    //   fn = decodeToken("fn").fn;
    //   ln = decodeToken("ln").ln;
    //   ui = decodeToken("ui").ui;
    //   mi = decodeToken("mi").mi;
    //   setUsername(`${fn} ${ln}`);
    //   getPending(ui);
    //   // getBudgetPlanningSummary(ui)
    //   // getBudgetPendingComents(ui)
    //   // getPendingBudgetSectors(+ui,)

    //   console.log(ui);
    //   setUserId(ui);
    //   setMdaId(mi);
    // }
  }, []);

  const catchProjectInitiative = (lob) => {
    console.log(lob);
    setProjectInitiative(lob);
  };

  console.log(arrayHolder);

  const removeItem = (id) => {
    console.log(id);
    const temp = arrayHolder.filter((item) => item.idz !== id);

    setArrayHolder(temp);
  };

  const callBatchId = (id) => {
    console.log(id);
    setBatchId(id);

    axios
      .get(`${BASE_API_URL}/BudgetPlanning/GetInitiativesByBatchId`, {
        params: { id },
      })
      .then((response) => {
        console.log(response.data);
        setEnvelopeHolder(response.data);
        // setProjectInitiative(response.data[0].projectInitiative)
        // setEmployees( response.data)
      });
  };

  const toPostEnvelope = () => {
    setIsLoading(true);
    // let poster = arrayHolder.map((a)=>())
    axios
      .post(`${BASE_API_URL}/BudgetPlanning/BudgetEnvelope`, {
        id: batchId,
        dateCreated: dateCreated,
        enteredBy: userId + "",
        dateUpdated: "2023-02-09T05:56:40.680Z",
        updatedBy: userId + "",
        isDelete: false,
        dateDeleted: "2023-02-09T05:56:40.680Z",
        projectBatchId: batchId,
        totalAmount: +totalCost,
        mdaId: +mdaId,
        pId: 0,
      })
      .then((res) => {
        setIsLoading(false);
        setOnAction(false);
        getPending();
        getBudgetPlanningSummary();
        notification({
          title: "Successful",
          message: "Allocated Mda budget has been sent successfully",
          type: "success",
        });
      })

      .catch((error) => {
        if (error.response) {
          // Request made and server responded
          console.log(error.response.data);
          console.log(error.response.title);
          console.log(error.response.headers);
          // setLoading(false)
          notification({
            title: "ERROR MESSAGE",
            message: `
            ${error.response.data}`,
            type: "danger",
            // container:'top-center'
            // animationIn: ['animate__animated', 'animate__slideIn'],
          });

          console.log(error.response);
          setIsLoading(false);

          // notification({
          //   title: '',
          //   message: 'Something went wrong. Please try again',
          //   type: 'danger'
          //             });
          //       })
          // setErrorMessage(error.response.data.title)
          // setError(true)
        } else if (error.request) {
          // setLoading(false)
          // The request was made but no response was received
          console.log(error.request);
        } else {
          // setLoading(false)
          // Something happened in setting up the request that triggered an Error
          console.log("Error", error.message);
        }
      });
  };

  const getPending = (id) => {
    axios
      .get(
        `${BASE_API_URL}/BudgetPlanning/GetPendingInitiativesSummaryByUserId`,
        { params: { id } }
      )
      .then((response) => {
        console.log(response.data);
        setArrayHolder(response.data);
      });
  };

  const getBudgetPendingComents = (id) => {
    axios
      .get(
        `${BASE_API_URL}/CommentPlan/GetPendingCommentsSummaryByApproverId`,
        { params: { id } }
      )
      .then((response) => {
        console.log(response);
        setBudgetPending(response.data);
        // console.log(response.data[0].budgetPlanning)
        // setCommentsBudget(response.data)
        // setCommentsBudgetA(response.data[0]?.budgetPlanning)
      });
  };

  const getPendingBudgetSectors = (userId) => {
    axios
      .get(`${BASE_API_URL}/BudgetPlanning/GetPendingBudgetSectors`, {
        params: { userId },
      })
      .then((response) => {
        console.log(response);
        setSectorId(response.data);

        // console.log(response.data[0].budgetPlanning)
        // setCommentsBudget(response.data)
        // setCommentsBudgetA(response.data[0]?.budgetPlanning)
      });
  };
  console.log(sectorId);

  // console.log(budgetPending)

  const getBudgetPlanningSummary = (id) => {
    axios
      .get(`${BASE_API_URL}/BudgetPlanning/GetBudgetPlanningSummaryByUserId`, {
        params: { id },
      })
      .then((response) => {
        console.log(response.data);
        // console.log(response.data[0].budgetPlanning)
        setCommentsBudget(response.data);
        setCommentsBudgetA(response.data[0]?.budgetPlanning);
      });
  };

  return (
    <div className="w-100">
      {onAction && (
        <div
          className="overlay flex w-100"
          style={{ backgroundColor: "", padding: "20px" }}
        >
          <div className="w-100 m-r-10">
            <h3 className="m-b-20 p-10 bold bg-white">{projectInitiative}</h3>

            <div className="w-100">
              <table className="table-view m-t-10">
                <thead className="table-header">
                  <tr className="table-row">
                    <th className="th" style={{ padding: "20px 10px" }}>
                      <p>Project Name</p>
                    </th>
                    <th className="th" style={{ padding: "20px 10px" }}>
                      <p>Status</p>
                    </th>
                    {/* <th className="th" style={{ padding: "20px 10px" }}>
          <p>Vendor/Source</p>
          </th> */}
                    {/* <th className="th" style={{ padding: "20px 10px" }}>
          <p>Status</p>
          </th> */}
                    {/* <th className="th" style={{ padding: "20px 10px" }}>
          <p>Amount</p>
          </th> */}
                    <th className="th" style={{ padding: "20px 10px" }}>
                      <p>Date Created</p>
                    </th>
                  </tr>
                </thead>
                <tbody className="table-body">
                  {envelopeHolder?.length !== 0 &&
                    envelopeHolder?.map((item) => (
                      <ListItem2
                        key={item.id}
                        detail={item}
                        removeItem={removeItem}
                        // callBatchId={callBatchId}
                        setOnAction={setOnAction}
                        setDateCreated={setDateCreated}
                        // batchId={batchId}
                        // projectInitiative={projectInitiative}

                        // currentId={currentId}
                        // handleDetails={handleDetails}
                      />
                    ))}
                </tbody>
              </table>
            </div>

            <div className="textAlign" style={{ textAlign: "center" }}>
              <p className="bold bg-white m-t-10" style={{ fontSize: "18px" }}>
                Enter Amount available to MDA
              </p>
            </div>
            <div
              className="flex"
              style={{ margin: "auto", justifyContent: "center" }}
            >
              <input
                style={{ textAlign: "center" }}
                className="p-10 m-t-5 bold"
                onChange={(e) => {
                  setTotalCost(e.target.value);
                }}
                value={totalCost}
              />
            </div>
            <div
              className="flex"
              style={{ margin: "auto", justifyContent: "center" }}
            >
              <Button
                loading={isLoading}
                style={{ width: "18%" }}
                className=" m-t-5 pointer"
                onClick={toPostEnvelope}
              >
                Send
              </Button>
            </div>
          </div>
          <div
            onClick={() => {
              setOnAction(false);
            }}
          >
            <p
              className="bold pointer"
              style={{
                backgroundColor: "red",
                padding: "10px",
                fontSize: "26px",
                color: "white",
              }}
            >
              X
            </p>
          </div>
        </div>
      )}

      <div
        className="w-100 flex space-between flex-v-baseline"
        style={{ marginBottom: "50px" }}
      >
        <Greeting />
        <LocationDetails />
      </div>

      {arrayHolder.length == 0 &&
        commentsBudget.length == 0 &&
        budgetPending.length == 0 && (
          <p className="bold" style={{ fontSize: "20px" }}>
            Budget Review
          </p>
        )}

      {arrayHolder.length !== 0 && (
        <div className="w-100">
          <p className="bold" style={{ fontSize: "20px" }}>
            Budget Review Table
          </p>

          <table className="table-view m-t-10">
            <thead className="table-header">
              <tr className="table-row">
                <th className="th" style={{ padding: "20px 10px" }}>
                  <p>Document Title</p>
                </th>
                <th className="th" style={{ padding: "20px 10px" }}>
                  <p>Date Created</p>
                </th>
                {/* <th className="th" style={{ padding: "20px 10px" }}>
          <p>Vendor/Source</p>
          </th> */}
                <th className="th" style={{ padding: "20px 10px" }}>
                  <p>Status</p>
                </th>
                {/* <th className="th" style={{ padding: "20px 10px" }}>
          <p>Amount</p>
          </th> */}
                <th className="th" style={{ padding: "20px 10px" }}>
                  <p>Actions</p>
                </th>
              </tr>
            </thead>
            <tbody className="table-body">
              {arrayHolder.length !== 0 &&
                arrayHolder.map((item) => (
                  <ListItem
                    key={item.id}
                    detail={item}
                    removeItem={removeItem}
                    callBatchId={callBatchId}
                    setOnAction={setOnAction}
                    setDateCreated={setDateCreated}
                    batchId={batchId}
                    catchProjectInitiative={catchProjectInitiative}
                  />
                ))}
            </tbody>
          </table>
        </div>
      )}

      {commentsBudget.length !== 0 && (
        <div className="w-100">
          <p className="bold" style={{ fontSize: "20px" }}>
            Budget Comment Table
          </p>

          <table className="table-view m-t-10">
            <thead className="table-header">
              <tr className="table-row">
                <th className="th" style={{ padding: "20px 10px" }}>
                  <p>Document Title</p>
                </th>
                <th className="th" style={{ padding: "20px 10px" }}>
                  <p>Date Created</p>
                </th>
                {/* <th className="th" style={{ padding: "20px 10px" }}>
          <p>Vendor/Source</p>
          </th> */}
                {/* <th className="th" style={{ padding: "20px 10px" }}>
          <p>Status</p>
          </th> */}
                {/* <th className="th" style={{ padding: "20px 10px" }}>
          <p>Amount</p>
          </th> */}
                <th className="th" style={{ padding: "20px 10px" }}>
                  <p>Actions</p>
                </th>
              </tr>
            </thead>
            <tbody className="table-body">
              <p>{commentsBudget.title}</p>
              {commentsBudget.length !== 0 &&
                commentsBudget.map((item) => (
                  <ListItem3
                    userId={userId}
                    key={item.id}
                    detail={item}
                    removeItem={removeItem}
                    callBatchId={callBatchId}
                    setOnAction={setOnAction}
                    setDateCreated={setDateCreated}
                    batchId={batchId}
                    catchProjectInitiative={catchProjectInitiative}
                  />
                ))}
            </tbody>
          </table>
        </div>
      )}

      {budgetPending.length !== 0 && (
        <div className="w-100">
          <p className="bold" style={{ fontSize: "20px" }}>
            Budget Approvers Table
          </p>

          <table className="table-view m-t-10">
            <thead className="table-header">
              <tr className="table-row">
                <th className="th" style={{ padding: "20px 10px" }}>
                  <p>Document Title</p>
                </th>
                <th className="th" style={{ padding: "20px 10px" }}>
                  <p>Date Created</p>
                </th>
                {/* <th className="th" style={{ padding: "20px 10px" }}>
          <p>Vendor/Source</p>
          </th> */}
                {/* <th className="th" style={{ padding: "20px 10px" }}>
          <p>Status</p>
          </th> */}
                {/* <th className="th" style={{ padding: "20px 10px" }}>
          <p>Amount</p>
          </th> */}
                <th className="th" style={{ padding: "20px 10px" }}>
                  <p>Actions</p>
                </th>
              </tr>
            </thead>
            <tbody className="table-body">
              <p>{commentsBudget.title}</p>
              {budgetPending.length !== 0 &&
                budgetPending.map((item) => (
                  <ListItem3
                    userId={userId}
                    key={item.id}
                    detail={item}
                    removeItem={removeItem}
                    callBatchId={callBatchId}
                    setOnAction={setOnAction}
                    setDateCreated={setDateCreated}
                    batchId={batchId}
                    catchProjectInitiative={catchProjectInitiative}
                    higherApprover={true}
                  />
                ))}
            </tbody>
          </table>
        </div>
      )}

      {sectorId && sectorId.length !== 0 && (
        <div
          onClick={() => {
            setOnShow(!onShow);
          }}
          className="p-10 pointer m-5 border"
        >
          Governors VIew
        </div>
      )}
      {onShow && <GovernorsVIew gov={sectorId} userId={userId} />}
    </div>
  );
};

const ListItem = ({
  detail,
  callBatchId,
  setOnAction,
  setDateCreated,
  batchId,
  catchProjectInitiative,
  handleDetails,
  current,
  removeItem,
}) => {
  const [itemDetails, setItemDetails] = useState(detail || {});
  const [showDetails, setShowDetails] = useState(false);

  return (
    <>
      <tr
        className={`${showDetails && "active"}  ${
          itemDetails.id
        } table-row pointer`}
        onClick={() => {
          setShowDetails(!showDetails);
          callBatchId(detail.batchId);
          catchProjectInitiative(itemDetails.title);
          console.log(itemDetails.title);
          // setProjectInitiative(detail.title)
        }}
        role="presentation"
      >
        <td className="td p-r" style={{ padding: "20px 10px" }}>
          {itemDetails.title}
          {/* {showDetails && (
            <IoMdClose
              className="dropdown-close-btn pointer"
              onClick={() => setShowDetails(!showDetails)}
            />
          )} */}
        </td>
        <td className="td p-r" style={{ padding: "20px 10px" }}>
          {formatDate(itemDetails.dateCreated)}
        </td>
        {/* <td className="td" style={{ padding: "20px 10px" }}>
          {itemDetails.vendor}
        </td> */}
        <td className="td p-r" style={{ padding: "20px 10px" }}>
          <div className={`status ${"pending"}-status`}>
            {detail.status || "Pending"}
          </div>
        </td>
        {/* <td className="td" style={{ padding: "20px 10px" }}>
          {(detail.amount)}
        </td> */}
        <td
          className="td p-r"
          onClick={() => {
            setDateCreated(detail.dateCreated);
            callBatchId(detail.batchId);
            setOnAction(true);
          }}
          style={{
            display: "flex",
            right: "0",
            justifyContent: "flex-end",
            padding: "20px 10px",
          }}
        >
          <span
            style={{
              border: "1px solid white",
              height: "30px",
              width: "30px",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              // backgroundColor: "#66ff99",
            }}
          >
            {/* {" "} */}
            <TiPencil
              style={{
                backgroundColor: "#66ff99",
                fontSize: "30px",
                padding: "5px",
              }}
            />
            {/* <CreateIcon style={{ color: "green" }} /> */}
          </span>
        </td>
      </tr>
      {showDetails && (
        <tr className="table-row bg-light-gray">
          <td colSpan={5}>
            {/* <RequestDetails
              handleDetails={handleDetails}
              current={current}
              currentId={currentId}
              requestId={itemDetails.id}
              updateList={updateInfo}
            /> */}
          </td>
        </tr>
      )}
    </>
  );
};

const ListItem2 = ({
  detail,
  callBatchId,
  setOnAction,
  setDateCreated,
  batchId,
  projectInitiative,
  handleDetails,
  current,
  removeItem,
}) => {
  const [itemDetails, setItemDetails] = useState(detail || {});
  const [showDetails, setShowDetails] = useState(false);

  //   const updateInfo = (newItem) => {
  //     setItemDetails(newItem || {});
  //   };

  return (
    <>
      <tr
        className={`${showDetails && "active"}  ${
          itemDetails.id
        } table-row pointer`}
        // onClick={() => {
        //     setShowDetails(!showDetails)
        //     callBatchId(detail.batchId)

        // }}
        role="presentation"
      >
        <td className="td p-r" style={{ padding: "20px 10px" }}>
          {itemDetails.projectInitiative}
          {/* {showDetails && (
            <IoMdClose
              className="dropdown-close-btn pointer"
              onClick={() => setShowDetails(!showDetails)}
            />
          )} */}
        </td>

        {/* <td className="td" style={{ padding: "20px 10px" }}>
          {itemDetails.vendor}
        </td> */}
        <td className="td p-r" style={{ padding: "20px 10px" }}>
          <div className={`status ${detail.status}-status`}>
            {detail.status || "Pending"}
          </div>
        </td>

        <td className="td p-r" style={{ padding: "20px 10px" }}>
          {formatDate(itemDetails.dateCreated)}
        </td>
        {/* <td className="td" style={{ padding: "20px 10px" }}>
          {(detail.amount)}
        </td> */}
      </tr>
    </>
  );
};

const ListItem3 = ({
  detail,
  userId,
  higherApprover,
  callBatchId,
  setOnAction,
  setDateCreated,
  batchId,
  projectInitiative,
  handleDetails,
  current,
  removeItem,
}) => {
  const [itemDetails, setItemDetails] = useState(detail || {});
  const [showDetails, setShowDetails] = useState(false);
  const [comentTable, setCommentTable] = useState(false);

  //   const updateInfo = (newItem) => {
  //     setItemDetails(newItem || {});
  //   };
  const closeModal = (close) => {
    console.log(close);
    setCommentTable(close);
  };

  return (
    <>
      {comentTable && (
        <div className="overlay w-60">
          <CommentTable
            higherApprover={higherApprover}
            closeModal={closeModal}
            userIdz={userId}
            project={detail.budgetPlanning}
            projectParams={detail}
          />
        </div>
      )}

      <tr
        className={`${showDetails && "active"}  ${
          itemDetails.id
        } table-row pointer`}
        // onClick={() => {
        //     setShowDetails(!showDetails)
        //     callBatchId(detail.batchId)

        // }}
        role="presentation"
      >
        <td className="td p-r" style={{ padding: "20px 10px" }}>
          {itemDetails.title || itemDetails.title}
          {/* {showDetails && (
            <IoMdClose
              className="dropdown-close-btn pointer"
              onClick={() => setShowDetails(!showDetails)}
            />
          )} */}
        </td>

        {/* <td className="td" style={{ padding: "20px 10px" }}>
          {itemDetails.vendor}
        </td> */}
        {/* <td className="td p-r" style={{ padding: "20px 10px" }}>
          <div className={`status ${detail.status}-status`}>
            {(detail.status||'Pending')}
          </div>
        </td> */}

        <td className="td p-r" style={{ padding: "20px 10px" }}>
          {formatDate(itemDetails.dateCreated)}
        </td>
        {/* <td className="td" style={{ padding: "20px 10px" }}>
          {(detail.amount)}
        </td> */}
        <td
          style={{
            display: "flex",
            right: "0",
            justifyContent: "flex-end",
            padding: "20px 10px",
          }}
        >
          <span
            style={{
              border: "1px solid white",
              height: "30px",
              width: "30px",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              // backgroundColor: "#ff8566",
            }}
            onClick={() => {
              setCommentTable(true);
            }}
          >
            <TiPencil
              style={{
                backgroundColor: "#66ff99",
                fontSize: "30px",
                padding: "5px",
              }}
            />
            {/* View <DeleteIcon style={{ color: "green" }} /> */}
          </span>
        </td>
      </tr>
    </>
  );
};

export default BudgetTeamReview;
