// import React from 'react';
// import logo from './logo.svg';
// import './App.css';
// import RequestMaintenance from './pages/RequestMaintenance';

// import MaintenanceRequestManagement from './pages/MaintenanceRequestManagement';
// import Header1 from './components/Header1';

import React, { useContext, useEffect, useRef, useState } from "react";
import {
  Table,
  Input,
  Button,
  Select,
  Form,
  Space,
  Alert,
  InputNumber,
  DatePicker,
  Upload,
  message,
} from "antd";
// import Page37Form from "./Page37Form";
// import Page25Form from "./Page25Form";
// import ApprovalJourney from "./ApprovalJourney";
// import Imagez from "./Imagez";
// import { Steps, Divider } from "antd";
// import {
//   UserOutlined,
//   SolutionOutlined,
//   UploadOutlined,
//   SmileOutlined,
// } from "@ant-design/icons";
// import ItemRequest from "./ItemRequest";
import Cookies from "universal-cookie";

// import { SwapTableContext } from "./Contexts/SwapTableContext";

const cookie = new Cookies();
function UploadButton(prop) {
  //   const {
  //     handleImage,
  //     imageHolder,
  //     handleMfilesArray,
  //     mfilesArray,
  //     holdMfilesArray,
  //   } = useContext(SwapTableContext);

  // const [fileList, setFileList] = useState([])

  let arrayz = [];
  const [infoz, setinfoz] = useState([]);
  let [count, setCount] = useState(1000);
  let token = cookie.get("token");

  const props = {
    name: "file",
    action: "https://edogoverp.com/services/api/documents/admin-stores",

    headers: {
      authorization: `Bearer ${token}`,
    },

    // beforeUpload: file => {
    //   if (file.type !== 'image/png') {
    //     message.error(`${file.name} is not a png file`);
    //   }
    //   return file.type === 'image/png' ? true : Upload.LIST_IGNORE;
    // },

    onChange(info) {
      setinfoz(info);
      if (info.file.status !== "uploading") {
        // console.log(info.file, info.fileList);
      }
      if (info.file.status === "done") {
        const it = message?.success(
          `${info.file.name} file uploaded successfully`
        );
        // handleMfilesArray({
        //   key: count++,
        //   name: info.file.name,
        //   image: info.fileList[info.fileList.length - 1].response.doclink,
        // });
        let a = prop?.handleDocAdd({
          key: count++,
          name: info.file.name,
          image: info.fileList[info.fileList.length - 1].response.doclink,
        });
        setCount(count);
        // handleImage(info.file.name, info.fileList[0].response.doclink)
        // if (handleImage.doclink == "") {
        //   console.log(handleImage);
        //   // arrayz.push(handleImage)
        // }
        console.log(info.fileList[0].response.doclink);
        console.log(info.file.name);
        //  console.log(arrayz);
      } else if (info.file.status === "error") {
        message.error(`${info.file.name} file upload failed.`);
      }
    },
  };
  console.log(infoz);

  return (
    <div style={{ width: "100%", borderRadius: "10px" }}>
      <Upload {...props} showUploadList={false}>
        <Button style={{ backgroundColor: "#EFD66BF2", borderRadius: "22px" }}>
          Click to Upload
        </Button>
      </Upload>
      {/* <Upload >
                <Button  className="border buttonz" style={{ borderTopRightRadius: "20px", borderBottomRightRadius: "20px", borderTopLeftRadius: "20px", borderBottomLeftRadius: "20px", backgroundColor:"#EFD66BF2", color:"black" }} block icon={<UploadOutlined style={{margin:"none", fontSize:"10px"}} />}> {prop.p}</Button>
            </Upload> */}
    </div>
  );
}

export default UploadButton;
