import React, { Component } from "react";

import notification from "../../utility/notification";

import { Preloader } from "../../Utils/Preloader";
import { store } from "react-notifications-component";
import { Input } from "antd";
import {
  formatDefaultDate,
  formatDefaultDateForInput,
  nullcheck,
  notify,
} from "../../Utils/Helper";
import { BsPencilSquare } from "react-icons/bs";
import { AiOutlineDelete } from "react-icons/ai";
import ValidationMessageComponent from "./ValidationMessage";

import budgetPeriodSchema from "../../Validation/BudgetPeriodValidate";
import axios from "axios";
// const BASE_API_URL = process.env.REACT_APP_BASE_API_URL;
import cookies from "js-cookie";
import Cookies from "js-cookie";
import { del, get, post, put } from "../../api-services/fetch";
const BASE_API_URL = "https://edogoverp.com/newbudgetapi/api";

// const {Option} = Select

export default class BudgetPeriodActivation extends Component {
  constructor(props) {
    super(props);
    this.state = {
      open: true,
      periodData: [],
      title: "",
      startDate: "",
      endDate: "",
      year: "",
      isCurrent: "false",
      loading: false,
      loader: false,
      deleteId: "",
      edit: false,
      editId: "",
      message: "",
      showNotification: false,
      notificationSuccess: false,
      notificationMessage: "Successfully saved!",
      deleteModal: false,
      type: "",
      notifymessage: "",
      search: "",
      headers: {
        Authorization: Cookies.get("token"), // Replace with your actual token or header
        "Content-Type": "application/json", // Adjust content type as needed
        // Add any other headers as necessary
      },
    };
  }

  componentDidMount() {
    this.getBudgetPeriod();
  }

  handleChange = (ev) => {
    this.setState({
      [ev.target.name]: ev.target.value,
    });
  };
  handleYearChange = (ev) => {
    const re = /^[0-9\b]+$/;
    if (ev.target.value === "" || re.test(ev.target.value)) {
      if (ev.target.value.length <= 4) {
        this.setState({
          [ev.target.name]: ev.target.value,
        });
      } else if (ev.target.value.length > 4) {
        this.setState({
          [ev.target.name]: ev.target.value.substring(0, 4),
        });
      }
    } else {
      this.setState({
        [ev.target.name]: "1990",
      });
    }
  };

  handleSubmit = async () => {
    let title = this.state.title;
    let startDate = this.state.startDate;
    let endDate = this.state.endDate;
    let year = this.state.year;
    let isCurrent = this.state.isCurrent === "true";
    let enteredBy = this.props.employeeId.toString();

    // budgetPeriodSchema.is(data)
    let data = { title, startDate, endDate, year, isCurrent, enteredBy };

    budgetPeriodSchema
      .validate(data)
      .then(async (message) => {
        this.setState({
          loader: true,
          message: "",
        });
        // axios
        //   .post(`${BASE_API_URL}/Settings/BudgetPeriod`, {...this.state.headers }, { ...data })
        //   .then((response) => {
        const res = await post({
          endpoint: `/api/Settings/BudgetPeriod`,
          auth: true,
          body: data,
        });

        if (res && res.status == 200) {
          this.setState({
            loader: false,
            message: "",
            title: "",
            startDate: "",
            endDate: "",
            year: "",
            isCurrent: "false",
          });
          // notify(store, 'success', 'budget added successfully');
          notification({
            title: "Successful",
            message: "budget added successfully",
            type: "success",
          });
          this.getBudgetPeriod();
        }
        // .catch((err) => {
        //   this.setState({
        //     loader: false,
        //     message:
        //       nullcheck(err.response) == ""
        //         ? "server error"
        //         : err.response.data,
        //     showNotification: true,
        //     notificationMessage: err.message,
        //   });
        // });
      })
      .catch((err) => {
        this.setState({
          message: err.errors,
        });
      });
  };

  handleDelete = async () => {
    let id = this.state.deleteId;

    this.setState({
      loader: true,
      message: "",
      deleteModal: false,
      deleteId: "",
    });
    // axios
    //   .delete(
    //     `${BASE_API_URL}/Settings/DeleteBudgetPeriod?id=${id}`,
    //     this.state.headers
    //   )
    //   .then((response) => {
    const res = await del({
      endpoint: `/api/Settings/DeleteBudgetPeriod?id=${id}`,
      auth: true,
      //  body: payload,
    });

    if (res && res.status == 200) {
      this.setState({
        loader: false,
        message: "",
        title: "",
        startDate: "",
        endDate: "",
        year: "",
        editId: "",
        Id: "",
        deleteModal: false,
        deleteId: "",
      });
      this.getBudgetPeriod();
      // notify(store, 'success', 'budget deleted successfully');
      notification({
        title: "Successful",
        message: "budget deleted successfully",
        type: "success",
      });
    }
    // .catch((err) => {
    //   this.setState({
    //     message:
    //       nullcheck(err.response) == "" ? "server error" : err.response.data,
    //     deleteModal: false,
    //     deleteId: "",
    //   });
    // });
  };

  handleEdit = (data) => {
    console.log(formatDefaultDateForInput(data.startDate));
    this.setState({
      title: data.title,
      startDate: formatDefaultDateForInput(data.startDate),
      endDate: formatDefaultDateForInput(data.endDate),
      year: data.year,
      edit: true,
      editId: data.id,
      isCurrent: data.isCurrent === true ? "true" : "false",
    });
  };

  handleEditSubmit = () => {
    let title = this.state.title;
    let startDate = this.state.startDate;
    let endDate = this.state.endDate;
    let year = this.state.year;
    let id = this.state.editId;
    let isCurrent = this.state.isCurrent === "true";
    let updatedBy = this.props.employeeId.toString();

    let data = { title, startDate, endDate, year, id, isCurrent, updatedBy };
    console.log(data);

    budgetPeriodSchema
      .validate(data)
      .then(async (message) => {
        this.setState({
          loader: true,
          message: "",
        });
        // axios
        //   .put(`${BASE_API_URL}/Settings/UpdateBudgetPeriod`, this.state.headers, {
        //     ...data,
        //   })
        //   .then((response) => {
        const res = await put({
          endpoint: `/api/Settings/UpdateBudgetPeriod`,
          auth: true,
          body: data,
        });

        if (res && res.status == 200) {
          this.setState({
            loader: false,
            message: "",
            title: "",
            startDate: "",
            endDate: "",
            year: "",
            editId: "",
            edit: false,
          });
          this.getBudgetPeriod();
          // notify(store, 'success', 'budget updated successfully');
          notification({
            title: "Successful",
            message: "budget updated successfully",
            type: "success",
          });
        }
        // .catch((err) => {
        //   console.log(err);
        //   //this.setState({
        //   //    loader: false,
        //   //    message: nullcheck(err.response) == "" ? "server error" : err.response.data,
        //   //});
        // });
      })
      .catch((err) => {
        this.setState({
          loader: false,
          message: err.errors,
        });
      });
  };

  getBudgetPeriod = async () => {
    this.setState({
      loader: true,
      message: "",
    });
    // axios
    //   .get(`${BASE_API_URL}/Settings`, this.state.headers)
    //   .then((response) => {
    const res = await get({
      endpoint: `/api/Settings`,
      auth: true,
      //  body: payload,
    });

    if (res && res.status == 200) {
      this.setState({
        periodData: res.data,
        loader: false,
        message: "",
      });
    }
    // .catch((err) => {
    //   this.setState({
    //     loader: false,
    //     message:
    //       nullcheck(err.response) == "" ? "server error" : err.response.data,
    //   });
    // });
  };

  render() {
    let periodDatas = this.state.periodData.filter((periodData) => {
      return (
        periodData.title
          .toLowerCase()
          .indexOf(this.state.search.toLowerCase()) !== -1
      );
    });

    let allPeriodData = periodDatas.map((periodData) => {
      let startDate = new Date(periodData.startDate).toDateString();

      let endDate = new Date(periodData.endDate).toDateString();
      return (
        <tr
          style={{
            borderBottom: "1px solid #C7C7C7",
          }}
        >
          <td class="px-6 py-4 whitespace-nowrap">
            <div
              className="bg-pen "
              onClick={() => this.handleEdit(periodData)}
            >
              <BsPencilSquare
                style={{
                  backgroundColor: "#43425D",
                  color: "white",
                  fontSize: "26px",
                  padding: "5px",
                }}
              />
              {/* <svg
                                width="18"
                                height="18"
                                xmlns="http://www.w3.org/2000/svg"
                                fill="none"
                                viewBox="0 0 24 24"
                                stroke="#fff"
                            >
                                <path
                                    stroke-linecap="round"
                                    stroke-linejoin="round"
                                    stroke-width="2"
                                    d="M15.232 5.232l3.536 3.536m-2.036-5.036a2.5 2.5 0 113.536 3.536L6.5 21.036H3v-3.572L16.732 3.732z"
                                />
                            </svg> */}
            </div>
          </td>
          <td class="px-6 py-4 text-sm font-avenir-medium table-color">
            {periodData.title}
          </td>
          <td class=" text-sm font-avenir-medium table-color">
            {startDate.substring(8, 10) +
              "-" +
              startDate.substring(4, 7) +
              "-" +
              startDate.substring(11, 15)}
          </td>
          <td class="px-6 py-4 text-sm font-avenir-medium table-color">
            {endDate.substring(8, 10) +
              "-" +
              endDate.substring(4, 7) +
              "-" +
              endDate.substring(11, 15)}
          </td>
          <td class="px-6 py-4 text-sm font-avenir-medium table-color">
            {periodData.year}
          </td>
          <td class="px-6 py-4 text-sm font-avenir-medium table-color">
            {periodData.isCurrent ? "Active" : "Not Active"}
          </td>
          <td class="px-6 py-4 text-sm font-avenir-medium">
            <a
              className="cursor-pointer"
              onClick={() =>
                this.setState({
                  deleteModal: !this.state.deleteModal,
                  deleteId: periodData.id,
                })
              }
            >
              {/* <img
                                src="/delete-icon.png"
                                className="ml-5 h-5 w-5"
                            /> */}
              <AiOutlineDelete
                style={{
                  backgroundColor: "#43425D",
                  color: "white",
                  fontSize: "26px",
                  padding: "5px",
                }}
              />
            </a>
          </td>
        </tr>
      );
    });
    return (
      <React.Fragment>
        {this.state.loader === true ? <Preloader /> : ""}
        {this.state.deleteModal && (
          <>
            <div className="overlay center-delete p-10 justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
              <div className="relative w-3/12 my-6 mx-auto bg-white p-8 delete-card">
                {/*content*/}
                <div className=" py-4 px-4 shadow-sm overflow-hidden cursor-pointer">
                  <p className="flex-1 mt-2 text-sm font-medium font-avenir-black text-color text-center">
                    Are you sure you want to delete?
                  </p>
                </div>
                <div class="p-10 m-10 flex justify-center">
                  <button
                    onClick={() => this.handleDelete()}
                    type="submit"
                    class="pointer delete-button green-color mr-5 w-40 inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-bold text-white primary-bg-green focus:outline-none"
                  >
                    Yes
                  </button>
                  <button
                    type="button"
                    class="pointer delete-button w-40 bg-white py-2 px-4 border border-gray-300 shadow-sm text-sm font-medium text-color bg-gray-200 hover:bg-gray-50 focus:outline-none"
                    onClick={() =>
                      this.setState({
                        deleteModal: !this.state.deleteModal,
                      })
                    }
                  >
                    No
                  </button>
                </div>
              </div>
            </div>
            <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
          </>
        )}

        {this.state.periodData ? (
          <React.Fragment>
            {this.state.open ? (
              <div className="mb-3 p-10">
                <div
                  className=" pointer m-b-5  bg-card-header py-4 px-4 shadow-sm overflow-hidden bb-green cursor-pointer"
                  onClick={() => {
                    this.getBudgetPeriod();
                    this.setState({ open: !this.state.open });
                  }}
                >
                  <div class="flex-between">
                    <div class="">
                      <p className="text-sm font-medium font-avenir-black text-color">
                        Budget Period Activation
                      </p>
                    </div>

                    <Input
                      placeholder="Search"
                      className="border border-solid px-2 h-8 w-50 mt-2"
                      style={{
                        backgroundColor: "transparent",
                        borderColor: "#707070",
                        width: "25%",
                      }}
                      name="search"
                      onClick={(e) => e.stopPropagation()}
                      onChange={this.handleChange}
                    />
                  </div>
                </div>

                <div className="w-100 p-10 flex bg-white py-4 pl-4 shadow-sm cursor-pointer">
                  <div className="w-40 w-80 mr-10">
                    <div class="m-t-5 m-r-5 grid grid-cols-1 gap-y-6 gap-x-4 grid-cols-6">
                      <div class="">
                        {this.state.message !== "" ? (
                          <ValidationMessageComponent
                            message={this.state.message}
                          ></ValidationMessageComponent>
                        ) : (
                          // <p>gggg</p>
                          ""
                        )}

                        <label
                          for="title"
                          class="w-100 block text-sm font-medium label-color"
                        >
                          Title
                        </label>
                        <div class="w-100 mt-1">
                          <Input
                            type="text"
                            name="title"
                            id="title"
                            value={this.state.title}
                            class="w-100 shadow-sm focus:outline-none block w-full text-sm"
                            onChange={this.handleChange}
                          />
                        </div>
                      </div>
                      <div class="w-100 m-t-10 col-span-12">
                        <label
                          for="startDate"
                          class="block text-sm font-medium label-color"
                        >
                          Start Date
                        </label>
                        <div class="w-100 mt-1">
                          <Input
                            type="date"
                            name="startDate"
                            id="startDate"
                            value={this.state.startDate}
                            class="shadow-sm focus:outline-none block w-full text-sm"
                            onChange={this.handleChange}
                          />
                        </div>
                      </div>
                      <div class="w-100 col-span-12">
                        <label
                          for="endDate"
                          class="block text-sm font-medium label-color"
                        >
                          End Date
                        </label>
                        <div class="mt-1">
                          <Input
                            type="date"
                            name="endDate"
                            id="endDate"
                            value={this.state.endDate}
                            class="shadow-sm focus:outline-none block w-full text-sm"
                            onChange={this.handleChange}
                          />
                        </div>
                      </div>
                      <div class="w-100 col-span-12">
                        <label
                          for="year"
                          class="block text-sm font-medium label-color"
                        >
                          Budget Year
                        </label>
                        <div class="mt-1">
                          <Input
                            type="text"
                            name="year"
                            id="year"
                            value={this.state.year}
                            class="shadow-sm focus:outline-none block w-full text-sm"
                            onChange={this.handleYearChange}
                          />
                        </div>
                      </div>
                      <div class="w-100 col-span-12">
                        <label
                          for="year"
                          class="block text-sm font-medium label-color"
                        >
                          Is Current
                        </label>
                        <div class="w-100 mt-1">
                          <select
                            name="isCurrent"
                            id="isCurrent"
                            style={{ minwidth: "200px", padding: "7px" }}
                            value={this.state.isCurrent}
                            // class="shadow-sm focus:outline-none block w-full text-sm"
                            onChange={this.handleChange}
                          >
                            <option value="false">False</option>
                            <option value="true">True</option>
                          </select>
                        </div>
                      </div>
                      <div class="pt-5 col-span-12 m-t-10">
                        <div class="flex">
                          <button
                            type="submit"
                            class="mr-5 w-40 inline-flex delete-button green-color  justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-bold text-white primary-bg-green focus:outline-none"
                            onClick={
                              this.state.edit
                                ? this.handleEditSubmit
                                : this.handleSubmit
                            }
                          >
                            Save Record
                          </button>
                          <button
                            onClick={() => {
                              this.setState({
                                title: "",
                                startDate: "",
                                endDate: "",
                                year: "",
                              });
                            }}
                            type="button"
                            class="w-40 bg-white delete-button cursor-pointer py-2 px-4 border border-gray-300 shadow-sm text-sm font-medium text-color bg-gray-200 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                          >
                            Cancel
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div
                    className="w-4/6  w-100 md:flex-grow p-10"
                    style={{ marginTop: "-9px" }}
                  >
                    <div class="flex  w-100 flex-col">
                      <div class="-my-2   w-100 overflow-y-scroll">
                        <table class="min-w-full  w-100">
                          <thead class="bg-table-header">
                            <tr>
                              <th
                                scope="col"
                                class="px-6 py-3 text-left text-sm font-avenir-medium text-white tracking-wider"
                              >
                                <svg
                                  version="1.0"
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="20"
                                  height="20"
                                  viewBox="0 0 1278.000000 1280.000000"
                                  preserveAspectRatio="xMidYMid meet"
                                >
                                  <g
                                    transform="translate(0.000000,1280.000000) scale(0.100000,-0.100000)"
                                    fill="#fff"
                                    stroke="none"
                                  >
                                    <path
                                      d="M5565 12793 c-437 -20 -955 -111 -1410 -249 -859 -261 -1717 -753
                                        -2352 -1348 -51 -47 -95 -86 -97 -86 -3 0 -122 149 -266 330 -144 182 -282
                                        347 -307 368 -24 20 -68 60 -98 88 -74 70 -128 94 -212 94 -114 0 -208 -61
                                        -264 -169 -33 -64 -36 -151 -43 -1161 -4 -481 -11 -1322 -17 -1869 -5 -546 -8
                                        -995 -6 -997 4 -5 -24 -16 1912 766 270 109 738 298 1040 420 303 122 569 233
                                        591 246 53 30 90 74 122 143 33 72 33 152 -2 262 -29 95 -66 146 -130 184 -36
                                        21 -584 207 -858 292 -49 15 -88 30 -88 33 0 14 236 191 377 283 309 200 595
                                        339 927 449 394 131 768 200 1181 217 512 22 983 -45 1478 -210 372 -123 711
                                        -285 1020 -485 54 -35 100 -64 102 -64 3 0 16 18 30 40 l26 41 79 -56 79 -56
                                        34 53 c231 364 837 1361 831 1367 -13 12 -246 160 -334 213 -431 259 -963 489
                                        -1460 632 -642 185 -1242 258 -1885 229z"
                                    />
                                    <path
                                      d="M9310 10957 c0 -8 -16 -125 -35 -259 -36 -246 -187 -1284 -295 -2033
                                        -132 -913 -161 -1115 -181 -1249 -30 -199 -29 -257 6 -329 34 -68 78 -110 152
                                        -144 105 -48 201 -40 295 25 24 17 198 172 387 346 190 173 346 314 347 313 2
                                        -1 10 -47 18 -102 98 -641 32 -1341 -189 -1985 -292 -851 -810 -1542 -1558
                                        -2078 -146 -105 -276 -186 -464 -290 l-152 -84 19 -39 c11 -21 20 -42 20 -46
                                        0 -5 -34 -25 -76 -46 -41 -21 -74 -41 -72 -45 6 -15 731 -1357 750 -1389 l19
                                        -33 62 32 c391 197 862 515 1227 826 792 678 1366 1481 1720 2407 269 705 400
                                        1415 400 2175 0 429 -36 796 -117 1190 -20 96 -38 187 -40 201 l-5 26 264 42
                                        c521 83 548 89 623 125 39 18 90 39 115 46 109 30 184 99 215 196 34 107 -4
                                        239 -88 308 -36 29 -3302 1884 -3349 1902 -12 4 -18 1 -18 -9z"
                                    />
                                    <path
                                      d="M4 7453 c-4 -49 -4 -214 0 -368 22 -980 241 -1847 696 -2755 431
                                        -860 1128 -1639 1965 -2197 435 -289 894 -512 1450 -704 91 -32 139 -53 137
                                        -61 -2 -7 -73 -195 -159 -418 -173 -450 -183 -480 -183 -564 0 -76 26 -131 88
                                        -186 26 -23 72 -66 103 -96 96 -92 206 -124 311 -89 58 19 3339 1905 3335
                                        1918 -6 19 -2978 2350 -3024 2372 -102 49 -253 26 -329 -50 -14 -14 -47 -36
                                        -73 -49 -54 -28 -116 -92 -137 -142 -28 -68 -18 -148 77 -580 50 -229 88 -418
                                        85 -421 -7 -7 -353 163 -481 237 -626 361 -1171 883 -1561 1497 -363 571 -573
                                        1185 -660 1928 -14 113 -18 231 -18 476 l-1 326 -149 7 c-82 3 -445 6 -807 6
                                        l-657 0 -8 -87z"
                                    />
                                  </g>
                                </svg>
                              </th>
                              <th
                                scope="col"
                                class="px-6 py-3 text-left text-sm font-avenir-medium text-white tracking-wider"
                              >
                                Title
                              </th>
                              <th
                                scope="col"
                                class="px-6 py-3 text-left text-sm font-avenir-medium text-white tracking-wider"
                              >
                                Start Date
                              </th>
                              <th
                                scope="col"
                                class="px-6 py-3 text-left text-sm font-avenir-medium text-white tracking-wider"
                              >
                                End Date
                              </th>
                              <th
                                scope="col"
                                class="px-6 py-3 text-left text-sm font-avenir-medium text-white tracking-wider"
                              >
                                Year
                              </th>
                              <th
                                scope="col"
                                class="px-6 py-3 text-left text-sm font-avenir-medium text-white tracking-wider"
                              >
                                Status
                              </th>
                              <th
                                scope="col"
                                class="px-6 py-3 text-left text-sm font-avenir-medium text-white tracking-wider"
                              >
                                Action
                              </th>
                            </tr>
                          </thead>
                          <tbody class="bg-white">{allPeriodData}</tbody>
                        </table>
                      </div>
                    </div>
                    {this.state.periodData.length > 0 && (
                      <p className="text-sm font-normal text-color py-4">
                        Showing{" "}
                        {this.state.periodData && this.state.periodData.length}{" "}
                        to{" "}
                        {this.state.periodData && this.state.periodData.length}{" "}
                        of{" "}
                        {this.state.periodData && this.state.periodData.length}{" "}
                        entries
                      </p>
                    )}
                  </div>
                </div>
                {this.state.showNotification && (
                  <div class="mt-20 fixed inset-0 flex items-end justify-center px-4 py-6 pointer-events-none p-6 items-start justify-end">
                    <div class="max-w-sm w-full bg-white shadow-lg rounded-lg pointer-events-auto ring-1 ring-black ring-opacity-5 overflow-hidden">
                      <div class="p-4">
                        <div class="flex items-start">
                          <div class="flex-shrink-0">
                            {this.state.notificationSuccess ? (
                              <svg
                                class="h-6 w-6 text-green-400"
                                xmlns="http://www.w3.org/2000/svg"
                                fill="none"
                                viewBox="0 0 24 24"
                                stroke="currentColor"
                                aria-hidden="true"
                              >
                                <path
                                  stroke-linecap="round"
                                  stroke-linejoin="round"
                                  stroke-width="2"
                                  d="M9 12l2 2 4-4m6 2a9 9 0 11-18 0 9 9 0 0118 0z"
                                />
                              </svg>
                            ) : (
                              <svg
                                class="h-6 w-6 text-green-400"
                                xmlns="http://www.w3.org/2000/svg"
                                fill="none"
                                viewBox="0 0 24 24"
                                stroke="currentColor"
                              >
                                <path
                                  stroke-linecap="round"
                                  stroke-linejoin="round"
                                  stroke-width="2"
                                  d="M10 14l2-2m0 0l2-2m-2 2l-2-2m2 2l2 2m7-2a9 9 0 11-18 0 9 9 0 0118 0z"
                                />
                              </svg>
                            )}
                          </div>
                          <div class="ml-3 w-0 flex-1 pt-0.5">
                            <p class="text-sm font-medium text-gray-900">
                              {this.state.notificationMessage}
                            </p>
                          </div>
                          <div class="ml-4 flex-shrink-0 flex">
                            <button
                              class="bg-white rounded-md inline-flex text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                              onClick={() =>
                                this.setState({
                                  showNotification:
                                    !this.state.showNotification,
                                })
                              }
                            >
                              <span class="sr-only">Close</span>
                              <svg
                                class="h-5 w-5"
                                xmlns="http://www.w3.org/2000/svg"
                                viewBox="0 0 20 20"
                                fill="currentColor"
                                aria-hidden="true"
                              >
                                <path
                                  fill-rule="evenodd"
                                  d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                                  clip-rule="evenodd"
                                />
                              </svg>
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                )}
              </div>
            ) : (
              <div
                className="bg-white py-4 px-4 pointer shadow-sm overflow-hidden cursor-pointer mb-3"
                onClick={() => this.setState({ open: !this.state.open })}
              >
                <p className="text-sm font-medium text-color">
                  Budget Period Activation
                </p>
              </div>
            )}
          </React.Fragment>
        ) : (
          <Preloader />
        )}
      </React.Fragment>
    );
  }
}
