const paths = {
  login: "/api/auth/authorization",
  request: "/api/request",
  meeting: "/api/meeting",
  assign: "/api/request-assignment",
  employee: "/api/v1/employee",
  assigntodirector: "/api/BudgetEnvelope/AssignEnvelopeInitiator",
  assigntodirectorItem: "/api/BudgetEnvelope/AssignBudgetItemEnvelope",
  assignbydirectorEnvelope: "/api/BudgetEnvelope/GetAssignedEnvelopeInitiatorMemo",
  pendingfromdirector: "/api/BudgetEnvelope/GetPendingEnvelopeInitiatorMemo",
  pendingfromdirectoritem:
    "/api/BudgetEnvelope/GetPendingBudgetItemInitiatorMemo",
};
// /api/BudgetEnvelope/AssignBudgetItemEnvelope
export default paths;
