import {
  Button,
  Form,
  Input,
  InputNumber,
  Popconfirm,
  Select,
  Table,
  Typography,
} from "antd";
import moment from "moment";
import React, { useEffect, useState } from "react";
import notification from "../../utility/notification";
import axios from "axios";
import { get } from "../../api-services/fetch";

const EconomicSegment = (props) => {
  const [form] = Form.useForm();

  const [economicSegment, setEconomicSegment] = useState([]);
  const [selectedSegment, setSelectedSegment] = useState();

  const { Option } = Select;

  //   console.log(selectedSegment);

  useEffect(async () => {
    const res = await get({
      endpoint: "/api/v1/EconomicSegment",
      auth: true,
      // body: payload,
    });
    // axios
    //   .get(`https://edogoverp.com/newbudgetapi/api/v1/EconomicSegment/`)
    //   .then((res) =>

    if (res && res.status == 200) {
      console.log(res?.data);
      setEconomicSegment(res?.data);

      // form.setFieldsValue({ economicSegment: "kokoko" });

      // this.setState({
      //   costProperties: res?.data || [],
      //   overheadCostTotal: res?.data?.overheadCost || 0,
      //   personelCostTotal: res?.data?.personelCost || 0,
      //   capitalCostTotal: res?.data?.capitalCost || 0,
      //   capitalReceiptCostTotal: res?.data?.capitalReceiptCost || 0,
      //   overheadMonitorCostTotal: res?.data?.overheadMonitorCost || 0,
      //   recurrentRevenueCostTotal: res?.data?.recurrentRevenueCost || 0,
      // });

      // setE/nvelope(res.data);
    }
    // .catch((err) => {
    //   console.log(err?.response);
    // });
  }, []);

  useEffect(() => {
    if (props.count !== 0) {
      handleClear();
    }
  }, [props.count]);

  const handleClear = () => {
    form.resetFields(); // Reset the form to clear the Select input
  };
  return (
    <div>
      <div
        style={{ fontSize: "", marginRight: "10px" }}
        // style={{ fontSize: "", backgroundColor: "#D5FFD5" }}
        // className="p-10 flex  m-b-10"
      >
        <p>Economic Segment </p>
      </div>
      <Form form={form}>
        <Form.Item name="economicSegment">
          <Select
            onChange={(e) => {
              console.log(e);
              setSelectedSegment(e);
              props?.collectEconomicSegmentId(e);
            }}
            showSearch
            labelInValue
            filterOption={(input, option) =>
              option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
            }
            filterSort={(optionA, optionB) =>
              optionA.children
                .toLowerCase()
                .localeCompare(optionB.children.toLowerCase())
            }
            allowClear
            style={{ width: "100%", border: "" }}
            placeholder="Please select Budget Period"
          >
            {Array.isArray(economicSegment) &&
              economicSegment?.map((obj) => (
                <Option
                  value={obj?.id}
                  key={obj?.code}
                >{`${obj?.economicSegmentName}`}</Option>
              ))}
          </Select>
        </Form.Item>
        {/* <Button onClick={handleClear}>CLear</Button> */}
      </Form>
    </div>
  );
};
export default EconomicSegment;

// import React from "react";
// import { Form, Button, Select } from "antd";

// const { Option } = Select;

// const MyForm = () => {
//   const [form] = Form.useForm();

//   const handleClear = () => {
//     form.resetFields(); // Reset the form to clear the Select input
//   };

//   const handleSubmit = (values) => {
//     // Handle form submission here
//     console.log("Form values:", values);
//   };

//   return (
//     <Form form={form} onFinish={handleSubmit}>
//       <Form.Item
//         name="selectInput"
//         label="Select Input"
//         rules={[{ required: true, message: "Please select an option" }]}
//       >
//         <Select placeholder="Please select an option">
//           <Option value="option1">Option 1</Option>
//           <Option value="option2">Option 2</Option>
//           <Option value="option3">Option 3</Option>
//         </Select>
//       </Form.Item>

//       <Form.Item>
//         <Button type="primary" htmlType="submit">
//           Submit
//         </Button>
//         <Button type="default" onClick={handleClear}>
//           Clear
//         </Button>
//       </Form.Item>
//     </Form>
//   );
// };

// export default MyForm;
