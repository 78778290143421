import { Form, Input, InputNumber, Popconfirm, Table, Typography } from "antd";
import moment from "moment";
import React, { useEffect, useState } from "react";

const originData = [];
for (let i = 0; i < 100; i++) {
  originData.push({
    key: i.toString(),
    name: `Edrward ${i}`,
    age: 32,
    address: `London Park no. ${i}`,
  });
}
const EditableCell = ({
  editing,
  dataIndex,
  title,
  inputType,
  record,
  index,
  children,
  ...restProps
}) => {
  const inputNode =
    inputType === "number" ? (
      <InputNumber
        formatter={(value) =>
          `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
        }
        parser={(value) => value?.replace(/\$\s?|(,*)/g, "")}
      />
    ) : (
      <Input />
    );
  return (
    <td {...restProps}>
      {editing ? (
        <Form.Item
          name={dataIndex}
          style={{
            margin: 0,
          }}
          rules={[
            {
              required: true,
              message: `Please Input ${title}!`,
            },
          ]}
        >
          {inputNode}
        </Form.Item>
      ) : (
        children
      )}
    </td>
  );
};
const EditableTableI = (props) => {
  const [form] = Form.useForm();
  const [data, setData] = useState([]);
  const [ongoingP, setOngoingP] = useState(originData);
  const [editingKey, setEditingKey] = useState("");
  const isEditing = (record) => record.key === editingKey;

  const edit = (record) => {
    form.setFieldsValue({
      budgetPlanId: "",
      budgetPeriodId: "",
      adminSectorCode: "",
      adminSector: "",
      project: "",
      title: "",
      programmeCode: "",
      programmeSegment: "",
      administrativeCode: "",
      economicCode: "",
      economicSegment: "",
      fundCode: "",
      fundSegment: "",
      functionalCode: "",
      functionalSegment: "",
      geoCode: "",
      geoArea: "",
      status: "",
      projectLocation: "",
      proposedBudget: "",
      previousApprovedBudget: "",
      currentExpenditures: "",
      previousExpenditures: "",
      performance: "",
      actuals: "",
      mdaId: "",
      approvalStatus: "",
      stage: "",

      ...record,
    });
    setEditingKey(record.key);
  };
  const cancel = () => {
    setEditingKey("");
  };
  const save = async (key) => {
    try {
      const row = await form.validateFields();
      const newData = [...data];
      const index = newData.findIndex((item) => key === item.key);
      if (index > -1) {
        const item = newData[index];
        newData.splice(index, 1, {
          ...item,
          ...row,
        });
        console.log(newData);

        setData(newData);
        setEditingKey("");
      } else {
        newData.push(row);
        setData(newData);
        setEditingKey("");
        console.log(newData);
      }
    } catch (errInfo) {
      console.log("Validate Failed:", errInfo);
    }
  };

  const columnsBudgetItems = [
    {
      title: "Project",
      dataIndex: "project",
      editable: true,
      key: "name",
      render: (text) => <a>{text}</a>,
    },
    //  {
    //    title: "Title",
    //    editable: true,
    //    dataIndex: "title",
    //    key: "age",
    //  },
    {
      title: "Proposed Budgetes",
      dataIndex: "proposedBudget",
      editable: true,
      key: "address",
      width: "20%",
    },
    {
      title: "operation",
      dataIndex: "operation",
      render: (_, record) => {
        const editable = isEditing(record);
        return editable ? (
          <span>
            <Typography.Link
              onClick={() => save(record.key)}
              style={{
                marginRight: 8,
              }}
            >
              Save
            </Typography.Link>
            <Popconfirm title="Sure to cancel?" onConfirm={cancel}>
              <a>Cancel</a>
            </Popconfirm>
          </span>
        ) : (
          <Typography.Link
            disabled={editingKey !== ""}
            // onClick={() => edit(record)}
          >
            Edit
          </Typography.Link>
        );
      },
    },
    // {
    //   title: "Tags",
    //   key: "tags",
    //   dataIndex: "tags",
    //   render: (_, { tags }) => (
    //     <>
    //       {tags?.map((tag) => {
    //         let color = tag.length > 5 ? "geekblue" : "green";

    //         if (tag === "loser") {
    //           color = "volcano";
    //         }

    //         return (
    //           <Tag color={color} key={tag}>
    //             {tag?.toUpperCase()}
    //           </Tag>
    //         );
    //       })}
    //     </>
    //   ),
    // },
    // {
    //   title: "Action",
    //   key: "action",
    //   render: (_, record) => (
    //     <Space size="middle">
    //       <a>Invite {record.name}</a>
    //       <a>Delete</a>
    //     </Space>
    //   ),
    // },
  ];

  useEffect(() => {
    createData();
  }, [props.propzApproved]);

  const createData = () => {
    // console.log(props.propzApproved[0]?.budgetItems);
    const ongoingProjectsData = props.propzApproved?.budgetItems?.map((e) => ({
      // budgetPlanId: "3fa85f64-5717-4562-b3fc-2c963f66afa6",
      // budgetPeriodId: "36935819-3047-4c35-94d8-c423fa12d1d8",
      adminSectorCode: e.adminSectorCode,
      adminSector: e.adminSector,
      project: e.project,
      title: e.title,
      programmeCode: e.programmeCode,
      programmeSegment: e.programmeSegment,
      administrativeCode: e.administrativeCode,
      economicCode: e.economicCode,
      economicSegment: e.economicSegment,
      fundCode: e.fundCode,
      fundSegment: e.fundSegment,
      functionalCode: e.functionalCode,
      functionalSegment: e.functionalSegment,
      geoCode: e.geoCode,
      geoArea: e.geoArea,
      status: e.status,
      projectLocation: e.projectLocation,
      proposedBudget: e.proposedBudget,
      previousApprovedBudget: e.previousApprovedBudget,
      currentExpenditures: e.currentExpenditures,
      previousExpenditures: e.previousExpenditures,
      performance: e.performance,
      actuals: e.actuals,
      mdaId: e.mdaId,
      approvalStatus: e.approvalStatus,
      stage: e.stage,
    }));

    console.log(ongoingProjectsData);
    setData(ongoingProjectsData);
  };

  //  console.log(props.propzApproved);
  const columns = [
    {
      title: "Project",
      dataIndex: "name",
      width: "25%",
      editable: true,
    },
    {
      title: "age",
      dataIndex: "age",
      width: "15%",
      editable: true,
    },
    {
      title: "address",
      dataIndex: "address",
      width: "40%",
      editable: true,
    },
    {
      title: "operation",
      dataIndex: "operation",
      render: (_, record) => {
        const editable = isEditing(record);
        return editable ? (
          <span>
            <Typography.Link
              onClick={() => save(record.key)}
              style={{
                marginRight: 8,
              }}
            >
              Save
            </Typography.Link>
            <Popconfirm title="Sure to cancel?" onConfirm={cancel}>
              <a>Cancel</a>
            </Popconfirm>
          </span>
        ) : (
          <Typography.Link
            disabled={editingKey !== ""}
            // onClick={() => edit(record)}
          >
            Edit
          </Typography.Link>
        );
      },
    },
  ];
  const mergedColumns = columnsBudgetItems.map((col) => {
    if (!col.editable) {
      return col;
    }
    return {
      ...col,
      onCell: (record) => ({
        record,
        inputType: col.dataIndex === "age" ? "number" : "text",
        dataIndex: col.dataIndex,
        title: col.title,
        editing: isEditing(record),
      }),
    };
  });
  return (
    <Form form={form} component={false}>
      <Table
        components={{
          body: {
            cell: EditableCell,
          },
        }}
        bordered
        dataSource={data}
        columns={mergedColumns}
        rowClassName="editable-row"
        pagination={{
          onChange: cancel,
        }}
      />
    </Form>
  );
};
export default EditableTableI;
