import React, { useEffect, useState } from "react";
import { Table, Input, Button, Space, InputNumber, Select } from "antd";
import "antd/dist/antd.css";
import Cookies from "js-cookie";
import notification from "../../utility/notification";
import { post, put } from "../../api-services/fetch";

const { Option } = Select;

const AdditionTable = (prop) => {
  const [dataSource, setDataSource] = useState([]);
  const [nstatus, setnstatus] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [formData, setFormData] = useState({
    adminCode: "",
    economicDetails: "",
    economicMainHeadCode: "",
    previousApprovedBudget: 0,
    proposedAppropriation: 0,
    currentApprovedAppropriation: 0,
    actualExpenditureAsAt: 0,
    functionalCode: "",
    locationCode: "",
    // status: "",
  });

  useEffect(() => {
    console.log(prop.dataFromUp);
  }, [prop.dataFromUp]);

  let mm = {
    budgetPeriodId: "9f5ff7b5-a0cd-4768-9481-75d2ed785540",
    mdaId: +prop?.dataFromUp?.mdaId,
    budgetItems: [
      {
        dateCreated: new Date(),
        enteredBy: +Cookies.get("userId"),
        dateUpdated: new Date(),
        updatedBy: Cookies.get("userId"),
        isDelete: false,
        dateDeleted: new Date(),
        budgetPlanId: "",
        budgetDeptId: "",
        budgetPeriodId: "9f5ff7b5-a0cd-4768-9481-75d2ed785540",
        project: "",
        title: "",
        adminSectorCode: 0,
        adminCode: "",
        programmeCode: "",
        economicMainHeadCode: "",
        economicDetailsCod: "",
        economicDetails: "",
        fundCode: "",
        fundDetailsCode: "",
        functionalCode: "",
        functionalDetailsCode: "",
        locationCode: "",
        status: "3",
        projectLocation: "",
        proposedAppropriation: 0,
        previousApprovedBudget: 0,
        currentApprovedAppropriation: 0,
        actualExpenditureAsAt: 0,
        performance: "",
        actuals: "",
        mdaId: +prop.dataFromUp?.mdaId,
        approvalStatus: 0,
        stage: 0,
      },
    ],
  };

  const handleChange = (e) => {
    const { name, value } = e?.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleAddToTable = () => {
    // Validate form data before adding to the table
    // if (
    //   !formData.economicDetails ||
    //   // !formData.economicMainHeadCode ||
    //   // !formData.programmeCode
    //   // ||
    //   // !formData.functionalMainHeadCode ||
    //   // !formData.currentApprovedAppropriation ||
    //   !formData.adminCode
    //   // ||
    //   // !formData.actualExpenditureAsAt
    // ) {
    //   alert("Please fill in all fields.");
    //   return;
    // }

    let kk = {
      dateCreated: new Date(),
      enteredBy: Cookies.get("userId"),
      dateUpdated: new Date(),
      updatedBy: Cookies.get("userId"),
      isDelete: false,
      dateDeleted: new Date(),
      deptBudgetId: "3fa85f64-5717-4562-b3fc-2c963f66afa6",
      budgetPeriodId: "9f5ff7b5-a0cd-4768-9481-75d2ed785540",
      details: "",
      adminCode: "",
      programmeCode: "",
      economicMainHeadCode: "",
      economicDetailsCode: "",
      functionalMainHeadCode: "",
      functionalDetailsCode: "",
      locationCode: "",
      fundMainCode: "",
      fundDetailsCode: "",
      fundMainCode2: "",
      fundDetailsCode2: "",
      fundMainCode3: "",
      fundDetailsCode3: "",
      fundAmount: 0,
      fundAmount2: 0,
      fundAmount3: 0,
      stateOfCompletion: "",
      status: "",
      location: "",
      currentApprovedAppropriation: 0,
      actualExpenditureAsAt: 0,
      proposedAppropriation: 0,
      targetsRemarks: "",
      mdaId: +prop?.dataFromUp?.mdaId,
    };

    // let kk = {
    //   dateCreated: new Date(),
    //   enteredBy: +Cookies.get("userId"),
    //   dateUpdated: new Date(),
    //   updatedBy: Cookies.get("userId"),
    //   isDelete: false,
    //   dateDeleted: new Date(),
    //   budgetPlanId: "",
    //   budgetDeptId: "",
    //   budgetPeriodId: "9f5ff7b5-a0cd-4768-9481-75d2ed785540",
    //   project: "",
    //   title: "",
    //   adminSectorCode: 0,
    //   adminCode: "",
    //   programmeCode: "",
    //   economicMainHeadCode: "",
    //   economicDetailsCode: "",
    //   economicDetails: "",
    //   fundCode: "",
    //   fundDetailsCode: "",
    //   functionalCode: "",
    //   functionalDetailsCode: "",
    //   locationCode: "",
    //   status: "3",
    //   projectLocation: "",
    //   proposedAppropriation: 0,
    //   previousApprovedBudget: 0,
    //   currentApprovedAppropriation: 0,
    //   actualExpenditureAsAt: 0,
    //   performance: "",
    //   actuals: "",
    //   mdaId: +prop?.dataFromUp?.mdaId,
    //   approvalStatus: 0,
    //   stage: 0,
    // };

    let pp = { ...kk, ...formData };

    console.log(pp);

    // const yy = [].map((e)=>(
    //     {
    //         ...e
    //     }
    // ))

    // Add form data to the table
    setDataSource((prevDataSource) =>
      [...prevDataSource, pp].map((e) => ({
        ...e,
        economicMainHeadCode: e?.economicMainHeadCode,
        economicDetails: e?.economicDetails,
        details: e?.economicDetails,
        locationCode: e?.locationCode,
        proposedAppropriation: +e?.proposedAppropriation,
        economicDetailsCode: e?.economicMainHeadCode,
        status: nstatus,
        currentApprovedAppropriation: +e?.currentApprovedAppropriation,
        actualExpenditureAsAt: +e?.actualExpenditureAsAt,
      }))
    );

    // Clear the form data for the next entry
    setFormData({
      ...formData,
      economicMainHeadCode: "",
      economicDetails: "",
      proposedAppropriation: 0,
      // previousApprovedBudget: 0,
      actualExpenditureAsAt: 0,
      currentApprovedAppropriation: 0,
    });
  };

  const columns = [
    {
      title: "Administrative Code",
      dataIndex: "adminCode",
      editable: true,
      key: "adminCode",
    },
    {
      title: "Economic Code",
      dataIndex: "economicMainHeadCode",
      editable: true,
      key: "economicMainHeadCode",
    },
    {
      title: "Program Code",
      dataIndex: "programmeCode",
      editable: true,
      key: "programmeCode",
    },
    {
      title: "Details",
      dataIndex: "economicDetails",
      editable: true,
      key: "economicDetails",
    },
    {
      title: "Proposed Estimates 2024",
      dataIndex: "proposedAppropriation",
      editable: true,
      key: "proposedAppropriation",
    },
    // {
    //   title: "Approved Estimates 2023",
    //   dataIndex: "previousApprovedBudget",
    //   key: "previousApprovedBudget",
    // },
    {
      title: "Actual Expenditure At June 30th 2023",
      dataIndex: "actualExpenditureAsAt",
      editable: true,
      key: "actualExpenditureAsAt",
    },
    {
      title: "Actual Expenditure 2022",
      dataIndex: "currentApprovedAppropriation",
      editable: true,
      key: "currentApprovedAppropriation",
    },
    {
      title: "Functional Code",
      dataIndex: "functionalCode",
      editable: true,
      key: "functionalCode",
    },
    {
      title: "Location Code",
      dataIndex: "locationCode",
      editable: true,
      key: "locationCode",
    },
  ];
  const toPostAcceptEnvelopeAssignment = async () => {
    // setIsLoading(true);
  };

  const handlePost = async () => {
    console.log(dataSource);
    setIsLoading(true)

    let payload = {
      budgetPeriodId: "9f5ff7b5-a0cd-4768-9481-75d2ed785540",
      mdaId: +prop?.dataFromUp?.mdaId,
      capitalProjects: dataSource,
    };
    const res = await post({
      endpoint: "/api/v1/BudgetPlanning/AddCapitalCostItems",
      auth: true,
      body: payload,
    });

    if (res && res.status == 200) {
        setIsLoading(false);
      notification({
        title: "Success",
        message: "Successfully Accepted",
        type: "success",
      });
      prop?.refeshwithoutPost();
    }
  };

  return (
    <div>
      <Space direction="vertical">
        <div>
          <label className="bold">Administrative Code</label>

          <Input
            placeholder="Administrative Code"
            name="adminCode"
            value={formData.adminCode}
            onChange={handleChange}
          />
        </div>
        <div>
          <label className="bold">Economic Code</label>

          <Input
            placeholder="Economic Code"
            name="economicMainHeadCode"
            value={formData.economicMainHeadCode}
            onChange={handleChange}
          />
        </div>
        <div>
          <label className="bold">Program Code</label>

          <Input
            placeholder="Program Code"
            name="programmeCode"
            value={formData.programmeCode}
            onChange={handleChange}
          />
        </div>
        <div>
          <label className="bold">Details</label>

          <Input
            placeholder="Details"
            name="economicDetails"
            value={formData.economicDetails}
            onChange={handleChange}
          />
        </div>
        <div
          style={{ display: "flex", flexDirection: "column", width: "100%" }}
        >
          <label className="bold">Proposed Estimates</label>

          <Input
            style={{ width: "100%" }}
            placeholder="Proposed Estimates"
            name="proposedAppropriation"
            value={+formData.proposedAppropriation}
            onChange={handleChange}
          />
        </div>

        <div style={{ width: "100%" }}>
          <label className="bold">Project Status</label>

          <Select
            name="status"
            onChange={(e) => setnstatus(e)}
            // value={formData.status}
            style={{ width: "100%" }}
          >
            <Option value={'1'} key={1}>
              New
            </Option>{" "}
            <Option value={'2'} key={2}>
              Ongoing
            </Option>
          </Select>
        </div>
        {/* <div
          style={{ display: "flex", flexDirection: "column", width: "100%" }}
        >
          <label className="bold">Approved Estimates</label>

          <Input
            style={{ width: "100%" }}
            placeholder="Approved Estimates"
            name="previousApprovedBudget"
            value={+formData.previousApprovedBudget}
            onChange={handleChange}
          />
        </div> */}
        <div
          style={{ display: "flex", flexDirection: "column", width: "100%" }}
        >
          <label className="bold">Actual Expenditure At June 30th 2023</label>

          <Input
            style={{ width: "100%" }}
            placeholder="Actual Expenditure At June 30th 2023"
            name="actualExpenditureAsAt"
            value={+formData.actualExpenditureAsAt}
            onChange={handleChange}
          />
        </div>
        <div
          style={{ display: "flex", flexDirection: "column", width: "100%" }}
        >
          <label className="bold">Actual Expenditure 2022</label>

          <Input
            style={{ width: "100%" }}
            placeholder="Actual Expenditure 2022"
            name="currentApprovedAppropriation"
            value={+formData.currentApprovedAppropriation}
            onChange={handleChange}
          />
        </div>
        {/* <Input
          placeholder="Name"
          name="name"
          value={formData.name}
          onChange={handleChange}
        />
        <Input
          placeholder="Age"
          name="age"
          value={formData.age}
          onChange={handleChange}
        /> */}

       
      </Space>

      <Table dataSource={dataSource} columns={columns} />

      <Button loading={isLoading} onClick={handlePost}>Update Entries For {prop?.dataFromUp?.mda}</Button>
    </div>
  );
};

export default AdditionTable;
