import {
  Button,
  Form,
  Input,
  InputNumber,
  Modal,
  Popconfirm,
  Table,
  Typography,
} from "antd";
import moment from "moment";
import React, { useEffect, useState } from "react";
import Cookies from "js-cookie";
import { decodeToken } from "../../utility/auth";
import AdditionTable from "./AdditionTable";
import Item from "antd/lib/list/Item";

const originData = [];
for (let i = 0; i < 100; i++) {
  originData.push({
    key: i.toString(),
    name: `Edrward ${i}`,
    age: 32,
    address: `London Park no. ${i}`,
  });
}
const EditableCell = ({
  editing,
  dataIndex,
  title,
  inputType,
  record,
  index,
  children,
  ...restProps
}) => {
  const inputNode =
    inputType === "number" ? (
      <InputNumber
        formatter={(value) =>
          `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
        }
        parser={(value) => value?.replace(/\$\s?|(,*)/g, "")}
      />
    ) : (
      <Input />
    );
  return (
    <td {...restProps}>
      {editing ? (
        <Form.Item
          name={dataIndex}
          style={{
            margin: 0,
          }}
          // rules={[
          //   {
          //     required: true,
          //     message: `Please Input ${title}!`,
          //   },
          // ]}
        >
          {inputNode}
        </Form.Item>
      ) : (
        children
      )}
    </td>
  );
};
const EditableBudgetItems = (props) => {
  const [form] = Form.useForm();
  const [data, setData] = useState([]);
  const [mdaId, setMdaId] = useState("");
  const [userId, setUserId] = useState("");
  const [showAddTable, setshowAddTable] = useState(false);
  const [editingKey, setEditingKey] = useState("");
  const isEditing = (record) => record.key === editingKey;

  function numberWithCommas(x) {
    return x?.toString()?.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  }

  useEffect(() => {
    //  toGetAdministrativeSegment();
    //  toGetEconimicItems();

    console.log(+Cookies.get("mdaId"));

    setMdaId(+Cookies.get("mdaId"));

    console.log(Cookies.get("signature"));
    console.log(Cookies.get("firstName"));
    console.log(Cookies.get("lastName"));
    // setsignature(Cookies.get("signature"));

    // let ui = localStorage.getItem("ui");
    // ui = decodeToken("ui").ui;
    // console.log(ui);
    // setUserId(ui);
  }, []);

  const edit = (record) => {
    form.setFieldsValue({
      // id: "",
      // dateCreated: "2023-10-24T09:59:27.481Z",
      // enteredBy: userId + "",
      // dateUpdated: "2023-10-24T09:59:27.481Z",
      // updatedBy: userId + "",
      // isDelete: false,
      // dateDeleted: "2023-10-24T09:59:27.481Z",
      // budgetPlanId: "3fa85f64-5717-4562-b3fc-2c963f66afa6",
      // budgetDeptId: "3fa85f64-5717-4562-b3fc-2c963f66afa6",
      // budgetPeriodId: "3fa85f64-5717-4562-b3fc-2c963f66afa6",
      // project: "string",
      // title: "string",
      // adminSectorCode: 0,
      // administrativeCode: "string",
      // programmeCode: "string",
      // economicCode: "string",
      // economicDetailsCode: "string",
      // fundCode: "string",
      // fundDetailsCode: "string",
      // functionalCode: "string",
      // functionalDetailsCode: "string",
      // geoCode: "string",
      // status: "string",
      // projectLocation: "string",
      // proposedBudget: 0,
      // previousApprovedBudget: 0,
      // currentExpenditures: 0,
      // previousExpenditures: 0,
      // performance: "string",
      // actuals: "string",
      // mdaId: +mdaId,
      // approvalStatus: 0,
      // stage: 0,

      ...record,
    });
    setEditingKey(record.key);
  };
  const handleAdd = () => {
    // setshowAddTable((prev) => !prev);
    const newData = {
      dateCreated: new Date(),
      enteredBy: Cookies.get("userId"),
      dateUpdated: new Date(),
      updatedBy: Cookies.get("userId"),
      isDelete: false,
      dateDeleted: new Date(),
      deptBudgetId: "3fa85f64-5717-4562-b3fc-2c963f66afa6",
      budgetPeriodId: "9f5ff7b5-a0cd-4768-9481-75d2ed785540",
      // id: "",
      budgetPlanId: "3fa85f64-5717-4562-b3fc-2c963f66afa6",
      budgetDeptId: "3fa85f64-5717-4562-b3fc-2c963f66afa6",
      project: "",
      title: "",
      adminSectorCode: 0,
      administrativeCode: "",
      programmeCode: "",
      economicCode: "",
      economicDetailsCode: "",
      fundCode: "",
      fundDetailsCode: "",
      functionalCode: "",
      functionalDetailsCode: "",
      geoCode: "",
      status: "",
      projectLocation: "",
      proposedBudget: 0,
      previousApprovedBudget: 0,
      currentExpenditures: 0,
      previousExpenditures: 0,
      performance: "",
      actuals: "",
      mdaId: +props.propzApproved?.mdaId,
      approvalStatus: 0,
      stage: 0,
      key: data?.length,

      economicDetails: "",
      adminSector: "",
      programmeSegment: "",
      economicSegment: "",
      fundSegment: "",
      functionalSegment: "",
      geoArea: "",
      availableBudget: 0,
      editedAmount: 0,
    };
    setData([newData,...data, ]);
    // setDataSource([...dataSource, newData]);
    // setCount(count + 1);
  };
  const cancel = () => {
    setEditingKey("");
  };
  const save = async (key, record) => {
    try {
      let row = await form.validateFields();
      row = {
        ...row,
        editedAmount: +row.availableBudget - +record?.proposedBudget,
        availableBudget: +row.availableBudget,
        proposedBudget: +row?.proposedBudget,
        previousApprovedBudget: +row?.previousApprovedBudget,
        currentExpenditures: +row?.currentExpenditures,
        previousExpenditures: +row?.previousExpenditures,
      };
      console.log(row);

      const newData = [...data];
      const index = newData.findIndex((item) => key === item.key);
      if (index > -1) {
        const item = newData[index];
        console.log(item);
        newData.splice(index, 1, {
          ...item,
          ...row,
        });
        console.log(newData);

        // const { [approvers]: _, ...props?.propzApproved
        // } = originalObject;

        // let kkk = props.propzApproved

        let kkk = {
          ...props.propzApproved,
          budgetItems: newData,
          approvers: null,
        };
        delete kkk["budgetDeptApprovers"];
        delete kkk["approvers"];

        console.log(kkk);
        props.saveAndRefreshApprovedMDAs(kkk);

        // console.log(kkk)

        setData(newData);
        setEditingKey("");
      } else {
        newData.push(row);
        setData(newData);
        setEditingKey("");
        console.log(newData);
      }
    } catch (errInfo) {
      console.log("Validate Failed:", errInfo);
    }
  };

  //   const ongoingProjectsColumn = [
  //     {
  //       title: "Project",
  //       dataIndex: "project",
  //       editable: true,
  //       key: "name",
  //       render: (text) => <a>{text}</a>,
  //     },
  //     {
  //       title: "Status",
  //       dataIndex: "status",
  //       editable: true,
  //       key: "age",
  //     },
  //     {
  //       title: "Date Of Commencement",
  //       dataIndex: "startDate",
  //       key: "startDate",
  //       editable: true,
  //       width: "20%",
  //     },
  //     {
  //       title: "Amount Spent to date",
  //       dataIndex: "amountSpent",
  //       key: "amountSpent",
  //       editable: true,
  //       width: "20%",
  //     },
  //     {
  //       title: "Estimate to Conclusion",
  //       dataIndex: "conclusionEstimate",
  //       key: "conclusionEstimate",
  //       editable: true,
  //       width: "20%",
  //     },
  //     {
  //       title: "Time To Completion",
  //       dataIndex: "completionTime",
  //       key: "completionTime",
  //       editable: true,
  //       width: "20%",
  //     },
  //     {
  //       title: "operation",
  //       dataIndex: "operation",
  //       render: (_, record) => {
  //         const editable = isEditing(record);
  //         return editable ? (
  //           <span>
  //             <Typography.Link
  //               onClick={() => save(record.key)}
  //               style={{
  //                 marginRight: 8,
  //               }}
  //             >
  //               Save
  //             </Typography.Link>
  //             <Popconfirm title="Sure to cancel?" onConfirm={cancel}>
  //               <a>Cancel</a>
  //             </Popconfirm>
  //           </span>
  //         ) : (
  //           <Typography.Link
  //             disabled={editingKey !== ""}
  //             onClick={() => edit(record)}
  //           >
  //             Edit
  //           </Typography.Link>
  //         );
  //       },
  //     },
  //   ];
  const ongoingProjectsColumn = [
    {
      title: "Administrative Code",
      dataIndex: "administrativeCode",
      editable: true,
      key: "name",
      render: (text) => <a>{text}</a>,
    },
    {
      title: "Economic code",
      dataIndex: "economicCode",
      key: "economicCode",
      editable: true,
      // editable: true,
      //  width: "20%",
    },
    {
      title: "Details",
      dataIndex: "economicDetails",
      key: "economicDetails",
      editable: true,
      //  width: "20%",
    },
    {
      title: "Proposed Estimates",
      dataIndex: "proposedBudget",
      key: "proposedBudget",
      render: (text) => <a>{numberWithCommas(+text)}</a>,
      editable: true,

      //  width: "20%",
    },

    {
      title: "Available Budget",
      dataIndex: "availableBudget",
      key: "availableBudget",
      render: (text) => <a>{numberWithCommas(+text)}</a>,
      editable: true,

      //  width: "20%",
    },
    {
      title: "Edited Amount",
      dataIndex: "editedAmount",
      key: "editedAmount",
      //  width: "20%",
    },
    {
      title: "Approved Estimates 2023",
      dataIndex: "previousApprovedBudget",
      key: "previousApprovedBudget",
      render: (text) => <a>{numberWithCommas(+text)}</a>,
      editable: true,

      //  width: "20%",
    },
    {
      title: "Actual Expenditure At June 30th 2023",
      dataIndex: "currentExpenditures",
      key: "currentExpenditures",
      render: (text) => <a>{numberWithCommas(+text)}</a>,
      editable: true,

      //  width: "20%",
    },
    {
      title: "Actual Expenditure 2022",
      dataIndex: "previousExpenditures",
      key: "previousExpenditures",
      render: (text) => <a>{numberWithCommas(+text)}</a>,
      editable: true,

      //  width: "20%",
    },

    // {
    //   title: "Annualised Total Expenditure 2023",
    //   dataIndex: "annualisedTotalExpenditure",
    //   key: "annualisedTotalExpenditure",
    //   //  width: "20%",
    // },

    {
      title: "Functional Code ",
      dataIndex: "functionalCode",
      editable: true,
      key: "functionalDetailsCode",
      //   width: "20%",
    },
    {
      title: "Location Code ",
      dataIndex: "locationCode",
      editable: true,
      key: "locationCode",
      //   width: "20%",
    },

    // {
    //   title: "Fund Code ",
    //   dataIndex: "fundCode",
    //   editable: true,
    //   key: "fundMainCode",
    //   //   width: "20%",
    // },
    {
      title: "operation",
      dataIndex: "operation",
      render: (_, record) => {
        const editable = isEditing(record);
        return editable ? (
          <span>
            <Typography.Link
              onClick={() => save(record.key, record)}
              style={{
                marginRight: 8,
              }}
            >
              Save
            </Typography.Link>
            <Popconfirm title="Sure to cancel?" onConfirm={cancel}>
              <a>Cancel</a>
            </Popconfirm>
          </span>
        ) : (
          <Typography.Link
            disabled={editingKey !== ""}
            onClick={() => props.isAdminEdit && edit(record)}
          >
            Edit
          </Typography.Link>
        );
      },
    },
  ];

  useEffect(() => {
    // console.log(props.propzApproved)
    createData();
  }, [props.propzApproved]);

  const createData = () => {
    // console.log(props.propzApproved[0]?.ongoingProjects);
    const ongoingProjectsData = props.propzApproved?.budgetItems?.map(
      (e, idx) => ({
        id: idx,
        key: idx,
        dateCreated: e.dateCreated,
        enteredBy: userId + "",
        dateUpdated: e.dateUpdated,
        updatedBy: userId + "",
        isDelete: false,
        dateDeleted: e.dateDeleted,
        budgetPlanId: e.budgetDeptId,
        budgetDeptId: e.budgetDeptId,
        budgetPeriodId: e.budgetPeriodId,
        project: e.project,
        title: e.title,
        adminSectorCode: e.adminSectorCode,
        administrativeCode: e.administrativeCode,
        programmeCode: e.programmeCode,
        economicCode: e.economicCode,
        economicDetailsCode: e.economicDetailsCode,
        fundCode: e.fundCode,
        fundDetailsCode: e.fundDetailsCode,
        functionalCode: e.functionalCode,
        functionalDetailsCode: e.functionalDetailsCode,
        geoCode: e.geoCode,
        locationCode: e.geoCode,

        status: e.status,
        projectLocation: e.projectLocation,
        proposedBudget: +e?.proposedBudget || 0,
        availableBudget: +e?.availableBudget || 0,
        editedAmount: +e?.editedAmount || 0,
        previousApprovedBudget: +e.previousApprovedBudget || 0,
        currentExpenditures: +e.currentExpenditures || 0,
        previousExpenditures: +e.previousExpenditures || 0,
        performance: e.performance,
        actuals: e.actuals,
        mdaId: +props.propzApproved.mdaId,
        approvalStatus: e.approvalStatus,
        stage: e.stage,
        ...e,
      })
    );

    console.log(ongoingProjectsData);
    setData(ongoingProjectsData);
  };

  //  console.log(props.propzApproved);
  const columns = [
    {
      title: "Project",
      dataIndex: "name",
      width: "25%",
      editable: true,
    },
    {
      title: "age",
      dataIndex: "age",
      width: "15%",
      editable: true,
    },
    {
      title: "address",
      dataIndex: "address",
      width: "40%",
      editable: true,
    },
    {
      title: "operation",
      dataIndex: "operation",
      render: (_, record) => {
        const editable = isEditing(record);
        return editable ? (
          <span>
            <Typography.Link
              onClick={() => save(record.key)}
              style={{
                marginRight: 8,
              }}
            >
              Save
            </Typography.Link>
            <Popconfirm title="Sure to cancel?" onConfirm={cancel}>
              <a>Cancel</a>
            </Popconfirm>
          </span>
        ) : (
          <Typography.Link
            disabled={editingKey !== ""}
            onClick={() => edit(record)}
          >
            Edit
          </Typography.Link>
        );
      },
    },
  ];
  const mergedColumns = ongoingProjectsColumn
    .map((item) => {
      if (!props.switching && item?.dataIndex == "proposedBudget") {
        console.log({ ...item, editable: true });
        return { ...item, editable: true };
      } else return item;
    })
    ?.map((col) => {
      if (!col.editable) {
        return col;
      }
      return {
        ...col,
        onCell: (record) => ({
          record,
          inputType: col.dataIndex === "age" ? "number" : "text",
          dataIndex: col.dataIndex,
          title: col.title,
          editing: isEditing(record),
        }),
      };
    });
  return (
    <Form form={form} component={false}>
      {props.isAdminEdit && (
        <Button onClick={handleAdd}>+ Add OverHead Budget</Button>
      )}
      <Modal
        title={`Overhed Budget Form ${props.propzApproved.mda}`}
        visible={showAddTable}
        width={1000}
        footer={false}
        // onOk={handleAdd}
        onCancel={handleAdd}
      >
        <AdditionTable
          refeshwithoutPost={props.refeshwithoutPost}
          dataFromUp={props.propzApproved}
        />
      </Modal>
      <Table
        components={{
          body: {
            cell: EditableCell,
          },
        }}
        bordered
        dataSource={data}
        columns={mergedColumns}
        rowClassName="editable-row"
        pagination={{
          onChange: cancel,
        }}
      />
    </Form>
  );
};
export default EditableBudgetItems;
