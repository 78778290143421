import React from "react";
import { Card, Table } from "antd";

// const data = [
//   {
//     key: '1',
//     name: 'User 1',
//     comment: 'Request comment 1',
//     status: 'Pending',
//   },
//   {
//     key: '2',
//     name: 'User 2',
//     comment: 'Request comment 2',
//     status: 'In Progress',
//   },
//   // Add more data rows as needed
// ];

const RequestStatusCard = ({ data, employee }) => {
  const columns = [
    {
      title: "Assignee",
      dataIndex: "assigneeName",
      key: "name",
      // render: (_, record)=><div>
      // <p>{`${employee.find((e)=>record.assignee == e.id)?.firstName} ${employee.find((e)=>record.assignee == e.id)?.lastName}`}</p>

      // </div>
    },
    {
      title: "Comment",
      dataIndex: "comment",
      key: "comment",
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      render: (_, record) => (
        <div>
          <p>{record.status == 7 && "Accepted"}</p>
          <p>{record.status == 3 && "Pending"}</p>
          <p>{record.status == 8 && "Rejected"}</p>
        </div>
      ),
    },
  ];
  return (
    <Card title="Assignee Status Information" style={{ width: 600 }}>
      <Table columns={columns} dataSource={data} />
    </Card>
  );
};

export default RequestStatusCard;
