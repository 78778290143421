import React, { useEffect, useState, useRef } from "react";
import axios from "axios";
import "../../assets/css/Dashboard.css";
import { IoMdClose } from "react-icons/io";
// import { AiOutlineRedEnvelope } from "react-icons/ai";
// import { BiCalendar } from "react-icons/bi";
// import { HiOutlineUserGroup } from "react-icons/hi";
// import Calendar from 'react-calendar';
// import CardGiftcardIcon from "@material-ui/icons/CardGiftcard";
// import EventNoteIcon from "@material-ui/icons/EventNote";
// import SearchInput from "../inputs/SearchInput";
// import RequestDetails from "../layouts/RequestDetails";
import { get, post, put } from "../../api-services/fetch";
// import IsLoading from "../common/IsLoading";
import {
  formatSimpleDate,
  formatDate,
  formatDateforPicker,
} from "../../utility/dateTime";
import Greeting from "../common/Greeting";
import LocationDetails from "../layouts/LocationDetails";
import { formatStatus } from "../../utility/general";
import notification from "../../utility/notification";
import "../../assets/css/calendar.css";
// import useClickOutsideBox from "../hooks/useClickOutsideBox";
// import SelectInputColored from "../inputs/SelectInputColored";
import CreateIcon from "@material-ui/icons/Create";
import DeleteIcon from "@material-ui/icons/Delete";
// import { decodeToken } from "../../utility/auth";
// import Page6Cards from "../layouts/Page6Cards";
// import DashboardC from "../layouts/DashboardC";
import Cookies from "js-cookie";
import { Button, Select } from "antd";
import ReportTableAll from "../layouts/ReportTableAll";

const { Option } = Select;

const Dashboard = (prop) => {
  const { history } = prop;
  // const BASE_API_URL = 'http://localhost:5000/api/v1';

  // const navigate = useNavigate();

  const BASE_API_URL = "https://edogoverp.com/newbudgetapi/api/v1";

  const fullName = "";
  const role = "";
  const time = "";

  const pdfExportComponent = React.useRef(null);

  // const { handleImage, imageHolder, mFilesArray, handleMfilesArray, holdMfilesArray

  // } = useContext(SwapTableContext)
  // const currentPath = location.pathname;

  const [employee, setEmployee] = useState([]);
  const [subject, setSubject] = useState("");
  const [budgetPeriodId, setBudgetPeriodId] = useState();
  const [OverallBudgetVsActual, setOverallBudgetVsActual] = useState();
  const [OverallBudgetVsActualByMda, setOverallBudgetVsActualByMda] =
    useState();
  const [envelope, setEnvelope] = useState([]);
  const [acceptedBudgetEnvelope, setAcceptedBudgetEnvelope] = useState();
  const [budgetButtonType, setbudgetButtonType] = useState("overhead");
  const [isDetailed, setisDetailed] = useState("first");
  const [SelectedMdaId, setSelectedMdaId] = useState();
  const [viewMdaId, setviewMdaId] = useState(false);

  const [
    acceptedAssignedToEnterDetailsArray,
    setAcceptedAssignedToEnterDetailsArray,
  ] = useState([]);
  const [acceptedBudgetEnvelopeArray, setAcceptedBudgetEnvelopeArray] =
    useState([]);
  const [listOfMdas, setListOfMdas] = useState([]);
  const [mdaId, setMdaId] = useState(undefined);
  const [data, setdata] = useState();
  const [dataDetail, setdataDetail] = useState([]);
  const [dataDetailMda, setdataDetailMda] = useState([]);
  const [dataDetailMdaAll, setdataDetailMdaAll] = useState([]);

  const [showDataSetail, setshowDataSetail] = useState(false);
  const [pdfName, setPdfName] = useState("");
  const [budgetPeriod, setBudgetPeriod] = useState([]);

  useEffect(() => {
    // onReset()
    getBudgetPeriod();
    setPdfName(subject);
    getEmployeeList();
    getMdas();
    // let ui = localStorage.getItem("ui");
    // ui = decodeToken("ui").ui;
    // console.log(ui);
    // setuserId(ui);
  }, [subject]);

  function numberWithCommas(x) {
    return x?.toString()?.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  }

  const getBudgetPeriod = async () => {
    // axios
    //   .get("https://edogoverp.com/newbudgetapi/api/Settings")

    const res = await get({ endpoint: "/api/Settings", auth: true });
    if (res.status == 200) {
      console.log(res.data.find((e) => e.isCurrent).id);
      setBudgetPeriodId(res.data.find((e) => e.isCurrent).id);
      setBudgetPeriod(res.data);
      // console.log(res.data.find((e) => e.current)?.id);

      // this.setState({
      //     allBudgetCodification: response.data,
      //     loader: false,
      // });
    } else {
      // this.setState({
      //     message: nullcheck(err.response) == "" ? "server error" : err.response.data,
      //     loader: false,
      // });
    }
  };

  const getMdas = async () => {
    // axios
    //   .get(`${BASE_API_URLs}v1/Mda`)

    const res = await get({
      endpoint: `/api/v1/mda`,
      auth: true,
      // body: payload,
    });
    if (res && res.status == 200) {
      console.log(res);
      setListOfMdas(res.data);

      // this.setState({
      //     allBudgetCodification: response.data,
      //     loader: false,
      // });
    } else {
      // this.setState({
      //     message: nullcheck(err.response) == "" ? "server error" : err.response.data,
      //     loader: false,
      // });
    }
  };

  const getBudgetVsActualByAll = async () => {
    // axios
    //   .get(`${BASE_API_URLs}v1/Mda`)
    setviewMdaId(true);
    setshowDataSetail(false);
    setisDetailed("");

    const res = await get({
      endpoint: `/api/Reports/GetOverallBudgetVsActual?budgetPeriodId=${budgetPeriodId}`,
      auth: true,
      // body: payload,
    });
    if (res && res.status == 200) {
      console.log(res);
      setOverallBudgetVsActual(res.data);
      setdata(res.data);

      // this.setState({
      //     allBudgetCodification: response.data,
      //     loader: false,
      // });
    } else {
      setdata({
        totalBudget: 0,
        actualSpent: 0,
        percentageUtilization: 0,
      });
      // this.setState({
      //     message: nullcheck(err.response) == "" ? "server error" : err.response.data,
      //     loader: false,
      // });
    }
  };
  const getBudgetVsActualByMdas = async (id) => {
    // axios
    //   .get(`${BASE_API_URLs}v1/Mda`)
    setisDetailed("first");
    setviewMdaId(true);
    setshowDataSetail(false);
    if (id) {
      const res = await get({
        endpoint: `/api/Reports/GetBudgetVsActualByMdaId?id=${id}&budgetPeriodId=${budgetPeriodId}`,
        auth: true,
        // body: payload,
      });
      if (res && res.status == 200) {
        console.log(res);
        setOverallBudgetVsActualByMda(res.data);
        setdata(res.data);

        // this.setState({
        //     allBudgetCodification: response.data,
        //     loader: false,
        // });
      } else {
        setdata({
          totalBudget: 0,
          actualSpent: 0,
          percentageUtilization: 0,
        });
        // this.setState({
        //     message: nullcheck(err.response) == "" ? "server error" : err.response.data,
        //     loader: false,
        // });
      }
    } else {
      alert("Select MdaId");
      setdata({
        totalBudget: 0,
        actualSpent: 0,
        percentageUtilization: 0,
      });
    }
  };
  const getOverallAdminDetailsByAll = async () => {
    // axios
    //   .get(`${BASE_API_URLs}v1/Mda`)
    setshowDataSetail(true);
    setviewMdaId(false);
    setisDetailed("");

    const res = await get({
      endpoint: `/api/Reports/GetOverallAdminReport?budgetPeriodId=${budgetPeriodId}`,
      auth: true,
      // body: payload,
    });
    if (res && res.status == 200) {
      console.log(res);
      setdataDetail(res.data);
      setdataDetailMdaAll();

      // this.setState({
      //     allBudgetCodification: response.data,
      //     loader: false,
      // });
    } else {
      // this.setState({
      //     message: nullcheck(err.response) == "" ? "server error" : err.response.data,
      //     loader: false,
      // });
    }
  };
  const getOverallAdminDetailsByMdaId = async (id) => {
    setshowDataSetail(true);
    setviewMdaId(false);
    // axios
    //   .get(`${BASE_API_URLs}v1/Mda`)
    setisDetailed("second");
    // alert("second");

    if (id) {
      const res = await get({
        endpoint: `/api/Reports/GetOverallAdminReportDetailsByMdaId?mdaId=${
          id || mdaId
        }&budgetPeriodId=${budgetPeriodId}`,
        auth: true,
        // body: payload,
      });
      if (res && res.status == 200) {
        console.log(res);
        // setdataDetailMda
        setdataDetail([]);

        setdataDetailMdaAll(res.data);
        console.log(res.data);
        setdataDetailMda(res.data?.overHeadReport);

        // this.setState({
        //     allBudgetCodification: response.data,
        //     loader: false,
        // });
      } else {
        setdataDetail([]);
        setdataDetailMdaAll([]);

        // this.setState({
        //     message: nullcheck(err.response) == "" ? "server error" : err.response.data,
        //     loader: false,
        // });
      }
    } else {
      alert("select MDA");
      setdataDetail([]);
      setdataDetailMdaAll([]);
    }
  };

  const getEmployeeList = async () => {
    const res = await get({
      endpoint: `employee`,
      auth: true,
      pQuery: { q: "a" },
    });
    console.log(res);

    if (res && res.status === 200) {
      const { data } = res;
      if (res.status === 200) {
        console.log(data);
        setEmployee(data);
      } else {
        notification({
          title: "Error Getting List of Employees",
          message: data.message,
          type: "danger",
        });
      }
    } else {
      notification({
        title: "Network",
        message: "Something has gone wrong. Please, try again.",
        type: "danger",
      });
    }
  };

  // const [role, setrole] = useState([])
  // const [time, settime] = useState([])

  // setfullName(authUser.firstName + " " + authUser.lastName)
  // setrole(authUser.role)
  // settime(time)

  // fullName: "",
  // role: "",
  // time: "",
  // departmentBudgetApprovalProcess: [],
  // budgetInformationData: [],
  // budgetInformationDataLength: 0,
  // mdas: [],
  // mdaNames: [],
  // approvalProcessId: [],
  // approvalProcess: [],
  // departmentBudget: [],
  // reviewData: [],
  // commentData: [],
  // employeeData: [],
  // userolesData: [],
  // staffId: "",
  // message: "",
  // loader: "",
  // employeeId: 0,
  // mdaNo: 0,
  // submittedBudget: 0,
  // reviewedBudget: 0,
  // returnedBudget: 0,
  // budgetUnderReview: 0,
  // budgetPeriod:'',

  useEffect(() => {
    // getAuthorisedUser();
    // getDashboardStarts();
    // getDepartmentBudgetApprovalProcess();
    // getBudgetInformation();
    // getComment();
    // getEmployees();
    // getUserRoles();
    // getReviews();
    // getCurrentYear();
  }, []);

  return (
    <div className="w-100">
      {/* <div className="overlay">
          <div className="calendar" ref={wrapperRef}>
            <Calendar
              onChange={handleCalendarChange}
              value={calendar}
              className="calendar"
              selectRange
            />
          </div>
        </div> */}

      <div className="w-100 flex space-between flex-v-baseline">
        <Greeting />
        <LocationDetails />
      </div>

      <div className="flex " style={{ gap: 10 }}>
        <div
          onClick={() => getBudgetVsActualByAll()}
          class="page-6-card border w-100 pointer p-10"
          // style={{ backgroundColor: "transparent", margin: "0px 2.5px;" }}
        >
          <div style={{ borderBottom: "3px solid green" }}>
            <div
              style={{
                height: "130px",
                display: "flex",
                flexDirection: "column",
                justifyContent: "space-between",
              }}
            >
              <div
                class="flex-between "
                style={{ padding: "10px 15px; margin-bottom: 3px;" }}
              >
                <p>Overall Budget VS Actual</p>
              </div>
              {/* <h2 style={{ padding: "0px 15px", fontFamily: "system-ui" }}>
                <b>{data?.length || 0}</b>
              </h2> */}
            </div>
          </div>
        </div>
        <div
          onClick={() => getBudgetVsActualByMdas(mdaId)}
          class="page-6-card border w-100 pointer p-10"
          // style={{ backgroundColor: "transparent", margin: "0px 2.5px;" }}
        >
          <div style={{ borderBottom: "3px solid green" }}>
            <div
              style={{
                height: "130px",
                display: "flex",
                flexDirection: "column",
                justifyContent: "space-between",
              }}
            >
              <div
                class="flex-between "
                style={{ padding: "10px 15px; margin-bottom: 3px;" }}
              >
                <p>Overall Budget VS Actual By MDA</p>
              </div>
              {/* <h2 style={{ padding: "0px 15px", fontFamily: "system-ui" }}>
                <b>{acceptedBudgetEnvelopeArray?.length || 0}</b>
              </h2> */}
            </div>
          </div>
        </div>
        <div
          onClick={() => getOverallAdminDetailsByAll()}
          class="page-6-card border w-100 pointer p-10"
          // style={{ backgroundColor: "transparent", margin: "0px 2.5px;" }}
        >
          <div style={{ borderBottom: "3px solid green" }}>
            <div
              style={{
                height: "130px",
                display: "flex",
                flexDirection: "column",
                justifyContent: "space-between",
              }}
            >
              <div
                class="flex-between "
                style={{ padding: "10px 15px; margin-bottom: 3px;" }}
              >
                <p>Overall Admin Reports</p>
              </div>
              {/* <h2 style={{ padding: "0px 15px", fontFamily: "system-ui" }}>
                <b>{acceptedAssignedToEnterDetailsArray?.length || 0}</b>
              </h2> */}
            </div>
          </div>
        </div>
        <div
          onClick={() => getOverallAdminDetailsByMdaId(mdaId)}
          class="page-6-card border w-100 pointer p-10"
          // style={{ backgroundColor: "transparent", margin: "0px 2.5px;" }}
        >
          <div style={{ borderBottom: "3px solid green" }}>
            <div
              style={{
                height: "130px",
                display: "flex",
                flexDirection: "column",
                justifyContent: "space-between",
              }}
            >
              <div
                class="flex-between "
                style={{ padding: "10px 15px; margin-bottom: 3px;" }}
              >
                <p>Overall Admin Reports By MDA</p>
              </div>
              {/* <h2 style={{ padding: "0px 15px", fontFamily: "system-ui" }}>
                <b>{acceptedAssignedToEnterDetailsArray?.length || 0}</b>
              </h2> */}
            </div>
          </div>
        </div>
      </div>

      <div className="p-10 bold m-t-10" style={{ backgroundColor: "#d5ffd5" }}>
        <p className="no-margin">Budget Reporting</p>

        <div className="" style={{ width: "40%" }}>
          <Select
            // mode="multiple"
            showSearch
            filterOption={(input, option) =>
              option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
            }
            filterSort={(optionA, optionB) =>
              optionA.children
                .toLowerCase()
                .localeCompare(optionB.children.toLowerCase())
            }
            onChange={(e) => {
              setMdaId(e.value);
              isDetailed == "first"
                ? getBudgetVsActualByMdas(e.value)
                : isDetailed === "second"
                ? getOverallAdminDetailsByMdaId(e.value)
                : alert(e);
              // getApprovedMdasByID(SelectedbudgetPeriod, e?.value);
              setSelectedMdaId(e);
            }}
            labelInValue
            // disabled={!SelectedbudgetPeriod}
            allowClear
            value={SelectedMdaId}
            style={{ width: "100%", border: "" }}
            placeholder="Please search and select MDA"
          >
            {Array.isArray(listOfMdas) &&
              listOfMdas?.map((obj) => (
                <Option value={obj?.id} key={obj?.id}>{`${obj?.name}`}</Option>
              ))}
          </Select>
        </div>
      </div>
      {viewMdaId && (
        <div class="flex" style={{ gap: 10 }}>
          <div
            class="page-6-card border w-100 pointer p-10"
            // style={{ backgroundColor: "transparent", margin: "0px 2.5px;" }}
          >
            <div style={{ borderBottom: "3px solid green" }}>
              <div
                style={{
                  height: "130px",
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "space-between",
                }}
              >
                <div
                  class="flex-between"
                  style={{ padding: "10px 15px; margin-bottom: 3px;" }}
                >
                  <p>Total Budget</p>
                </div>
                <h2 style={{ padding: "0px 15px", fontFamily: "system-ui" }}>
                  <b>&#8358; {numberWithCommas(data?.totalBudget?.toFixed(2)) || 0}</b>
                </h2>
              </div>
            </div>
          </div>
          <div
            class="page-6-card border w-100 pointer p-10"
            // style={{ backgroundColor: "transparent", margin: "0px 2.5px;" }}
          >
            <div style={{ borderBottom: "3px solid green" }}>
              <div
                style={{
                  height: "130px",
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "space-between",
                }}
              >
                <div
                  class="flex-between "
                  style={{ padding: "10px 15px; margin-bottom: 3px;" }}
                >
                  <p>Actual Spent</p>
                </div>
                <h2 style={{ padding: "0px 15px", fontFamily: "system-ui" }}>
                  <b>&#8358; {numberWithCommas(data?.actualSpent?.toFixed(2)) || 0}</b>
                </h2>
              </div>
            </div>
          </div>
          <div
            class="page-6-card border w-100 pointer p-10"
            // style={{ backgroundColor: "transparent", margin: "0px 2.5px;" }}
          >
            <div style={{ borderBottom: "3px solid green" }}>
              <div
                style={{
                  height: "130px",
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "space-between",
                }}
              >
                <div
                  class="flex-between "
                  style={{ padding: "10px 15px; margin-bottom: 3px;" }}
                >
                  <p>Percentage Utilization</p>
                </div>
                <h2 style={{ padding: "0px 15px", fontFamily: "system-ui" }}>
                  <b>{data?.percentageUtilization?.toFixed(2) || 0} %</b>
                </h2>
              </div>
            </div>
          </div>
        </div>
      )}

      {showDataSetail && (
        <div>
          {isDetailed == "second" && (
            <div>
              <Button
                onClick={(e) => {
                  setbudgetButtonType("overhead");
                  setdataDetailMda(dataDetailMdaAll?.overHeadReport);
                }}
                style={{
                  backgroundColor:
                    budgetButtonType == "overhead" ? "green" : "yellow",
                  color:  budgetButtonType == "overhead" ? "white" : "black"
                }}
              >
                Overhead
              </Button>
              <Button
                onClick={(e) => {
                  setbudgetButtonType("capital");
                  setdataDetailMda(dataDetailMdaAll?.capitalReport);
                }}
                style={{
                  backgroundColor:
                    budgetButtonType == "capital" ? "green" : "yellow",
                  color:  budgetButtonType == "capital" ? "white" : "black"
                }}
              >
                Capital
              </Button>
              <Button
                onClick={(e) => {
                  setbudgetButtonType("personnel");
                  setdataDetailMda(dataDetailMdaAll?.personnelReport)

                }}
                style={{
                  backgroundColor:
                    budgetButtonType == "personnel" ? "green" : "yellow",
                  color:  budgetButtonType == "personnel" ? "white" : "black"
                }}
              >
                Personnel
              </Button>
            </div>
          )}
          <div style={{ width: "100%", overflowX: "auto" }}>
            <ReportTableAll data={dataDetail} dataDetailMda={dataDetailMda} />
          </div>
        </div>
      )}
    </div>
  );
};

export default Dashboard;
