import { Form, Input, InputNumber, Popconfirm, Table, Typography } from "antd";
import moment from "moment";
import React, { useEffect, useState } from "react";

const originData = [];
for (let i = 0; i < 100; i++) {
  originData.push({
    key: i.toString(),
    name: `Edrward ${i}`,
    age: 32,
    address: `London Park no. ${i}`,
  });
}
const EditableCell = ({
  editing,
  dataIndex,
  title,
  inputType,
  record,
  index,
  children,
  ...restProps
}) => {
  const inputNode =
    inputType === "number" ? (
      <InputNumber
        formatter={(value) =>
          `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
        }
        parser={(value) => value?.replace(/\$\s?|(,*)/g, "")}
      />
    ) : (
      <Input />
    );
  return (
    <td {...restProps}>
      {editing ? (
        <Form.Item
          name={dataIndex}
          style={{
            margin: 0,
          }}
          // rules={[
          //   {
          //     required: true,
          //     message: `Please Input ${title}!`,
          //   },
          // ]}
        >
          {inputNode}
        </Form.Item>
      ) : (
        children
      )}
    </td>
  );
};
const EditableTableOverHead = (props) => {
  const [form] = Form.useForm();
  const [data, setData] = useState([]);
  const [ongoingP, setOngoingP] = useState(originData);
  const [editingKey, setEditingKey] = useState("");
  const isEditing = (record) => record.key === editingKey;

  const edit = (record) => {
    form.setFieldsValue({
      deptBudgetId: "3fa85f64-5717-4562-b3fc-2c963f66afa6",
      economicCode: "string",
      economicCodeDetails: "string",
      fundCodeMain: "string",
      fundCodeDetails: "string",
      adminCode: "string",
      details: "string",
      proposedRevenue: 0,
      previousApprovedRevenue: 0,
      currentRevenueAsAt: 0,
      forecastOfCurrentRevenue: 0,
      previousGeneratedRevenue: 0,

      ...record,
    });
    setEditingKey(record.key);
  };
  const cancel = () => {
    setEditingKey("");
  };
  const save = async (key) => {
    try {
      const row = await form.validateFields();
      const newData = [...data];
      const index = newData.findIndex((item) => key === item.key);
      if (index > -1) {
        const item = newData[index];
        newData.splice(index, 1, {
          ...item,
          ...row,
        });
        console.log(newData);

        setData(newData);
        setEditingKey("");
      } else {
        newData.push(row);
        setData(newData);
        setEditingKey("");
        console.log(newData);
      }
    } catch (errInfo) {
      console.log("Validate Failed:", errInfo);
    }
  };

  const ongoingProjectsColumn = [
    {
      title: "Economic Code",
      dataIndex: "economicCode",
      editable: true,
      key: "name",
      render: (text) => <a>{text}</a>,
    },
    {
      title: "details",
      dataIndex: "details",
      editable: true,
      key: "details",
    },
    {
      title: "frequency Per Month",
      dataIndex: "frequencyPerMonth",
      editable: true,
      key: "frequencyPerMonth",
      //  width: "20%",
    },

    {
      title: "units ",
      editable: true,
      dataIndex: "units",
      key: "units",
      //  width: "20%",
    },
    {
      title: "cost Per Unit",
      editable: true,
      dataIndex: "costPerUnit",
      key: "costPerUnit",
      //  width: "20%",
    },

    // {
    //   title: "targets",
    //   dataIndex: "targets",
    //   key: "targets",
    //   //  width: "20%",
    // },
    {
      title: "cost Per Month",
      dataIndex: "costPerMonth",
      editable: true,
      key: "costPerMonth",
      //   width: "20%",
    },
    {
      title: "cost Per Annum",
      dataIndex: "costPerAnnum",
      editable: true,
      key: "costPerAnnum",
      //   width: "20%",
    },
    {
      title: " Remarks",
      dataIndex: "remarks",
      editable: true,
      key: "remarks",
      //   width: "20%",
    },
    {
      title: "operation",
      dataIndex: "operation",
      render: (_, record) => {
        const editable = isEditing(record);
        return editable ? (
          <span>
            <Typography.Link
              onClick={() => save(record.key)}
              style={{
                marginRight: 8,
              }}
            >
              Save
            </Typography.Link>
            <Popconfirm title="Sure to cancel?" onConfirm={cancel}>
              <a>Cancel</a>
            </Popconfirm>
          </span>
        ) : (
          <Typography.Link
            disabled={editingKey !== ""}
            // onClick={() => edit(record)}
          >
            Edit
          </Typography.Link>
        );
      },
    },
  ];

  useEffect(() => {
    createData();
  }, [props.propzApproved]);

  const createData = () => {
    // console.log(props.propzApproved[0]?.ongoingProjects);
    const ongoingProjectsData = props.propzApproved?.overHeadCostMonitor?.map(
      (e, idx) => ({
        key: idx,
        id: idx,
        // id: "3fa85f64-5717-4562-b3fc-2c963f66afa6",
        dateCreated: "2023-10-23T12:23:22.628Z",
        enteredBy: "string",
        dateUpdated: "2023-10-23T12:23:22.628Z",
        updatedBy: "string",
        isDelete: false,
        dateDeleted: "2023-10-23T12:23:22.628Z",
        // deptBudgetId: "3fa85f64-5717-4562-b3fc-2c963f66afa6",
        economicCode: e.economicCode,
        details: e.details,
        frequencyPerMonth: +e.frequencyPerMonth,
        units: e.units,
        costPerUnit: +e.costPerUnit,
        costPerMonth: +e.costPerMonth,
        costPerAnnum: +e.costPerAnnum,
        remarks: e.remarks,
        ...e,
      })
    );

    console.log(ongoingProjectsData);
    setData(ongoingProjectsData);
  };

  //  console.log(props.propzApproved);
  const columns = [
    {
      title: "Project",
      dataIndex: "name",
      width: "25%",
      editable: true,
    },
    {
      title: "age",
      dataIndex: "age",
      width: "15%",
      editable: true,
    },
    {
      title: "address",
      dataIndex: "address",
      width: "40%",
      editable: true,
    },
    {
      title: "operation",
      dataIndex: "operation",
      render: (_, record) => {
        const editable = isEditing(record);
        return editable ? (
          <span>
            <Typography.Link
              onClick={() => save(record.key)}
              style={{
                marginRight: 8,
              }}
            >
              Save
            </Typography.Link>
            <Popconfirm title="Sure to cancel?" onConfirm={cancel}>
              <a>Cancel</a>
            </Popconfirm>
          </span>
        ) : (
          <Typography.Link
            disabled={editingKey !== ""}
            onClick={() =>  props.isAdminEdit &&edit(record)}
          >
            Edit
          </Typography.Link>
        );
      },
    },
  ];
  const mergedColumns = ongoingProjectsColumn.map((col) => {
    if (!col.editable) {
      return col;
    }
    return {
      ...col,
      onCell: (record) => ({
        record,
        inputType: col.dataIndex === "age" ? "number" : "text",
        dataIndex: col.dataIndex,
        title: col.title,
        editing: isEditing(record),
      }),
    };
  });
  return (
    <Form form={form} component={false}>
      <Table
        components={{
          body: {
            cell: EditableCell,
          },
        }}
        bordered
        dataSource={data}
        columns={mergedColumns}
        rowClassName="editable-row"
        pagination={{
          onChange: cancel,
        }}
      />
    </Form>
  );
};
export default EditableTableOverHead;
