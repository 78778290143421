import React, { useState, useEffect, useContext } from "react";
import {
  Button,
  Select,
  Form,
  Input,
  // Input,
  Table,
  Typography,
  Popconfirm,
  InputNumber,
} from "antd";
import { get, patch, post, del } from "../../api-services/fetch";
import axios from "axios";
import notification from "../../utility/notification";
import Cookies from "js-cookie";
import moment from "moment";
// import { CKEditor } from '@ckeditor/ckeditor5-react';
// import ClassicEditor from '@ckeditor/ckeditor5-build-classic';

// import ViewGovTable from './ViewGovTable';
import { decodeToken } from "../../utility/auth";
import EconomicSegment from "./EconomicSegment";
import EconomicType from "./EconomicType";
import EconomicSubType from "./EconomicSubType";
import EconomicSubSubType from "./EconomicSubSubType";
import EconomicItem from "./EconomicItem";
// import SearchDocument from '../layouts/SearchDocument';
const BASE_API_URL = "https://edogoverp.com/budget/api/v1";
const BASE_API_URLs = "https://edogoverp.com/newbudgetapi/api/v1";
// const BASE_API_URL = 'http://localhost:5000/api/v1';

// allMdaDrop: '/api/employee/mdas',

const originData = [];
for (let i = 0; i < 100; i++) {
  originData.push({
    key: i.toString(),
    name: `Edrward ${i}`,
    age: 32,
    address: `London Park no. ${i}`,
  });
}
const { Text } = Typography;
const { Option } = Select;

const { TextArea } = Input;
const EditableCell = ({
  editing,
  dataIndex,
  title,
  inputType,
  record,
  index,
  children,
  ...restProps
}) => {
  const inputNode =
    inputType === "number" ? (
      <Input
      // formatter={(value) =>
      //   `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
      // }
      // parser={(value) => value?.replace(/\$\s?|(,*)/g, "")}
      />
    ) : (
      <TextArea rows={4} />
    );
  return (
    <td {...restProps}>
      {editing ? (
        <Form.Item
          name={dataIndex}
          style={{
            margin: 0,
          }}
          // rules={[
          //   {
          //     required: true,
          //     message: `Please Input ${title}!`,
          //   },
          // ]}
        >
          {inputNode}
        </Form.Item>
      ) : (
        children
      )}
    </td>
  );
};

const RecurrentRev = (props) => {
  const [commentsBudget, setCommentsBudget] = useState([]);
  const [economicSegment, setEconomicSegment] = useState([]);
  const [tableKey, setTableKey] = useState(1);
  const [economicSegmentId, setEconomicSegmentId] = useState();
  const [economicTypeId, setEconomicTypeId] = useState();
  const [economicSubTypeId, setEconomicSubTypeId] = useState();
  const [economicItemId, setEconomicItemId] = useState();
  const [count, setCount] = useState(0);
  const [economicSubSubTypeId, setEconomicSubSubTypeId] = useState();
  const [fundCode, setFundCode] = useState([]);
  const [functionalCode, setFunctionalCode] = useState([]);
  const [programmeCode, setprogrammeCode] = useState([]);
  const [locationCode, setLocationCode] = useState([]);

  const [tot, settot] = useState({
    name: "Overhead Expenditure",
    Proposed2024: 0,
    Approved2023: 0,
    ActualJanToSep2023: 0,
    Approved2021: 0,
  });

  const [form] = Form.useForm();
  const [data, setData] = useState([]);
  const [ongoingP, setOngoingP] = useState(originData);
  const [editingKey, setEditingKey] = useState("");
  const isEditing = (record) => record.key === editingKey;
  // const { Option } = Select;
  const { Text } = Typography;

  const { Option } = Select;
  useEffect(() => {
    // toGetEconimicItems();
    toGetFundCode();
    toGetFunctioCode();
    toGetProgrammeCode();
    toGetLocationCode();

    setData(
      props?.savedBudgetPlanning[0]?.recurrentRevenues?.map((e) => ({
        ...e,
        key: e.id,
      })) || []
    );
  }, [props?.savedBudgetPlanning]);
  function numberWithCommas(x) {
    return x?.toString()?.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  }
  // const toGetEconimicItems = () => {
  //   axios
  //     .get(`${BASE_API_URLs}/EconomicSegment`)
  //     // .post(`${BASE_API_URL}/budgetcodification`, { ...data })

  //     .then((res) => {
  //       //  setIsLoading(false);

  //       console.log(res);
  //       setEconomicSegment(res.data);
  //       //  notification({
  //       //    title: "Successful",
  //       //    message: "Projects have been successfully sent",
  //       //    type: "success",
  //       //  });
  //     })
  //     .catch((err) => {
  //       console.log(err?.response);
  //       //  setIsLoading(false);
  //       //  notification({
  //       //    title: "Sending Failed",
  //       //    message: `{err?.response?.data}`,
  //       //    type: "danger",
  //       //  });
  //     });
  // };

  const opt = economicSegment.map((e) => (
    <Option value={e.code}>{`${e.economicSegmentName} (${e?.code})`}</Option>
  ));

  const edit = (record) => {
    form.setFieldsValue({
      // label: "",
      // amount: "",
      // comment: "",

      ...record,
    });
    setEditingKey(record.key);
  };
  const cancel = () => {
    setEditingKey("");
  };
  const save = async (key) => {
    try {
      const row = await form.validateFields();
      const newData = [...data];
      const index = newData.findIndex((item) => key === item.key);
      if (index > -1) {
        const item = newData[index];
        newData.splice(index, 1, {
          ...item,
          ...row,
        });
        // props.setEnvelopeData(newData);
        // let yyy =
        //   props.totalfromup -
        //     [...newData].reduce((acc, curr) => {
        //       return acc + curr.proposedRevenue;
        //     }, 0) || 0;

        // console.log(yyy);

        // if (yyy < 0) {
        //   notification({
        //     title: "Warning",
        //     message:
        //       "With this amount you are about to exceeded your allocation for Recurrent Revenue. Please adjust",
        //     type: "danger",
        //   });
        //   return;
        // } else

        // setData(newData);
        props.handleSaved({
          recurrentRevenues: [
            ...newData?.map((e) => ({
              ...e,
              proposedRevenue: +e.proposedRevenue || 0,
              previousApprovedRevenue: +e.previousApprovedRevenue || 0,
              currentRevenueAsAt: +e.currentRevenueAsAt || 0,
              forecastOfCurrentRevenue: +e.forecastOfCurrentRevenue || 0,
              previousGeneratedRevenue: +e.previousGeneratedRevenue || 0,
            })),
          ],
        });

        setEditingKey("");
      } else {
        newData.push(row);
        setData(newData);
        // props.handleSaved({ recurrentRevenues: [...newData] });

        setEditingKey("");
        console.log(newData);
      }
    } catch (errInfo) {
      console.log("Validate Failed:", errInfo);
    }
  };

  // const todelete = (key) => {
  //   const newData = [...data];

  //   const remAfterdelete = newData.filter((item) => key !== item.key);
  //   console.log(remAfterdelete);

  //   setData(remAfterdelete);
  // };

  const todelete = async (id) => {
    const newData = [...data];

    // const remAfterdelete = newData.filter((item) => key !== item.key);
    // console.log(remAfterdelete);

    // setnewLoading(true);
    // setL

    // /  handleDelete = async (id) => {

    if (true) {
      // axios
      //     .post(
      //       `https://edogoverp.com/newbudgetapi/api/v1/BudgetPlanning`,
      //       payload
      //     )
      //     .then((response) => {
      let res = await del({
        endpoint: `/api/v1/BudgetPlanning/DeleteRecurreentRevenue?id=${id}`,
        auth: true,
        // pQuery: { id },
      });

      if ((res && res.status == 200) || res.status < 300) {
        // setnewLoading(false);

        props.getSavedBudgetPlanning();
        // notify(store, 'success', 'department budget added successfully');
        notification({
          title: "MESSAGE",
          message: `
                  ${"department budget deleted successfully"}`,
          type: "success",
          // container:'top-center'
          // animationIn: ['animate__animated', 'animate__slideIn'],
        });
      } else {
        if (res?.data?.status > 300) {
          notification({
            title: "MESSAGE",
            message:
              res?.data?.title ||
              `
                    ${"An error occured contact support"}`,
            type: "danger",
            // container:'top-center'
            // animationIn: ['animate__animated', 'animate__slideIn'],
          });
        }
      }
    } else
      notification({
        title: "ERROR MESSAGE",
        message: `
            Make Sure Approvers and Admin code have been Selected`,
        type: "danger",
        // container:'top-center'
        // animationIn: ['animate__animated', 'animate__slideIn'],
      });
  };

  const reccurentRevenueColumn = [
    {
      title: "Economic Code",
      dataIndex: "economicCode",
      editable: true,
      key: "name",
      render: (text) => <a>{text}</a>,
    },
    {
      title: "Details of Revenue",
      dataIndex: "details",
      key: "age",
      editable: true,
    },
    {
      title: "Location code",
      dataIndex: "geoCode",
      key: "geoCode",
      editable: true,

      // editable: true,
      //  width: "20%",
    },
    {
      title: "Program code",
      dataIndex: "programmeCode",
      key: "programmeCode",
      editable: true,

      // editable: true,
      //  width: "20%",
    },
    {
      title: "Proposed Budget 2024",
      dataIndex: "proposedRevenue",
      editable: true,
      key: "address",
      render: (text) => <a>{numberWithCommas(text)}</a>,

      //  width: "20%",
    },
    {
      title: "Current Budget 2023 ",
      dataIndex: "previousApprovedRevenue",
      editable: true,
      key: "address",
      render: (text) => <a>{numberWithCommas(text)}</a>,

      //  width: "20%",
    },
    {
      title: "Actual Budget Jan-Sept 2023 ",
      dataIndex: "currentRevenueAsAt",
      editable: true,
      key: "address",
      render: (text) => <a>{numberWithCommas(text)}</a>,

      //  width: "20%",
    },
    {
      title: "Forecast of Actual Revenue up to 31st December (Annualised)",
      dataIndex: "forecastOfCurrentRevenue",
      editable: true,
      key: "address",
      render: (text) => <a>{numberWithCommas(text)}</a>,

      //  width: "20%",
    },

    {
      title: "Actual Budget 2022 ",
      dataIndex: "previousGeneratedRevenue",
      editable: true,
      key: "address",
      render: (text) => <a>{numberWithCommas(text)}</a>,

      //  width: "20%",
    },
    {
      title: "operation",
      dataIndex: "operation",
      render: (_, record) => {
        const editable = isEditing(record);
        return editable ? (
          <span>
            <Typography.Link
              onClick={() => save(record.key)}
              style={{
                marginRight: 8,
              }}
            >
              Save
            </Typography.Link>
            <Popconfirm title="Sure to cancel?" onConfirm={cancel}>
              <a>Cancel</a>
            </Popconfirm>
          </span>
        ) : (
          <div className="flex justify-between">
            <div style={{ marginRight: "10px" }}>
              <Typography.Link
                className="mr-10"
                disabled={editingKey !== ""}
                onClick={() => edit(record)}
              >
                Edit
              </Typography.Link>
            </div>

            <Typography.Link
              disabled={editingKey !== ""}
              onClick={() => todelete(record.key)}
            >
              Delete
            </Typography.Link>
          </div>
        );
      },
    },
  ];

  const recurrentRevenueData = commentsBudget?.map((e) => ({
    //  deptBudgetId: "fd9de34e-86f6-43c1-98ce-998a4812c289",
    economicCode: e.economicCode,
    details: e.details,
    proposedRevenue: e.proposedRevenue,
    previousApprovedRevenue: e.previousApprovedRevenue || 0,
    currentRevenueAsAt: e.currentRevenueAsAt || 0,
    forecastOfCurrentRevenue: e.forecastOfCurrentRevenue || 0,
    previousGeneratedRevenue: e.previousGeneratedRevenue || 0,
  }));

  let jj = {
    deptBudgetId: "3fa85f64-5717-4562-b3fc-2c963f66afa6",
    economicCode: "string",
    economicCodeDetails: "string",
    fundCodeMain: "string",
    fundCodeDetails: "string",
    adminCode: "string",
    details: "string",
    proposedRevenue: 0,
    previousApprovedRevenue: 0,
    currentRevenueAsAt: 0,
    forecastOfCurrentRevenue: 0,
    previousGeneratedRevenue: 0,
  };

  const onFinish = (values) => {
    let payload = {
      adminCode: props?.adminCode,
      // deptBudgetId: "3fa85f64-5717-4562-b3fc-2c963f66afa6",
      deptBudgetId: "00000000-0000-0000-0000-000000000000",
      budgetPeriodId: "00000000-0000-0000-0000-000000000000",

      key: tableKey,
      economicCode: values.economicCode,
      economicCodeDetails: values.economicCodeDetails,

      fundCodeMain: values.fundCodeMain,
      fundCodeDetails: values.fundCodeDetails,
      mdaId: +Cookies.get("requestsMdaId"),

      details: values.details,
      proposedRevenue: +values.proposedRevenue || 0,
      previousApprovedRevenue: +values.previousApprovedRevenue || 0,
      currentRevenueAsAt: +values.currentRevenueAsAt || 0,
      forecastOfCurrentRevenue: +values.forecastOfCurrentRevenue || 0,
      previousGeneratedRevenue: +values.previousGeneratedRevenue || 0,

      programmeCode: values.programmeCode,
      fundCodeMain: values.fundDetailsCode,
      functionalCode: values.functionalDetailsCode,
      geoCode: values.locationCode,
    };
    // console.log("Success:", values);
    // let yyy =
    //   props.totalfromup -
    //     [...data, payload].reduce((acc, curr) => {
    //       return acc + curr.proposedRevenue;
    //     }, 0) || 0;

    // console.log(yyy);

    // if (yyy < 0) {
    //   notification({
    //     title: "Warning",
    //     message:
    //       "You have exceeded your allocation for Recurrent Revenue. Please adjust",
    //     type: "danger",
    //   });
    //   return;
    // } else
    // {
    // setData([...data, payload]);
    props.handleSaved({ recurrentRevenues: [...data, payload] });

    setCount((prev) => prev + 1);
    // }
    setCommentsBudget([...commentsBudget, payload]);

    // createTotal(payload);

    // props.recurrentR(payload);
    setTableKey((prev) => prev + 1);
  };

  useEffect(() => {
    props.recurrentR(data);
    createTotal(data);
  }, [data]);
  const createTotal = (payload) => {
    console.log(payload);
    props.total({
      name: "Recurrent Revenue",
      // Proposed2024: +tot.Proposed2024 + +payload.proposedRevenue,
      // Approved2023: +tot.Approved2023 + +payload.previousApprovedRevenue,
      // ActualJanToSep2023: +tot.ActualJanToSep2023 + +payload.currentRevenueAsAt,
      // Approved2021: +tot.Approved2021 + +payload.previousGeneratedRevenue,
      Proposed2024:
        (payload &&
          payload?.length &&
          payload?.reduce((acc, curr) => {
            return +acc + +curr.proposedRevenue;
          }, 0)) ||
        0,
      // +tot.Proposed2024 + +payload.proposedBudget,
      Approved2023:
        (payload &&
          payload?.length &&
          payload?.reduce((acc, curr) => {
            return +acc + +curr.previousApprovedRevenue;
          }, 0)) ||
        0,
      // +tot.Approved2023 + +payload.previousApprovedBudget,
      ActualJanToSep2023:
        (payload &&
          payload?.length &&
          payload?.reduce((acc, curr) => {
            return +acc + +curr.currentRevenueAsAt;
          }, 0)) ||
        0,
      // +tot.ActualJanToSep2023 + +payload.currentExpenditures,
      Approved2021:
        (payload &&
          payload?.length &&
          payload?.reduce((acc, curr) => {
            return +acc + +curr.previousGeneratedRevenue;
          }, 0)) ||
        0,
    });
    settot({
      name: "Recurrent Revenue",

      Proposed2024:
        (payload &&
          payload?.length &&
          payload?.reduce((acc, curr) => {
            return +acc + +curr.proposedRevenue;
          }, 0)) ||
        0,
      // +tot.Proposed2024 + +payload.proposedBudget,
      Approved2023:
        (payload &&
          payload?.length &&
          payload?.reduce((acc, curr) => {
            return +acc + +curr.previousApprovedRevenue;
          }, 0)) ||
        0,
      // +tot.Approved2023 + +payload.previousApprovedBudget,
      ActualJanToSep2023:
        (payload &&
          payload?.length &&
          payload?.reduce((acc, curr) => {
            return +acc + +curr.currentRevenueAsAt;
          }, 0)) ||
        0,
      // +tot.ActualJanToSep2023 + +payload.currentExpenditures,
      Approved2021:
        (payload &&
          payload?.length &&
          payload?.reduce((acc, curr) => {
            return +acc + +curr.previousGeneratedRevenue;
          }, 0)) ||
        0,
      // Proposed2024: +tot.Proposed2024 + +payload.proposedRevenue,
      // Approved2023: +tot.Approved2023 + +payload.previousApprovedRevenue,
      // ActualJanToSep2023: +tot.ActualJanToSep2023 + +payload.currentRevenueAsAt,
      // Approved2021: +tot.Approved2021 + +payload.previousGeneratedRevenue,
    });
  };

  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };

  //  "deptBudgetId": "3fa85f64-5717-4562-b3fc-2c963f66afa6",
  // "project": "string",
  // "status": "string",
  // "location": "string",
  // "startDate": "2023-10-11T17:08:24.133Z",
  // "amountSpent": 0,
  // "conclusionEstimate": 0,
  // "completionTime": 0,
  // "pId": 0,
  // "mdaId": 0
  // const [form] = Form.useForm();
  const mergedColumns = reccurentRevenueColumn.map((col) => {
    if (!col.editable) {
      return col;
    }
    return {
      ...col,
      onCell: (record) => ({
        record,
        inputType: col.dataIndex === "comment" ? "text" : "number",
        dataIndex: col.dataIndex,
        title: col.title,
        editing: isEditing(record),
      }),
    };
  });

  const toGetFundCode = async () => {
    // axios
    //   .get(`${BASE_API_URLs}/FundSegment`)

    //   .then((res) => {
    const res = await get({
      endpoint: `/api/v1/FundSegment`,
      auth: true,
      // body: payload,
    });

    if (res && res.status == 200) {
      console.log(res);
      setFundCode(res.data);
    } else {
      // console.log(err?.response);
      //  setIsLoading(false);
      //  notification({
      //    title: "Sending Failed",
      //    message: `{err?.response?.data}`,
      //    type: "danger",
      //  });
    }
  };

  const toGetFunctioCode = async () => {
    // axios
    // .get(`${BASE_API_URLs}/FunctionalSegment`)
    // // .post(`${BASE_API_URL}/budgetcodification`, { ...data })

    // .then((res) => {
    const res = await get({
      endpoint: `/api/v1/FunctionalSegment`,
      auth: true,
      // body: payload,
    });

    if (res && res.status == 200) {
      //  setIsLoading(false);

      console.log(res);
      setFunctionalCode(res.data);
      //  notification({
      //    title: "Successful",
      //    message: "Projects have been successfully sent",
      //    type: "success",
      //  });
    } else {
      // console.log(err?.response);
      //  setIsLoading(false);
      //  notification({
      //    title: "Sending Failed",
      //    message: `{err?.response?.data}`,
      //    type: "danger",
      //  });
    }
  };
  const toGetProgrammeCode = async () => {
    // axios
    //   .get(`${BASE_API_URLs}/Programme`)
    //   // .post(`${BASE_API_URL}/budgetcodification`, { ...data })

    //   .then((res) => {
    //     //  setIsLoading(false);
    const res = await get({
      endpoint: `/api/v1/Programme`,
      auth: true,
      // body: payload,
    });

    if (res && res.status == 200) {
      //  setIsLoading(false);

      console.log(res);
      setprogrammeCode(res.data);
      //  notification({
      //    title: "Successful",
      //    message: "Projects have been successfully sent",
      //    type: "success",
      //  });
    } else {
      // console.log(err?.response);
      //  setIsLoading(false);
      //  notification({
      //    title: "Sending Failed",
      //    message: `{err?.response?.data}`,
      //    type: "danger",
      //  });
    }
  };
  const toGetLocationCode = async () => {
    // axios
    // .get(`${BASE_API_URLs}/GeoCode`)
    // // .post(`${BASE_API_URL}/budgetcodification`, { ...data })

    // .then((res) => {
    const res = await get({
      endpoint: `/api/v1/GeoCode`,
      auth: true,
      // body: payload,
    });

    if (res && res.status == 200) {
      //  setIsLoading(false);

      //  setIsLoading(false);

      console.log(res);
      setLocationCode(res.data);
      //  notification({
      //    title: "Successful",
      //    message: "Projects have been successfully sent",
      //    type: "success",
      //  });
    } else {
      // console.log(err?.response);
      //  setIsLoading(false);
      //  notification({
      //    title: "Sending Failed",
      //    message: `{err?.response?.data}`,
      //    type: "danger",
      //  });
    }
  };

  // const functionalCodes = functionalCode.map((e) => (
  //   <Option value={e.code}>{`${e.functionalSegmentName} (${e?.code})`}</Option>
  // ));
  // const programmeCodes = programmeCode.map((e) => (
  //   <Option value={e.programmeCode}>
  //     {`${e.programmeName} (${e?.programmeCode})`}
  //   </Option>
  // ));
  // const locationCodes = locationCode.map((e) => (
  //   <Option value={e.code}>{`${e.geoCodeName} (${e?.code})`}</Option>
  // ));

  const collectEconomicSegmentId = (id) => {
    form.setFieldsValue({ economicCode: id.key });
    setEconomicSegmentId(id.value);
  };
  const collectEconomicTypeId = (id) => {
    form.setFieldsValue({ economicCode: id.key });
    setEconomicTypeId(id.value);
  };
  const collectEconomicSubTypeId = (id) => {
    form.setFieldsValue({ economicCode: id.key });
    setEconomicSubTypeId(id.value);
  };
  const collectEconomicSubSubTypeId = (id) => {
    form.setFieldsValue({ economicCode: id.key });
    setEconomicSubSubTypeId(id.value);
  };
  const collectEconomicItem = (id) => {
    form.setFieldsValue({ economicCode: id.key });
    setEconomicItemId(id.value);
  };

  const functionalCodes = functionalCode.map((e) => (
    <Option value={e.code}>{`${e.functionalSegmentName} (${e?.code})`}</Option>
  ));
  const fundCodes = fundCode.map((e) => (
    <Option value={e.fundSegmentCode}>
      {`${e.fundSegmentName} (${e?.fundSegmentCode})`}
    </Option>
  ));
  const programmeCodes = programmeCode.map((e) => (
    <Option value={e.programmeCode}>
      {`${e.programmeName} (${e?.programmeCode})`}
    </Option>
  ));
  const locationCodes = locationCode.map((e) => (
    <Option value={e.code}>{`${e.geoCodeName} (${e?.code})`}</Option>
  ));

  return (
    <div className="w-100">
      <h2>Recurrent Revenue</h2>
      <div
        className="w-full flex mb-10"
        style={{ gap: 10, marginBottom: "10px" }}
      >
        <EconomicSegment
          collectEconomicSegmentId={collectEconomicSegmentId}
          count={count}
          prefix={props.filter}
        />
        <EconomicType
          collectEconomicTypeId={collectEconomicTypeId}
          count={count}
          prefix={props.filter}
          id={economicSegmentId}
        />
        <EconomicSubType
          collectEconomicSubTypeId={collectEconomicSubTypeId}
          count={count}
          prefix={props.filter}
          id={economicTypeId}
        />
        <EconomicSubSubType
          collectEconomicSubSubTypeId={collectEconomicSubSubTypeId}
          count={count}
          prefix={props.filter}
          id={economicSubTypeId}
        />
        <EconomicItem
          collectEconomicItem={collectEconomicItem}
          count={count}
          prefix={props.filter}
          id={economicSubSubTypeId}
        />
      </div>

      <Form
        layout="vertical"
        form={form}
        // labelCol={{
        //   span: 8,
        // }}
        // wrapperCol={{
        //   span: 16,
        // }}
        // initialValues={{
        //   remember: true,
        // }}ber: true,
        // }}
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
        autoComplete="off"
      >
        <div class="flex-between">
          {/* <Form.Item
            className="m-r-10"
            label="Funds Code"
              style={{ width: `100%` }}
              name="fundDetailsCode"
          >
            <Select
              style={{ width: `100%` }}
              // onChange={handleChange}
            >
              {fundCodes}
            </Select> 
          </Form.Item>*/}
          <Form.Item
            className="m-r-10"
            label="Funds Code"
            style={{ width: `100%` }}
            name="fundDetailsCode"
          >
            <Select
              filterOption={(input, option) =>
                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
              filterSort={(optionA, optionB) =>
                optionA.children
                  .toLowerCase()
                  .localeCompare(optionB.children.toLowerCase())
              }
              allowClear
              style={{ width: `100%` }}
              // onChange={handleChange}
            >
              {fundCodes}
            </Select>
          </Form.Item>
          <Form.Item
            className="m-r-10"
            label="Functional Code"
            name="functionalDetailsCode"
            style={{ width: `100%` }}
          >
            <Select
              filterOption={(input, option) =>
                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
              filterSort={(optionA, optionB) =>
                optionA.children
                  .toLowerCase()
                  .localeCompare(optionB.children.toLowerCase())
              }
              allowClear
              // onChange={handleChange}
              // onChange={handleChange}
              style={{ width: `100%` }}
              // onChange={handleChange}
            >
              {functionalCodes}
            </Select>
          </Form.Item>
          <Form.Item
            label="Programme Code"
            className="m-r-10"
            style={{ width: `100%` }}
            name="programmeCode"
          >
            <Select
              filterOption={(input, option) =>
                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
              filterSort={(optionA, optionB) =>
                optionA.children
                  .toLowerCase()
                  .localeCompare(optionB.children.toLowerCase())
              }
              allowClear
              style={{ width: `100%` }}
              // onChange={handleChange}
            >
              {programmeCodes}
            </Select>
          </Form.Item>
          <Form.Item
            className="m-r-10"
            label="Location Code"
            style={{ width: `100%` }}
            name="locationCode"
          >
            <Select
              filterOption={(input, option) =>
                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
              filterSort={(optionA, optionB) =>
                optionA.children
                  .toLowerCase()
                  .localeCompare(optionB.children.toLowerCase())
              }
              allowClear
              style={{ width: `100%` }}
              // onChange={handleChange}
            >
              {locationCodes}
            </Select>
          </Form.Item>
          <Form.Item
            style={{ width: `100%` }}
            label="Economic Code"
            name="economicCode"
          >
            {/* <Select
            labelInValue
            showSearch
            style={{ width: "100%" }}
            placeholder="Search to Select"
            optionFilterProp="children"
            filterOption={(input, option) =>
              option?.children?.toLowerCase()?.indexOf(input?.toLowerCase()) >=
              0
            }
            filterSort={(optionA, optionB) =>
              optionA?.children
                ?.toLowerCase()
                ?.localeCompare(optionB?.children?.toLowerCase())
            }
            // defaultValue="lucy"
            // style={{ width: "50%" }}
            onChange={(e) => {
              console.log();
              form.setFieldsValue({
                details: e?.label,
              });
            }}
          >
            {opt}
          </Select> */}
            <Input style={{ width: `100%` }} readOnly />
          </Form.Item>
        </div>

        {/* <Form.Item
          label="Economic Code"
          name="economicCode"
          rules={[
            {
              required: true,
              message: "Please input your username!",
            },
          ]}
        >
          <Input />
        </Form.Item> */}
        <div class="flex-between">
          <div className="w-100 m-r-10">
            <Form.Item label="Details Of Revenue" name="details">
              <TextArea />
            </Form.Item>
            <Form.Item
              label="Actual Budget 2022"
              name="previousGeneratedRevenue"
            >
              <Input
                formatter={(value) =>
                  `₦ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                }
                parser={(value) => value?.replace(/\₦\s?|(,*)/g, "")}
                style={{ width: "100%" }}
              />
            </Form.Item>
            {/* <Form.Item
              label="Proposed Rev Estimates 2023"
              name="proposedRevenue2023"
            >
            



              <Input style={{ width: "100%" }} />
            </Form.Item> */}
          </div>
          <div className="w-100 m-r-10">
            <Form.Item label="Proposed Budget 2024" name="proposedRevenue">
              <Input
                formatter={(value) =>
                  `₦ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                }
                parser={(value) => value?.replace(/\₦\s?|(,*)/g, "")}
                style={{ width: "100%" }}
              />
            </Form.Item>

            <Form.Item
              label="Actual Budget Jan-Sept 2023"
              name="currentRevenueAsAt"
            >
              <Input
                formatter={(value) =>
                  `₦ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                }
                parser={(value) => value?.replace(/\₦\s?|(,*)/g, "")}
                style={{ width: "100%" }}
              />
            </Form.Item>
          </div>
          <div className="w-100 m-r-10">
            <Form.Item
              label="Current Actual Budget(2023)"
              name="previousApprovedRevenue"
            >
              <Input
                formatter={(value) =>
                  `₦ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                }
                parser={(value) => value?.replace(/\₦\s?|(,*)/g, "")}
                style={{ width: "100%" }}
              />
            </Form.Item>

            <Form.Item
              label="Forecast of Current Rev upto 31st Dec 2023"
              name="forecastOfCurrentRevenue"
            >
              <Input
                formatter={(value) =>
                  `₦ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                }
                parser={(value) => value?.replace(/\₦\s?|(,*)/g, "")}
                style={{ width: "100%" }}
              />
            </Form.Item>
          </div>
        </div>

        <Form.Item
        //   wrapperCol={{
        //     offset: 8,
        //     span: 16,
        //   }}
        >
          <Button
            style={{ backgroundColor: "#1d811c" }}
            type="primary"
            htmlType="submit"
          >
            Save
          </Button>
        </Form.Item>

        <div style={{ overflowX: "scroll" }}>
          <div style={{ width: "120%" }}>
            <Table
              components={{
                body: {
                  cell: EditableCell,
                },
              }}
              pagination={{
                onChange: cancel,
              }}
              columns={mergedColumns}
              dataSource={data}
              summary={(pageData) => {
                let totalproposedRevenue = 0;
                let totalpreviousApprovedRevenue = 0;
                let totalcurrentRevenueAsAt = 0;
                let totalpreviousGeneratedRevenue = 0;
                let totalforecastOfCurrentRevenue = 0;
                // proposedRevenue;
                // previousApprovedRevenue;
                // currentRevenueAsAt;
                // forecastOfCurrentRevenue;
                // previousGeneratedRevenue;
                pageData.forEach(
                  ({
                    proposedRevenue,
                    previousApprovedRevenue,
                    currentRevenueAsAt,
                    previousGeneratedRevenue,
                    forecastOfCurrentRevenue,
                  }) => {
                    totalproposedRevenue += +proposedRevenue;
                    totalpreviousApprovedRevenue += +previousApprovedRevenue;
                    totalcurrentRevenueAsAt += +currentRevenueAsAt;
                    totalpreviousGeneratedRevenue += +previousGeneratedRevenue;
                    totalforecastOfCurrentRevenue += +forecastOfCurrentRevenue;

                    // props.total({
                    //   name: "Recurrent Revenue",

                    //   Proposed2024: proposedRevenue,
                    //   Approved2023: totalpreviousApprovedRevenue,
                    //   ActualJanToSep2023: totalcurrentRevenueAsAt,
                    //   Approved2021: totalpreviousGeneratedRevenue,
                    // });
                  }
                );
                return (
                  <>
                    <Table.Summary.Row>
                      <Table.Summary.Cell index={0}>Total</Table.Summary.Cell>
                      <Table.Summary.Cell index={1}></Table.Summary.Cell>
                      <Table.Summary.Cell index={1}></Table.Summary.Cell>
                      <Table.Summary.Cell index={1}></Table.Summary.Cell>
                      <Table.Summary.Cell index={2}>
                        <Text type="success">
                          {`₦${numberWithCommas(totalproposedRevenue)}`}
                        </Text>
                      </Table.Summary.Cell>
                      <Table.Summary.Cell index={3}>
                        <Text type="success">
                          {`₦ ${numberWithCommas(
                            totalpreviousApprovedRevenue
                          )}`}
                        </Text>
                      </Table.Summary.Cell>
                      <Table.Summary.Cell index={4}>
                        <Text type="success">
                          {`₦${numberWithCommas(totalcurrentRevenueAsAt)}`}
                        </Text>
                      </Table.Summary.Cell>
                      {/* <Table.Summary.Cell index={1}></Table.Summary.Cell> */}
                      <Table.Summary.Cell index={5}>
                        <Text type="success">
                          {`₦ ${numberWithCommas(
                            totalforecastOfCurrentRevenue
                          )}`}
                        </Text>
                      </Table.Summary.Cell>
                      <Table.Summary.Cell index={5}>
                        <Text type="success">
                          {`₦${numberWithCommas(
                            totalpreviousGeneratedRevenue
                          )}`}
                        </Text>
                      </Table.Summary.Cell>
                    </Table.Summary.Row>
                    {/* const {Text} = Typography; */}
                    {/* <Table.Summary.Row>
                    <Table.Summary.Cell index={0}>Balance</Table.Summary.Cell>
                    <Table.Summary.Cell index={1} colSpan={2}>
                      <Text type="danger">{totalforecastOfCurrentRevenue}</Text>
                    </Table.Summary.Cell>
                  </Table.Summary.Row> */}
                  </>
                );
              }}
            />
          </div>
        </div>
      </Form>
    </div>
  );
};

export default RecurrentRev;
