import React from 'react';
import { Switch, Route } from 'react-router';
import { pure } from 'recompose';

import ProtectedRoute from './ProtectedRoute';
import SessionHOC from '../components/hoc/SessionHOC';

import Dashboard from '../components/pages/Dashboard';
// import BudgetPlanning from '../components/pages/BudgetPlanning';
import InitiateBudget from '../components/pages/InitiateBudget';
// import ApproversView from '../components/pages/ApproversView';
import ExecView from '../components/pages/ExecView';
import EnvelopeReview from '../components/pages/EnvelopeReview';
// import DetailFinance from '../components/pages/DetailFinance';
// import BudgetTeamReview from '../components/pages/BudgetTeamReview';

// import BudgetItemsSetupC from '../components/pages/BudgetItemsSetupC';
import NewBudgetPlanPage from '../components/pages/NewBudgetPlanPage';
// import BudgetHistory from '../components/pages/BudgetHistory';
// import BudgetCodification from '../components/pages/BudgetCodification';
import BudgetReview from '../components/pages/BudgetReview'; 
// import Finance from '../components/pages/Finance'; 
// import GeneralReport from '../components/pages/GeneralReport'; 
// import VarianceReport from '../components/pages/VarianceReport'; 
import BudgetReq from '../components/pages/BudgetReq'; 
import BudgetTeamReview from '../components/pages/BudgetTeamReview'; 
// import GeoCode from '../components/pages/GeoCode'; 
// import FundsCode from '../components/pages/FundsCode'; 
// import ProgramCode from '../components/pages/ProgramCode'; 
// import FunctionalCode from '../components/pages/FunctionalCode'; 
// import EconomicCode from '../components/pages/EconomicCode'; 
// import FundsType from '../components/pages/FundsType'; 

// import GeneralRequests from '../components/pages/GeneralRequests';
// import MeetingRequest from '../components/pages/MeetingRequest';
import InitiatorsReview from '../components/pages/InitiatorsReview';
// import MeetingManager from '../components/pages/MeetingManager';
// import CreateRequest from '../components/pages/CreateRequest';
// import SummaryReport from '../components/pages/SummaryReport';
import Settings from '../components/pages/Settings';
import BudgetTeamCorrespondence from '../components/pages/BudgetTeamCorrespondence';
import ExecInitiation from '../components/pages/ExecInitiation';
import CameBackToBudgetForReview from '../components/pages/CameBackToBudgetForReview';
import StartBudgetProcess from '../components/pages/StartBudgetProcess.js';
import AdminBudgetReq from '../components/pages/AdminBudgetReq.js';
import AdminBudgetReport from '../components/pages/AdminBudgetReport.js';
// import { FundsType } from '../../../../edoerpclient-master/src/Components/FundsType';

export default pure(() => (
  <Switch>
    <ProtectedRoute path="/budget/" exact component={SessionHOC(Dashboard)} />
    {/* <ProtectedRoute
      path="/budget/create-request"
      exact
      component={SessionHOC(BudgetPlanning)}
    /> */}
    {/* <ProtectedRoute
      path="/budget/budget-summary"
      exact
      // component={SessionHOC(BudgetPlanning)}
    /> */}

    <ProtectedRoute
      path="/budget/Initiate-budget"
      exact
      component={SessionHOC(InitiateBudget)}
    />
    <ProtectedRoute
      path="/budget/review/executive-review"
      exact
      component={SessionHOC(ExecView)}
    />

    {/* <ProtectedRoute
      path='/budget/budget-item-setup'
      exact
      component={SessionHOC(BudgetItemsSetupC)}
      NewBudgetPlanPage
    /> */}
    <ProtectedRoute
      path="/budget/budget-item-setup"
      exact
      component={SessionHOC(NewBudgetPlanPage)}
    />
    <ProtectedRoute
      path="/budget/envelope-review"
      exact
      component={SessionHOC(EnvelopeReview)}
    />
    {/* <ProtectedRoute
      path="/budget/budget-history"
      exact
      component={SessionHOC(BudgetHistory)}
    /> */}
    {/* <ProtectedRoute
    path='/budget/budget-codification'
    exact
    component={SessionHOC(BudgetCodification)}
  />  */}
    {/* <ProtectedRoute
      path="/budget/budget-codification"
      exact
      component={SessionHOC(BudgetCodification)}
    /> */}
    {/* <ProtectedRoute
      path="/budget/finance"
      exact
      component={SessionHOC(Finance)}
    /> */}
    {/* <ProtectedRoute
      path="/budget/geo-code"
      exact
      component={SessionHOC(GeoCode)}
    /> */}
    {/* <ProtectedRoute
      path="/budget/funds-code"
      exact
      component={SessionHOC(FundsCode)}
    /> */}
    {/* <ProtectedRoute
      path="/budget/programme-code"
      exact
      component={SessionHOC(ProgramCode)}
    /> */}
{/* 
    <ProtectedRoute
      path="/budget/functional-code"
      exact
      component={SessionHOC(FunctionalCode)}
    /> */}

    {/* <ProtectedRoute
      path="/budget/economic-code"
      exact
      component={SessionHOC(EconomicCode)}
    /> */}
    {/* <ProtectedRoute
  path='/budget/settings'
  exact
  component={SessionHOC(EconomicCode)}
/> */}

    {/* <ProtectedRoute
      path="/budget/finance-detail:id"
      exact
      component={SessionHOC(DetailFinance)}
    /> */}

    {/* <ProtectedRoute
      path="/budget/general-report"
      exact
      component={SessionHOC(GeneralReport)}
    />
    <ProtectedRoute
      path="/budget/variance-report"
      exact
      component={SessionHOC(VarianceReport)}
    />
    <ProtectedRoute
      path="/budget/report-summary"
      exact
      component={SessionHOC(SummaryReport)}
    /> */}
    <ProtectedRoute
      path="/budget/settings"
      exact
      component={SessionHOC(Settings)}
    />

    <ProtectedRoute
      path="/budget/initiate/budget-initiation"
      exact
      component={SessionHOC(BudgetReq)}
    />

    <ProtectedRoute
      path="/budget/initiate/executive-approvals"
      exact
      component={SessionHOC(ExecInitiation)}
    />
    <ProtectedRoute
      path="/budget/executive/correspondence"
      exact
      component={SessionHOC(ExecView)}
    />

    <ProtectedRoute
      path="/budget/budgetoffice/execreview"
      exact
      component={SessionHOC(CameBackToBudgetForReview)}
    />

    <ProtectedRoute
      path="/budget/budget-review"
      exact
      component={SessionHOC(BudgetReview)}
    />
      <ProtectedRoute
      path="/budget/start-budget-process"
      exact
      component={SessionHOC(StartBudgetProcess)}
    />

<ProtectedRoute
      path="/budget/admin-budget-edit"
      exact
      component={SessionHOC(AdminBudgetReq)}
    />


    <ProtectedRoute
      path="/budget/admin-budget-reporting"
      exact
      component={SessionHOC(AdminBudgetReport)}
    />
    {/* <ProtectedRoute
      path="/budget/requests"
      exact
      component={SessionHOC(GeneralRequests)}
    /> */}
    {/* <ProtectedRoute
      path="/budget/request-manager"
      exact
      component={SessionHOC(RequestManager)}
    /> */}
    {/* <ProtectedRoute
      path="/budget/meeting-request"
      exact
      component={SessionHOC(MeetingRequest)}
    /> */}
    {/* <ProtectedRoute
      path="/budget/meeting-manager"
      exact
      component={SessionHOC(MeetingManager)}
    /> */}
    <ProtectedRoute
      path="/budget/review/correspondence"
      exact
      component={SessionHOC(BudgetTeamCorrespondence)}
    />
    <ProtectedRoute
      path="/budget/initiate/correspondence"
      exact
      component={SessionHOC(InitiatorsReview)}
    />
    <ProtectedRoute
      path="/budget/review/costing"
      exact
      component={SessionHOC(BudgetTeamReview)}
    />
    <Route render={() => <h1>Error 404. Page not found.</h1>} />
  </Switch>
));
