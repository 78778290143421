// import { Button, Checkbox, Form, Input } from "antd";
// import React from "react";

// const PersonelCosts = () => {
//   const onFinish = (values) => {
//     console.log("Success:", values);
//   };

//   const onFinishFailed = (errorInfo) => {
//     console.log("Failed:", errorInfo);
//   };

//   return (
//     <Form
//       name="basic"
//       labelCol={{
//         span: 8,
//       }}
//       wrapperCol={{
//         span: 16,
//       }}
//       initialValues={{
//         remember: true,
//       }}
//       onFinish={onFinish}
//       onFinishFailed={onFinishFailed}
//       autoComplete="off"
//     >
//       <Form.Item
//         label="Username"
//         name="username"
//
//       >
//         <Input />
//       </Form.Item>

//       <Form.Item
//         label="Password"
//         name="password"
//         rules={[
//           {
//             required: true,
//             message: "Please input your password!",
//           },
//         ]}
//       >
//         <Input.Password />
//       </Form.Item>

//       <Form.Item
//         name="remember"
//         valuePropName="checked"
//         wrapperCol={{
//           offset: 8,
//           span: 16,
//         }}
//       >
//         <Checkbox>Remember me</Checkbox>
//       </Form.Item>

//       <Form.Item
//         wrapperCol={{
//           offset: 8,
//           span: 16,
//         }}
//       >
//         <Button type="primary" htmlType="submit">
//           Submit
//         </Button>
//       </Form.Item>
//     </Form>
//   )
// } export default PersonelCosts

import React, { useState, useEffect, useContext } from "react";
import {
  Button,
  Select,
  Form,
  Input,
  // Input,
  Table,
  Typography,
  Popconfirm,
} from "antd";
import { get, patch, post, del } from "../../api-services/fetch";
import axios from "axios";
import notification from "../../utility/notification";
import Cookies from "js-cookie";
import moment from "moment";
// import { CKEditor } from '@ckeditor/ckeditor5-react';
// import ClassicEditor from '@ckeditor/ckeditor5-build-classic';

// import ViewGovTable from './ViewGovTable';
import { decodeToken } from "../../utility/auth";
import EconomicSegment from "./EconomicSegment";
import EconomicType from "./EconomicType";
import EconomicSubType from "./EconomicSubType";
import EconomicSubSubType from "./EconomicSubSubType";
import EconomicItem from "./EconomicItem";
// import SearchDocument from '../layouts/SearchDocument';
const BASE_API_URL = "https://edogoverp.com/budget/api/v1";
// const BASE_API_URLs = "https://edogoverp.com/newbudgetapi/api/v1";
const BASE_API_URLs = "https://edogoverp.com/newbudgetapi/api/v1";

// const BASE_API_URL = 'http://localhost:5000/api/v1';

// allMdaDrop: '/api/employee/mdas',

const originData = [];
for (let i = 0; i < 100; i++) {
  originData.push({
    key: i.toString(),
    name: `Edrward ${i}`,
    age: 32,
    address: `London Park no. ${i}`,
  });
}
const { Text } = Typography;
const { Option } = Select;

const { TextArea } = Input;
const EditableCell = ({
  editing,
  dataIndex,
  title,
  inputType,
  record,
  index,
  children,
  ...restProps
}) => {
  const inputNode = inputType === "number" ? <Input /> : <TextArea rows={4} />;
  return (
    <td {...restProps}>
      {editing ? (
        <Form.Item
          name={dataIndex}
          style={{
            margin: 0,
          }}
          // rules={[
          //   {
          //     required: true,
          //     message: `Please Input ${title}!`,
          //   },
          // ]}
        >
          {inputNode}
        </Form.Item>
      ) : (
        children
      )}
    </td>
  );
};

const PersonelCosts = (props) => {
  const [commentsBudget, setCommentsBudget] = useState([]);
  const [economicSegment, setEconomicSegment] = useState([]);
  const [economicSegmentId, setEconomicSegmentId] = useState();
  const [economicTypeId, setEconomicTypeId] = useState();
  const [economicSubTypeId, setEconomicSubTypeId] = useState();
  const [economicSubSubTypeId, setEconomicSubSubTypeId] = useState();
  const [count, setcount] = useState(0);
  const [economicItemId, setEconomicItemId] = useState();
  const [tot, settot] = useState({
    name: "Overhead Expenditure",
    Proposed2024: 0,
    Approved2023: 0,
    ActualJanToSep2023: 0,
    Approved2021: 0,
  });
  const [tableKey, setTableKey] = useState(1);

  const [form] = Form.useForm();
  const [form2] = Form.useForm();
  const [data, setData] = useState([]);
  const [ongoingP, setOngoingP] = useState(originData);
  const [newLoading, setnewLoading] = useState(false);
  const [fundCode, setFundCode] = useState([]);
  const [functionalCode, setFunctionalCode] = useState([]);
  const [programmeCode, setprogrammeCode] = useState([]);
  const [locationCode, setLocationCode] = useState([]);
  const [editingKey, setEditingKey] = useState("");
  const isEditing = (record) => record.key === editingKey;

  const { Option } = Select;
  const { Text } = Typography;

  useEffect(() => {
    toGetFundCode();
    toGetFunctioCode();
    toGetEconimicItems();
    toGetProgrammeCode();
    toGetLocationCode();

    setData(
      props?.savedBudgetPlanning[0]?.personnelCosts?.map((e) => ({
        ...e,
        key: e.id,
        // economicCodeDetails: e.economicDetails || e?.edonomicDetails,
        // economicDetails: e.economicDetails || e?.economicCodeDetails,
      })) || []
    );
  }, [props?.savedBudgetPlanning]);
  let grade = [];
  for (let grades = 1; grades < 18; grades++) {
    grade = [
      ...grade,
      <Option key={grades} value={`Grade ${grades}`}>
        {`Grade ${grades}`}
      </Option>,
    ];
  }

  const edit = (record) => {
    console.log(record);
    form.setFieldsValue({
      // label: "",
      // amount: "",
      // comment: "",

      ...record,
    });
    setEditingKey(record.key);
  };
  const cancel = () => {
    setEditingKey("");
  };
  const save = async (key) => {
    console.log(key);

    try {
      const row = await form.validateFields();

      console.log(row);
      const newData = [...data];
      const index = newData.findIndex((item) => key === item.key);
      if (index > -1) {
        const item = newData[index];
        newData.splice(index, 1, {
          ...item,
          ...row,
        });
        // props.setEnvelopeData(newData);
        let yyy =
          props.totalfromup -
            [...newData]?.reduce((acc, curr) => {
              return +acc + +curr.proposedExpense;
            }, 0) || 0;

        console.log(yyy);

        if (yyy < 0) {
          notification({
            title: "Warning",
            message:
              "With this amount you are about to exceeded your allocation for Personnell cost. Please adjust",
            type: "danger",
          });
          return;
        } else {
          // setData(newData);
          props.handleSaved({
            personnelCosts: [
              ...newData?.map((e) => ({
                ...e,
                proposedExpense: +e.proposedExpense || 0,
                prevNoOfStaff: +e.prevNoOfStaff || 0,
                prevApprovedExpense: +e.prevApprovedExpense || 0,
                currentExpenseAsAt: +e.currentExpenseAsAt || 0,
                previousExpense: +e.previousExpense || 0,
              })),
            ],
          });
        }
        setEditingKey("");
      } else {
        newData.push(row);
        setData(newData);
        // props.handleSaved({ personnelCosts: [ ...newData?.map((e) => ({
        //   ...e,
        //   proposedExpense: +e.proposedExpense || 0,
        //   // prevNoOfStaff: +e.prevNoOfStaff || 0,
        //   prevApprovedExpense: +e.prevApprovedExpense || 0,
        //   currentExpenseAsAt: +e.currentExpenseAsAt || 0,
        //   previousExpense: +e.previousExpense || 0,
        // })),] });
        // props.handleSaved({ personnelCosts: [...newData] });
        setEditingKey("");
        console.log(newData);
      }
    } catch (errInfo) {
      console.log("Validate Failed:", errInfo);
    }
  };
  const toGetProgrammeCode = async () => {
    // axios
    //   .get(`${BASE_API_URLs}/Programme`)
    //   // .post(`${BASE_API_URL}/budgetcodification`, { ...data })

    //   .then((res) => {
    //     //  setIsLoading(false);
    const res = await get({
      endpoint: `/api/v1/Programme`,
      auth: true,
      // body: payload,
    });

    if (res && res.status == 200) {
      //  setIsLoading(false);

      console.log(res);
      setprogrammeCode(res.data);
      //  notification({
      //    title: "Successful",
      //    message: "Projects have been successfully sent",
      //    type: "success",
      //  });
    } else {
      // console.log(err?.response);
      //  setIsLoading(false);
      //  notification({
      //    title: "Sending Failed",
      //    message: `{err?.response?.data}`,
      //    type: "danger",
      //  });
    }
  };
  const toGetFundCode = async () => {
    // axios
    //   .get(`${BASE_API_URLs}/FundSegment`)

    //   .then((res) => {
    const res = await get({
      endpoint: `/api/v1/FundSegment`,
      auth: true,
      // body: payload,
    });

    if (res && res.status == 200) {
      console.log(res);
      setFundCode(res.data);
    } else {
      // console.log(err?.response);
      //  setIsLoading(false);
      //  notification({
      //    title: "Sending Failed",
      //    message: `{err?.response?.data}`,
      //    type: "danger",
      //  });
    }
  };
  //  const toGetProgramCode = () => {
  //    axios
  //      .get(`${BASE_API_URLs}/Programme`)

  //      .then((res) => {
  //        console.log(res);
  //        setFundCode(res.data);
  //      })
  //      .catch((err) => {
  //        console.log(err?.response);
  //        //  setIsLoading(false);
  //        //  notification({
  //        //    title: "Sending Failed",
  //        //    message: `{err?.response?.data}`,
  //        //    type: "danger",
  //        //  });
  //      });
  //  };
  const toGetFunctioCode = async () => {
    // axios
    // .get(`${BASE_API_URLs}/FunctionalSegment`)
    // // .post(`${BASE_API_URL}/budgetcodification`, { ...data })

    // .then((res) => {
    const res = await get({
      endpoint: `/api/v1/FunctionalSegment`,
      auth: true,
      // body: payload,
    });

    if (res && res.status == 200) {
      //  setIsLoading(false);

      console.log(res);
      setFunctionalCode(res.data);
      //  notification({
      //    title: "Successful",
      //    message: "Projects have been successfully sent",
      //    type: "success",
      //  });
    } else {
      // console.log(err?.response);
      //  setIsLoading(false);
      //  notification({
      //    title: "Sending Failed",
      //    message: `{err?.response?.data}`,
      //    type: "danger",
      //  });
    }
  };
  const toGetLocationCode = async () => {
    // axios
    // .get(`${BASE_API_URLs}/GeoCode`)
    // // .post(`${BASE_API_URL}/budgetcodification`, { ...data })

    // .then((res) => {
    const res = await get({
      endpoint: `/api/v1/GeoCode`,
      auth: true,
      // body: payload,
    });

    if (res && res.status == 200) {
      //  setIsLoading(false);

      //  setIsLoading(false);

      console.log(res);
      setLocationCode(res.data);
      //  notification({
      //    title: "Successful",
      //    message: "Projects have been successfully sent",
      //    type: "success",
      //  });
    } else {
      // console.log(err?.response);
      //  setIsLoading(false);
      //  notification({
      //    title: "Sending Failed",
      //    message: `{err?.response?.data}`,
      //    type: "danger",
      //  });
    }
  };

  const todelete = async (id) => {
    const newData = [...data];

    // const remAfterdelete = newData.filter((item) => key !== item.key);
    // console.log(remAfterdelete);

    setnewLoading(true);
    // setL

    // /  handleDelete = async (id) => {

    if (true) {
      // axios
      //     .post(
      //       `https://edogoverp.com/newbudgetapi/api/v1/BudgetPlanning`,
      //       payload
      //     )
      //     .then((response) => {
      let res = await del({
        endpoint: `/api/v1/BudgetPlanning/DeletePersonnelBudget?id=${id}`,
        auth: true,
        // pQuery: { id },
      });

      if ((res && res.status == 200) || res.status < 300) {
        setnewLoading(false);

        props.getSavedBudgetPlanning();
        // notify(store, 'success', 'department budget added successfully');
        notification({
          title: "MESSAGE",
          message: `
                  ${"department budget deleted successfully"}`,
          type: "success",
          // container:'top-center'
          // animationIn: ['animate__animated', 'animate__slideIn'],
        });
      } else {
        if (res?.data?.status > 300) {
          notification({
            title: "MESSAGE",
            message:
              res?.data?.title ||
              `
                    ${"An error occured contact support"}`,
            type: "danger",
            // container:'top-center'
            // animationIn: ['animate__animated', 'animate__slideIn'],
          });
        }
      }
    } else
      notification({
        title: "ERROR MESSAGE",
        message: `
            Make Sure Approvers and Admin code have been Selected`,
        type: "danger",
        // container:'top-center'
        // animationIn: ['animate__animated', 'animate__slideIn'],
      });
  };

  const toGetEconimicItems = () => {
    axios
      .get(`${BASE_API_URLs}/EconomicSegment`)
      // .post(`${BASE_API_URL}/budgetcodification`, { ...data })

      .then((res) => {
        //  setIsLoading(false);

        console.log(res);
        let kk = res.data.filter((e) => e?.code?.startsWith("21"));
        console.log(kk);
        setEconomicSegment(kk);
        //  notification({
        //    title: "Successful",
        //    message: "Projects have been successfully sent",
        //    type: "success",
        //  });
      })
      .catch((err) => {
        console.log(err?.response);
        //  setIsLoading(false);
        //  notification({
        //    title: "Sending Failed",
        //    message: `{err?.response?.data}`,
        //    type: "danger",
        //  });
      });
  };

  const opt = economicSegment.map((e) => (
    <Option value={e.code}>{`${e.economicSegmentName} (${e?.code})`}</Option>
  ));

  //   Current Approved Appropriation (Present year)
  // Actual Expenditure (current year
  // Approved 2021 (Add to Personal Capital and Recurrent)

  const personnelCostsColumn = [
    {
      title: "Econimic Code",
      dataIndex: "economicCodeMain",
      editable: true,
      key: "economicCodeMain",
      render: (text) => <a>{text}</a>,
    },
    {
      title: "Economic Details",
      dataIndex: "economicDetails",
      key: "economicCode",
      editable: true,
      //  width: "20%",
    },
    {
      title: "Location code",
      dataIndex: "locationCode",
      key: "locationCode",
      editable: true,
      //  width: "20%",
    },
    {
      title: "Program code",
      dataIndex: "programmeCode",
      key: "programmeCode",
      editable: true,
      //  width: "20%",
    },
    {
      title: "Grade Level",
      dataIndex: "gl",
      editable: true,
      key: "age",
      //  width: "20%",
    },
    {
      title: "No. of Staff 2024",
      dataIndex: "noOfStaff",
      editable: true,
      key: "address",
      //  width: "20%",
    },
    {
      title: "Proposed Budget 2024 ",
      dataIndex: "proposedExpense",
      editable: true,
      key: "address",
      render: (text) => <a>{numberWithCommas(text)}</a>,

      //  width: "20%",
    },
    {
      title: "No. of Staff 2023",
      dataIndex: "prevNoOfStaff",
      editable: true,
      key: "address",
      render: (text) => <a>{numberWithCommas(text)}</a>,

      //  width: "20%",
    },
    {
      title: "Current Budget 2023  ",
      dataIndex: "prevApprovedExpense",
      editable: true,
      key: "address",
      render: (text) => <a>{numberWithCommas(text)}</a>,

      //  width: "20%",
    },

    {
      title: "•	Actual Budget Jan-Sept 2023 ",
      dataIndex: "currentExpenseAsAt",
      editable: true,
      key: "address",
      render: (text) => <a>{numberWithCommas(text)}</a>,

      //  width: "20%",
    },
    {
      title: "•	Actual Budget 2022 ",
      editable: true,
      dataIndex: "previousExpense",
      key: "address",
      render: (text) => <a>{numberWithCommas(text)}</a>,

      //  width: "20%",
    },
    {
      title: "operation",
      dataIndex: "operation",
      render: (_, record) => {
        const editable = isEditing(record);
        return editable ? (
          <span>
            <Typography.Link
              onClick={() => save(record.key)}
              style={{
                marginRight: 8,
              }}
            >
              Save
            </Typography.Link>
            <Popconfirm title="Sure to cancel?" onConfirm={cancel}>
              <a>Cancel</a>
            </Popconfirm>
          </span>
        ) : (
          <div className="flex justify-between">
            <div style={{ marginRight: "10px" }}>
              <Typography.Link
                className="mr-10"
                disabled={editingKey !== ""}
                onClick={() => edit(record)}
              >
                Edit
              </Typography.Link>
            </div>

            <Typography.Link
              disabled={editingKey !== ""}
              onClick={() => todelete(record.id)}
            >
              Delete
            </Typography.Link>
          </div>
        );
      },
    },
  ];

  const personnelCostsData = commentsBudget?.map((e) => ({
    // deptBudgetId: "fd9de34e-86f6-43c1-98ce-998a4812c289",
    administrativeCode: props.adminCode,
    economicDetails: e.economicDetails,
    gl: e.gl,
    noOfStaff: e.noOfStaff,
    endDate: moment("2023-10-11T17:08:24.133").format("ll"),
    proposedExpense: e.proposedExpense || 0,
    prevNoOfStaff: e.prevNoOfStaff,
    prevApprovedExpense: e.prevApprovedExpense || 0,
    currentExpenseAsAt: e.currentExpenseAsAt || 0,
    previousExpense: e.previousExpense || 0,
  }));

  const onFinish = (values) => {
    console.log("Success:", values);

    let payload = {
      key: tableKey,
      // deptBudgetId: "3fa85f64-5717-4562-b3fc-2c963f66afa6",
      administrativeCode: props.adminCode,
      economicCodeMain: values.economicCode,
      deptBudgetId: "00000000-0000-0000-0000-000000000000",
      budgetPeriodId: "00000000-0000-0000-0000-000000000000",
      gl: values.gl,
      economicDetails: values.economicDetails,
      economicCodeDetails: values.economicDetails,
      noOfStaff: +values.noOfStaff,
      endDate: "2023-10-11T17:08:24.133Z",
      proposedExpense: +values.proposedExpense || 0,
      prevNoOfStaff: +values.prevNoOfStaff || 0,
      prevApprovedExpense: +values.prevApprovedExpense || 0,
      currentExpenseAsAt: +values.currentExpenseAsAt || 0,
      previousExpense: +values.previousExpense || 0,
      mdaId: +Cookies.get("requestsMdaId"),
      programmeCode: values.programmeCode,
      fundCodeMain: values.fundDetailsCode,
      functionCodeMain: values.functionalDetailsCode,
      locationCode: values.locationCode,
    };

    // console.log(payload);
    // createTotal(payload);
    // console.log(props.totalfromup);

    let yyy =
      props.totalfromup -
        [...data, payload]?.reduce((acc, curr) => {
          return +acc + +curr.proposedExpense;
        }, 0) || 0;

    console.log(yyy);

    if (yyy < 0) {
      notification({
        title: "Warning",
        message: "You have exceeded your allocation for Personnell cost",
        type: "danger",
      });
      return;
    } else {
      // setData([...data, payload]);
      props.handleSaved({ personnelCosts: [...data, payload] });

      setcount((prev) => prev + 1);
    }

    // props.personelC(payload);
    setTableKey((prev) => prev + 1);

    // onReset();
  };
  function numberWithCommas(x) {
    return x?.toString()?.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  }

  useEffect(() => {
    console.log(data);
    props?.total({
      name: "Personel Expenditure",
      Proposed2024: data?.reduce((acc, current) => {
        return +acc + +current.proposedExpense || 0;
      }, 0),
      Approved2023: data?.reduce((acc, current) => {
        return +acc + +current.prevApprovedExpense || 0;
      }, 0),
      ActualJanToSep2023: data?.reduce((acc, current) => {
        return +acc + +current.currentExpenseAsAt || 0;
      }, 0),

      Approved2021: data?.reduce((acc, current) => {
        return +acc + +current.previousExpense || 0;
      }, 0),
    });

    settot({
      name: "Personel Expenditure",
      Proposed2024: data?.reduce((acc, current) => {
        return +acc + +current.proposedExpense || 0;
      }, 0),
      Approved2023: data?.reduce((acc, current) => {
        return +acc + +current.prevApprovedExpense || 0;
      }, 0),
      ActualJanToSep2023: data?.reduce((acc, current) => {
        return +acc + +current.currentExpenseAsAt || 0;
      }, 0),

      Approved2021: data?.reduce((acc, current) => {
        return +acc + +current.previousExpense || 0;
      }, 0),
    });
  }, [data]);

  const createTotal = (payload) => {
    console.log(tot);
    props.total({
      name: "Personel Expenditure",
      Proposed2024:
        [...payload]?.reduce((acc, curr) => {
          return +acc + +curr.proposedExpense;
        }, 0) || 0,
      // +tot.Proposed2024 + +payload.proposedExpense,
      Approved2023:
        [...payload]?.reduce((acc, curr) => {
          return +acc + +curr.prevApprovedExpense;
        }, 0) || 0,
      // +tot.Approved2023 + +payload.prevApprovedExpense,
      ActualJanToSep2023:
        [...payload]?.reduce((acc, curr) => {
          return +acc + +curr.currentExpenseAsAt;
        }, 0) || 0,
      // +tot.ActualJanToSep2023 + +payload.currentExpenseAsAt,
      Approved2021:
        [...payload]?.reduce((acc, curr) => {
          return +acc + +curr.previousExpense;
        }, 0) || 0,
      // +tot.Approved2021 + +payload.previousExpense,
    });
    settot({
      name: "Personel Expenditure",
      Proposed2024:
        [...payload]?.reduce((acc, curr) => {
          return +acc + +curr.proposedExpense;
        }, 0) || 0,
      // +tot.Proposed2024 + +payload.proposedExpense,
      Approved2023:
        [...payload]?.reduce((acc, curr) => {
          return +acc + +curr.prevApprovedExpense;
        }, 0) || 0,
      // +tot.Approved2023 + +payload.prevApprovedExpense,
      ActualJanToSep2023:
        [...payload]?.reduce((acc, curr) => {
          return +acc + +curr.currentExpenseAsAt;
        }, 0) || 0,
      // +tot.ActualJanToSep2023 + +payload.currentExpenseAsAt,
      Approved2021:
        [...payload]?.reduce((acc, curr) => {
          return +acc + +curr.previousExpense;
        }, 0) || 0,
      // +tot.Approved2021 + +payload.previousExpense,
    });
  };

  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };

  //  "deptBudgetId": "3fa85f64-5717-4562-b3fc-2c963f66afa6",
  // "project": "string",
  // "status": "string",
  // "location": "string",
  // "startDate": "2023-10-11T17:08:24.133Z",
  // "amountSpent": 0,
  // "conclusionEstimate": 0,
  // "completionTime": 0,
  // "pId": 0,
  // "mdaId": 0
  const mergedColumns = personnelCostsColumn.map((col) => {
    if (!col.editable) {
      return col;
    }
    return {
      ...col,
      onCell: (record) => ({
        record,
        inputType: col.dataIndex === "comment" ? "text" : "number",
        dataIndex: col.dataIndex,
        title: col.title,
        editing: isEditing(record),
      }),
    };
  });

  const onReset = () => {
    form.resetFields();
  };

  useEffect(() => {
    props.personelC(data);
  }, [data]);

  // const [form] = Form.useForm();
  const handleInputChange = (e) => {
    const newValue = e?.target?.value;
    console.log(e);

    let tt = form.getFieldsValue();
    console.log(tt);
    // Update the value of the second input field
    form.setFieldsValue({
      proposedExpense: (tt.amount || 0) * (tt.noOfStaff || 0),
    });
  };
  const fundCodes = fundCode.map((e) => (
    <Option value={e.fundSegmentCode}>
      {`${e.fundSegmentName} (${e?.fundSegmentCode})`}
    </Option>
  ));
  const functionalCodes = functionalCode.map((e) => (
    <Option value={e.code}>{`${e.functionalSegmentName} (${e?.code})`}</Option>
  ));
  const programmeCodes = programmeCode.map((e) => (
    <Option value={e.programmeCode}>
      {`${e.programmeName} (${e?.programmeCode})`}
    </Option>
  ));
  const locationCodes = locationCode.map((e) => (
    <Option value={e.code}>{`${e.geoCodeName} (${e?.code})`}</Option>
  ));
  const collectEconomicSegmentId = (id) => {
    form.setFieldsValue({ economicCode: id.key });
    setEconomicSegmentId(id.value);
  };
  const collectEconomicTypeId = (id) => {
    form.setFieldsValue({ economicCode: id.key });
    setEconomicTypeId(id.value);
  };
  const collectEconomicSubTypeId = (id) => {
    form.setFieldsValue({ economicCode: id.key });
    setEconomicSubTypeId(id.value);
  };
  const collectEconomicSubSubTypeId = (id) => {
    form.setFieldsValue({ economicCode: id.key });
    setEconomicSubSubTypeId(id.value);
  };
  const collectEconomicItem = (id) => {
    form.setFieldsValue({ economicCode: id.key });
    setEconomicItemId(id.value);
  };

  return (
    <div className="w-100">
      <h2>Personel Costs</h2>

      <div
        className="w-full flex mb-10"
        style={{ gap: 10, marginBottom: "10px" }}
      >
        <EconomicSegment
          collectEconomicSegmentId={collectEconomicSegmentId}
          count={count}
          prefix={props.filter}
        />
        <EconomicType
          count={count}
          prefix={props.filter}
          collectEconomicTypeId={collectEconomicTypeId}
          id={economicSegmentId}
        />
        <EconomicSubType
          count={count}
          prefix={props.filter}
          collectEconomicSubTypeId={collectEconomicSubTypeId}
          id={economicTypeId}
        />
        <EconomicSubSubType
          count={count}
          prefix={props.filter}
          collectEconomicSubSubTypeId={collectEconomicSubSubTypeId}
          id={economicSubTypeId}
        />
        <EconomicItem
          count={count}
          prefix={props.filter}
          collectEconomicItem={collectEconomicItem}
          id={economicSubSubTypeId}
        />
      </div>

      <Form
        name="basic"
        layout="vertical"
        form={form}
        // labelCol={{
        //   span: 8,
        // }}
        // wrapperCol={{
        //   span: 16,
        // }}
        // initialValues={{
        //   remember: true,
        // }}
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
        autoComplete="off"
      >
        {" "}
        <div class="flex-between">
          <Form.Item
            className="m-r-10"
            label="Funds Code"
            style={{ width: `100%` }}
            name="fundDetailsCode"
          >
            <Select
              filterOption={(input, option) =>
                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
              filterSort={(optionA, optionB) =>
                optionA.children
                  .toLowerCase()
                  .localeCompare(optionB.children.toLowerCase())
              }
              allowClear
              style={{ width: `100%` }}
              // onChange={handleChange}
            >
              {fundCodes}
            </Select>
          </Form.Item>
          <Form.Item
            filterOption={(input, option) =>
              option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
            }
            filterSort={(optionA, optionB) =>
              optionA.children
                .toLowerCase()
                .localeCompare(optionB.children.toLowerCase())
            }
            allowClear
            className="m-r-10"
            label="Functional Code"
            name="functionalDetailsCode"
            style={{ width: `100%` }}
          >
            <Select
              filterOption={(input, option) =>
                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
              filterSort={(optionA, optionB) =>
                optionA.children
                  .toLowerCase()
                  .localeCompare(optionB.children.toLowerCase())
              }
              allowClear
              // onChange={handleChange}
              // onChange={handleChange}
              style={{ width: `100%` }}
              // onChange={handleChange}
            >
              {functionalCodes}
            </Select>
          </Form.Item>
          <Form.Item
            label="Programme Code"
            className="m-r-10"
            style={{ width: `100%` }}
            name="programmeCode"
          >
            <Select
              filterOption={(input, option) =>
                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
              filterSort={(optionA, optionB) =>
                optionA.children
                  .toLowerCase()
                  .localeCompare(optionB.children.toLowerCase())
              }
              allowClear
              style={{ width: `100%` }}
              // onChange={handleChange}
            >
              {programmeCodes}
            </Select>
          </Form.Item>
          <Form.Item
            className="m-r-10"
            label="Location Code"
            style={{ width: `100%` }}
            name="locationCode"
          >
            <Select
              showSearch
              //  labelInValue
              filterOption={(input, option) =>
                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
              filterSort={(optionA, optionB) =>
                optionA.children
                  .toLowerCase()
                  .localeCompare(optionB.children.toLowerCase())
              }
              allowClear
              style={{ width: `100%` }}
              // onChange={handleChange}
            >
              {locationCodes}
            </Select>
          </Form.Item>
        </div>
        <div className="flex-between w-100">
          <div className="w-100 m-r-10">
            <Form.Item label="Econimic Code" name="economicCode">
              {/* <Select
                labelInValue
                // defaultValue="lucy"
                showSearch
                style={{ width: "100%" }}
                placeholder="Search to Select"
                optionFilterProp="children"
                filterOption={(input, option) =>
                  option?.children
                    ?.toLowerCase()
                    ?.indexOf(input?.toLowerCase()) >= 0
                }
                filterSort={(optionA, optionB) =>
                  optionA?.children
                    ?.toLowerCase()
                    ?.localeCompare(optionB?.children?.toLowerCase())
                }
                // style={{ width: "100%" }}
                // onChange={handleChange}
              >
                {opt}
              </Select> */}
              <Input readOnly />
            </Form.Item>
            <Form.Item
              style={{ marginTop: "50px" }}
              label="Grade Level"
              name="gl"
            >
              <Select
                // defaultValue="lucy"
                // labelInValue
                // showSearch
                style={{ width: "100%" }}
                // placeholder="Search to Select"
                // optionFilterProp="children"
                // filterOption={(input, option) =>
                //   option?.children
                //     ?.toLowerCase()
                //     ?.indexOf(input?.toLowerCase()) >= 0
                // }
                // filterSort={(optionA, optionB) =>
                //   optionA?.children
                //     ?.toLowerCase()
                //     ?.localeCompare(optionB?.children?.toLowerCase())
                // }
                // style={{ width: "100%" }}
              >
                {grade}
                <Option key={"consolidated"} value={`Condolidated`}>
                  {`Consolidated`}
                </Option>
                ,
              </Select>
            </Form.Item>

            <Form.Item
              label="Actual Budget Jan-Sept 2023 "
              name="currentExpenseAsAt"
            >
              <Input
                formatter={(value) =>
                  `₦ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                }
                parser={(value) => value?.replace(/\₦\s?|(,*)/g, "")}
                style={{ width: "100%" }}
                // parser={(value) => value?.replace(/\$\s?|(,*)/g, "")}
                // style={{ width: "100%" }}
              />
            </Form.Item>
          </div>

          <div className="w-100 m-r-10">
            <Form.Item label="Economic Detail" name="economicDetails">
              <TextArea style={{ width: "100%" }} />
            </Form.Item>
            <div className="flex m-r-10 m-t-10" style={{ marginTop: "100px" }}>
              <Form.Item
                className="flex m-r-10"
                label="No. Of Staff"
                name="noOfStaff"
              >
                <Input
                  formatter={(value) =>
                    `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                  }
                  parser={(value) => value?.replace(/\₦\s?|(,*)/g, "")}
                  style={{ width: "100%" }}
                  onChange={handleInputChange}
                />
              </Form.Item>

              <Form.Item label="Amount" name="amount">
                <Input
                  formatter={(value) =>
                    `₦${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                  }
                  parser={(value) => value?.replace(/\₦\s?|(,*)/g, "")}
                  style={{ width: "100%" }}
                  onChange={handleInputChange}
                />
              </Form.Item>
            </div>

            <Form.Item label="Prev no. of Staff" name="prevNoOfStaff">
              <Input
                formatter={(value) =>
                  `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                }
                parser={(value) => value?.replace(/\₦\s?|(,*)/g, "")}
                style={{ width: "100%" }}
              />
            </Form.Item>
            <Form.Item
              label="Actual Budget 2022 "
              name="previousExpense"
              // rules={[
              //   {
              //     required: true,
              //     message: "Please input your password!",
              //   },
              // ]}
            >
              <Input
                formatter={(value) =>
                  `₦ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                }
                parser={(value) => value?.replace(/\₦\s?|(,*)/g, "")}
                style={{ width: "100%" }}
              />
            </Form.Item>
          </div>
          <div className="w-100 m-r-10">
            <Form.Item label=" " name=""></Form.Item>
            <Form.Item
              style={{ marginTop: "50px" }}
              label="Proposed Budget 2024 "
              name="proposedExpense"
            >
              <Input
                // readOnly
                formatter={(value) =>
                  `₦ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                }
                parser={(value) => value?.replace(/\₦\s?|(,*)/g, "")}
                style={{ width: "100%" }}
              />
            </Form.Item>
            <Form.Item label="Current Budget 2023 " name="prevApprovedExpense">
              <Input
                formatter={(value) =>
                  `₦ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                }
                parser={(value) => value?.replace(/\₦\s?|(,*)/g, "")}
                style={{ width: "100%" }}
              />
            </Form.Item>
          </div>
        </div>
        <Form.Item>
          <Button
            style={{ backgroundColor: "#1d811c" }}
            type="primary"
            htmlType="submit"
          >
            Save
          </Button>
        </Form.Item>
        <div style={{ overflowX: "scroll" }}>
          <div style={{ width: "1500px" }}>
            <Table
              components={{
                body: {
                  cell: EditableCell,
                },
              }}
              pagination={{
                onChange: cancel,
              }}
              columns={mergedColumns}
              dataSource={data}
              summary={(pageData) => {
                let totalproposedExpense = 0;
                let totalprevApprovedExpense = 0;
                let totalcurrentExpenseAsAt = 0;
                let totalpreviousExpense = 0;
                pageData.forEach(
                  ({
                    proposedExpense,
                    prevApprovedExpense,
                    currentExpenseAsAt,
                    previousExpense,
                  }) => {
                    totalproposedExpense += +proposedExpense || 0;
                    totalprevApprovedExpense += +prevApprovedExpense || 0;
                    totalcurrentExpenseAsAt += +currentExpenseAsAt || 0;
                    totalpreviousExpense += +previousExpense || 0;
                    //  props.total({
                    //    name: "Personel Expenditure",

                    //    Proposed2024: totalproposedExpense,
                    //    Approved2023: totalprevApprovedExpense,
                    //    ActualJanToSep2023: totalcurrentExpenseAsAt,
                    //    Approved2021: totalpreviousExpense,
                    //  });
                  }
                );
                return (
                  <>
                    <Table.Summary.Row>
                      <Table.Summary.Cell index={0}>Total</Table.Summary.Cell>
                      <Table.Summary.Cell index={1}></Table.Summary.Cell>
                      <Table.Summary.Cell index={1}></Table.Summary.Cell>
                      <Table.Summary.Cell index={1}></Table.Summary.Cell>
                      <Table.Summary.Cell index={1}></Table.Summary.Cell>
                      <Table.Summary.Cell index={1}></Table.Summary.Cell>
                      <Table.Summary.Cell index={2}>
                        <Text type="success">
                          {`₦${numberWithCommas(totalproposedExpense)}`}
                          {/* {`₦${numberWithCommas(totalproposedExpense)}`} */}
                        </Text>
                      </Table.Summary.Cell>
                      <Table.Summary.Cell index={1}></Table.Summary.Cell>

                      <Table.Summary.Cell index={3}>
                        <Text type="success">
                          {`₦ ${numberWithCommas(totalprevApprovedExpense)}`}
                        </Text>
                      </Table.Summary.Cell>
                      <Table.Summary.Cell index={4}>
                        <Text type="success">
                          {`₦ ${numberWithCommas(totalcurrentExpenseAsAt)}`}
                        </Text>
                      </Table.Summary.Cell>
                      <Table.Summary.Cell index={5}>
                        <Text type="success">
                          {`₦ ${numberWithCommas(totalpreviousExpense)}`}
                        </Text>
                      </Table.Summary.Cell>
                    </Table.Summary.Row>
                    {/* const {Text} = Typography; */}
                    {/* <Table.Summary.Row>
                    <Table.Summary.Cell index={0}>Balance</Table.Summary.Cell>
                    <Table.Summary.Cell index={1} colSpan={2}>
                      <Text type="danger">{totalBorrow - totalRepayment}</Text>
                    </Table.Summary.Cell>
                  </Table.Summary.Row> */}
                  </>
                );
              }}
            />
          </div>
        </div>
      </Form>
    </div>
  );
};

export default PersonelCosts;
