import React, { useState, useEffect, useContext } from "react";
import {
  Button,
  Select,
  Form,
  Input,
  // Input,
  Table,
  Typography,
  Popconfirm,
} from "antd";
import { get, patch, post, del } from "../../api-services/fetch";
import axios from "axios";
import notification from "../../utility/notification";
import Cookies from "js-cookie";
import moment from "moment";
// import { CKEditor } from '@ckeditor/ckeditor5-react';
// import ClassicEditor from '@ckeditor/ckeditor5-build-classic';

// import ViewGovTable from './ViewGovTable';
import { decodeToken } from "../../utility/auth";
import { setDisplayName } from "recompose";
import EconomicSegment from "./EconomicSegment";
import EconomicType from "./EconomicType";
import EconomicSubType from "./EconomicSubType";
import EconomicSubSubType from "./EconomicSubSubType";
import EconomicItem from "./EconomicItem";
// import SearchDocument from '../layouts/SearchDocument';
const BASE_API_URL = "https://edogoverp.com/budget/api/v1";
// const BASE_API_URLs = "https://edogoverp.com/newbudgetapi/api/v1";
const BASE_API_URLs = "https://edogoverp.com/newbudgetapi/api/v1";

// const BASE_API_URL = 'http://localhost:5000/api/v1';

const originData = [];
for (let i = 0; i < 100; i++) {
  originData.push({
    key: i.toString(),
    name: `Edrward ${i}`,
    age: 32,
    address: `London Park no. ${i}`,
  });
}
const { Text } = Typography;
const { Option } = Select;

const { TextArea } = Input;
const EditableCell = ({
  editing,
  dataIndex,
  title,
  inputType,
  record,
  index,
  children,
  ...restProps
}) => {
  const inputNode = inputType === "number" ? <Input /> : <TextArea rows={4} />;
  return (
    <td {...restProps}>
      {editing ? (
        <Form.Item
          name={dataIndex}
          style={{
            margin: 0,
          }}
          // rules={[
          //   {
          //     required: true,
          //     message: `Please Input ${title}!`,
          //   },
          // ]}
        >
          {inputNode}
        </Form.Item>
      ) : (
        children
      )}
    </td>
  );
};
const BudgetItems = (props) => {
  const [commentsBudget, setCommentsBudget] = useState([]);
  const [administrativeSegment, setAdministrativeSegment] = useState([]);
  const [fundCode, setFundCode] = useState([]);
  const [functionalCode, setFunctionalCode] = useState([]);
  const [programmeCode, setprogrammeCode] = useState([]);
  const [locationCode, setLocationCode] = useState([]);
  const [economicSegment, setEconomicSegment] = useState([]);
  const [economicSegmentId, setEconomicSegmentId] = useState();
  const [economicTypeId, setEconomicTypeId] = useState();
  const [economicSubTypeId, setEconomicSubTypeId] = useState();
  const [economicSubSubTypeId, setEconomicSubSubTypeId] = useState();
  const [economicItemId, setEconomicItemId] = useState();
  const [envelope, setEnvelope] = useState();
  const [mdaId, setMdaId] = useState([]);
  const [userId, setUserId] = useState([]);
  const [tableKey, setTableKey] = useState(1);
  const [tot, settot] = useState({
    name: "Overhead Expenditure",
    Proposed2024: 0,
    Approved2023: 0,
    ActualJanToSep2023: 0,
    Approved2021: 0,
  });

  const [form] = Form.useForm();
  const [data, setData] = useState([]);
  const [count, setcount] = useState(0);
  const [editingKey, setEditingKey] = useState("");
  const isEditing = (record) => record.key === editingKey;

  const edit = (record) => {
    console.log(record);
    form.setFieldsValue({
      // label: "",
      // amount: "",
      // comment: "",

      ...record,
    });
    setEditingKey(record.key);
  };
  const cancel = () => {
    setEditingKey("");
  };
  const save = async (key) => {
    console.log(key);
    try {
      const row = await form.validateFields();
      console.log(row);
      const newData = [...data];
      const index = newData.findIndex((item) => key === item.key);
      if (index > -1) {
        const item = newData[index];
        newData.splice(index, 1, {
          ...item,
          ...row,
        });
        // props.setEnvelopeData(newData);
        console.log(newData);

        let yyy =
          props.totalfromup -
            [...newData].reduce((acc, curr) => {
              return +acc + +curr.proposedBudget;
            }, 0) || 0;

        console.log(yyy);

        if (yyy < 0) {
          notification({
            title: "Warning",
            message:
              "With this amount you are about to exceeded your allocation for Overhead cost. Please adjust",
            type: "danger",
          });
          return;
        } else {
          // setData(newData);
          props.handleSaved({
            budgetItems: [
              ...newData?.map((e) => ({
                ...e,
                proposedBudget: +e?.proposedBudget || 0,
                previousApprovedBudget: +e?.previousApprovedBudget || 0,
                currentExpenditures: +e?.currentExpenditures || 0,
                previousExpenditures: +e?.previousExpenditures || 0,
              })),
            ],
          });
        }
        setEditingKey("");
      } else {
        newData.push(row);
        // setData(newData);
        props.handleSaved({
          budgetItems: [
            ...newData?.map((e) => ({
              ...e,
              proposedBudget: +e?.proposedBudget || 0,
              previousApprovedBudget: +e?.previousApprovedBudget || 0,
              currentExpenditures: +e?.currentExpenditures || 0,
              previousExpenditures: +e?.previousExpenditures || 0,
            })),
          ],
        });

        setEditingKey("");
        console.log(newData);
      }
    } catch (errInfo) {
      console.log("Validate Failed:", errInfo);
    }
  };

  // const todelete = (key) => {
  //   const newData = [...data];

  //   const remAfterdelete = newData.filter((item) => key !== item.key);
  //   console.log(remAfterdelete);

  //   setData(remAfterdelete);
  // };

  const todelete = async (id) => {
    const newData = [...data];

    // const remAfterdelete = newData.filter((item) => key !== item.key);
    // console.log(remAfterdelete);

    // setnewLoading(true);
    // setL

    // /  handleDelete = async (id) => {

    if (true) {
      // axios
      //     .post(
      //       `https://edogoverp.com/newbudgetapi/api/v1/BudgetPlanning`,
      //       payload
      //     )
      //     .then((response) => {
      let res = await del({
        endpoint: `/api/v1/BudgetPlanning/DeleteBudgetPlanning?id=${id}`,
        auth: true,
        // pQuery: { id },
      });

      if ((res && res?.status == 200) || res?.status < 300) {
        // setnewLoading(false);

        props.getSavedBudgetPlanning();
        // notify(store, 'success', 'department budget added successfully');
        notification({
          title: "MESSAGE",
          message: `
                  ${"department budget deleted successfully"}`,
          type: "success",
          // container:'top-center'
          // animationIn: ['animate__animated', 'animate__slideIn'],
        });
      } else {
        if (res?.data?.status > 300) {
          notification({
            title: "MESSAGE",
            message:
              res?.data?.title ||
              `
                    ${"An error occured contact support"}`,
            type: "danger",
            // container:'top-center'
            // animationIn: ['animate__animated', 'animate__slideIn'],
          });
        }
      }
    } else
      notification({
        title: "ERROR MESSAGE",
        message: `
            Make Sure Approvers and Admin code have been Selected`,
        type: "danger",
        // container:'top-center'
        // animationIn: ['animate__animated', 'animate__slideIn'],
      });
  };

  useEffect(() => {
    // if (data && data?.length > 0) {
    // }

    props.handleSubmitz(data);
    createTotal(data);
  }, [data]);

  useEffect(() => {
    toGetAdministrativeSegment();
    toGetFundCode();
    toGetFunctioCode();
    toGetProgrammeCode();
    toGetLocationCode();
    // toGetEconimicItems();
    // toGetEnvelope()

    console.log(+Cookies.get("mdaId"));

    setMdaId(+Cookies.get("requestsMdaId"));

    // console.log(Cookies.get("signature"));
    // console.log(Cookies.get("firstName"));
    // console.log(Cookies.get("lastName"));

    // console.log(props?.savedBudgetPlanning);

    setData(
      props?.savedBudgetPlanning[0]?.budgetItems?.map((e) => ({
        ...e,
        key: e.id,
      })) || []
    );
    // setsignature(Cookies.get("signature"));

    // let ui = localStorage.getItem("ui");
    // ui = decodeToken("ui").ui;
    // console.log(ui);
    // setUserId(ui);
  }, [props?.savedBudgetPlanning]);

  const toGetEnvelope = () => {
    // console.log(payload.id);
    // setIsLoading(true)

    axios
      .get(
        `https://edogoverp.com/newbudgetapi/api/BudgetEnvelope/GetBudgetEnvelopeByMda?mdaId=${+Cookies.get(
          "requestsMdaId"
        )}`
      )
      .then((res) => {
        console.log(res?.data);

        setEnvelope(res?.data);
      })
      .catch((err) => {
        console.log(err?.response);
      });
  };

  // const toGetEconimicItems = () => {
  //   axios
  //     .get(`${BASE_API_URLs}/EconomicSegment `)
  //     // .post(`${BASE_API_URL}/budgetcodification`, { ...data })

  //     .then((res) => {
  //       //  setIsLoading(false);
  //       let kk = res?.data.filter((e) => e?.code?.startsWith("22"));

  //       console.log(res);
  //       setEconomicSegment(kk);
  //       //  notification({
  //       //    title: "Successful",
  //       //    message: "Projects have been successfully sent",
  //       //    type: "success",
  //       //  });
  //     })
  //     .catch((err) => {
  //       console.log(err?.response);
  //       //  setIsLoading(false);
  //       //  notification({
  //       //    title: "Sending Failed",
  //       //    message: `{err?.response?.data}`,
  //       //    type: "danger",
  //       //  });
  //     });
  // };

  const toGetAdministrativeSegment = () => {
    axios
      .get(`${BASE_API_URLs}/AdministrativeSegment`)
      // .post(`${BASE_API_URL}/budgetcodification`, { ...data })

      .then((res) => {
        //  setIsLoading(false);

        console.log(res);
        setAdministrativeSegment(res?.data);
        //  notification({
        //    title: "Successful",
        //    message: "Projects have been successfully sent",
        //    type: "success",
        //  });
      })
      .catch((err) => {
        console.log(err?.response);
        //  setIsLoading(false);
        //  notification({
        //    title: "Sending Failed",
        //    message: `{err?.response?.data}`,
        //    type: "danger",
        //  });
      });
  };

  // const opt = economicSegment.map((e, id) => (
  //   <Option key={id} value={e.code}>
  //     {`${e.economicSegmentName} (${e.code})`}
  //   </Option>
  // ));

  const optA = administrativeSegment.map((e, id) => (
    <Option key={id} value={e?.code}>
      {e?.administrativeSegmentName}
    </Option>
  ));

  const personnelCostsColumn = [
    // {
    //   title: "Administrative Code",
    //   dataIndex: "administrativeCode",
    //   key: "name",
    //   render: (text) => <a>{text}</a>,
    // },
    {
      title: "Economic code",
      dataIndex: "economicCode",
      key: "economicCode",
      editable: true,
      //  width: "20%",
    },
    {
      title: "Detail of Expenditure",
      dataIndex: "economicDetails",
      key: "economicCode",
      editable: true,
      //  width: "20%",
    },
    {
      title: "Functional code",
      dataIndex: "functionalCode",
      key: "functionalCode",
      editable: true,
      //  width: "20%",
    },
    {
      title: "Location code",
      dataIndex: "geoCode",
      key: "geoCode",
      editable: true,
      //  width: "20%",
    },
    {
      title: "Program code",
      dataIndex: "programmeCode",
      key: "programmeCode",
      editable: true,
      //  width: "20%",
    },
    {
      title: "Proposed overhead Budget 2024",
      dataIndex: "proposedBudget",
      key: "proposedBudget",
      editable: true,
      render: (text) => <a>{numberWithCommas(text)}</a>,

      //  width: "20%",
    },
    {
      title: "Overhead Budget 2023 ",
      dataIndex: "previousApprovedBudget",
      key: "previousApprovedBudget",
      editable: true,
      render: (text) => <a>{numberWithCommas(text)}</a>,

      //  width: "20%",
    },

    {
      title: "Actual Overhead Budget as Jan-Sept 2023",
      dataIndex: "currentExpenditures",
      key: "currentExpenditures",
      editable: true,
      render: (text) => <a>{numberWithCommas(text)}</a>,

      //  width: "20%",
    },
    {
      title: "Actual Overhead Budget 2022",
      dataIndex: "previousExpenditures",
      key: "previousExpenditures",
      editable: true,
      render: (text) => <a>{numberWithCommas(text)}</a>,

      //  width: "20%",
    },
    {
      title: "operation",
      dataIndex: "operation",
      render: (_, record) => {
        const editable = isEditing(record);
        return editable ? (
          <span>
            <Typography.Link
              onClick={() => save(record.key)}
              style={{
                marginRight: 8,
              }}
            >
              Save
            </Typography.Link>
            <Popconfirm title="Sure to cancel?" onConfirm={cancel}>
              <a>Cancel</a>
            </Popconfirm>
          </span>
        ) : (
          <div className="flex justify-between">
            <div style={{ marginRight: "10px" }}>
              <Typography.Link
                className="mr-10"
                disabled={editingKey !== ""}
                onClick={() => edit(record)}
              >
                Edit
              </Typography.Link>
            </div>

            <Typography.Link
              disabled={editingKey !== ""}
              onClick={() => todelete(record.key)}
            >
              Delete
            </Typography.Link>
          </div>
        );
      },
    },
  ];
  function numberWithCommas(x) {
    return x?.toString()?.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  }

  const personnelCostsData = commentsBudget?.map((e, id) => ({
    key: id,
    dateCreated: new Date(),
    enteredBy: userId + "",
    dateUpdated: new Date(),
    updatedBy: userId + "",
    isDelete: false,
    dateDeleted: new Date(),
    // budgetPlanId: "3fa85f64-5717-4562-b3fc-2c963f66afa6",
    // budgetPeriodId: "3fa85f64-5717-4562-b3fc-2c963f66afa6",
    adminSectorCode: 0,
    project: "string",
    title: "",
    programmeCode: "111111111111",
    administrativeCode: props?.adminCode,
    economicCode: e.economicCode,
    economicDetails: e.economicDetails,
    //   administrativeCode: e.administrativeCode,
    fundCode: "444444444444",
    functionalCode: "555555555555",
    geoCode: "666666666666",
    status: "string",
    projectLocation: "string",
    proposedBudget: +e.proposedBudget || 0,
    previousApprovedBudget: +e.previousApprovedBudget || 0,
    currentExpenditures: +e.currentExpenditures || 0,
    previousExpenditures: +e.previousExpenditures || 0,
    annualisedTotalExpenditure: +e.annualisedTotalExpenditure || 0,
    performance: "string",
    actuals: "string",
    mdaId: +mdaId,
    approvalStatus: 0,
    stage: 0,
  }));
  // setData(personnelCostsData);

  // console.log(personnelCostsData);

  //   props.handleSubmitz(personnelCostsData);
  // const mergedColumns = personnelCostsColumn.map((col) => {
  //   if (!col.editable) {
  //     return col;
  //   }
  //   return {
  //     ...col,
  //     onCell: (record) => ({
  //       record,
  //       inputType: col.dataIndex === "comment" ? "text" : "number",
  //       dataIndex: col.dataIndex,
  //       title: col.title,
  //       editing: isEditing(record),
  //     }),
  //   };
  // });
  let yupKey = 1;

  // let tot = 0

  const createTotal = (payload) => {
    console.log(tot);
    props.total({
      name: "Overhead Expenditure",
      Proposed2024:
        (payload &&
          payload?.length &&
          payload?.reduce((acc, curr) => {
            return +acc + +curr.proposedBudget;
          }, 0)) ||
        0,
      // +tot.Proposed2024 + +payload.proposedBudget,
      Approved2023:
        (payload &&
          payload?.length &&
          payload?.reduce((acc, curr) => {
            return +acc + +curr.previousApprovedBudget;
          }, 0)) ||
        0,
      // +tot.Approved2023 + +payload.previousApprovedBudget,
      ActualJanToSep2023:
        (payload &&
          payload?.length &&
          payload?.reduce((acc, curr) => {
            return +acc + +curr.currentExpenditures;
          }, 0)) ||
        0,
      // +tot.ActualJanToSep2023 + +payload.currentExpenditures,
      Approved2021:
        (payload &&
          payload?.length &&
          payload?.reduce((acc, curr) => {
            return +acc + +curr.previousExpenditures;
          }, 0)) ||
        0,
      // +tot.Approved2021 + +payload.previousExpenditures,
    });
    settot({
      name: "Overhead Expenditure",
      Proposed2024:
        (payload &&
          payload?.length &&
          payload?.reduce((acc, curr) => {
            return +acc + +curr.proposedBudget;
          }, 0)) ||
        0,
      // +tot.Proposed2024 + +payload.proposedBudget,
      Approved2023:
        (payload &&
          payload?.length &&
          payload?.reduce((acc, curr) => {
            return +acc + +curr.previousApprovedBudget;
          }, 0)) ||
        0,
      // +tot.Approved2023 + +payload.previousApprovedBudget,
      ActualJanToSep2023:
        (payload &&
          payload?.length &&
          payload?.reduce((acc, curr) => {
            return +acc + +curr.currentExpenditures;
          }, 0)) ||
        0,
      // +tot.ActualJanToSep2023 + +payload.currentExpenditures,
      Approved2021:
        (payload &&
          payload?.length &&
          payload?.reduce((acc, curr) => {
            return +acc + +curr.previousExpenditures;
          }, 0)) ||
        0,
    });
  };
  // console.log(commentsBudget);

  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };

  //  "deptBudgetId": "3fa85f64-5717-4562-b3fc-2c963f66afa6",
  // "project": "string",
  // "status": "string",
  // "location": "string",
  // "startDate": "2022-10-11T17:08:24.133Z",
  // "amountSpent": 0,
  // "conclusionEstimate": 0,
  // "completionTime": 0,
  // "pId": 0,
  // "mdaId": 0
  const onReset = () => {
    form.resetFields();
  };
  // let yupKey = 1
  const onFinish = (values) => {
    console.log("Success:", values);

    let payload = {
      key: tableKey,
      dateCreated: new Date(),
      enteredBy: userId + "",
      dateUpdated: new Date(),
      updatedBy: userId + "",
      isDelete: false,
      dateDeleted: new Date(),
      deptBudgetId: "00000000-0000-0000-0000-000000000000",
      budgetPeriodId: "00000000-0000-0000-0000-000000000000",
      // budgetPlanId: "3fa85f64-5717-4562-b3fc-2c963f66afa6",
      // budgetPeriodId: "3fa85f64-5717-4562-b3fc-2c963f66afa6",
      // budgetPeriodId: "3389652d-147e-4305-959e-6ddaca88a517",
      adminSectorCode: 0,
      project: "string",
      title: "",
      programmeCode: values.programmeCode,
      administrativeCode: props?.adminCode,
      economicCode: values.economicCode,
      economicDetails: values.economicDetails,
      //   administrativeCode: values.administrativeCode,
      fundCode: values.fundCode,
      functionalCode: values.functionalDetailsCode,
      geoCode: values.locationCode,
      status: "string",
      projectLocation: "string",
      proposedBudget: +values.proposedBudget || 0,
      previousApprovedBudget: +values.previousApprovedBudget || 0,
      currentExpenditures: +values.currentExpenditures || 0,
      previousExpenditures: +values.previousExpenditures || 0,
      performance: "string",
      actuals: "string",
      mdaId: +mdaId,
      approvalStatus: 0,
      stage: 0,
    };

    setCommentsBudget([...commentsBudget, payload]);
    let yyy =
      props.totalfromup -
        [...data, payload].reduce((acc, curr) => {
          return +acc + +curr.proposedBudget;
        }, 0) || 0;

    console.log(yyy);

    if (yyy < 0) {
      notification({
        title: "Warning",
        message:
          "You have exceeded your allocation for Overhead cost. Please adjust",
        type: "danger",
      });
      return;
    } else {
      // setData([...data, payload]);
      props.handleSaved({ budgetItems: [...data, payload] });

      form.resetFields();
      setcount((prev) => prev + 1);
    } // onReset();

    // createTotal(payload);
    setTableKey((prev) => prev + 1);
    //  props.personelC(payload);
  };

  // const onFinish = (values) => {
  //   // setIsLoading(true);
  //   console.log(values);
  //   //  setEditableMdas(values.to);
  //   // submits && setIsLoading2(true)
  //   let mdas =
  //     values?.to && values?.to?.length !== 0
  //       ? values?.to?.map((e, idx) => ({
  //           // id: '',

  //           mdaId: e.value,
  //           mdaName: e.label,

  //           dateDeleted: "2022-02-26T18:29:27.222Z",
  //           dateCreated: "2022-02-26T18:29:27.222Z",
  //           dateUpdated: "2022-02-26T18:29:27.222Z",
  //           //  enteredBy: userId + "",
  //           //  updatedBy: userId + "",
  //           isDelete: false,
  //         }))
  //       : [];
  //   console.log(mdas);
  //   // let documents = mFilesArray.length !== 0 ? mFilesArray.map((e) => (

  //   //   {
  //   //     id: e.id,
  //   //     name: e.name,
  //   //     path: e.path
  //   //   }

  //   // ))
  //   //   :
  //   //   []

  //   let poster = {
  //     // id: "",
  //     dateCreated: "2022-09-16T17:36:08.160Z",
  //     //  enteredBy: userId + "",
  //     dateUpdated: "2022-09-16T17:36:08.160Z",
  //     //  updatedBy: userId + "",
  //     isDelete: false,
  //     dateDeleted: "2022-09-16T17:36:08.160Z",
  //     ongoingProjects: values.ongoingProjects,
  //     details: 3,
  //     budgetPeriodId: 45,
  //     // pId: 0,
  //     responses: mdas,
  //   };
  //   console.log(poster);
  //   setData([poster]);

  //   //  setPayload(poster);

  //   // toPost(poster);
  //   // onReset();
  // };
  const toGetFundCode = async () => {
    // axios
    //   .get(`${BASE_API_URLs}/FundSegment`)

    //   .then((res) => {
    const res = await get({
      endpoint: `/api/v1/FundSegment`,
      auth: true,
      // body: payload,
    });

    if (res && res?.status == 200) {
      console.log(res);
      setFundCode(res?.data);
    } else {
      // console.log(err?.response);
      //  setIsLoading(false);
      //  notification({
      //    title: "Sending Failed",
      //    message: `{err?.response?.data}`,
      //    type: "danger",
      //  });
    }
  };

  const toGetFunctioCode = async () => {
    // axios
    // .get(`${BASE_API_URLs}/FunctionalSegment`)
    // // .post(`${BASE_API_URL}/budgetcodification`, { ...data })

    // .then((res) => {
    const res = await get({
      endpoint: `/api/v1/FunctionalSegment`,
      auth: true,
      // body: payload,
    });

    if (res && res?.status == 200) {
      //  setIsLoading(false);

      console.log(res);
      setFunctionalCode(res?.data);
      //  notification({
      //    title: "Successful",
      //    message: "Projects have been successfully sent",
      //    type: "success",
      //  });
    } else {
      // console.log(err?.response);
      //  setIsLoading(false);
      //  notification({
      //    title: "Sending Failed",
      //    message: `{err?.response?.data}`,
      //    type: "danger",
      //  });
    }
  };
  const toGetProgrammeCode = async () => {
    // axios
    //   .get(`${BASE_API_URLs}/Programme`)
    //   // .post(`${BASE_API_URL}/budgetcodification`, { ...data })

    //   .then((res) => {
    //     //  setIsLoading(false);
    const res = await get({
      endpoint: `/api/v1/Programme`,
      auth: true,
      // body: payload,
    });

    if (res && res?.status == 200) {
      //  setIsLoading(false);

      console.log(res);
      setprogrammeCode(res?.data);
      //  notification({
      //    title: "Successful",
      //    message: "Projects have been successfully sent",
      //    type: "success",
      //  });
    } else {
      // console.log(err?.response);
      //  setIsLoading(false);
      //  notification({
      //    title: "Sending Failed",
      //    message: `{err?.response?.data}`,
      //    type: "danger",
      //  });
    }
  };
  const toGetLocationCode = async () => {
    // axios
    // .get(`${BASE_API_URLs}/GeoCode`)
    // // .post(`${BASE_API_URL}/budgetcodification`, { ...data })

    // .then((res) => {
    const res = await get({
      endpoint: `/api/v1/GeoCode`,
      auth: true,
      // body: payload,
    });

    if (res && res?.status == 200) {
      //  setIsLoading(false);

      //  setIsLoading(false);

      console.log(res);
      setLocationCode(res?.data);
      //  notification({
      //    title: "Successful",
      //    message: "Projects have been successfully sent",
      //    type: "success",
      //  });
    } else {
      // console.log(err?.response);
      //  setIsLoading(false);
      //  notification({
      //    title: "Sending Failed",
      //    message: `{err?.response?.data}`,
      //    type: "danger",
      //  });
    }
  };

  const functionalCodes = functionalCode.map((e) => (
    <Option value={e.code}>{`${e.functionalSegmentName} (${e?.code})`}</Option>
  ));
  const programmeCodes = programmeCode.map((e) => (
    <Option value={e.programmeCode}>
      {`${e.programmeName} (${e?.programmeCode})`}
    </Option>
  ));
  const locationCodes = locationCode.map((e) => (
    <Option value={e.code}>{`${e.geoCodeName} (${e?.code})`}</Option>
  ));
  const createData = () => {
    // console.log(props.propzApproved[0]?.ongoingProjects);
    const ongoingProjectsData = props.propzApproved;

    // ?.ongoingProjects?.map(
    //   (e) => ({
    //     deptBudgetId: "fd9de34e-86f6-43c1-98ce-998a4812c289",
    //     project: e.project,
    //     status: e.status,
    //     location: e.location,
    //     startDate: moment("2022-10-11T17:08:24.133").format("ll"),
    //     amountSpent: e.amountSpent,
    //     conclusionEstimate: e.conclusionEstimate,
    //     completionTime: e.completionTime,
    //     mdaId: 11,
    //   })
    // );

    console.log(ongoingProjectsData);
    setData(ongoingProjectsData);
  };

  //  console.log(props.propzApproved);

  const mergedColumns = personnelCostsColumn.map((col) => {
    if (!col.editable) {
      return col;
    }
    return {
      ...col,
      onCell: (record) => ({
        record,
        inputType: col.dataIndex === "comment" ? "text" : "number",
        dataIndex: col.dataIndex,
        title: col.title,
        editing: isEditing(record),
      }),
    };
  });

  const collectEconomicSegmentId = (id) => {
    console.log(id);
    form.setFieldsValue({ economicCode: id?.key });
    setEconomicSegmentId(id?.value);
    setEconomicSegment(id);
  };
  const collectEconomicTypeId = (id) => {
    form.setFieldsValue({ economicCode: id?.key });
    setEconomicTypeId(id?.value);
  };
  const collectEconomicSubTypeId = (id) => {
    form.setFieldsValue({ economicCode: id?.key });
    setEconomicSubTypeId(id?.value);
  };
  const collectEconomicSubSubTypeId = (id) => {
    form.setFieldsValue({ economicCode: id?.key });
    setEconomicSubSubTypeId(id?.value);
  };

  const collectEconomicItem = (id) => {
    form.setFieldsValue({ economicCode: id?.key });
    setEconomicItemId(id?.value);
  };

  return (
    <Form form={form} onFinish={onFinish} layout="vertical">
      <div
        className="w-full flex mr-10 mb-10"
        style={{ gap: 10, marginBottom: "10px" }}
      >
        <EconomicSegment
          count={count}
          prefix={props.filter}
          collectEconomicSegmentId={collectEconomicSegmentId}
        />
        <EconomicType
          count={count}
          prefix={props.filter}
          collectEconomicTypeId={collectEconomicTypeId}
          id={economicSegmentId}
        />
        <EconomicSubType
          count={count}
          prefix={props.filter}
          collectEconomicSubTypeId={collectEconomicSubTypeId}
          id={economicTypeId}
        />
        <EconomicSubSubType
          count={count}
          prefix={props.filter}
          collectEconomicSubSubTypeId={collectEconomicSubSubTypeId}
          id={economicSubTypeId}
        />
        <EconomicItem
          count={count}
          prefix={props.filter}
          collectEconomicItem={collectEconomicItem}
          id={economicSubSubTypeId}
        />
      </div>

      <div class="flex-between">
        {/* <Form.Item
            className="m-r-10"
            label="Funds Code"
              style={{ width: `100%` }}
              name="fundDetailsCode"
          >
            <Select
              style={{ width: `100%` }}
              // onChange={handleChange}
            >
              {fundCodes}
            </Select> 
          </Form.Item>*/}
        <Form.Item
          className="m-r-10"
          label="Functional Code"
          name="functionalDetailsCode"
          style={{ width: `100%` }}
        >
          <Select
            filterOption={(input, option) =>
              option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
            }
            filterSort={(optionA, optionB) =>
              optionA.children
                .toLowerCase()
                .localeCompare(optionB.children.toLowerCase())
            }
            allowClear
            // onChange={handleChange}
            // onChange={handleChange}
            style={{ width: `100%` }}
            // onChange={handleChange}
          >
            {functionalCodes}
          </Select>
        </Form.Item>
        <Form.Item
          label="Programme Code"
          className="m-r-10"
          style={{ width: `100%` }}
          name="programmeCode"
        >
          <Select
            filterOption={(input, option) =>
              option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
            }
            filterSort={(optionA, optionB) =>
              optionA.children
                .toLowerCase()
                .localeCompare(optionB.children.toLowerCase())
            }
            allowClear
            style={{ width: `100%` }}
            // onChange={handleChange}
          >
            {programmeCodes}
          </Select>
        </Form.Item>
        <Form.Item
          className="m-r-10"
          label="Location Code"
          style={{ width: `100%` }}
          name="locationCode"
        >
          <Select
            filterOption={(input, option) =>
              option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
            }
            filterSort={(optionA, optionB) =>
              optionA.children
                .toLowerCase()
                .localeCompare(optionB.children.toLowerCase())
            }
            allowClear
            style={{ width: `100%` }}
            // onChange={handleChange}
          >
            {locationCodes}
          </Select>
        </Form.Item>
      </div>

      <div className="w-100 flex-between m-r-10">
        <Form.Item
          style={{ width: "100%", marginRight: "10px" }}
          label="Economic Code"
          name="economicCode"
        >
          {/* <Select
            // defaultValue="lucy"
            labelInValue
            // optionFilterProp="children"
            // filterOption={(input, option) =>
            //   option?.children?.toLowerCase()?.indexOf(input?.toLowerCase()) >=
            //   0
            // }
            // filterSort={(optionA, optionB) =>
            //   optionA?.children
            //     ?.toLowerCase()
            //     ?.localeCompare(optionB?.children?.toLowerCase())
            // }
            // showSearch
            // style={{ width: "100%" }}
            // placeholder="Search to Select"
            // optionFilterProp="children"
            // filterOption={(input, option) =>
            //   option?.children?.toLowerCase()?.indexOf(input?.toLowerCase()) >=
            //   0
            // }
            // filterSort={(optionA, optionB) =>
            //   optionA?.children
            //     ?.toLowerCase()
            //     ?.localeCompare(optionB?.children?.toLowerCase())
            // }
            onChange={(e) => {
              // console.log(e);
              // form.setFieldsValue({
              //   economicDetails: e.label,
              // });
            }}
          >
            {opt}
          </Select> */}
          <Input readOnly />
        </Form.Item>

        <Form.Item
          style={{ width: "100%", marginRight: "10px" }}
          label="Detail of Expenditure"
          name="economicDetails"
        >
          <TextArea />
        </Form.Item>

        <Form.Item
          style={{ width: "100%", marginRight: "10px" }}
          label="Proposed overhead Budget 2024"
          name="proposedBudget"
        >
          <Input
            formatter={(value) =>
              `₦ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
            }
            parser={(value) => value?.replace(/\₦\s?|(,*)/g, "")}
            style={{ width: "100%" }}
          />
        </Form.Item>
      </div>
      <div className="flex-between w-100">
        <Form.Item
          style={{ width: "100%", marginRight: "10px" }}
          label="Overhead Budget 2023"
          name="previousApprovedBudget"
        >
          <Input
            formatter={(value) =>
              `₦ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
            }
            parser={(value) => value?.replace(/\₦\s?|(,*)/g, "")}
            style={{ width: "100%" }}
          />
        </Form.Item>
        <div className="w-100 m-r-10">
          <Form.Item
            label="Actual Overhead Budget as Jan-Sept 2023"
            name="currentExpenditures"
          >
            <Input
              formatter={(value) =>
                `₦ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
              }
              parser={(value) => value?.replace(/\₦\s?|(,*)/g, "")}
              style={{ width: "100%" }}
            />
          </Form.Item>
        </div>
        <div className="w-100">
          <Form.Item
            label="Actual Overhead Budget 2022"
            name="previousExpenditures"
          >
            <Input
              formatter={(value) =>
                `₦ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
              }
              parser={(value) => value?.replace(/\₦\s?|(,*)/g, "")}
              style={{ width: "100%" }}
            />
          </Form.Item>
          {/* <Form.Item label="Current Expense At" name="currentExpenseAsAt">
              <Input style={{ width: "100%" }} />
            </Form.Item> */}
        </div>
      </div>

      <Form.Item
      //   wrapperCol={{
      //     offset: 8,
      //     span: 16,
      //   }}
      >
        <Button
          style={{ backgroundColor: "#1d811c" }}
          type="primary"
          htmlType="submit"
        >
          Save
        </Button>
      </Form.Item>

      <div style={{ overflowX: "scroll" }}>
        <div style={{ width: "1100px" }}>
          <Table
            components={{
              body: {
                cell: EditableCell,
              },
            }}
            bordered
            columns={mergedColumns}
            dataSource={data}
            rowClassName="editable-row"
            pagination={{
              onChange: cancel,
            }}
            summary={(pageData) => {
              let totalproposedBudget = 0;
              let totalpreviousApprovedBudget = 0;
              let totalcurrentExpenditures = 0;
              let totalpreviousExpenditures = 0;
              pageData.forEach(
                ({
                  proposedBudget,
                  previousApprovedBudget,
                  currentExpenditures,
                  previousExpenditures,
                }) => {
                  totalproposedBudget += +proposedBudget;
                  totalpreviousApprovedBudget += +previousApprovedBudget;
                  totalcurrentExpenditures += +currentExpenditures;
                  totalpreviousExpenditures += +previousExpenditures;
                  //  props.total({
                  //   name:'Overhead Expenditure',
                  //    Proposed2024: totalproposedBudget,
                  //    Approved2023: totalpreviousApprovedBudget,
                  //    ActualJanToSep2023: totalcurrentExpenditures,
                  //    Approved2021: totalpreviousExpenditures,
                  //  });
                }
              );
              return (
                <>
                  <Table.Summary.Row>
                    <Table.Summary.Cell index={0}>Total</Table.Summary.Cell>
                    <Table.Summary.Cell index={1}></Table.Summary.Cell>
                    <Table.Summary.Cell index={1}></Table.Summary.Cell>
                    <Table.Summary.Cell index={1}></Table.Summary.Cell>
                    <Table.Summary.Cell index={1}></Table.Summary.Cell>
                    <Table.Summary.Cell index={2}>
                      <Text type="success">
                        {`₦ ${numberWithCommas(totalproposedBudget)}`}
                      </Text>
                    </Table.Summary.Cell>
                    <Table.Summary.Cell index={3}>
                      <Text type="success">
                        {`₦ ${numberWithCommas(totalpreviousApprovedBudget)}`}
                      </Text>
                    </Table.Summary.Cell>
                    <Table.Summary.Cell index={4}>
                      <Text type="success">
                        {`₦ ${numberWithCommas(totalcurrentExpenditures)}`}
                      </Text>
                    </Table.Summary.Cell>
                    <Table.Summary.Cell index={5}>
                      <Text type="success">{`₦ ${numberWithCommas(
                        totalpreviousExpenditures
                      )}`}</Text>
                    </Table.Summary.Cell>
                  </Table.Summary.Row>
                  {/* const {Text} = Typography; */}
                  {/* <Table.Summary.Row>
                    <Table.Summary.Cell index={0}>Balance</Table.Summary.Cell>
                    <Table.Summary.Cell index={1} colSpan={2}>
                      <Text type="danger">{totalBorrow - totalRepayment}</Text>
                    </Table.Summary.Cell>
                  </Table.Summary.Row> */}
                </>
              );
            }}
          />
        </div>
      </div>
      {/* <Form.Item
        style={{ width: "100%", marginRight: "10px" }}
        label="Detail of Expenditure"
        name="ongoingProjects"
      >
        <Input />
      </Form.Item> */}

      {/* <Form.Item>
        <Button
          style={{ backgroundColor: "#1d811c" }}
          type="primary"
          htmlType="submit"
        >
          Save
        </Button>
      </Form.Item> */}
    </Form>
  );
};
export default BudgetItems;
