/* eslint-disable operator-linebreak */
/* eslint-disable object-curly-newline */
/* eslint-disable comma-dangle */
/* eslint-disable quotes */
/* eslint-disable indent */
/* eslint-disable linebreak-style */
/* eslint-disable array-bracket-spacing */
/* eslint-disable no-empty */
/* eslint-disable keyword-spacing */
/* eslint-disable brace-style */
/* eslint-disable import/order */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable max-len */
/* eslint-disable no-unused-vars */
import React, { useEffect, useState, useRef, useCallback } from "react";
import "../../assets/css/Dashboard.css";
// import AutoComplete from "../inputs/AutoComplete";
// import CommentTable from "../layouts/CommentTable";

// import { IoMdClose } from "react-icons/io";
// // import { AiOutlineRedEnvelope } from 'react-icons/ai';
// // import { BiCalendar } from 'react-icons/bi';
// // import { HiOutlineUserGroup } from 'react-icons/hi';
// // import Calendar from "react-calendar";
// import CardGiftcardIcon from "@material-ui/icons/CardGiftcard";
// import EventNoteIcon from "@material-ui/icons/EventNote";
// import SearchInput from "../inputs/SearchInput";
// import RequestDetails from "../layouts/RequestDetails";
import { get, put } from "../../api-services/fetch";
// import { newget } from "../../api-services/newfetch";
// import IsLoading from "../common/IsLoading";
// import {
//   formatSimpleDate,
//   formatDate,
//   formatDateforPicker,
// } from "../../utility/dateTime";
import axios from "axios";

// import ExecViewuestForm from "../layouts/ExecViewuestForm";
// import Greeting from "../common/Greeting";
// import LocationDetails from "../layouts/LocationDetails";
// // import { formatStatus } from "../../utility/general";
// import notification from "../../utility/notification";
import "../../assets/css/calendar.css";
// import useClickOutsideBox from "../hooks/useClickOutsideBox";
// import SelectInputColored from "../inputs/SelectInputColored";
// // import CreateIcon from "@material-ui/icons/Create";
// import DeleteIcon from "@material-ui/icons/Delete";
// import { decodeToken } from "../../utility/auth";
// import RequestQueryForm from "../layouts/QueryForm";
// import QueryThread from "../layouts/QueryThread";
// import Massive from "../layouts/massive";
import GovernorsVIew from "./GovernorsView";
import {
  Table,
  Button,
  Input,
  Modal,
  Select,
  Tooltip,
  Form,
  Card,
  Typography,
  Spin,
} from "antd";
import Cookies from "js-cookie";
import notification from "../../utility/notification";
// import { useForm } from "antd/lib/form/Form";

const EnvelopeReview = ({ history }) => {
  const [pageWaiting, setpageWaiting] = useState(false);
  const [amount, setAmount] = useState("");
  const [status, setStatus] = useState("");
  const [arrayHolder, setArrayHolder] = useState([]);
  const [envelopHolder, setenvelopHolder] = useState([]);
  const [id, setId] = useState(1);
  const [title, setTile] = useState("");
  const [totalCost, setTotalCost] = useState("");
  const [dateCreated, setDateCreated] = useState("");
  const [onShow, setOnShow] = useState(false);

  const [isLoading, setIsLoading] = useState(false);
  const [details, setDetails] = useState([]);
  const [budgetInvitationId, setbudgetInvitationId] = useState();
  const [meetingCounts, setMeetingCounts] = useState({});
  const [errorMsg, setErrorMsg] = useState("No Request Yet");
  const [Mda, setMda] = useState([]);
  const [mdaId, setMdaId] = useState([]);
  const [employees, setEmployees] = useState([]);
  const [employeesId, setEmployeesId] = useState([]);
  const [ApproverId, setApproverId] = useState([]);
  const [mailDisplay, setMailDisplay] = useState(true);
  const [mdaDisplay, setMdaDisplay] = useState(false);
  const [category, setCategory] = useState([]);
  const [searchText, setSearchText] = useState("");
  //   const [status, setStatus] = useState("actioned");
  const [ourUrl, setOurUrl] = useState("");
  const [username, setUsername] = useState("");
  const [userId, setUserId] = useState("");
  const [summary, setSummaary] = useState("");
  const [batchId, setBatchId] = useState("");
  const [projectInitiative, setProjectInitiative] = useState("");
  const [onAction, setOnAction] = useState(false);
  const [sectorId, setSectorId] = useState();
  const [sentBudgetByDirector, setsentBudgetByDirector] = useState(false);

  const [approversTableInfo, setApproversTableInfo] = useState([]);
  const [commentsBudget, setCommentsBudget] = useState([]);
  const [commentsBudgetA, setCommentsBudgetA] = useState([]);
  const [budgetPeriodId, setbudgetPeriodId] = useState([]);

  const [listOfMdas, setListOfMdas] = useState([]);
  const [budgetPeriod, setbudgetPeriod] = useState([]);
  const [PSInvitationData, setPSInvitationData] = useState([]);
  const [PSENvelopeData, setPSENvelopeData] = useState([]);
  const [alreadyApprovedByPsArray, setalreadyApprovedByPsArray] = useState([]);
  const [meetingCalendar, setMeetingCalendar] = useState(null);
  const [showQueryForm, setShowQueryForm] = useState(false);
  const [showQueryThread, setShowQueryThread] = useState(false);
  const token =
    "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJVc2VySUQiOiIxIiwiRmlyc3ROYW1lIjoiRWRvIEdvdnYiLCJMYXN0TmFtZSI6IkFkbWluIiwiZXhwIjoxNjIxNzg3NTQ1LCJpc3MiOiJzZXJ2ZXIiLCJhdWQiOiJjbGllbnQifQ.JpkTA-250nWIkIxrOyaLZcbw33fJNXuV7z1tPE9zz0w";
  const wrapperRef = useRef(null);
  const focusRef = useRef();
  const [current, setCurrent] = useState("");
  const [currentId, setCurrentId] = useState("");
  // console.log(details);
  // console.log(errorMsg);

  const BASE_API_URL = "https://edogoverp.com/budget/api/v1";
  // const BASE_API_URL = 'http://localhost:5000/api/v1';

  //   import React from 'react';
  // import { Table } from 'antd';
  // import 'antd/dist/antd.css';

  const { TextArea } = Input;
  const [form] = Form.useForm();

  const data = [
    ...PSENvelopeData,
    // {
    //   projectBatchId: "1171ab8e-74f8-4a14-9653-509eb50b4f02",
    //   totalAmount: 122000,
    //   overheadCost: 33000,
    //   personelCost: 23000,
    //   capitalCost: 12000,
    //   capitalReceiptCost: 34000,
    //   overheadMonitorCost: null,
    //   recurrentRevenueCost: 20000,
    //   mdaId: 50,
    //   comments: "",
    //   status: null,
    //   dateCreated: "2023-10-23T04:12:20.807",
    //   enteredBy: "",
    //   dateUpdated: "2023-10-23T04:12:20.807",
    //   updatedBy: "",
    //   isDelete: false,
    //   dateDeleted: "2023-10-23T04:12:20.807",
    //   id: "f9b8e274-8569-4559-95ba-f70cdf149b07",
    // },
  ];
  const data2 = [
    ...alreadyApprovedByPsArray,
    // {
    //   projectBatchId: "1171ab8e-74f8-4a14-9653-509eb50b4f02",
    //   totalAmount: 122000,
    //   overheadCost: 33000,
    //   personelCost: 23000,
    //   capitalCost: 12000,
    //   capitalReceiptCost: 34000,
    //   overheadMonitorCost: null,
    //   recurrentRevenueCost: 20000,
    //   mdaId: 50,
    //   comments: "",
    //   status: null,
    //   dateCreated: "2023-10-23T04:12:20.807",
    //   enteredBy: "",
    //   dateUpdated: "2023-10-23T04:12:20.807",
    //   updatedBy: "",
    //   isDelete: false,
    //   dateDeleted: "2023-10-23T04:12:20.807",
    //   id: "f9b8e274-8569-4559-95ba-f70cdf149b07",
    // },
  ];

  const returnStatus = (status) => {
    if (status == 1) return "Pending";
    if (status == 7) return "Approved";
  };

  const columns = [
    {
      title: "MDA ID",
      dataIndex: "mdaId",
      key: "mdaId",
      render: (text) => <a>{listOfMdas?.find((e) => e.id == +text)?.name}</a>,
    },

    {
      title: "Total Amount",
      dataIndex: "totalAmount",
      key: "totalAmount",
      render: (text) => <a>{numberWithCommas(+text)}</a>,
    },
    {
      title: "Overhead Cost",
      dataIndex: "overheadCost",
      key: "overheadCost",
      render: (text) => numberWithCommas(+text),
    },
    {
      title: "Personnel Cost",
      dataIndex: "personelCost",
      key: "personelCost",
      render: (text) => numberWithCommas(+text),
    },
    {
      title: "Capital Cost",
      dataIndex: "capitalCost",
      key: "capitalCost",
      render: (text) => <a>{numberWithCommas(+text)}</a>,
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      render: (text) => <div>{returnStatus(+text)}</div>,
    },
    //   {
    //     title: 'Overhead Monitor Cost',
    //     dataIndex: 'overheadMonitorCost',
    //     key: 'overheadMonitorCost',
    //   },
    // {
    //   title: "Recurrent Revenue Cost",
    //   dataIndex: "recurrentRevenueCost",
    //   key: "recurrentRevenueCost",
    // },

    //   {
    //     title: 'Date Created',
    //     dataIndex: 'dateCreated',
    //     key: 'dateCreated',
    //   },
    // {
    //   title: "Entered By",
    //   dataIndex: "enteredBy",
    //   key: "enteredBy",
    // },
    //   {
    //     title: 'Date Updated',
    //     dataIndex: 'dateUpdated',
    //     key: 'dateUpdated',
    //   },
    //   {
    //     title: 'Updated By',
    //     dataIndex: 'updatedBy',
    //     key: 'updatedBy',
    //   },
    //   {
    //     title: 'Is Delete',
    //     dataIndex: 'isDelete',
    //     key: 'isDelete',
    //   },
    //   {
    //     title: 'Date Deleted',
    //     dataIndex: 'dateDeleted',
    //     key: 'dateDeleted',
    //   },
    //   {
    //     title: 'ID',
    //     dataIndex: 'id',
    //     key: 'id',
    //   },
  ];

  // const getMdas = () => {
  //   axios
  //     .get(`${BASE_API_URL}/Mda`)
  //     .then((response) => {
  //       // console.log(response);
  //       setListOfMdas(response.data);

  //       // this.setState({
  //       //     allBudgetCodification: response.data,
  //       //     loader: false,
  //       // });
  //     })
  //     .catch((err) => {
  //       // this.setState({
  //       //     message: nullcheck(err.response) == "" ? "server error" : err.response.data,
  //       //     loader: false,
  //       // });
  //     });
  // };
  const getMdas = async () => {
    // axios
    //   .get(`${BASE_API_URLs}v1/Mda`)

    const res = await get({
      endpoint: `/api/v1/mda`,
      auth: true,
      // body: payload,
    });
    if (res && res.status == 200) {
      console.log(res);
      setListOfMdas(res.data);

      // this.setState({
      //     allBudgetCodification: response.data,
      //     loader: false,
      // });
    } else {
      // this.setState({
      //     message: nullcheck(err.response) == "" ? "server error" : err.response.data,
      //     loader: false,
      // });
    }
  };

  function numberWithCommas(x) {
    return x?.toString()?.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  }

  const getBudgetPeriod = async () => {
    // axios
    //   .get("https://edogoverp.com/newbudgetapi/api/Settings")

    const res = await get({ endpoint: "/api/Settings", auth: true });
    if (res.status == 200) {
      console.log(res?.data?.find((e) => e?.isCurrent));
      setbudgetPeriod(res?.data);
      setbudgetPeriodId(res?.data?.find((e) => e?.isCurrent)?.id);
      getInvitationFOrPSApproval(res?.data?.find((e) => e?.isCurrent));
      // getInvitationAlreadyPSApproval(res?.data?.find((e) => e?.isCurrent));

      // this.setState({
      //     allBudgetCodification: response.data,
      //     loader: false,
      // });
    } else {
      // this.setState({
      //     message: nullcheck(err.response) == "" ? "server error" : err.response.data,
      //     loader: false,
      // });
    }
  };

  useEffect(() => {
    getBudgetPeriod();
    getMdas();
    //   getInvitationFOrPSApproval()
    getEnvelopesPS();
    getApprovedEnvelopesPS();
    // const r = localStorage.getItem("r");
    // let fn = localStorage.getItem("fn");
    // let ln = localStorage.getItem("ln");
    // let ui = localStorage.getItem("ui");
  }, []);

  const getExecutivePendingBudgetApprovals = async () => {
    const res = await get({
      endpoint: `/api/v1/BudgetPlanning/GetBudgetPlanningBudgetDeptByUserId?userId=${Cookies.get(
        "userId"
      )}`,
      auth: true,
    });

    // console.log(res);
    setApproversTableInfo(res.data);
  };

  const getEnvelopesPS = async () => {
    // axios
    //   .get("https://edogoverp.com/newbudgetapi/api/Settings")

    const res = await get({
      endpoint: "/api/BudgetEnvelope/GetBudgetEnvelopesPS",
      auth: true,
    });
    if (res.status == 200) {
      // console.log(res);
      //   setbudgetPeriod(res.data)
      setPSENvelopeData(res?.data);
    } else {
      // this.setState({
      //     message: nullcheck(err.response) == "" ? "server error" : err.response.data,
      //     loader: false,
      // });
    }
  };
  const getApprovedEnvelopesPS = async () => {
    // axios
    //   .get("https://edogoverp.com/newbudgetapi/api/Settings")

    const res = await get({
      endpoint: "/api/BudgetEnvelope/GetApprovedBudgetEnvelopesPS",
      auth: true,
    });
    if (res.status == 200) {
      // console.log(res);
      //   setbudgetPeriod(res.data)
      setalreadyApprovedByPsArray(res?.data);
    } else {
      // this.setState({
      //     message: nullcheck(err.response) == "" ? "server error" : err.response.data,
      //     loader: false,
      // });
    }
  };
  const getInvitationFOrPSApproval = async (budgetPeriod) => {
    // axios
    //   .get("https://edogoverp.com/newbudgetapi/api/Settings")

    setpageWaiting(false);

    const res = await get({
      endpoint: "/api/BudgetInvitation/GetBudgetInvitationsForApproval",
      auth: true,
    });
    // console.log(res);
    if (res.status == 200) {
      // console.log(res);
      setPSInvitationData(
        res?.data.find((e) => e?.budgetPeriodId === budgetPeriod?.id)
      );
      // console.log(
      //   res?.data.find((e) => e?.budgetPeriodId === budgetPeriod?.id)
      // );
      res.data.length &&
        form.setFieldsValue({
          ...res?.data.find((e) => e?.budgetPeriodId === budgetPeriod?.id),
          budgetPeriodId: budgetPeriod?.title,
        });

      setpageWaiting(true);
    } else {
      // this.setState({
      //     message: nullcheck(err.response) == "" ? "server error" : err.response.data,
      //     loader: false,
      // });
    }
  };

  // const getInvitationAlreadyPSApproval = async (budgetPeriod) => {
  //   // axios
  //   //   .get("https://edogoverp.com/newbudgetapi/api/Settings")

  //   setpageWaiting(false);

  //   const res = await get({
  //     endpoint: "/api/BudgetInvitation/GetBudgetInvitationsForApproval",
  //     auth: true,
  //   });
  //   // console.log(res);
  //   if (res.status == 200) {
  //     // console.log(res);
  //     setPSInvitationData(
  //       res?.data.find((e) => e?.budgetPeriodId === budgetPeriod?.id)
  //     );
  //     // console.log(
  //     //   res?.data.find((e) => e?.budgetPeriodId === budgetPeriod?.id)
  //     // );
  //     res.data.length &&
  //       form.setFieldsValue({
  //         ...res?.data.find((e) => e?.budgetPeriodId === budgetPeriod?.id),
  //         budgetPeriodId: budgetPeriod?.title,
  //       });

  //     setpageWaiting(true);
  //   } else {
  //     // this.setState({
  //     //     message: nullcheck(err.response) == "" ? "server error" : err.response.data,
  //     //     loader: false,
  //     // });
  //   }
  // };

  const doApprove = async () => {
    setIsLoading(true);
    let payload = {
      comments: "Approved",
      approverId: +Cookies.get("userId"),
      id: PSInvitationData?.id,
      budgetPeriodId: budgetPeriodId,
      approverStatus: 7,
    };
    const res = await put({
      endpoint: `/api/ApprovalProcess/action_plan_invitations`,
      auth: true,
      body: payload,
    });

    if (res && res.status == 200) {
      notification({
        title: "Success",
        message: "Approved Successfully",
        type: "success",
      });
      setIsLoading(false);

      getBudgetPeriod();
    }
  };

  return (
    <Card className="w-100">
      {PSInvitationData || PSENvelopeData ? (
        pageWaiting ? (
          <div>
            <Form
              labelAlign="right"
              layout="vertical"
              form={form}
              name="myForm"
              //   initialValues={PSInvitationData}
              //   onFinish={onFinish}
            >
              <Form.Item label="Subject" name="subject">
                <Input readOnly />
              </Form.Item>
              <Form.Item label="Details" name="details">
                <TextArea rows={3} readOnly bordered={false} />
              </Form.Item>
              <Form.Item label="Budget Period" name="budgetPeriodId">
                <Input readOnly />
              </Form.Item>
            </Form>

            {Cookies.get("isBudgetPS") == "true" && (
              <div>
                <div
                  style={{
                    display: "flex",
                    gap: 20,
                    marginBottom: 20,
                    width: "100%",
                    justifyContent: "flex-end",
                  }}
                >
                  <Button
                    className="bold"
                    style={{
                      backgroundColor: !sentBudgetByDirector
                        ? "#1C811C"
                        : "white",
                      color: sentBudgetByDirector ? "#1C811C" : "white",
                      marginTop: "20px",
                      height: "40px",
                      padding: "0 50px",
                    }}
                    onClick={() => {
                      setsentBudgetByDirector(!sentBudgetByDirector);
                    }}
                  >
                    Pending
                  </Button>
                  <Button
                    style={{
                      backgroundColor: sentBudgetByDirector
                        ? "#1C811C"
                        : "white",
                      color: !sentBudgetByDirector ? "#1C811C" : "white",
                      marginTop: "20px",
                      height: "40px",
                      padding: "0 50px",
                    }}
                    onClick={() => {
                      setsentBudgetByDirector(!sentBudgetByDirector);
                    }}
                  >
                    Actioned
                  </Button>
                </div>
                <div>
                  {!sentBudgetByDirector ? (
                    <Table dataSource={data} columns={columns} />
                  ) : (
                    <Table dataSource={data2} columns={columns} />
                  )}
                </div>
                <div
                  style={{ display: "flex", justifyContent: "center" }}
                  className="justify-center flex-center flex w-100"
                >
                  <Button
                    onClick={doApprove}
                    loading={isLoading}
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      padding: "10px",
                      height: "40px",
                      backgroundColor: "#1C811C",
                      color: "white",
                      width: "50%",
                    }}
                  >
                    Approve & Send to MDAs
                  </Button>
                </div>
              </div>
            )}
          </div>
        ) : (
          <div
            style={{
              width: "100%",
              height: "80vh",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Spin size="large" />
          </div>
        )
      ) : (
        "There is Currently No Invitation/Envelope awaiting your Approval"
      )}
    </Card>
  );
};

export default EnvelopeReview;
