import React, { Component, useState } from "react";
// import { store } from "react-notifications-component";
import { nullcheck, notify } from "../../Utils/Helper";

import Select from "react-select";
import { BsPencilSquare, BsTrash } from "react-icons/bs";
import { get, patch, post, del, put } from "../../api-services/fetch";

// import { ImBin2from,  } from 'react-icons/im';

import axios from "axios";
import { Preloader } from "../../Utils/Preloader";
import { Button, Input, Tabs, Select as AntSelect } from "antd";
import notification from "../../utility/notification";
// import ValidationMessageComponent from "./ValidationMessage";
// import departmentBudgetSchema from "../../Validation/DepartmentBudgetValidation";
import cookies from "js-cookie";
// import BudgetCodificationC from "../layouts/BudgetCodificationC";
// import { toHaveDisplayValue } from "@testing-library/jest-dom/dist/matchers";
// import OngoingProjects from "../layouts/OngoingProjects";
import PersonelCosts from "../layouts/PersonelCosts";
import RecurrentRev from "../layouts/RecurrentRev";
// import { GiArtilleryShell } from "react-icons/gi";
import CapitalProject from "../layouts/CapitalProjects";
// import KPI from "../layouts/KPI";
// import Rankings from "../layouts/Rankings";
import OverHeadCostMonitor from "../layouts/OverHeadCostMonitor";
import BudgetItems from "../layouts/BudgetItems";
import BudgetItemsDisplay from "../layouts/BudgetItemsDisplay";
import CapitalReceipts from "../layouts/CapitalReceipts";
import Cookies from "js-cookie";

// const BASE_API_URL = 'http://localhost:5000/api/v1';
const BASE_API_URL = "https://edogoverp.com/newbudgetapi/api/v1";
const BASE_API_URLs = "https://edogoverp.com/newbudgetapi/api/v1";
// const BASE_API_URL = process.env.REACT_APP_BASE_API_URL;
function isEmpty(obj) {
  return Object.keys(obj).length === 0;
}

const { Option } = AntSelect;

export default class DepartmentBudgetItems extends Component {
  constructor(props) {
    super(props);
    this.state = {
      open: true,
      listOfMdas: [],
      allBudgetDepartment: [],
      budgetDepartment: "",
      programSegment: "",
      administrativeSegment: [],
      employee: "",
      allEmployees: [],
      allProgramme: [],
      expenditureTypes: "",
      expenditureTypesData: [],
      item: "",
      visible: false,
      isLoading: false,
      budgetCurrencies: [],
      approvers: [],
      holders: [],
      summaryArray: [],
      savedBudgetPlanning: [],

      ongoingProjects: [],
      personnelCosts: [],
      recurrentRevenues: [],
      budgetItemz: [],
      capitalPro: [],
      capitalReceipts: [],
      kpiz: [],
      rankingz: [],
      overHeadz: [],

      costProperties: [],

      overheadCostTotal: 0,
      personelCostTotal: 0,
      capitalCostTotal: 0,
      capitalReceiptCostTotal: 0,
      overheadMonitorCostTotal: 0,
      recurrentRevenueCostTotal: 0,

      budgetPeriods: [],
      id: "",
      userid: "",
      deleteId: "",
      editMode: false,
      loading: false,
      title: "",
      nairaRate: "",
      budgetPeriodID: "",
      budgetPeriodValue: "",
      budgetPeriodText: "",
      message: "",
      showNotification: false,
      notificationSuccess: false,
      notificationMessage: "Successfully saved!",
      loader: false,

      catchTotalRecurrent: {
        name: "Recurrent Revenue",
        Proposed2024: 0,
        Approved2023: 0,
        ActualJanToSep2023: 0,
        Approved2021: 0,
      },
      catchTotalPersonal: {
        name: "Personnel Expenditure",
        Proposed2024: 0,
        Approved2023: 0,
        ActualJanToSep2023: 0,
        Approved2021: 0,
      },
      catchTotalOverHead: {
        name: "Overhead Expenditure",
        Proposed2024: 0,
        Approved2023: 0,
        ActualJanToSep2023: 0,
        Approved2021: 0,
      },
      catchTotalCapitalExp: {
        name: "Capital Expenditure",
        Proposed2024: 0,
        Approved2023: 0,
        ActualJanToSep2023: 0,
        Approved2021: 0,
      },
      catchTotalCapitalReceipt: {
        name: "Capital Receipts",
        Proposed2024: 0,
        Approved2023: 0,
        ActualJanToSep2023: 0,
        Approved2021: 0,
      },

      fuillName: "",
      employeeId: "",
      role: "",
      time: "",
      ministry: "",
      mdaId: 0,
      authorized: false,
      authorisedMdaId: 0,
      deleteModal: false,
    };
    // let w = ''
  }

  getMdas = async () => {
    // axios
    //   .get(`${BASE_API_URLs}v1/Mda`)

    const res = await get({
      endpoint: `/api/v1/mda`,
      auth: true,
      // body: payload,
    });
    if (res && res.status == 200) {
      console.log(res);
      this.setState({ listOfMdas: res?.data });

      // this.setState({
      //     allBudgetCodification: response.data,
      //     loader: false,
      // });
    } else {
      // this.setState({
      //     message: nullcheck(err.response) == "" ? "server error" : err.response.data,
      //     loader: false,
      // });
    }
  };

  componentDidMount() {
    notification({
      title: "Success",
      message: `Welcome!! Please proceed to carefully set up your Budget Items...`,
      type: "success",
    });
    this.getMdas();
    // this.getEmployees();
    this.getSavedBudgetPlanning();
    this.getEmployeeList();
    this.toGetEnvelope();
    this.getAdministrativeSegment();
    // this.getEconomicSegment();
    this.getAuthorisedUser();
  }
  getAuthorisedUser = () => {
    // const { cookies } = this.props;

    if (typeof cookies.get("userId") !== "undefined") {
      console.log(cookies.get("userId"));

      let authUser = cookies.get("userId");
      console.log(authUser);
      // let time = authUser.lastLoginTime.split("T")[1]
      this.setState({
        fullName: authUser.firstName + " " + authUser.lastName,
        role: authUser.role,
        authorisedMdaId: cookies.get("mdaId"),
        // time: time,
        ///ministry: authUser.mda.name,
        employeeId: authUser,
        ///mdaId: authUser.mdaId
      });
      // this.checkUserCanInitiate(authUser);
    }
  };
  handleSearch = (value) => {
    console.log(value);
    // Filter the options based on the search value
    const filteredOptions = this?.state?.options?.filter((option) =>
      option?.toLowerCase()?.includes(value?.toLowerCase())
    );

    this.setState({ options: filteredOptions });
  };

  handleChangeSelect = (value) => {
    this.setState({ selectedValue: value });
  };

  handleSelectedExpenditureTypeChange = (selectedExpenditure) => {
    this.setState(
      { selectedExpenditure, expenditureTypes: selectedExpenditure },
      () => console.log(`Option selected:`, selectedExpenditure)
    );
  };
  handleSelectedProgrammeChange = (selectedProgramSegment) => {
    this.setState(
      { selectedProgramSegment, programSegment: selectedProgramSegment },
      () => console.log(`Option selected:`, selectedProgramSegment)
    );
  };
  handleEmployeeChange = (employeeSegment) => {
    console.log(employeeSegment);
    this.setState({ employeeSegment }, () => {
      // console.log(this.state.employee)

      this.setState(
        {
          approvers: [
            ...this.state.approvers,
            {
              // "id": "3fa85f64-5717-4562-b3fc-2c963f66afa6",
              // label: this.state.employee.label,
              // label:"'"
              dateCreated: "2023-09-17T13:22:34.007Z",
              enteredBy: this.state.employeeId,
              dateUpdated: "2023-09-17T13:22:34.007Z",
              updatedBy: this.state.employeeId,
              isDelete: false,
              dateDeleted: "2023-09-17T13:22:34.007Z",
              // batchId: "3fa85f64-5717-4562-b3fc-2c963f66afa6",
              mdaId: +cookies.get("requestsMdaId"),
              approverId: employeeSegment.value,
            },
          ],
          holders: [
            ...this.state.holders,
            {
              // "id": "3fa85f64-5717-4562-b3fc-2c963f66afa6",
              label: employeeSegment.label,
              // label:"'"
              dateCreated: "2023-09-17T13:22:34.007Z",
              enteredBy: this.state.employeeId,
              dateUpdated: "2023-09-17T13:22:34.007Z",
              updatedBy: this.state.employeeId,
              isDelete: false,
              dateDeleted: "2023-09-17T13:22:34.007Z",
              batchId: "3fa85f64-5717-4562-b3fc-2c963f66afa6",
              mdaId: +cookies.get("requestsMdaId"),
              approverId: employeeSegment?.value,
            },
          ],
        },
        () => {
          console.log(this.state.approvers);
        }
      );

      console.log(`Option selected:`, employeeSegment);
    });
  };
  handleChange = (ev) => {
    this.setState({
      [ev.target.name]: ev.target.value,
    });
  };

  budgetzz = (val) => {
    // alert('hi')
    this.setState({ budgetItemz: val }, () =>
      console.log(this.state.budgetItemz)
    );
    // console.log(val);
  };
  overHead = (val) => {
    // alert('hi')
    this.setState({ overHeadz: [...this.state.overHeadz, val] }, () =>
      console.log(this.state.overHeadz)
    );
    // console.log(val);
  };
  rankings = (val) => {
    // alert('hi')
    this.setState({ rankingz: [...this.state.rankingz, val] }, () =>
      console.log(this.state.rankingz)
    );
    // console.log(val);
  };

  kpi = (val) => {
    // alert('hi')
    this.setState({ kpiz: [...this.state.kpiz, val] }, () =>
      console.log(this.state.kpiz)
    );
    // console.log(val);
  };

  capitalP = (val) => {
    // alert('hi')
    this.setState({ capitalPro: [...val] }, () =>
      console.log(this.state.capitalPro)
    );
    // console.log(val);
  };

  capitalReceipt = (val) => {
    // alert('hi')
    this.setState({ capitalReceipts: [...val] }, () =>
      console.log(this.state.capitalReceipts)
    );
    // console.log(val);
  };

  ongoingProj = (val) => {
    // alert('hi')
    this.setState({ ongoingProjects: val }, () =>
      console.log(this.state.ongoingProjects)
    );
    console.log(val);
  };

  personelC = (val) => {
    this.setState({ personnelCosts: [...val] }, () =>
      console.log(this.state.personnelCosts)
    );

    console.log(val);
  };

  recurrentR = (val) => {
    this.setState(
      {
        recurrentRevenues: [...val],
      },
      () => console.log(this.state.recurrentRevenues)
    );

    console.log(val);
  };

  handleDelete = () => {
    let id = this.state.deleteId;
    let mdaId = this.state.authorisedMdaId;
    this.setState({
      loader: true,
      message: "",
      deleteModal: false,
      deleteId: "",
    });
    axios
      .delete(`${BASE_API_URL}/BudgetPlan`)
      .then((response) => {
        this.setState({
          editMode: false,
          id: "",
          item: "",
          loader: false,
          message: "",
        });
        // notify(store, 'success', 'department budget deleted successfully');
        notification({
          title: "MESSAGE",
          message: `
                  ${"department budget deleted successfully"}`,
          type: "success",
          // container:'top-center'
          // animationIn: ['animate__animated', 'animate__slideIn'],
        });
        this.getDepartmentBudget(mdaId);
      })
      .catch((err) => {
        this.setState({
          loader: false,
          message:
            nullcheck(err.response) == "" ? "server error" : err.response.data,
        });

        // notification({
        //   title: 'ERROR MESSAGE',
        //   message: `
        //   ${nullcheck(err.response) == "" ? "server error" : err.response.data}`,
        //   type: 'danger',
        //   // container:'top-center'
        //   // animationIn: ['animate__animated', 'animate__slideIn'],
        // });
      });
    // this.setState({ deleteId: id }, () =>
    //   this.props.removeAction(DataTypes, { id })
    // );
  };

  // toGetEnvelope = () => {
  //   // console.log(payload.id);
  //   // setIsLoading(true)

  //   // overheadCost: 12;
  //   // overheadMonitorCost: null;
  //   // personelCost: 12;
  //   // projectBatchId: "b6996930-c8e7-4c5f-9891-3ed58eb12186";
  //   // recurrentRevenueCost: 12;

  //   axios
  //     .get(
  //       `https://edogoverp.com/newbudgetapi/api/BudgetEnvelope/GetBudgetEnvelopeByMda?mdaId=${+Cookies.get(
  //         "mdaId"
  //       )}`
  //     )
  //     .then((res) => {
  //       console.log(res?.data);

  //       this.setState({
  //         costProperties: res?.data || [],
  //         overheadCostTotal: res?.data?.overheadCost || 0,
  //         personelCostTotal: res?.data?.personelCost || 0,
  //         capitalCostTotal: res?.data?.capitalCost || 0,
  //         capitalReceiptCostTotal: res?.data?.capitalReceiptCost || 0,
  //         overheadMonitorCostTotal: res?.data?.overheadMonitorCost || 0,
  //         recurrentRevenueCostTotal: res?.data?.recurrentRevenueCost || 0,
  //       });

  //       // setEnvelope(res.data);
  //     })
  //     .catch((err) => {
  //       console.log(err?.response);
  //     });
  // };

  toGetEnvelope = async () => {
    // console.log(payload.id);
    // setIsLoading(true)

    // axios
    //   .get(
    //     `https://edogoverp.com/newbudgetapi/api/BudgetEnvelope/GetBudgetEnvelopeByMda?mdaId=${+Cookies.get(
    //       "mdaId"
    //     )}`
    //   )
    //   .then((res) =>
    const res = await get({
      endpoint: `/api/BudgetEnvelope/GetBudgetEnvelopeByMda?mdaId=${+Cookies.get(
        "requestsMdaId"
      )}`,
      auth: true,
      // body: payload,
    });

    if (res && res.status == 200) {
      this.setState({
        costProperties: res?.data || [],
        overheadCostTotal: res?.data[0]?.overheadCost || 0,
        personelCostTotal: res?.data[0]?.personelCost || 0,
        capitalCostTotal: res?.data[0]?.capitalCost || 0,
        capitalReceiptCostTotal: res?.data[0]?.capitalReceiptCost || 0,
        overheadMonitorCostTotal: res?.data[0]?.overheadMonitorCost || 0,
        recurrentRevenueCostTotal: res?.data[0]?.recurrentRevenueCost || 0,
      });
      console.log(res?.data);

      // setEnvelope(res.data);
    } else {
      console.log(res);
    }
  };

  handleSubmit = async (tot, itm) => {
    this.setState({ isLoading: true });
    // console.log(tot);
    // let programmeId = this?.state?.programSegment?.value;
    // let economicSegmentId = this?.state.expenditureTypes?.value;
    // let item = this?.state?.item;
    // //   let mdaId = this?.state?.authorisedMdaId
    // //   let _enteredBy = this?.state?.employeeId

    // let enteredBy = cookies.get("userId");

    // let data = { economicSegmentId, programmeId, item, enteredBy };
    // // departmentBudgetSchema
    // //   .validate(data)
    // //   .then((message) => {
    // console.log(data);
    // this.setState({
    //   loader: true,
    //   message: "",
    // });

    // let bud = [
    //   // ...this.budgetItemz,
    //   {
    //     dateCreated: "2023-09-17T13:22:34.007Z",
    //     enteredBy: this.state.employeeId,
    //     dateUpdated: "2023-09-17T13:22:34.007Z",
    //     updatedBy: this.state.employeeId,
    //     isDelete: false,
    //     dateDeleted: "2023-09-17T13:22:34.007Z",
    //     budgetPlanId: "3fa85f64-5717-4562-b3fc-2c963f66afa6",
    //     budgetPeriodId: "3fa85f64-5717-4562-b3fc-2c963f66afa6",
    //     adminSectorCode: 0,
    //     project: "string",
    //     title: this?.state?.item,
    //     programmeCode: "111111111111",
    //     administrativeCode: "2222222222222",
    //     economicCode: "333333333333",
    //     fundCode: "444444444444",
    //     functionalCode: "555555555555",
    //     geoCode: "666666666666",
    //     status: "string",
    //     projectLocation: "string",
    //     proposedBudget: +tot,
    //     performance: "string",
    //     actuals: "string",
    //     mdaId: +this.state.authorisedMdaId,
    //     approvalStatus: 0,
    //     stage: 0,
    //   },
    // ];
    console.log(this.state.budgetItemz);
    let payload = {
      // budgetPeriodId: "",
      mdaId: +Cookies.get("requestsMdaId"),
      budgetItems: this.state.budgetItemz, // id: "3fa85f64-5717-4562-b3fc-2c963f66afa6",
      budgetPlanApprovalProcessesVM: this.state.approvers,
      ongoingProjects: this.state.ongoingProjects,
      personnelCosts: this.state.personnelCosts,
      recurrentRevenues: this.state.recurrentRevenues,
      capitalProjects: this.state.capitalPro,
      capitalReceipts: this.state.capitalReceipts,
      kpi: this.state.kpiz,
      rankings: this.state.rankingz,
      overHeadCostMonitor: this.state.overHeadz,
      revenueMonitor: [],
    };

    console.log(payload);

    if (
      this.state.approvers &&
      this.state.approvers.length > 0 &&
      this.state.selectedValue
    ) {
      // axios
      //     .post(
      //       `https://edogoverp.com/newbudgetapi/api/v1/BudgetPlanning`,
      //       payload
      //     )
      //     .then((response) => {
      let res = await post({
        endpoint: `/api/v1/BudgetPlanning`,
        auth: true,
        body: payload,
      });

      if (res && res.status == 200) {
        this.setState({
          item: "",
          expenditureTypes: "",
          loader: false,
          isLoading: false,
          message: "",
        });
        // notify(store, 'success', 'department budget added successfully');
        notification({
          title: "MESSAGE",
          message: `
                ${"department budget added successfully"}`,
          type: "success",
          // container:'top-center'
          // animationIn: ['animate__animated', 'animate__slideIn'],
        });
        // this.getDepartmentBudget(mdaId);
        // this.getFundSegments();
      }

      //     //   .catch((err) => {
      //     //     this.setState({
      //     //       loader: false,
      //     //         message: nullcheck(err.response) == "" ? "server error" : err.response.data,
      //     //     });
      //     //     // notification({
      //         //   title: 'ERROR MESSAGE',
      //         //   message: `
      //         //   ${nullcheck(err.response) == "" ? "server error" : err.response.data}`,
      //         //   type: 'danger',
      //         //   // container:'top-center'
      //         //   // animationIn: ['animate__animated', 'animate__slideIn'],
      //         // });
      //     //   });

      //   })
      else {
        this.setState({
          loader: false,
          message: res.errors,
        });
      }
    } else
      notification({
        title: "ERROR MESSAGE",
        message: `
          Make Sure Approvers and Admin code have been Selected`,
        type: "danger",
        // container:'top-center'
        // animationIn: ['animate__animated', 'animate__slideIn'],
      });
    this.setState({ isLoading: false });
  };

  getSavedBudgetPlanning = async (data) => {
    this.setState({ isLoading: true });

    // console.log(payload);

    //  ios
    //     .post(
    //       `https://edogoverp.com/newbudgetapi/api/v1/BudgetPlanning`,
    //       payload
    //     )
    //     .then((response) => {
    let res = await get({
      endpoint: `/api/v1/BudgetPlanning/GetSavedBudgetPlanningByMdaId?mdaId=${+Cookies.get(
        "requestsMdaId"
      )}`,
      auth: true,
      // body: payload,
    });

    if (res && res.status == 200) {
      console.log(res);

      this.setState({ savedBudgetPlanning: res?.data });

      notification({
        title: "MESSAGE",
        message: `
                ${"department budget updated successfully"}`,
        type: "success",
        // container:'top-center'
        // animationIn: ['animate__animated', 'animate__slideIn'],
      });
      // this.getDepartmentBudget(mdaId);
      // this.getFundSegments();
    } else {
      this.setState({
        loader: false,
        message: res.errors,
      });
    }

    this.setState({ isLoading: false });
  };

  handleSaved = async (data) => {
    this.setState({ isLoading: true });

    console.log(this.state.budgetItemz);
    let payload = {
      // budgetPeriodId: "",
      deptBudgetId: "",
      mda: "",
      mdaId: +Cookies.get("requestsMdaId"),
      budgetItems: this.state.budgetItemz, // id: "3fa85f64-5717-4562-b3fc-2c963f66afa6",
      budgetPlanApprovalProcessesVM: this.state.approvers,
      ongoingProjects: this.state.ongoingProjects,
      personnelCosts: this.state.personnelCosts,
      recurrentRevenues: this.state.recurrentRevenues,
      capitalProjects: this.state.capitalPro,
      capitalReceipts: this.state.capitalReceipts,
      kpi: this.state.kpiz,
      rankings: this.state.rankingz,
      overHeadCostMonitor: this.state.overHeadz,
      revenueMonitor: [],
      ...data,
    };

    console.log(payload);

    if (
      // this.state.approvers &&
      // this.state.approvers.length > 0 &&
      this.state.selectedValue
    ) {
      // axios
      //     .post(
      //       `https://edogoverp.com/newbudgetapi/api/v1/BudgetPlanning`,
      //       payload
      //     )
      //     .then((response) => {
      let res = await put({
        endpoint: `/api/v1/BudgetPlanning/SaveBudgetPlanning`,
        auth: true,
        body: payload,
      });

      if (res && res.status == 200) {
        this.setState({
          item: "",
          expenditureTypes: "",
          loader: false,
          isLoading: false,
          message: "",
        });

        this.getSavedBudgetPlanning();
        // notify(store, 'success', 'department budget added successfully');
        notification({
          title: "MESSAGE",
          message: `
                ${"department budget added successfully"}`,
          type: "success",
          // container:'top-center'
          // animationIn: ['animate__animated', 'animate__slideIn'],
        });
      } else {
        if (res?.data?.status > 300) {
          notification({
            title: "MESSAGE",
            message:
              res?.data?.title ||
              `
                  ${"An error occured contact support"}`,
            type: "danger",
            // container:'top-center'
            // animationIn: ['animate__animated', 'animate__slideIn'],
          });
          this.setState({
            loader: false,
            message: res.errors,
          });
        }
      }
    } else
      notification({
        title: "ERROR MESSAGE",
        message: `
          Make Sure Approvers and Admin code have been Selected`,
        type: "danger",
        // container:'top-center'
        // animationIn: ['animate__animated', 'animate__slideIn'],
      });
    this.setState({ isLoading: false });
  };

  getTotalRecurrent = (val) => {
    console.log(val);
    this.setState({ catchTotalRecurrent: val });
  };
  getTotalPersonal = (val) => {
    console.log(val);
    this.setState({ catchTotalPersonal: val });
  };
  getTotalOverHead = (val) => {
    console.log(val);
    this.setState({ catchTotalOverHead: val });
  };
  getTotalCapitalExp = (val) => {
    this.setState({ catchTotalCapitalExp: val });
  };
  getTotalCapitalReceipt = (val) => {
    this.setState({ catchTotalCapitalReceipt: val });
  };
  showSummary = (val) => {
    this.setState({ visible: !this.state.visible });
  };
  // getTotal = (val) => {
  //   console.log(val);
  // };

  getBudgetPeriod() {
    this.setState({
      loader: true,
      message: "",
    });
    axios
      .get(`${BASE_API_URL}/BudgetPeriod`)
      .then((response) => {
        this.setState({
          budgetPeriods: response.data,
          loader: false,
          message: "",
        });
      })
      .catch((err) => {
        this.setState({
          loader: false,
          message:
            nullcheck(err.response) == "" ? "server error" : err.response.data,
        });
        notification({
          title: "ERROR MESSAGE",
          message: `
          ${nullcheck(err.errors) == "" ? "server error" : err.response.data}`,
          type: "danger",
          // container:'top-center'
          // animationIn: ['animate__animated', 'animate__slideIn'],
        });
      });
  }
  getProgramme() {
    this.setState({
      loader: true,
      message: "",
    });
    axios
      .get(`${BASE_API_URL}/programme/getprogram`)
      .then((response) => {
        this.setState({
          allProgramme: response.data,
          loader: false,
          message: "",
        });
      })
      .catch((err) => {
        // this.setState({
        //   loader: false,
        //   message:
        //     nullcheck(err.response) == "" ? "server error" : err.response.data,
        // });
        // notification({
        //   title: "ERROR MESSAGE",
        //   message: `
        //   ${nullcheck(err.errors) == "" ? "server error" : err.response.data}`,
        //   type: "danger",
        //   // container:'top-center'
        //   // animationIn: ['animate__animated', 'animate__slideIn'],
        // });
      });
  }

  getEmployeeList = async () => {
    const res = await get({
      endpoint: `employee`,
      auth: true,
      pQuery: { q: "a" },
    });
    console.log(res);

    if (res && res.status === 200) {
      const { data } = res;
      if (res.status === 200) {
        console.log(data);
        // this.setState(data);
        this.setState({
          allEmployees: data,
          loader: false,
          message: "",
        });
      } else {
        notification({
          title: "Error Getting List of Employees",
          message: data.message,
          type: "danger",
        });
      }
    } else {
      notification({
        title: "Network",
        message: "Something has gone wrong. Please, try again.",
        type: "danger",
      });
    }
  };

  // async getEmployees() {
  //   //   alert('gg')
  //   const res = await get({
  //     endpoint: `employee`,
  //     auth: true,
  //     pQuery: { q: "a" },
  //   });
  //   console.log(res);
  //   let j = res?.data?.map((e) => ({
  //     label: `${e?.firstName} ${e?.lastName}`,
  //     value: e?.id,
  //   }));
  //   console.log(j);
  //   this.setState({
  //     allEmployees: j,
  //     loader: false,
  //     message: "",
  //   });

  //   // this.setState({
  //   //   loader: true,
  //   //   message: "",
  //   // });
  //   // axios
  //   //   .get(`${BASE_API_URL}/Employee`)
  //   //   .then((response) => {
  //   //     this.setState({
  //   //       allEmployees: response.data,
  //   //       loader: false,
  //   //       message: "",
  //   //     });
  //   //   })
  //   //   .catch((err) => {
  //   //     this.setState({
  //   //       loader: false,
  //   //         message: nullcheck(err.response) == "" ? "server error" : err.response.data,
  //   //     });
  //   //     notification({
  //   //       title: 'ERROR MESSAGE',
  //   //       message: `
  //   //       ${nullcheck(err.errors) == "" ? "server error" : err.response.data}`,
  //   //       type: 'danger',
  //   //       // container:'top-center'
  //   //       // animationIn: ['animate__animated', 'animate__slideIn'],
  //   //     });
  //   //   });
  // }

  handleEnployeeSearch = async (text) => {
    console.log(text);
    const res = await get({
      endpoint: "employee",
      auth: true,
      pQuery: { q: text || "e" },
    });
    console.log(res);

    if (res && res.status === 200) {
      const { data } = res;
      if (
        data
        // data.code === 1
      ) {
        console.log(data);
        this.setState({
          allEmployees: data,
          // loader: false,
          // message: "",
        });
        // // setEmployee(data);
      } else {
        // notification({
        //   title: "Error Getting List of Employees",
        //   message: data.message,
        //   type: "danger",
        // });
      }
    } else {
      // notification({
      //   title: "Network",
      //   message: "Something has gone wrong. Please, try again.",
      //   type: "danger",
      // });
    }
  };

  deleteApprover(id) {
    console.log(id);
    let kk = this.state.holders.filter((e) => e.approverId !== id);
    this.setState({
      holders: kk,
    });
    // console.log(kk);
  }

  getDepartmentBudget(mdaId) {
    this.setState({
      loader: true,
      message: "",
    });

    axios
      .get(
        `${BASE_API_URL}/departmentbudget/GetDepartmentBudgetByMdaId?mdaId=${mdaId}`
      )
      .then((response) => {
        this.setState({
          allBudgetDepartment: response.data,
          loader: false,
          message: "",
        });
      })
      .catch((err) => {
        console.log(err);
        this.setState({
          loader: false,
          message:
            nullcheck(err.response) == "" ? "server error" : err.response.data,
        });
        notification({
          title: "ERROR MESSAGE",
          message: `
          ${nullcheck(err.errors) == "" ? "server error" : err.response.data}`,
          type: "danger",
          // container:'top-center'
          // animationIn: ['animate__animated', 'animate__slideIn'],
        });
      });
  }
  getEconomicSegment = () => {
    this.setState({
      loader: true,
      message: "",
    });
    axios
      .get(`${BASE_API_URL}/economicsegment/geteconomicsegment`)
      .then((response) => {
        let economicSegment =
          response.data.length === 0 ? "" : response.data[0];
        console.log(economicSegment);
        this.setState({
          expenditureTypesData: response.data,
          expenditureType: economicSegment,
          loader: false,
          message: "",
        });
      })
      .catch((err) => {
        this.setState({
          loader: false,
          message:
            nullcheck(err.response) == "" ? "server error" : err.response.data,
        });
        notification({
          title: "ERROR MESSAGE",
          message: `
          ${nullcheck(err.errors) == "" ? "server error" : err.response.data}`,
          type: "danger",
          // container:'top-center'
          // animationIn: ['animate__animated', 'animate__slideIn'],
        });
      });
  };

  getAdministrativeSegment = async () => {
    this.setState({
      loader: true,
      message: "",
    });
    // axios
    //   .get(`${BASE_API_URL}/api/v1/AdministrativeSegment/GetAdministrativeSegment`)
    //   .then((response) => {
    const res = await get({
      endpoint: `/api/v1/AdministrativeSegment`,
      auth: true,
      // body: payload,
    });
    // console.log(res)

    if (res && res.status == 200) {
      // console.log(res?.data)
      // let economicSegment =
      //   response.data.length === 0 ? "" : response.data[0];
      // console.log(economicSegment);
      this.setState(
        {
          administrativeSegment: res?.data,
          // expenditureType: economicSegment,
          // loader: false,
          // message: "",
        },
        () => console.log(this.state?.administrativeSegment)
      );
    } else {
      this.setState({
        loader: false,
        // message:
        //   nullcheck(err.response) == "" ? "server error" : err.response.data,
      });
      // ${nullcheck(err.errors) == "" ? "server error" : err.response.data}

      notification({
        title: "ERROR MESSAGE",
        message: `error
          `,
        type: "danger",
        // container:'top-center'
        // animationIn: ['animate__animated', 'animate__slideIn'],
      });
    }
  };

  checkUserCanInitiate = (id) => {
    console.log(id);
    this.setState({
      loader: true,
      message: "",
    });
    axios
      .get(
        `${BASE_API_URL}/userprevilege/getuserprevilegebyemployeeId?employeeId=${id}`
      )
      .then((response) => {
        this.setState({
          loader: false,
          message: "",
        });

        if (response.data.length != 0) {
          this.setState({
            authorized: true,
            authorisedMdaId: response.data[0].mdaId,
          });
          this.getDepartmentBudget(response.data[0].mdaId);
        } else {
          this.setState({
            authorized: false,
          });
          this.props.history.push("/schedule-manager/");
        }
      })
      .catch((err) => {
        this.setState({
          loader: false,
          message:
            nullcheck(err.response) == "" ? "server error" : err.response.data,
        });
        notification({
          title: "ERROR MESSAGE",
          message: `
              ${
                nullcheck(err.errors) == "" ? "server error" : err.response.data
              }`,
          type: "danger",
          // container:'top-center'
          // animationIn: ['animate__animated', 'animate__slideIn'],
        });
      });
  };

  render() {
    function numberWithCommas(x) {
      return x?.toString()?.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    }

    let budgetPeriodsSelect = this.state.budgetPeriods.map((budgetPeriod) => {
      return (
        <option key={budgetPeriod.id} value={budgetPeriod.id}>
          {budgetPeriod.title}
        </option>
      );
    });
    let allBudgetDepartment =
      this.state?.allBudgetDepartment?.length == 0
        ? ""
        : this.state?.allBudgetDepartment.map((budgetDepartment) => {
            return (
              <tr
                key={budgetDepartment.id}
                style={{
                  borderBottom: "1px solid #C7C7C7",
                }}
              >
                <td className="px-6 py-4 whitespace-nowrap">
                  <div
                    className="bg-pen"
                    onClick={() => this.handleEdit(budgetDepartment)}
                  >
                    <BsPencilSquare
                      style={{ color: "#43425D", fontSize: "20px" }}
                    />
                  </div>
                </td>

                <td className="px-6 py-4 text-sm font-medium table-color">
                  {budgetDepartment?.programmes?.programmeName}
                </td>
                <td className="px-6 py-4 text-sm table-color">
                  {budgetDepartment?.item}
                  {/* {budgetDepartment.programmes.programmeName} */}
                </td>
                <td className="px-6 py-4 text-sm table-color">
                  {budgetDepartment.economicSegments?.economicSegmentName}
                </td>

                <td className="px-6 py-4 text-sm font-medium">
                  <a
                    className="cursor-pointer"
                    onClick={() =>
                      this.setState({
                        deleteModal: !this.state.deleteModal,
                        deleteId: budgetDepartment?.id,
                      })
                    }
                  >
                    <BsTrash style={{ color: "#43425D", fontSize: "20px" }} />
                  </a>
                </td>
              </tr>
            );
          });
    return (
      <React.Fragment>
        {this.state.loader === true ? <Preloader /> : ""}
        {this.state.deleteModal && (
          <>
            <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
          </>
        )}
        <div
          style={{
            display: "flex",
            marginTop: "10px",
            backgroundColor: "white",
            padding: "20px",
            width: "100%",
          }}
        >
          <div style={{ width: "100%" }}>
            <div
              style={{
                width: "100%",
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <AntSelect
                showSearch
                style={{ width: "50% " }}
                placeholder="Select admin Code"
                optionFilterProp="children"
                onSearch={this.handleSearch}
                onChange={this.handleChangeSelect}
                value={this.state.selectedValue}
                filterOption={(input, option) =>
                  option?.props.children
                    ?.toLowerCase()
                    ?.indexOf(input.toLowerCase()) >= 0
                }
              >
                {this.state.administrativeSegment?.map((option) => (
                  <Option key={option?.code} value={option?.code}>
                    {`${option.administrativeSegmentName} (${option?.code})`}
                  </Option>
                ))}
              </AntSelect>

              <div
                style={{
                  width: "100%",
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                {/* {+Cookies.get("requestsMdaId")} */}
                <h2>
                  {
                    this.state.listOfMdas?.find(
                      (mda) => mda?.id == +Cookies.get("requestsMdaId")
                    )?.name
                  }
                </h2>
              </div>

              <div>
                <h1>
                  ADMIN CODE:{" "}
                  <span style={{ color: "#1d811c" }}>
                    {this.state.selectedValue}
                  </span>
                </h1>
              </div>
            </div>
            {/* {this.state.administrativeSegment.map((e)=>'hi')} */}
            {this.state.selectedValue && (
              <Tabs defaultActiveKey="1">
                <Tabs.TabPane tab="Overhead" key="1">
                  <h2
                    className="w-100  bold"
                    style={{
                      display: "flex",
                      justifyContent: "flex-end",
                      color: "#1d811c",
                    }}
                  >
                    Allocated Budget: ₦
                    {numberWithCommas(
                      +this.state.overheadCostTotal -
                        +this.state?.budgetItemz?.reduce((acc, curr) => {
                          return +acc + +curr.proposedBudget;
                        }, 0)
                    )}
                  </h2>
                  <div className="w-100 flex-between flex-align-top">
                    <div>
                      <BudgetItems
                        adminCode={this.state.selectedValue}
                        handleSubmitz={this.budgetzz}
                        handleSaved={this.handleSaved}
                        name={this.state.item}
                        totalfromup={+this.state.overheadCostTotal}
                        total={this.getTotalOverHead}
                        filters={["22"]}
                        savedBudgetPlanning={this.state.savedBudgetPlanning}
                        getSavedBudgetPlanning={this.getSavedBudgetPlanning}
                      />
                    </div>
                  </div>
                </Tabs.TabPane>
                {/* <Tabs.TabPane tab="Ongoing Projects" key="2">
                <div style={{ marginRight: "10px", width: "100%" }}>
                  <OngoingProjects ongoingProj={this.ongoingProj} />
                </div>
              </Tabs.TabPane> */}
                <Tabs.TabPane tab="Personel Costs" key="3">
                  <h2
                    className="w-100  bold"
                    style={{
                      display: "flex",
                      justifyContent: "flex-end",
                      color: "#1d811c",
                    }}
                  >
                    Allocated Budget: ₦
                    {numberWithCommas(
                      +this.state.personelCostTotal -
                        +this.state.personnelCosts?.reduce((acc, curr) => {
                          return +acc + +curr.proposedExpense;
                        }, 0) || 0
                    )}
                  </h2>

                  <div style={{ marginRight: "10px", width: "100%" }}>
                    <PersonelCosts
                      adminCode={this.state.selectedValue}
                      personelC={this.personelC}
                      total={this.getTotalPersonal}
                      totalfromup={+this.state.personelCostTotal}
                      filters={["21"]}
                      handleSaved={this.handleSaved}
                      savedBudgetPlanning={this.state.savedBudgetPlanning}
                      getSavedBudgetPlanning={this.getSavedBudgetPlanning}
                    />
                  </div>
                </Tabs.TabPane>
                <Tabs.TabPane tab="Recurrent Revenue" key="4">
                  <h2
                    className="w-100  bold"
                    style={{
                      display: "flex",
                      justifyContent: "flex-end",
                      color: "#1d811c",
                    }}
                  >
                    Budget: ₦
                    {numberWithCommas(
                      +this.state.recurrentRevenues?.reduce((acc, curr) => {
                        return +acc + +curr.proposedRevenue;
                      }, 0)
                    )}
                  </h2>

                  <div style={{ marginRight: "10px", width: "100%" }}>
                    <RecurrentRev
                      adminCode={this.state.selectedValue}
                      filters={["11", "12"]}
                      recurrentR={this.recurrentR}
                      totalfromup={+this.state.recurrentRevenueCostTotal}
                      total={this.getTotalRecurrent}
                      handleSaved={this.handleSaved}
                      savedBudgetPlanning={this.state.savedBudgetPlanning}
                      getSavedBudgetPlanning={this.getSavedBudgetPlanning}
                    />
                  </div>
                </Tabs.TabPane>
                <Tabs.TabPane tab="Capital Expenditure" key="5">
                  <h2
                    className="w-100 bold"
                    style={{
                      display: "flex",
                      justifyContent: "flex-end",
                      color: "#1d811c",
                    }}
                  >
                    Allocated Budget: ₦
                    {numberWithCommas(
                      +this.state.capitalCostTotal -
                        +this.state.capitalPro?.reduce((acc, curr) => {
                          return +acc + +curr.proposedAppropriation;
                        }, 0)
                    )}
                  </h2>

                  <div style={{ marginRight: "10px", width: "100%" }}>
                    <CapitalProject
                      adminCode={this.state.selectedValue}
                      filters={["32"]}
                      capitalP={this.capitalP}
                      totalfromup={+this.state.capitalCostTotal}
                      total={this.getTotalCapitalExp}
                      handleSaved={this.handleSaved}
                      savedBudgetPlanning={this.state.savedBudgetPlanning}
                      getSavedBudgetPlanning={this.getSavedBudgetPlanning}
                    />
                  </div>
                </Tabs.TabPane>

                {/* <Tabs.TabPane tab="KPI" key="6">
                <div style={{ marginRight: "10px", width: "100%" }}>
                  <KPI kpi={this.kpi} />
                </div>
              </Tabs.TabPane> */}
                <Tabs.TabPane tab="Capital Receipts" key="6">
                  <h2
                    className="w-100 bold"
                    style={{
                      display: "flex",
                      justifyContent: "flex-end",
                      color: "#1d811c",
                    }}
                  >
                    Budget: ₦
                    {numberWithCommas(
                      +this.state.capitalReceipts?.reduce((acc, curr) => {
                        return +acc + +curr.proposedAppropriation;
                      }, 0)
                    )}
                  </h2>

                  <div style={{ marginRight: "10px", width: "100%" }}>
                    <CapitalReceipts
                      filters={["13", "14"]}
                      adminCode={this.state.selectedValue}
                      capitalReceipts={this.capitalReceipt}
                      totalfromup={+this.state.capitalReceiptCostTotal}
                      total={this.getTotalCapitalReceipt}
                      handleSaved={this.handleSaved}
                      savedBudgetPlanning={this.state.savedBudgetPlanning}
                      getSavedBudgetPlanning={this.getSavedBudgetPlanning}
                    />
                  </div>
                </Tabs.TabPane>
                {/* <Tabs.TabPane tab="Rankings" key="7">
                <div style={{ marginRight: "10px", width: "100%" }}>
                  <Rankings rankings={this.rankings} />
                </div>
              </Tabs.TabPane> */}
                {/* <Tabs.TabPane tab="Recurrent-Revenue Monitor" key="8">
                  <h2
                    className="w-100  bold"
                    style={{
                      display: "flex",
                      justifyContent: "flex-end",
                      color: "#1d811c",
                    }}
                  >
                    Allocated Budget: ₦
                    {numberWithCommas(
                      +this.state.overheadMonitorCostTotal -
                        +this.state.overHeadz.reduce((acc, curr) => {
                          return acc + curr.proposedAppropriation;
                        }, 0)
                    )}
                  </h2>

                  <div style={{ marginRight: "10px", width: "100%" }}>
                    <OverHeadCostMonitor
                      adminCode={this.state.selectedValue}
                      filters={["11", "12"]}
                      totalfromup={+this.state.overheadMonitorCostTotal}
                      overHead={this.overHead}
                    />
                  </div>
                </Tabs.TabPane> */}

                <Tabs.TabPane tab="Budget Items Summary" key="9">
                  <>
                    <div className="mb-3 m-r-10 w-40">
                      <div className="m-r-10 w-100">
                        <div
                          className="flex p-10 bg-card-header py-4 pl-4 shadow-sm overflow-hidden bb-green cursor-pointer"
                          onClick={() =>
                            this.setState({ open: this.state.open })
                          }
                        >
                          <p className="flex-1 Upload Budget in Excel text-sm font-avenir-black text-color">
                            Approvers List
                          </p>
                        </div>
                        <div className="flex w-100 bg-white py-4 pl-4 shadow-sm overflow-hidden cursor-pointer m-b-10">
                          <div className="w-100 p-10">
                            <div className="grid grid-cols-1 gap-y-6 gap-x-4 sm:grid-cols-6">
                              <div className="sm:col-span-12">
                                {this.state.message !== "" ? (
                                  // <ValidationMessageComponent
                                  //     message={this.state.message}
                                  // ></ValidationMessageComponent>
                                  <p></p>
                                ) : (
                                  ""
                                )}
                                <label
                                  htmlFor="programSegment"
                                  className="block text-sm font-medium text-gray-700"
                                >
                                  Empployees
                                </label>
                                <div className="mt-1">
                                  <AntSelect
                                    showSearch
                                    labelInValue
                                    style={{ width: "50% " }}
                                    placeholder="Select admin Code"
                                    optionFilterProp="children"
                                    onSearch={this.handleEnployeeSearch}
                                    onChange={this.handleEmployeeChange}
                                    // value={this.state.selectedValue}
                                    filterOption={(input, option) =>
                                      option.props.children
                                        .toLowerCase()
                                        .indexOf(input.toLowerCase()) >= 0
                                    }
                                  >
                                    {this.state.allEmployees?.map((option) => (
                                      <Option
                                        key={option?.id}
                                        value={option?.id}
                                      >
                                        {`${option.firstName} ${option?.middleName?option?.middleName:''} ${option?.lastName} (${option?.email})`}
                                      </Option>
                                    ))}
                                  </AntSelect>
                                  {/* <Select
                                    type="text"
                                    name="programme"
                                    id="programme"
                                    value={this.state.employee}
                                    onChange={this.handleEmployeeChange}
                                    options={this.state.allEmployees}
                                  /> */}
                                </div>
                                {/* {w} */}
                                <div style={{ margin: 5 }} className="p-3">
                                  {this.state.holders.map((e, idx) => (
                                    <div
                                      style={{
                                        margin: 3,
                                        padding: 2,
                                        backgroundColor: "yellow",
                                      }}
                                      key={idx}
                                      className="flex-between flex-align"
                                    >
                                      <p className="">{e.label}</p>

                                      <BsTrash
                                        onClick={() => {
                                          // console.log(e);
                                          // alert(e.approverId)
                                          this.deleteApprover(e?.approverId);
                                        }}
                                        style={{
                                          color: "#43425D",
                                          fontSize: "15px",
                                        }}
                                      />
                                    </div>
                                  ))}
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </>
                  <div style={{ marginRight: "10px", width: "100%" }}>
                    <BudgetItemsDisplay
                      overHeadCost={this.state.catchTotalOverHead}
                      RecurrentRevenue={this.state.catchTotalRecurrent}
                      PersonnelExpenditure={this.state.catchTotalPersonal}
                      // OverheadExpenditure={this.state.overHeadz}
                      CapitalExpenditure={this.state.catchTotalCapitalExp}
                      CapitalReceipts={this.state.catchTotalCapitalReceipt}
                      // this.state.ongoingProjects
                    />

                    <div
                      className="w-100"
                      style={{
                        marginTop: "20px",
                        // border: "2px solid red",
                        display: "flex",
                        justifyContent: "center",
                      }}
                    >
                      <Button
                        onClick={this.handleSubmit}
                        loading={this.state.isLoading}
                        block
                        style={{
                          backgroundColor: "#1d811c",
                          color: "white",
                          height: "40px",
                          width: "60%",
                          marginTop: "50px",
                        }}
                      >
                        Submit
                      </Button>
                      {/* {this.state.visible &&  <div>
            <BudgetItemsDisplay
              overHeadCost={this.state.catchTotalOverHead}
              RecurrentRevenue={this.state.catchTotalRecurrent}
              PersonnelExpenditure={this.state.catchTotalPersonal}
              // OverheadExpenditure={this.state.overHeadz}
              CapitalExpenditure={this.state.catchTotalCapitalExp}
              CapitalReceipts={this.state.catchTotalCapitalReceipt}
              // this.state.ongoingProjects
            />

            <Button
              onClick={this.handleSubmit}
              block
              style={{
                backgroundColor: "#1d811c",
                color: "white",
                height: "40px",
                width: "60%",
                marginTop: "50px",
              }}
            >
              Submit
            </Button>
          </div>} */}
                    </div>
                  </div>
                </Tabs.TabPane>
              </Tabs>
            )}
          </div>
        </div>
      </React.Fragment>
    );
  }
}
