import React from "react";

import { RiBarChartFill, RiMailSettingsLine } from "react-icons/ri";
import {
  BiEnvelope,
  BiCalendarPlus,
  BiCalendarAlt,
  BiMessageSquareAdd,
} from "react-icons/bi";
import Cookies from "js-cookie";
import { decodeToken } from "../utility/auth";

const sidebarMenu = () => {
  let isGov = null;
  let isPa = null;

  let isUser = Cookies?.get("isUser");
  let isApprover = Cookies?.get("isApprover");
  let isExec = Cookies?.get("isExecutive");

  console.log(isUser);
  console.log(isApprover);
  console.log(isExec);

  const ig = localStorage.getItem("ig");
  // if (ig) {
  //   isGov = decodeToken("ig").ig;
  // }

  const ip = localStorage.getItem("ip");
  // if (ip) {
  //   isPa = decodeToken("ip").ip;
  // }

  console.log("is-Gov: ", ig, isGov, "is-PA: ", ip, isPa);

  const menu = [];

  menu.push({
    title: "Dashboard",
    href: "/budget",
    icon: <RiBarChartFill className="icon" />,
  });

  menu.push({
    title: "Start Budget Process",
    href: "/budget/start-budget-process",
    icon: <RiBarChartFill className="icon" />,
  });
  // menu.push({
  //   title: "Budget Planning",
  //   href: "/budget",
  //   icon: <BiMessageSquareAdd className="icon" />,

  //   children: [
  //     // { title: 'Budget Summary', href:'/budget/budget-summary', icon:'ED'  },
  //     // { title: 'Allocate Monthly Budget', href: '/budget/initiate-budget', icon: 'CP' },
  //     // { title: 'Initiate Budget 2', href: '/budget/initiate-budget-2', icon: 'CP' },
  //     // { title: 'Review Initiated Budget 2', href: '/budget/review-initiated-budget-2', icon: 'CP' },
  //     {
  //       title: "Budget Item Setup",
  //       href: "/budget/budget-item-setup",
  //       icon: "ED",
  //     },
  //     // { title: 'Budget History', href: '/budget/budget-history', icon: 'ED' }
  //   ],
  // });

  menu.push({
    title: "Budget Initiation",
    href: "/budget/initiate",
    icon: <BiMessageSquareAdd className="icon" />,
    children: [
      {
        title: "Initiate Budget",
        href: "/budget/initiate/budget-initiation",
        icon: "ED",
      },
      {
        title: "View Invitation",
        href: "/budget/initiate/correspondence",
        icon: "CP",
      },
      {
        title: "Review Envelope",
        href: "/budget/envelope-review",
        icon: "CP",
      },
    ],
  });

  // Cookies.get("hasAccess") == "true"
  true
   && menu.push({
    title: "Executive Approvals",
    href: "/budget/executive",
    icon: <BiMessageSquareAdd className="icon" />,
    children: [
      {
        title: "Initiate Executive Approvals",
        href: "/budget/initiate/executive-approvals",
        icon: "ED",
      },
      {
        title: "Executive Approvals/Review",
        href: "/budget/executive/correspondence",
        icon: "CP",
      },
      {
        title: "Budget Office Review",
        href: "/budget/budgetoffice/execreview",
        icon: "CP",
      },
    ],
  });

  menu.push({
    title: " Budget Review ",
    href: "/budget/review/correspondence",
    icon: <BiMessageSquareAdd className="icon" />,
    // children: [
    //   { title: 'Budget Team Costing', href:'/budget/review/costing', icon:'ED'  },
    //   { title: 'Budget Team Review', href:'/budget/review/correspondence', icon:'ED'  },
    //   (isExec=='true') && { title: 'Executives Review', href: '/budget/review/executive-review', icon: 'CP' },

    // ] ,
  });

  // menu.push({ title: 'Budget Codification', href: '/budget/budget-codification', icon: <BiEnvelope className="icon" /> });
  // menu.push({ title: 'Finance', href: '/budget/finance', icon: <BiEnvelope className="icon" /> });
  // menu.push({ title: 'Finance detail', href: '/budget/finance-detail', icon: <BiEnvelope className="icon" /> });
  //  menu.push({ title: 'Budget Review', href: '/budget/budget-review', icon: <RiMailSettingsLine className="icon" /> });
  //  menu.push({ title: 'Initiators Review', href: '/budget/initiator-review', icon: <RiMailSettingsLine className="icon" /> });
  //  menu.push({ title: 'Budget team Review', href: '/budget/budget-team-review', icon: <RiMailSettingsLine className="icon" /> });
  // (isGov || isPa) && menu.push({ title: 'Meeting Requests', href: '/budget/meeting-request', icon: <BiCalendarAlt className="icon" /> });

  //   menu.push({ title: 'Report', href: '/budget/general-report', icon: <BiCalendarPlus className="icon" />,

  //   children: [
  //     { title: 'General Report', href:'/budget/general-report', icon:'ED'  },
  //     { title: 'Variance Report', href: '/budget/variance-report', icon: 'CP' },
  //     { title: 'Report Summary', href: '/budget/report-summary', icon: 'ED' },
  //   ] ,
  // });
  //  menu.push({ title: 'Report', href: '/budget/general-report', icon: <BiCalendarPlus className="icon" />,
  menu.push({
    title: "Settings",
    href: "/budget/settings",
    icon: <BiCalendarPlus className="icon" />,

    // children: [
    //   { title: 'Settings ', href:'/budget/settings', icon:'ED'  },
    //   // { title: 'Funds Segment ', href: '/budget/funds-code', icon: 'CP' },
    //   // { title: 'Programme Segment ', href: '/budget/programme-code', icon: 'ED' },
    //   // { title: 'Functional Segment ', href: '/budget/functional-code', icon: 'ED' },
    //   // { title: 'Economic Segment ', href: '/budget/economic-code', icon: 'ED' },
    // ] ,
  });
  Cookies.get("userId") == "137" && 
  menu.push({
    title: "Admin Edit",
    href: "/budget/admin-budget-edit",
    icon: <BiCalendarPlus className="icon" />,

    // children: [
    //   { title: 'Settings ', href:'/budget/settings', icon:'ED'  },
    //   // { title: 'Funds Segment ', href: '/budget/funds-code', icon: 'CP' },
    //   // { title: 'Programme Segment ', href: '/budget/programme-code', icon: 'ED' },
    //   // { title: 'Functional Segment ', href: '/budget/functional-code', icon: 'ED' },
    //   // { title: 'Economic Segment ', href: '/budget/economic-code', icon: 'ED' },
    // ] ,
  });

  Cookies.get("isAlreadyProcessed") == "137" && 
  menu.push({
    title: "Budget Performance",
    href: "/budget/admin-budget-reporting",
    icon: <BiCalendarPlus className="icon" />,

    // children: [
    //   { title: 'Settings ', href:'/budget/settings', icon:'ED'  },
    //   // { title: 'Funds Segment ', href: '/budget/funds-code', icon: 'CP' },
    //   // { title: 'Programme Segment ', href: '/budget/programme-code', icon: 'ED' },
    //   // { title: 'Functional Segment ', href: '/budget/functional-code', icon: 'ED' },
    //   // { title: 'Economic Segment ', href: '/budget/economic-code', icon: 'ED' },
    // ] ,
  });

  return menu;
};

export default sidebarMenu;
