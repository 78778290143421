import React, { useState, useEffect, useContext } from "react";
import {
  Button,
  Select,
  Form,
  // Input,
  Input,
  Table,
  Typography,
  Popconfirm,
} from "antd";
import { get, patch, post, del } from "../../api-services/fetch";
import axios from "axios";
import notification from "../../utility/notification";
import Cookies from "js-cookie";
import moment from "moment";
// import { CKEditor } from '@ckeditor/ckeditor5-react';
// import ClassicEditor from '@ckeditor/ckeditor5-build-classic';

// import ViewGovTable from './ViewGovTable';
import { decodeToken } from "../../utility/auth";
import EconomicSegment from "./EconomicSegment";
import EconomicType from "./EconomicType";
import EconomicSubType from "./EconomicSubType";
import EconomicSubSubType from "./EconomicSubSubType";
import EconomicItem from "./EconomicItem";
// import SearchDocument from '../layouts/SearchDocument';
const BASE_API_URL = "https://edogoverp.com/budget/api/v1";
const BASE_API_URLs = "https://edogoverp.com/newbudgetapi/api/v1";
// const BASE_API_URL = 'http://localhost:5000/api/v1';

// allMdaDrop: '/api/employee/mdas',

const originData = [];
for (let i = 0; i < 100; i++) {
  originData.push({
    key: i.toString(),
    name: `Edrward ${i}`,
    age: 32,
    address: `London Park no. ${i}`,
  });
}
const { Text } = Typography;
const { Option } = Select;

const { TextArea } = Input;
const EditableCell = ({
  editing,
  dataIndex,
  title,
  inputType,
  record,
  index,
  children,
  ...restProps
}) => {
  const inputNode =
    inputType === "number" ? (
      <Input
      // formatter={(value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
      // parser={(value) => value?.replace(/\$\s?|(,*)/g, "")}
      />
    ) : (
      <TextArea rows={4} />
    );
  return (
    <td {...restProps}>
      {editing ? (
        <Form.Item
          name={dataIndex}
          style={{
            margin: 0,
          }}
          // rules={[
          //   {
          //     required: true,
          //     message: `Please Input ${title}!`,
          //   },
          // ]}
        >
          {inputNode}
        </Form.Item>
      ) : (
        children
      )}
    </td>
  );
};

const CapitalProject = (props) => {
  const { Option } = Select;
  const { Text } = Typography;

  const [envelope, setEnvelope] = useState();
  const [commentsBudget, setCommentsBudget] = useState([]);
  const [mdaId, setMdaId] = useState([]);
  const [userId, setUserId] = useState([]);
  const [economicSegment, setEconomicSegment] = useState([]);
  const [fundCode, setFundCode] = useState([]);
  const [functionalCode, setFunctionalCode] = useState([]);
  const [programmeCode, setprogrammeCode] = useState([]);
  const [locationCode, setLocationCode] = useState([]);
  const [isNew, setIsNew] = useState(false);
  const [tableKey, setTableKey] = useState(1);
  const [count, setCount] = useState(0);
  const [economicSegmentId, setEconomicSegmentId] = useState();
  const [economicTypeId, setEconomicTypeId] = useState();
  const [economicSubTypeId, setEconomicSubTypeId] = useState();
  const [economicSubSubTypeId, setEconomicSubSubTypeId] = useState();
  const [economicItemId, setEconomicItemId] = useState();

  const [form] = Form.useForm();
  const [data, setData] = useState([]);
  const [ongoingP, setOngoingP] = useState(originData);
  const [editingKey, setEditingKey] = useState("");
  const isEditing = (record) => record.key === editingKey;
  const { TextArea } = Input;

  const edit = (record) => {
    form.setFieldsValue({
      // label: "",
      // amount: "",
      // comment: "",

      ...record,
    });
    setEditingKey(record.key);
  };
  const cancel = () => {
    setEditingKey("");
  };
  const save = async (key) => {
    try {
      const row = await form.validateFields();
      const newData = [...data];
      const index = newData.findIndex((item) => key === item.key);
      if (index > -1) {
        const item = newData[index];
        newData.splice(index, 1, {
          ...item,
          ...row,
        });
        // props.setEnvelopeData(newData);
        console.log(newData);

        let yyy =
          props.totalfromup -
            [...newData].reduce((acc, curr) => {
              return +acc + +curr.proposedAppropriation;
            }, 0) || 0;

        console.log(yyy);

        if (yyy < 0) {
          notification({
            title: "Warning",
            message:
              "With this amount you are about to exceeded your allocation for Capital Expenditure. Please adjust",
            type: "danger",
          });
          return;
        } else {
          // setData(newData);
          props.handleSaved({
            capitalProjects: [
              ...newData?.map((e) => ({
                ...e,
                currentApprovedAppropriation:
                  +e.currentApprovedAppropriation || 0,
                actualExpenditureAsAt: +e.actualExpenditureAsAt || 0,
                proposedAppropriation: +e.proposedAppropriation || 0,
              })),
            ],
          });
        }
        setEditingKey("");
      } else {
        newData.push(row);
        setData(newData);
        props.handleSaved({ capitalProjects: [...newData] });

        setEditingKey("");
        console.log(newData);
      }
    } catch (errInfo) {
      console.log("Validate Failed:", errInfo);
    }
  };

  // const todelete = (key) => {
  //   const newData = [...data];

  //   const remAfterdelete = newData.filter((item) => key !== item.key);
  //   console.log(remAfterdelete);

  //   setData(remAfterdelete);
  // };

  const todelete = async (id) => {
    const newData = [...data];

    // const remAfterdelete = newData.filter((item) => key !== item.key);
    // console.log(remAfterdelete);

    // setnewLoading(true);
    // setL

    // /  handleDelete = async (id) => {

    if (true) {
      // axios
      //     .post(
      //       `https://edogoverp.com/newbudgetapi/api/v1/BudgetPlanning`,
      //       payload
      //     )
      //     .then((response) => {
      let res = await del({
        endpoint: `/api/v1/BudgetPlanning/DeleteCapitalBudget?id=${id}`,
        auth: true,
        // pQuery: { id },
      });

      if ((res && res.status == 200) || res.status < 300) {
        // setnewLoading(false);

        props.getSavedBudgetPlanning();
        // notify(store, 'success', 'department budget added successfully');
        notification({
          title: "MESSAGE",
          message: `
                  ${"department budget deleted successfully"}`,
          type: "success",
          // container:'top-center'
          // animationIn: ['animate__animated', 'animate__slideIn'],
        });
      } else {
        if (res?.data?.status > 300) {
          notification({
            title: "MESSAGE",
            message:
              res?.data?.title ||
              `
                    ${"An error occured contact support"}`,
            type: "danger",
            // container:'top-center'
            // animationIn: ['animate__animated', 'animate__slideIn'],
          });
        }
      }
    } else
      notification({
        title: "ERROR MESSAGE",
        message: `
            Make Sure Approvers and Admin code have been Selected`,
        type: "danger",
        // container:'top-center'
        // animationIn: ['animate__animated', 'animate__slideIn'],
      });
  };

  useEffect(() => {
    props.capitalP(data);
    createTotal(data);
  }, [data]);

  const [tot, settot] = useState({
    name: "Overhead Expenditure",
    Proposed2024: 0,
    Approved2023: 0,
    ActualJanToSep2023: 0,
    Approved2021: 0,
  });
  useEffect(() => {
    //  toGetAdministrativeSegment();
    toGetEconimicItems();
    toGetFundCode();
    toGetFunctioCode();
    toGetProgrammeCode();
    toGetLocationCode();
    toGetEnvelope();
    // toGetProgramCode()

    //  const [mdaId, setMdaId] = useState([]);
    //  const [userId, setUserId] = useState([]);
    console.log(+Cookies.get("requestsMdaId"));

    setMdaId(+Cookies.get("requestsMdaId"));

    setData(
      props?.savedBudgetPlanning[0]?.capitalProjects?.map((e) => ({
        ...e,
        key: e.id,
      })) || []
    );

    // console.log(Cookies.get("signature"));
    // console.log(Cookies.get("firstName"));
    // console.log(Cookies.get("lastName"));
    // setsignature(Cookies.get("signature"));

    // let ui = localStorage.getItem("ui");
    // ui = decodeToken("ui").ui;
    // console.log(ui);
    // setUserId(ui);
  }, [props?.savedBudgetPlanning]);

  const opt = economicSegment.map((e) => (
    <Option value={e.code}>{`${e.economicSegmentName} (${e?.code})`}</Option>
  ));
  const fundCodes = fundCode.map((e) => (
    <Option value={e.fundSegmentCode}>
      {`${e.fundSegmentName} (${e?.fundSegmentCode})`}
    </Option>
  ));
  const functionalCodes = functionalCode.map((e) => (
    <Option value={e.code}>{`${e.functionalSegmentName} (${e?.code})`}</Option>
  ));
  const programmeCodes = programmeCode.map((e) => (
    <Option value={e.programmeCode}>
      {`${e.programmeName} (${e?.programmeCode})`}
    </Option>
  ));
  const locationCodes = locationCode.map((e) => (
    <Option value={e.code}>{`${e.geoCodeName} (${e?.code})`}</Option>
  ));

  const toGetEnvelope = () => {
    // console.log(payload.id);
    // setIsLoading(true)

    axios
      .get(
        `https://edogoverp.com/newbudgetapi/api/BudgetEnvelope/GetBudgetEnvelopeByMda?mdaId=${+Cookies.get(
          "requestsMdaId"
        )}`
      )
      .then((res) => {
        console.log(res?.data);

        setEnvelope(res.data);
      })
      .catch((err) => {
        console.log(err?.response);
      });
  };

  const toGetEconimicItems = () => {
    axios
      .get(`${BASE_API_URLs}/EconomicSegment`)
      // .post(`${BASE_API_URL}/budgetcodification`, { ...data })

      .then((res) => {
        //  setIsLoading(false);

        console.log(res);
        setEconomicSegment(res.data);
        //  notification({
        //    title: "Successful",
        //    message: "Projects have been successfully sent",
        //    type: "success",
        //  });
      })
      .catch((err) => {
        console.log(err?.response);
        //  setIsLoading(false);
        //  notification({
        //    title: "Sending Failed",
        //    message: `{err?.response?.data}`,
        //    type: "danger",
        //  });
      });
  };
  const toGetFundCode = async () => {
    // axios
    //   .get(`${BASE_API_URLs}/FundSegment`)

    //   .then((res) => {
    const res = await get({
      endpoint: `/api/v1/FundSegment`,
      auth: true,
      // body: payload,
    });

    if (res && res.status == 200) {
      console.log(res);
      setFundCode(res.data);
    } else {
      // console.log(err?.response);
      //  setIsLoading(false);
      //  notification({
      //    title: "Sending Failed",
      //    message: `{err?.response?.data}`,
      //    type: "danger",
      //  });
    }
  };
  //  const toGetProgramCode = () => {
  //    axios
  //      .get(`${BASE_API_URLs}/Programme`)

  //      .then((res) => {
  //        console.log(res);
  //        setFundCode(res.data);
  //      })
  //      .catch((err) => {
  //        console.log(err?.response);
  //        //  setIsLoading(false);
  //        //  notification({
  //        //    title: "Sending Failed",
  //        //    message: `{err?.response?.data}`,
  //        //    type: "danger",
  //        //  });
  //      });
  //  };
  const toGetFunctioCode = async () => {
    // axios
    // .get(`${BASE_API_URLs}/FunctionalSegment`)
    // // .post(`${BASE_API_URL}/budgetcodification`, { ...data })

    // .then((res) => {
    const res = await get({
      endpoint: `/api/v1/FunctionalSegment`,
      auth: true,
      // body: payload,
    });

    if (res && res.status == 200) {
      //  setIsLoading(false);

      console.log(res);
      setFunctionalCode(res.data);
      //  notification({
      //    title: "Successful",
      //    message: "Projects have been successfully sent",
      //    type: "success",
      //  });
    } else {
      // console.log(err?.response);
      //  setIsLoading(false);
      //  notification({
      //    title: "Sending Failed",
      //    message: `{err?.response?.data}`,
      //    type: "danger",
      //  });
    }
  };
  const toGetProgrammeCode = async () => {
    // axios
    //   .get(`${BASE_API_URLs}/Programme`)
    //   // .post(`${BASE_API_URL}/budgetcodification`, { ...data })

    //   .then((res) => {
    //     //  setIsLoading(false);
    const res = await get({
      endpoint: `/api/v1/Programme`,
      auth: true,
      // body: payload,
    });

    if (res && res.status == 200) {
      //  setIsLoading(false);

      console.log(res);
      setprogrammeCode(res.data);
      //  notification({
      //    title: "Successful",
      //    message: "Projects have been successfully sent",
      //    type: "success",
      //  });
    } else {
      // console.log(err?.response);
      //  setIsLoading(false);
      //  notification({
      //    title: "Sending Failed",
      //    message: `{err?.response?.data}`,
      //    type: "danger",
      //  });
    }
  };
  const toGetLocationCode = async () => {
    // axios
    // .get(`${BASE_API_URLs}/GeoCode`)
    // // .post(`${BASE_API_URL}/budgetcodification`, { ...data })

    // .then((res) => {
    const res = await get({
      endpoint: `/api/v1/GeoCode`,
      auth: true,
      // body: payload,
    });

    if (res && res.status == 200) {
      //  setIsLoading(false);

      //  setIsLoading(false);

      console.log(res);
      setLocationCode(res.data);
      //  notification({
      //    title: "Successful",
      //    message: "Projects have been successfully sent",
      //    type: "success",
      //  });
    } else {
      // console.log(err?.response);
      //  setIsLoading(false);
      //  notification({
      //    title: "Sending Failed",
      //    message: `{err?.response?.data}`,
      //    type: "danger",
      //  });
    }
  };

  //  •	Fund code (from charts of accounts)
  // •	Function Code (from charts of accounts)
  // •	Program Code (from charts of accounts)
  // •	Location code (from charts of accounts)

  //  const toGetEconimicItems = () => {
  //    axios
  //      .get(`${BASE_API_URLs}/EconomicSegment`)

  //      .then((res) => {
  //        console.log(res);
  //        setEconomicSegment(res.data);
  //      })
  //      .catch((err) => {
  //        console.log(err?.response);

  //      });
  //  };

  const reccurentRevenueColumn = [
    {
      title: "Economic Code",
      dataIndex: "economicDetailsCode",
      key: "name",
      render: (text) => <a>{text}</a>,
    },
    {
      title: "Details of Expenditure",
      dataIndex: "details",
      editable: true,
      key: "details",
    },
    {
      title: "Fund code",
      dataIndex: "fundDetailsCode",
      editable: true,
      key: "fundDetailsCode",
    },
    {
      title: "Function code",
      dataIndex: "functionalDetailsCode",
      editable: true,
      key: "functionalDetailsCode",
    },
    {
      title: "Program code",
      dataIndex: "programmeCode",
      editable: true,
      key: "programmeCode",
    },

    {
      title: "Location code",
      dataIndex: "locationCode",
      editable: true,
      key: "locationCode",
    },
    // {
    //   title: "State Of Completion %",
    //   dataIndex: "stateOfCompletion",
    //   editable: true,
    //   key: "stateOfCompletion",
    //   //  width: "20%",
    // },
    {
      title: "Status",
      dataIndex: "status",
      editable: true,
      key: "status",
      //  width: "20%",
    },
    // {
    //   title: "Location",
    //   dataIndex: "location",
    //   editable: true,
    //   key: "location",
    //   //  width: "20%",
    // },
    {
      title: "Current Approved Appropriation 2023",
      dataIndex: "currentApprovedAppropriation",
      editable: true,
      key: "currentApprovedAppropriation",
      //  width: "20%",
    },

    {
      title: "Actual Expenditure Jan-Sept 2023",
      editable: true,
      dataIndex: "actualExpenditureAsAt",
      key: "actualExpenditureAsAt",
      //  width: "20%",
    },
    {
      title: "Proposed Appropriation 2024",
      editable: true,
      dataIndex: "proposedAppropriation",
      key: "proposedAppropriation",
      //   width: "20%",
    },
    {
      title: "Target Remarks",
      dataIndex: "targetsRemarks",
      key: "targetsRemarks",
      //   width: "20%",
    },
    {
      title: "operation",
      dataIndex: "operation",
      render: (_, record) => {
        const editable = isEditing(record);
        return editable ? (
          <span>
            <Typography.Link
              onClick={() => save(record.key)}
              style={{
                marginRight: 8,
              }}
            >
              Save
            </Typography.Link>
            <Popconfirm title="Sure to cancel?" onConfirm={cancel}>
              <a>Cancel</a>
            </Popconfirm>
          </span>
        ) : (
          <div className="flex justify-between">
            <div style={{ marginRight: "10px" }}>
              <Typography.Link
                className="mr-10"
                disabled={editingKey !== ""}
                onClick={() => edit(record)}
              >
                Edit
              </Typography.Link>
            </div>

            <Typography.Link
              disabled={editingKey !== ""}
              onClick={() => todelete(record.key)}
            >
              Delete
            </Typography.Link>
          </div>
        );
      },
    },
  ];
  const reccurentRevenueColumn1 = [
    {
      title: "Economic Code",
      dataIndex: "economicDetailsCode",
      editable: true,
      key: "name",
      render: (text) => <a>{text}</a>,
    },
    {
      title: "Details of Expenditure",
      dataIndex: "details",
      editable: true,
      key: "details",
    },

    {
      title: "Fund code",
      dataIndex: "fundDetailsCode",
      editable: true,
      key: "fundDetailsCode",
    },
    {
      title: "Function code",
      dataIndex: "functionalDetailsCode",
      editable: true,
      key: "functionalDetailsCode",
    },
    {
      title: "Program code",
      dataIndex: "programmeCode",
      editable: true,
      key: "programmeCode",
    },

    {
      title: "Location code",
      dataIndex: "locationCode",
      editable: true,
      key: "locationCode",
    },
    // {
    //   title: "State Of Completion %",
    //   dataIndex: "stateOfCompletion",
    //   editable: true,
    //   key: "stateOfCompletion",
    //   //  width: "20%",
    // },
    {
      title: "Status",
      dataIndex: "status",
      editable: true,
      key: "status",
      //  width: "20%",
    },
    {
      title: "Location",
      dataIndex: "locationCode",
      editable: true,
      key: "locationCode",
      //  width: "20%",
    },

    {
      title: "Proposed Appropriation 2024",
      dataIndex: "proposedAppropriation",
      editable: true,
      render: (text) => <a>{numberWithCommas(text)}</a>,

      key: "proposedAppropriation",
      //   width: "20%",
    },
    {
      title: "Target Remarks",
      dataIndex: "targetsRemarks",
      editable: true,
      key: "targetsRemarks",
      //   width: "20%",
    },
    {
      title: "operation",
      dataIndex: "operation",
      render: (_, record) => {
        const editable = isEditing(record);
        return editable ? (
          <span>
            <Typography.Link
              onClick={() => save(record.key)}
              style={{
                marginRight: 8,
              }}
            >
              Save
            </Typography.Link>
            <Popconfirm title="Sure to cancel?" onConfirm={cancel}>
              <a>Cancel</a>
            </Popconfirm>
          </span>
        ) : (
          <Typography.Link
            disabled={editingKey !== ""}
            onClick={() => edit(record)}
          >
            Edit
          </Typography.Link>
        );
      },
    },
  ];
  function numberWithCommas(x) {
    return x?.toString()?.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  }

  const onFinish = (values) => {
    console.log(values);
    let payload = {
      // id: "3fa85f64-5717-4562-b3fc-2c963f66afa6",
      deptBudgetId: "00000000-0000-0000-0000-000000000000",
      budgetPeriodId: "00000000-0000-0000-0000-000000000000",
      key: tableKey,
      dateCreated: new Date(),
      enteredBy: "string",
      dateUpdated: new Date(),
      updatedBy: "string",
      isDelete: false,
      dateDeleted: new Date(),

      programmeCode: values.programmeCode,
      economicMainHeadCode: values.economicDetailsCode,
      economicDetailsCode: values.economicDetailsCode,
      functionalMainHeadCode: values.functionalDetailsCode,
      functionalDetailsCode: values.functionalDetailsCode,
      locationCode: values.locationCode,
      fundMainCode: values.fundMainCode,
      fundDetailsCode: values.fundDetailsCode,
      fundMainCode2: values.fundMainCode2,
      fundDetailsCode2: values.fundDetailsCode2,
      fundMainCode3: values.fundMainCode3,
      fundDetailsCode3: values.fundDetailsCode3,
      fundAmount: 0,
      fundAmount2: 0,
      fundAmount3: 0,
      adminCode: props?.adminCode,

      // economicCode: values.economicDetailsCode,
      details: values.details,
      // details: values.details,
      stateOfCompletion: values.stateOfCompletion,
      status: values.status,
      location: values.location,
      currentApprovedAppropriation: +values.currentApprovedAppropriation || 0,
      actualExpenditureAsAt: +values.actualExpenditureAsAt || 0,
      proposedAppropriation: +values.proposedAppropriation || 0,
      targetsRemarks: values.targetsRemarks,
      mdaId: mdaId,
    };
    console.log("Success:", values);

    setCommentsBudget([...commentsBudget, payload]);
    let yyy =
      props.totalfromup -
        [...data, payload].reduce((acc, curr) => {
          return acc + curr.proposedAppropriation;
        }, 0) || 0;

    console.log(yyy);

    if (yyy < 0) {
      notification({
        title: "Warning",
        message:
          "You have exceeded your allocation for Capital Expenditure. Please adjust",
        type: "danger",
      });
      return;
    } else {
      // setData([...data, payload]);
      props.handleSaved({ capitalProjects: [...data, payload] });

      setCount((prev) => prev + 1);
    }

    // createTotal(payload);
    setTableKey((prev) => prev + 1);

    // props.capitalP(payload);
    onReset();
  };

  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };

  //  "deptBudgetId": "3fa85f64-5717-4562-b3fc-2c963f66afa6",
  // "project": "string",
  // "status": "string",
  // "location": "string",
  // "startDate": "2023-10-11T17:08:24.133Z",
  // "amountSpent": 0,
  // "conclusionEstimate": 0,
  // "completionTime": 0,
  // "pId": 0,
  // "mdaId": 0

  const onReset = () => {
    form.resetFields();
  };

  const createTotal = (payload) => {
    console.log(tot);
    props.total({
      name: "Capital Expenditure",
      Proposed2024:
        (payload &&
          payload?.length &&
          payload?.reduce((acc, curr) => {
            return +acc + +curr.proposedAppropriation;
          }, 0)) ||
        0,
      // +tot.Proposed2024 + +payload.proposedBudget,
      Approved2023:
        (payload &&
          payload?.length &&
          payload?.reduce((acc, curr) => {
            return +acc + +curr.actualExpenditureAsAt;
          }, 0)) ||
        0,
      // +tot.Approved2023 + +payload.previousApprovedBudget,
      ActualJanToSep2023:
        (payload &&
          payload?.length &&
          payload?.reduce((acc, curr) => {
            return +acc + +curr.currentApprovedAppropriation;
          }, 0)) ||
        0,
      // +tot.ActualJanToSep2023 + +payload.currentExpenditures,
      Approved2021:
        (payload &&
          payload?.length &&
          payload?.reduce((acc, curr) => {
            return +acc + 0;
          }, 0)) ||
        0,
      // Proposed2024: +tot.Proposed2024 + +payload.proposedAppropriation,
      // Approved2023: +tot.Approved2023 + +payload.actualExpenditureAsAt,
      // ActualJanToSep2023:
      //   +tot.ActualJanToSep2023 + +payload.currentApprovedAppropriation,
      // Approved2021: tot.Approved2021 + 0,
    });
    settot({
      name: "Capital Expenditure",
      Proposed2024:
        (payload &&
          payload?.length &&
          payload?.reduce((acc, curr) => {
            return +acc + +curr.proposedAppropriation;
          }, 0)) ||
        0,
      // +tot.Proposed2024 + +payload.proposedBudget,
      Approved2023:
        (payload &&
          payload?.length &&
          payload?.reduce((acc, curr) => {
            return +acc + +curr.actualExpenditureAsAt;
          }, 0)) ||
        0,
      // +tot.Approved2023 + +payload.previousApprovedBudget,
      ActualJanToSep2023:
        (payload &&
          payload?.length &&
          payload?.reduce((acc, curr) => {
            return +acc + +curr.currentApprovedAppropriation;
          }, 0)) ||
        0,
      // +tot.ActualJanToSep2023 + +payload.currentExpenditures,
      Approved2021:
        (payload &&
          payload?.length &&
          payload?.reduce((acc, curr) => {
            return +acc + 0;
          }, 0)) ||
        0,
      // Proposed2024: +tot.Proposed2024 + +payload.proposedAppropriation,
      // Approved2023: +tot.Approved2023 + +payload.actualExpenditureAsAt,
      // ActualJanToSep2023:
      //   +tot.ActualJanToSep2023 + +payload.currentApprovedAppropriation,
      // Approved2021: +tot.Approved2021 + 0,
    });
  };

  const mergedColumns =
    //  !isNew
    // ?
    reccurentRevenueColumn.map((col) => {
      if (!col.editable) {
        return col;
      }
      return {
        ...col,
        onCell: (record) => ({
          record,
          inputType: col.dataIndex === "comment" ? "text" : "number",
          dataIndex: col.dataIndex,
          title: col.title,
          editing: isEditing(record),
        }),
      };
    });
  // : reccurentRevenueColumn1.map((col) => {
  //     if (!col.editable) {
  //       return col;
  //     }
  //     return {
  //       ...col,
  //       onCell: (record) => ({
  //         record,
  //         inputType: col.dataIndex === "comment" ? "text" : "number",
  //         dataIndex: col.dataIndex,
  //         title: col.title,
  //         editing: isEditing(record),
  //       }),
  //     };
  //   });

  const collectEconomicSegmentId = (id) => {
    setEconomicSegmentId(id.value);
    form.setFieldsValue({ economicDetailsCode: id.key });
  };
  const collectEconomicTypeId = (id) => {
    setEconomicTypeId(id.value);
    form.setFieldsValue({ economicDetailsCode: id.key });
  };
  const collectEconomicSubTypeId = (id) => {
    form.setFieldsValue({ economicDetailsCode: id.key });
    setEconomicSubTypeId(id.value);
  };
  const collectEconomicSubSubTypeId = (id) => {
    form.setFieldsValue({ economicDetailsCode: id.key });
    setEconomicSubSubTypeId(id.value);
  };
  const collectEconomicItem = (id) => {
    form.setFieldsValue({ economicDetailsCode: id.key });
    setEconomicItemId(id.value);
  };

  // const [form] = Form.useForm();
  return (
    <div className="w-100">
      <div className="flex-between" style={{ paddingLeft: "30px" }}>
        <h2>Capital Projects</h2>
        {/* <h2>Allocated Budget : {`₦ ${envelope?.totalAmount}`}</h2> */}
      </div>

      <div
        className="w-full flex mb-10"
        style={{ gap: 10, marginBottom: "10px" }}
      >
        <EconomicSegment
          collectEconomicSegmentId={collectEconomicSegmentId}
          prefix={props.filter}
          count={count}
        />
        <EconomicType
          collectEconomicTypeId={collectEconomicTypeId}
          prefix={props.filter}
          count={count}
          id={economicSegmentId}
        />
        <EconomicSubType
          collectEconomicSubTypeId={collectEconomicSubTypeId}
          prefix={props.filter}
          id={economicTypeId}
          count={count}
        />
        <EconomicSubSubType
          collectEconomicSubSubTypeId={collectEconomicSubSubTypeId}
          prefix={props.filter}
          id={economicSubTypeId}
          count={count}
        />
        <EconomicItem
          collectEconomicItem={collectEconomicItem}
          prefix={props.filter}
          id={economicSubSubTypeId}
          count={count}
        />
      </div>

      <Form
        layout="vertical"
        form={form}
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
        autoComplete="off"
      >
        <Form.Item label="Economic Code" name="economicDetailsCode">
          {/* <Select
            style={{ width: `20%` }}
            showSearch
            labelInValue
            // style={{ width: "100%" }}
            placeholder="Search to Select"
            optionFilterProp="children"
            filterOption={(input, option) =>
              option?.children?.toLowerCase()?.indexOf(input?.toLowerCase()) >=
              0
            }
            filterSort={(optionA, optionB) =>
              optionA?.children
                ?.toLowerCase()
                ?.localeCompare(optionB?.children?.toLowerCase())
            }
            // onChange={handleChange}
          >
            {opt}
          </Select> */}
          <Input readOnly />
        </Form.Item>
        <div class="flex-between">
          <Form.Item
            className="m-r-10"
            label="Funds Code"
            style={{ width: `100%` }}
            name="fundDetailsCode"
          >
            <Select
              filterOption={(input, option) =>
                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
              filterSort={(optionA, optionB) =>
                optionA.children
                  .toLowerCase()
                  .localeCompare(optionB.children.toLowerCase())
              }
              allowClear
              style={{ width: `100%` }}
              // onChange={handleChange}
            >
              {fundCodes}
            </Select>
          </Form.Item>
          <Form.Item
            className="m-r-10"
            label="Functional Code"
            name="functionalDetailsCode"
            style={{ width: `100%` }}
          >
            <Select
              filterOption={(input, option) =>
                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
              filterSort={(optionA, optionB) =>
                optionA.children
                  .toLowerCase()
                  .localeCompare(optionB.children.toLowerCase())
              }
              allowClear
              // onChange={handleChange}
              // onChange={handleChange}
              style={{ width: `100%` }}
              // onChange={handleChange}
            >
              {functionalCodes}
            </Select>
          </Form.Item>
          <Form.Item
            label="Programme Code"
            className="m-r-10"
            style={{ width: `100%` }}
            name="programmeCode"
          >
            <Select
              filterOption={(input, option) =>
                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
              filterSort={(optionA, optionB) =>
                optionA.children
                  .toLowerCase()
                  .localeCompare(optionB.children.toLowerCase())
              }
              allowClear
              style={{ width: `100%` }}
              // onChange={handleChange}
            >
              {programmeCodes}
            </Select>
          </Form.Item>
          <Form.Item
            className="m-r-10"
            label="Location Code"
            style={{ width: `100%` }}
            name="locationCode"
          >
            <Select
              filterOption={(input, option) =>
                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
              filterSort={(optionA, optionB) =>
                optionA.children
                  .toLowerCase()
                  .localeCompare(optionB.children.toLowerCase())
              }
              allowClear
              style={{ width: `100%` }}
              // onChange={handleChange}
            >
              {locationCodes}
            </Select>
          </Form.Item>
        </div>
        {/* <Form.Item

fundCodes
functionalCodes
programmeCodes
locationCodes


          label="Economic Code"
          name="economicCode"
          rules={[
            {
              required: true,
              message: "Please input your username!",
            },
          ]}
        >
          <Input />
        </Form.Item> */}
        <div class="flex-between">
          <div className="w-100 m-r-10">
            {/* <Form.Item label="Administrative Code" name="economicCode">
              <Select
                style={{ width: 120 }}
              >
                {opt}
              </Select>
            </Form.Item> */}
            <Form.Item label="Details Of Expenditure" name="details">
              <Input />
            </Form.Item>

            <Form.Item label=" Target Remarks" name="targetsRemarks">
              <Input style={{ width: "100%" }} />
            </Form.Item>
            {/* <Form.Item label="State Of Completion" name="stateOfCompletion">
              <Input style={{ width: "100%" }} />
            </Form.Item> */}
          </div>
          <div className="w-100 m-r-10">
            <Form.Item label="Status" name="status">
              <Select
                // defaultValue="lucy"
                // labelInValue
                // showSearch
                style={{ width: "100%" }}
                onChange={(e) => {
                  console.log(e);
                  // alert(e);
                  setIsNew(e == "New" ? true : false);
                }}
              >
                <Option key={1} value={"New"}>
                  New
                </Option>
                <Option key={2} value={"Ongoing"}>
                  Ongoing
                </Option>
                {/* <Option key={3} value={"Completed"}>
                  Completed
                </Option> */}
              </Select>
              {/* <Input style={{ width: "100%" }} /> */}
            </Form.Item>
            {!isNew && (
              <Form.Item
                label="Current Approved Appropriation"
                name="currentApprovedAppropriation"
              >
                <Input
                  formatter={(value) =>
                    `₦ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                  }
                  parser={(value) => value?.replace(/\₦\s?|(,*)/g, "")}
                  style={{ width: "100%" }}
                />
              </Form.Item>
            )}
          </div>
          <div className="w-100 m-r-10">
            {!isNew && (
              <Form.Item
                label="Actual Expenditure At"
                name="actualExpenditureAsAt"
              >
                <Input
                  formatter={(value) =>
                    `₦ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                  }
                  parser={(value) => value?.replace(/\₦\s?|(,*)/g, "")}
                  style={{ width: "100%" }}
                />
              </Form.Item>
            )}
            <Form.Item
              label="Proposed Appropriation"
              name="proposedAppropriation"
            >
              <Input
                formatter={(value) =>
                  `₦ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                }
                parser={(value) => value?.replace(/\₦\s?|(,*)/g, "")}
                style={{ width: "100%" }}
              />
            </Form.Item>
          </div>
        </div>

        <Form.Item
        //   wrapperCol={{
        //     offset: 8,
        //     span: 16,
        //   }}
        >
          <Button
            style={{ backgroundColor: "#1d811c" }}
            type="primary"
            htmlType="submit"
          >
            Save
          </Button>
        </Form.Item>

        <div style={{ overflowX: "scroll" }}>
          <div style={{ width: "120%" }}>
            <Table
              components={{
                body: {
                  cell: EditableCell,
                },
              }}
              pagination={{
                onChange: cancel,
              }}
              columns={mergedColumns}
              dataSource={data}
              summary={(pageData) => {
                let totalcurrentApprovedAppropriation = 0;
                let totalactualExpenditureAsAt = 0;
                let totalproposedAppropriation = 0;
                let totalpreviousExpenditures = 0;
                // const { Text } = Typography;
                // currentApprovedAppropriation;
                // actualExpenditureAsAt;
                // proposedAppropriation;
                pageData.forEach(
                  ({
                    currentApprovedAppropriation,
                    actualExpenditureAsAt,
                    proposedAppropriation,
                    previousExpenditures,
                  }) => {
                    totalcurrentApprovedAppropriation +=
                      +currentApprovedAppropriation;
                    totalactualExpenditureAsAt += +actualExpenditureAsAt;
                    totalproposedAppropriation += +proposedAppropriation;
                    totalpreviousExpenditures += +previousExpenditures;

                    // props.total({
                    //   name: "Capital Expenditure",

                    //   Proposed2024: totalproposedAppropriation,
                    //   Approved2023: totalcurrentApprovedAppropriation,
                    //   ActualJanToSep2023: totalactualExpenditureAsAt,
                    //   Approved2021: totalpreviousExpenditures,
                    // });
                  }
                );
                return (
                  <>
                    <Table.Summary.Row>
                      <Table.Summary.Cell index={0}>Total</Table.Summary.Cell>
                      <Table.Summary.Cell index={1}></Table.Summary.Cell>
                      <Table.Summary.Cell index={1}></Table.Summary.Cell>
                      <Table.Summary.Cell index={1}></Table.Summary.Cell>
                      <Table.Summary.Cell index={1}></Table.Summary.Cell>
                      <Table.Summary.Cell index={1}></Table.Summary.Cell>
                      <Table.Summary.Cell index={1}></Table.Summary.Cell>
                      <Table.Summary.Cell index={2}>
                        <Text type="success">
                          ₦{" "}
                          {numberWithCommas(totalcurrentApprovedAppropriation)}
                        </Text>
                      </Table.Summary.Cell>
                      <Table.Summary.Cell index={3}>
                        <Text type="success">
                          ₦ {numberWithCommas(totalactualExpenditureAsAt)}
                        </Text>
                      </Table.Summary.Cell>
                      <Table.Summary.Cell index={4}>
                        <Text type="success">
                          ₦ {numberWithCommas(totalproposedAppropriation)}
                        </Text>
                      </Table.Summary.Cell>
                      {/* <Table.Summary.Cell index={5}>
                        <Text type="success">{totalpreviousExpenditures}</Text>
                      </Table.Summary.Cell> */}
                    </Table.Summary.Row>
                    {/* const {Text} = Typography; */}
                    {/* <Table.Summary.Row>
                    <Table.Summary.Cell index={0}>Balance</Table.Summary.Cell>
                    <Table.Summary.Cell index={1} colSpan={2}>
                      <Text type="danger">{totalBorrow - totalRepayment}</Text>
                    </Table.Summary.Cell>
                  </Table.Summary.Row> */}
                  </>
                );
              }}
            />
          </div>
        </div>
      </Form>
    </div>
  );
};

export default CapitalProject;
