import React, { useState, useEffect } from "react";
import {
  Table,
  Button,
  Input,
  InputNumber,
  Popconfirm,
  Form,
  Typography,
  Select,
} from "antd";
import axios from "axios";
import notification from "../../utility/notification";

const BASE_API_URL = "https://edogoverp.com/budget/api/v1";
// const BASE_API_URL = 'http://localhost:5000/api/v1';

const originData = [];
const { Option } = Select;

// for (let i = 0; i < 100; i++) {
//   originData.push({
//     key: i.toString(),
//     name: `Edrward ${i}`,
//     age: 32,
//     address: `London Park no. ${i}`,
//   });
// }

const EditableCell = ({
  editing,
  dataIndex,
  title,
  inputType,
  record,
  index,
  children,
  ...restProps
}) => {
  const inputNode = inputType === "number" ? <InputNumber /> : <Input />;
  return (
    <td {...restProps}>
      {editing ? (
        <Form.Item
          name={dataIndex}
          style={{
            margin: 0,
          }}
          rules={[
            {
              required: true,
              message: `Please Input ${title}!`,
            },
          ]}
        >
          {inputNode}
        </Form.Item>
      ) : (
        children
      )}
    </td>
  );
};

const EditableTable = (props) => {
  const [form] = Form.useForm();
  //   setShowPreview

  //   const handleCancel2 = ()=>{
  //     setShowPreview(!showPreview)
  //   }

  const [data, setData] = useState([]);
  const [editingKey, setEditingKey] = useState("");
  const [programme, setProgramme] = useState("");
  const [programmeName, setProgrammeName] = useState("");
  const [programmeId, setProgrammeId] = useState("");
  const [item, setItem] = useState("");
  const [itemName, setItemName] = useState("");
  const [itemId, setItemId] = useState("");
  const [currency, setCurrency] = useState("");
  const [currencyId, setCurrencyId] = useState("");
  const [currencyName, setCurrencyName] = useState("");
  const [janVal, setjanVal] = useState("");
  const [febVal, setfebVal] = useState("");
  const [marVal, setmarVal] = useState("");
  const [aprVal, setaprVal] = useState("");
  const [mayVal, setmayVal] = useState("");
  const [junVal, setjunVal] = useState("");
  const [augVal, setaugVal] = useState("");
  const [septVal, setseptVal] = useState("");
  const [octVal, setoctVal] = useState("");
  const [julVal, setjulVal] = useState("");
  const [novVal, setnovVal] = useState("");
  const [decVal, setdecVal] = useState("");
  const [totalCost, setTotalCost] = useState("");
  //   const [count, setCount] = useState(1);

  const isEditing = (record) => record.key === editingKey;

  const edit = (record) => {
    form.setFieldsValue({
      name: "",
      age: "",
      address: "",
      ...record,
    });
    setEditingKey(record.key);
  };

  const cancel = () => {
    setEditingKey("");
  };

  const save = async (key) => {
    try {
      const row = await form.validateFields();
      const newData = [...data];
      const index = newData.findIndex((item) => key === item.key);

      if (index > -1) {
        const item = newData[index];
        newData.splice(index, 1, { ...item, ...row });
        setData(newData);
        setEditingKey("");
      } else {
        newData.push(row);
        setData(newData);
        setEditingKey("");
      }
    } catch (errInfo) {
      console.log("Validate Failed:", errInfo);
    }
  };
  // let count = 1

  useEffect(() => {
    setData([]);
  }, [props.data]);

  const toPost = () => {
    let postData = data.map((e) => ({
      // key: idx+1,

      id: e?.id,
      dateCreated: e?.dateCreated,
      enteredBy: e?.enteredBy,
      dateUpdated: e?.dateUpdated,
      updatedBy: e?.updatedBy,
      isDelete: e?.isDelete,
      dateDeleted: e?.dateDeleted,
      project: e?.project,
      budgetPlanId: e?.budgetPlanId,
      programmeCode: e?.programmeCode ? e?.programmeCode : "",
      status: e?.status,
      budgetDeptId: e.budgetDeptId,
      projectLocation: e?.projectLocation ? e?.projectLocation : "",
      proposedBudget: +e.proposedBudget ? e?.proposedBudget : "",
      performance: e?.performance ? e?.performance : "",
      actuals: e?.actuals ? e?.actuals : "",
      mdaId: e?.mdaId,
    }));
    // let poster = arrayHolder.map((a)=>())
    axios.post(`${BASE_API_URL}/BudgetPlanning`, postData).then(() => {
      //   notification({
      //       title: 'Successful',
      //       message: 'Costs have been Allocated successfully',
      //       type: 'success'
      // });
    });
  };

  useEffect(
    () => (
      console.log(props.formdata),
      form.setFieldsValue({ total: props.formdata.total }),
      form.setFieldsValue({ janVal: props.formdata.janVal }),
      form.setFieldsValue({ febVal: props.formdata.febVal }),
      form.setFieldsValue({ marVal: props.formdata.marVal }),
      form.setFieldsValue({ aprVal: props.formdata.aprVal }),
      form.setFieldsValue({ mayVal: props.formdata.mayVal }),
      form.setFieldsValue({ junVal: props.formdata.junVal }),
      form.setFieldsValue({ julVal: props.formdata.julVal }),
      form.setFieldsValue({ augVal: props.formdata.augVal }),
      form.setFieldsValue({ septVal: props.formdata.sepVal }),
      form.setFieldsValue({ octVal: props.formdata.octVal }),
      form.setFieldsValue({ novVal: props.formdata.novVal }),
      form.setFieldsValue({ decVal: props.formdata.decVal }),
      form.setFieldsValue({ currency: props.formdata.currencyName }),
      form.setFieldsValue({ programme: props.formdata.categoryName }),
      form.setFieldsValue({ item: props.formdata.departmentBudgetName })

      // form.setFieldsValue({total:(+props.formdata.janVal)+(+props.formdata.febVal)+(+props.formdata.marVal)+(+props.formdata.aprVal)+(+props.formdata.mayVal)+(+props.formdata.junVal)+(+props.formdata.julVal)+(+props.formdata.augVal)+(+props.formdata.septVal)+(+props.formdata.octVal)+(+props.formdata.novVal)+(+props.formdata.decVal)})

      // setTotalCost(janVal+febVal+marVal+aprVal+mayVal+junVal+julVal+augVal+septVal+octVal+novVal+decVal)
    ),
    [props.formdata]
  );

  return (
    <div>
      <div className="p-10" style={{ backgroundColor: "#66ff99" }}>
        {props.formdata.categoryName}
      </div>

      <Form
        className="w-100"
        layout="vertical"
        style={{ display: "" }}
        form={form}
      >
        <div class="bg-white p-20">
          <div class="flex-between  w-100">
            <Form.Item
              className="w-100 m-r-10"
              label="Programme"
              name="programme"
              //    rules={[{
              //    required: true,
              //    message: 'Please select who you are sending to!'},
              //    ]}
            >
              <Select
                onChange={(text, index) => {
                  // getItem(index.value,11)
                  setProgrammeName(index?.children);
                  setProgrammeId(index?.value);
                }}
                //  disabled = {fundType.length ==0? true:false}

                // labelInValue
                readOnly
                style={{ width: "100%", border: "" }}
                placeholder="Please select"
              >
                {Array.isArray(programme) &&
                  programme?.map((obj) => (
                    <Option
                      value={obj?.value}
                      key={obj?.id}
                    >{`${obj?.label}`}</Option>
                  ))}
              </Select>
            </Form.Item>

            <Form.Item
              className="w-100 m-r-10"
              label="Item"
              name="item"
              //    rules={[{
              //    required: true,
              //    message: 'Please select who you are sending to!'},
              //    ]}
            >
              <Select
                onChange={(text, index) => {
                  setItemName(index?.children);
                  setItemId(index?.value);
                }}
                //  disabled = {fundType.length ==0? true:false}

                // labelInValue
                readOnly
                style={{ width: "100%", border: "" }}
                placeholder="Please select"
              >
                {Array.isArray(item) &&
                  item?.map((obj) => (
                    <Option
                      value={obj?.value}
                      key={obj?.id}
                    >{`${obj?.label}`}</Option>
                  ))}
              </Select>
            </Form.Item>
            <Form.Item
              className="w-100 m-r-10"
              label="Currency"
              name="currency"
              //    rules={[{
              //    required: true,
              //    message: 'Please select who you are sending to!'},
              //    ]}
            >
              <Select
                onChange={(text, index) => {
                  setCurrencyName(index?.children);
                  setCurrencyId(index?.value);
                }}
                //  disabled = {fundType.length ==0? true:false}

                // labelInValue
                readOnly
                style={{ width: "100%", border: "" }}
                placeholder="Please select"
              >
                {Array.isArray(currency) &&
                  currency?.map((obj) => (
                    <Option
                      value={obj?.value}
                      key={obj?.id}
                    >{`${obj?.label}`}</Option>
                  ))}
              </Select>
            </Form.Item>
          </div>
          <div class="flex-between  w-100">
            <Form.Item
              className="w-100 m-r-10"
              label="January"
              name="janVal"
              // rules={[{
              // required: true,
              // message: 'Please select who you are sending to!'},
              // ]}
            >
              <InputNumber
                formatter={(value) =>
                  `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                }
                parser={(value) => value?.replace(/\$\s?|(,*)/g, "")}
                readOnly
                className="w-100 m-r-10"
                onChange={(e) => {
                  setjanVal(e);
                }}
              />
            </Form.Item>
            <Form.Item
              className="w-100 m-r-10"
              label="February"
              name="febVal"
              // rules={[{
              // required: true,
              // message: 'Please select who you are sending to!'},
              // ]}
            >
              <InputNumber
                formatter={(value) =>
                  `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                }
                parser={(value) => value?.replace(/\$\s?|(,*)/g, "")}
                readOnly
                className="w-100 m-r-10"
                onChange={(e) => {
                  setfebVal(e);
                }}
              />
            </Form.Item>
            <Form.Item
              className="w-100 m-r-10"
              label="March"
              name="marVal"
              // rules={[{
              // required: true,
              // message: 'Please select who you are sending to!'},
              // ]}
            >
              <InputNumber
                formatter={(value) =>
                  `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                }
                parser={(value) => value?.replace(/\$\s?|(,*)/g, "")}
                readOnly
                className="w-100 m-r-10"
                onChange={(e) => {
                  setmarVal(e);
                }}
              />
            </Form.Item>
          </div>
          <div class="flex-between  w-100">
            <Form.Item
              className="w-100 m-r-10"
              label="April"
              name="aprVal"
              // rules={[{
              // required: true,
              // message: 'Please select who you are sending to!'},
              // ]}
            >
              <InputNumber
                formatter={(value) =>
                  `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                }
                parser={(value) => value?.replace(/\$\s?|(,*)/g, "")}
                readOnly
                className="w-100 m-r-10"
                onChange={(e) => {
                  setaprVal(e);
                }}
              />
            </Form.Item>
            <Form.Item
              className="w-100 m-r-10"
              label="May"
              name="mayVal"
              // rules={[{
              // required: true,
              // message: 'Please select who you are sending to!'},
              // ]}
            >
              <InputNumber
                formatter={(value) =>
                  `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                }
                parser={(value) => value?.replace(/\$\s?|(,*)/g, "")}
                readOnly
                className="w-100 m-r-10"
                onChange={(e) => {
                  setmayVal(e);
                }}
              />
            </Form.Item>
            <Form.Item
              className="w-100 m-r-10"
              label="June"
              name="junVal"
              // rules={[{
              // required: true,
              // message: 'Please select who you are sending to!'},
              // ]}
            >
              <InputNumber
                formatter={(value) =>
                  `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                }
                parser={(value) => value?.replace(/\$\s?|(,*)/g, "")}
                readOnly
                className="w-100 m-r-10"
                onChange={(e) => {
                  setjunVal(e);
                }}
              />
            </Form.Item>
          </div>
          <div class="flex-between  w-100">
            <Form.Item
              className="w-100 m-r-10"
              label="July"
              name="julVal"
              // rules={[{
              // required: true,
              // message: 'Please select who you are sending to!'},
              // ]}
            >
              <InputNumber
                formatter={(value) =>
                  `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                }
                parser={(value) => value?.replace(/\$\s?|(,*)/g, "")}
                readOnly
                className="w-100 m-r-10"
                onChange={(e) => {
                  setjulVal(e);
                }}
              />
            </Form.Item>
            <Form.Item
              className="w-100 m-r-10"
              label="August"
              name="augVal"
              // rules={[{
              // required: true,
              // message: 'Please select who you are sending to!'},
              // ]}
            >
              <InputNumber
                formatter={(value) =>
                  `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                }
                parser={(value) => value?.replace(/\$\s?|(,*)/g, "")}
                readOnly
                className="w-100 m-r-10"
                onChange={(e) => {
                  setaugVal(e);
                }}
              />
            </Form.Item>
            <Form.Item
              className="w-100 m-r-10"
              label="September"
              name="septVal"
              // rules={[{
              // required: true,
              // message: 'Please select who you are sending to!'},
              // ]}
            >
              <InputNumber
                formatter={(value) =>
                  `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                }
                parser={(value) => value?.replace(/\$\s?|(,*)/g, "")}
                readOnly
                className="w-100 m-r-10"
                onChange={(e) => {
                  setseptVal(e);
                }}
              />
            </Form.Item>
          </div>
          <div class="flex-between w-100">
            <Form.Item
              className="w-100 m-r-10"
              label="October"
              name="octVal"
              // rules={[{
              // required: true,
              // message: 'Please select who you are sending to!'},
              // ]}
            >
              <InputNumber
                formatter={(value) =>
                  `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                }
                parser={(value) => value?.replace(/\$\s?|(,*)/g, "")}
                readOnly
                className="w-100 m-r-10"
                onChange={(e) => {
                  setoctVal(e);
                }}
              />
            </Form.Item>
            <Form.Item
              className="w-100 m-r-10"
              label="November"
              name="novVal"
              // rules={[{
              // required: true,
              // message: 'Please select who you are sending to!'},
              // ]}
            >
              <InputNumber
                formatter={(value) =>
                  `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                }
                parser={(value) => value?.replace(/\$\s?|(,*)/g, "")}
                readOnly
                className="w-100 m-r-10"
                onChange={(e) => {
                  setnovVal(e);
                }}
              />
            </Form.Item>
            <Form.Item
              className="w-100 m-r-10"
              label="December"
              name="decVal"
              // rules={[{
              // required: true,
              // message: 'Please select who you are sending to!'},
              // ]}
            >
              <InputNumber
                formatter={(value) =>
                  `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                }
                parser={(value) => value?.replace(/\$\s?|(,*)/g, "")}
                readOnly
                className="w-100 m-r-10"
                onChange={(e) => {
                  setdecVal(e);
                }}
              />
            </Form.Item>
          </div>

          <div
            style={{ dsplay: "flex", justifyContent: "center", width: "100%" }}
          >
            <Form.Item
              style={{
                dsplay: "flex",
                justifyContent: "center",
                width: "35%",
                margin: "0 auto",
              }}
              label="Total"
              name="total"
              // rules={[{
              // required: true,
              // message: 'Please select who you are sending to!'},
              // ]}
            >
              <InputNumber
                formatter={(value) =>
                  `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                }
                parser={(value) => value?.replace(/\$\s?|(,*)/g, "")}
                readOnly
                className="w-100 m-r-10"
              />
            </Form.Item>
          </div>
        </div>
      </Form>
    </div>
  );
};

export default EditableTable;
