import React from "react";
import { Button, Table } from "antd";


function numberWithCommas(x) {
    return x?.toString()?.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  }
const data = {
  capitalBudget: 0,
  amountSpentCapital: 0,
  personnelBudget: 0,
  amountSpentPersonnel: 0,
  overHeadBudget: 0,
  amountSpentOverHead: 0,
  percentageCapital: null,
  percentagePersonnel: null,
  percentageOverHead: null,
  mda: "EDO STATE FIRE SERVICE",
};

const columns = [
  { title: "MDA", dataIndex: "mda", key: "mda", fixed: "left", sorter: true },

  {
    title: "Capital Budget",
    dataIndex: "capitalBudget",
    key: "capitalBudget",
    render: (text, record) => <p> &#8358; {numberWithCommas(text?.toFixed(2))}</p>,
  },
  {
    title: "Capital Budget Spent",
    dataIndex: "amountSpentCapital",
    key: "amountSpentCapital",
    render: (text, record) => <p> &#8358; {numberWithCommas(text?.toFixed(2))}</p>,
  },
  {
    title: " % Capital Spent",
    dataIndex: "percentagePersonnel",
    key: "percentagePersonnel",
    render: (text, record) => <p>{numberWithCommas(text?.toFixed(2))} %</p>,
  },
  {
    title: "Personnel Budget",
    dataIndex: "personnelBudget",
    key: "personnelBudget",
    render: (text, record) => <p> &#8358; {numberWithCommas(text?.toFixed(2))}</p>,
  },
  {
    title: "Personnel Budget Spent",
    dataIndex: "amountSpentPersonnel",
    key: "amountSpentPersonnel",
    render: (text, record) => <p> &#8358; {numberWithCommas(text?.toFixed(2))}</p>,
  },
  {
    title: " % Personnel Spent",
    dataIndex: "percentageCapital",
    key: "percentageCapital",
    render: (text, record) => <p> {numberWithCommas(text?.toFixed(2))} %</p>,
  },

  {
    title: "Overhead Budget",
    dataIndex: "overHead Budget Spent",
    key: "overHeadBudget",
    render: (text, record) => <p> &#8358; {numberWithCommas(text?.toFixed(2))}</p>,
  },
  {
    title: "OverHead Budget Spent",
    dataIndex: "amountSpentOverHead",
    key: "amountSpentOverHead",
    render: (text, record) => <p> &#8358; {numberWithCommas(text?.toFixed(2))}</p>,
  },

  {
    title: "% OverHead Spent",
    dataIndex: "percentageOverHead",
    key: "percentageOverHead",
    render: (text, record) => <p> {numberWithCommas(text?.toFixed(2))} &#8358;</p>,
  },
];

const columns2 = [
  { title: "Mda", dataIndex: "mda", key: "mda", fixed: "left", sorter: true },
  {
    title: "budget Type",
    dataIndex: "budgetType",
    key: "budgetType",
    fixed: "left",
    sorter: true,
  },

  {
    title: "administrative Code",
    dataIndex: "administrativeCode",
    key: "administrativeCode",
  },
  { title: "Economic Code", dataIndex: "economicCode", key: "economicCode" },
  { title: "Programme Code", dataIndex: "programmeCode", key: "programmeCode" },
  { title: "Budget Item", dataIndex: "budgetItem", key: "budgetItem" },
  {
    title: "Economic Details",
    dataIndex: "economicDetails",
    key: "economicDetails",
  },
  {
    title: "Total Budgetted Amount",
    dataIndex: "totalAmount",
    key: "totalAmount",
    render: (text, record) => <p> &#8358; {numberWithCommas(text?.toFixed(2))}</p>,

  },
];
const ReportTableAll = ({ data, dataDetailMda }) => {
  console.log();

  return (
    <div class="w-100">
      <Table
        dataSource={data.length > 0 ? data : dataDetailMda}
        columns={data.length > 0 ? columns : columns2}
        pagination={false}
        scroll={{ x: 1900 }}
      />
    </div>
  );
};

export default ReportTableAll;
