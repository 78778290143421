import React, { Component } from "react";
import { Link } from "react-router-dom";
// import { Nav } from "../Components/Nav";
import BudgetPeriodActivation from "../layouts/BudgetPeriodActivation";
// import  BudgetApprovalOrder  from "../layouts/BudgetApprovalOrder";
// import  PrivilegesAccess  from "../layouts/PrivilegesAccess";
// import  BudgetCurrencyConversation  from "../layouts/BudgetCurrencyConversation";
// import { BsPencilSquare } from 'react-icons/bs';

// import { BudgetApprovalOrder } from "../Components/BudgetApprovalOrder";
// import ReactNotifications from 'react-notifications-component';

// import { DepartmentUser } from "../Components/DepartmentUser";
// import { BudgetCurrencyConversation } from "../Components/BudgetCurrencyConversation";
// import  BudgetCurrencyConversationPerMonth  from "../layouts/BudgetCurrencyConversionPerMonth";
// import PrivilegesAccess from "../Components/PrivilegesAccess";

// import DashboardC from '../layouts/DashboardC'
import cookies from "js-cookie";
const BASE_API_URL = "https://edogoverp.com/budget/api/v1";

export default class Settings extends Component {
  constructor(props) {
    super(props);
    this.state = {
      fullName: "",
      role: "",
      // time: "",
      employeeId: 0,
    };
  }
  componentDidMount() {
    this.getAuthorisedUser();
  }

  getAuthorisedUser = () => {
    //  const { cookies } = this.props;
    if (typeof cookies.get("userId") !== "undefined") {
      let authUser = cookies.get("userId");
      //  let time = authUser.lastLoginTime.split("T")[1]
      this.setState({
        fullName: authUser.firstName + " " + authUser.lastName,
        role: authUser.role,
        //  time: time,
        employeeId: authUser,
      });
    }
  };

  render() {
    return (
      <React.Fragment>
        {/* <ReactNotifications /> */}

        <main
          class=" w-100 flex-1 relative overflow-y-auto focus:outline-none"
          tabindex="0"
          style={{ outline: "none" }}
        >
          <div class="py-10 max-w-full mx-auto px-2 sm:px-4 md:px-8">
            <div class="flex">
              <h1 class="flex-1 text-base font-medium text-color">
                Budget Settings
              </h1>
              {/* <div className="flex">
                    <p className="text-sm font-normal mr-3">

                      <br />
                                  <span className="text-xs ml-5">
                                      Time of login: {this.state.time}
                      </span>
                    </p>
                    <img src="/location-icon@2x.png" />
                    <div style={{ height: 40, width: 40 }}>
                      <img src="/icn.png" />
                    </div>
                  </div> */}
            </div>
            <div className="py-6">
              <div className="m-t-10">
                <BudgetPeriodActivation
                  employeeId={this.state.employeeId}
                  {...this.props}
                />
              </div>
              {/* <div className="m-t-10">
                              <BudgetApprovalOrder employeeId={this.state.employeeId}
                                  {...this.props} />
                          </div> */}
              <div className="m-t-10">
                {/* <PrivilegesAccess employeeId={this.state.employeeId}
                                  {...this.props} /> */}
              </div>
              <div className="m-t-10">
                {/* <BudgetCurrencyConversation employeeId={this.state.employeeId}
                                  {...this.props} />  */}
              </div>
              {/* <DepartmentUser {...this.props} /> */}

              {/* <div className="m-t-10">
                      <BudgetCurrencyConversationPerMonth employeeId={this.state.employeeId} {...this.props} />
                  </div> */}
            </div>
          </div>
        </main>
      </React.Fragment>
    );
  }
}
