import { Form, Input, InputNumber, Popconfirm, Table, Typography } from "antd";
import moment from "moment";
import React, { useEffect, useState } from "react";

const { Text } = Typography;

const originData = [];
for (let i = 0; i < 100; i++) {
  originData.push({
    key: i.toString(),
    name: `Edrward ${i}`,
    age: 32,
    address: `London Park no. ${i}`,
  });
}

const { TextArea } = Input;
const EditableCell = ({
  editing,
  dataIndex,
  title,
  inputType,
  record,
  index,
  children,
  ...restProps
}) => {
  const inputNode =
    inputType === "number" ? (
      <InputNumber
        formatter={(value) =>
          `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
        }
        parser={(value) => value?.replace(/\$\s?|(,*)/g, "")}
      />
    ) : (
      <TextArea rows={4} />
    );
  return (
    <td {...restProps}>
      {editing ? (
        <Form.Item
          name={dataIndex}
          style={{
            margin: 0,
          }}
          rules={[
            {
              required: true,
              message: `Please Input ${title}!`,
            },
          ]}
        >
          {inputNode}
        </Form.Item>
      ) : (
        children
      )}
    </td>
  );
};
const EnvelopeTable = (props) => {
  const [form] = Form.useForm();
  const [data, setData] = useState([]);
  const [dataColumns, setDataColumns] = useState([]);
  const [ongoingP, setOngoingP] = useState(originData);
  const [editingKey, setEditingKey] = useState("");
  const isEditing = (record) => record.key === editingKey;

  const edit = (record) => {
    form.setFieldsValue({
      label: "",
      amount: "",
      comment: "",

      ...record,
    });
    setEditingKey(record.key);
  };
  const cancel = () => {
    setEditingKey("");
  };
  function numberWithCommas(x) {
    return x?.toString()?.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  }
  const save = async (key) => {
    try {
      const row = await form.validateFields();
      const newData = [...data];
      const index = newData.findIndex((item) => key === item.key);
      if (index > -1) {
        const item = newData[index];
        newData.splice(index, 1, {
          ...item,
          ...row,
        });
        // props.setEnvelopeData(newData);
        console.log(newData);

        setData(newData);
        setEditingKey("");
      } else {
        newData.push(row);
        setData(newData);
        setEditingKey("");
        console.log(newData);
      }
    } catch (errInfo) {
      console.log("Validate Failed:", errInfo);
    }
  };

  let ongoingProjectsColumn = [
    ...dataColumns,
    // {
    //   title: "operation",
    //   dataIndex: "operation",
    //   render: (_, record) => {
    //     const editable = isEditing(record);
    //     return editable ? (
    //       <span>
    //         <Typography.Link
    //           onClick={() => {
    //             save(record.key);
    //           }}
    //           style={{
    //             marginRight: 8,
    //           }}
    //         >
    //           Save
    //         </Typography.Link>
    //         <Popconfirm title="Sure to cancel?" onConfirm={cancel}>
    //           <a>Cancel</a>
    //         </Popconfirm>
    //       </span>
    //     ) : (
    //       <Typography.Link
    //         disabled={editingKey !== ""}
    //         onClick={() => edit(record)}
    //       >
    //         Edit
    //       </Typography.Link>
    //     );
    //   },
    // },
  ];

  //   [
  //     {
  //       title: "MDA",
  //       dataIndex: "label",
  //       editable: false,
  //       key: "mda",
  //       render: (text) => <a>{text}</a>,
  //     },
  //     {
  //       title: "Ongoing Project-Ceiling",
  //       dataIndex: "ongoingProjects",
  //       editable: true,
  //       key: "ongoingProjects",
  //       // render: (text) => <a>{text}</a>,
  //     },
  //     {
  //       title: "Personel Cost-Ceiling",
  //       dataIndex: "personnelCost",
  //       editable: true,
  //       key: "personnelCost",
  //     },
  //     {
  //       title: "Capital Project-Ceiling",
  //       dataIndex: "capitalProjects",
  //       editable: true,
  //       key: "capitalProjects",
  //     },
  //     {
  //       title: "Capital receipt Ceiling",
  //       dataIndex: "capitalReceipt",
  //       editable: true,
  //       key: "capitalReceipt",
  //     },
  //     {
  //       title: "Overhead Cost-Monitor-Ceiling",
  //       dataIndex: "overheadCost",
  //       editable: true,
  //       key: "overheadCost",
  //     },
  //     {
  //       title: "Recurrent Revenue-Ceiling",
  //       dataIndex: "recurrentRev",
  //       editable: true,
  //       key: "recurrentRev",
  //     },

  //     {
  //       title: "Amount",
  //       dataIndex: "amount",
  //       editable: true,
  //       key: "amoint",
  //     },

  //     {
  //       title: "Comment",
  //       dataIndex: "comment",
  //       editable: true,
  //       key: "comment",
  //     },
  //     {
  //       title: "operation",
  //       dataIndex: "operation",
  //       render: (_, record) => {
  //         const editable = isEditing(record);
  //         return editable ? (
  //           <span>
  //             <Typography.Link
  //               onClick={() => {
  //                 save(record.key);
  //               }}
  //               style={{
  //                 marginRight: 8,
  //               }}
  //             >
  //               Save
  //             </Typography.Link>
  //             <Popconfirm title="Sure to cancel?" onConfirm={cancel}>
  //               <a>Cancel</a>
  //             </Popconfirm>
  //           </span>
  //         ) : (
  //           <Typography.Link
  //             disabled={editingKey !== ""}
  //             onClick={() => edit(record)}
  //           >
  //             Edit
  //           </Typography.Link>
  //         );
  //       },
  //     },
  //   ];

  function numberWithCommas(x) {
    return x
      ?.toFixed(2)
      ?.toString()
      ?.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  }

  useEffect(() => {
    createData();
  }, [props.tableData]);

  const createData = () => {
    // console.log(props.propzApproved[0]?.ongoingProjects);
    const ongoingProjectsData = props.tableData;

    console.log(props.tableData);
    console.log(props.columns);
    //  ongoingProjectsColumn = props.column;

    // ?.ongoingProjects?.map(
    //   (e) => ({
    //     deptBudgetId: "fd9de34e-86f6-43c1-98ce-998a4812c289",
    //     project: e.project,
    //     status: e.status,
    //     location: e.location,
    //     startDate: moment("2023-10-11T17:08:24.133").format("ll"),
    //     amountSpent: e.amountSpent,
    //     conclusionEstimate: e.conclusionEstimate,
    //     completionTime: e.completionTime,
    //     mdaId: 11,
    //   })
    // );

    // console.log(ongoingProjectsData);
    setData(ongoingProjectsData);
    setDataColumns(props?.columns);
  };

  //  console.log(props.propzApproved);

  const mergedColumns = ongoingProjectsColumn?.map((col) => {
    if (!col.editable) {
      return col;
    }
    return {
      ...col,
      onCell: (record) => ({
        record,
        inputType: col.dataIndex === "comment" ? "text" : "number",
        dataIndex: col.dataIndex,
        title: col.title,
        editing: isEditing(record),
      }),
    };
  });

  console.log(mergedColumns);
  return (
    <Form form={form} component={false}>
      <Table
        components={{
          body: {
            cell: EditableCell,
          },
        }}
        bordered
        dataSource={data}
        columns={mergedColumns}
        rowClassName="editable-row"
        pagination={{
          onChange: cancel,
        }}
        summary={(pageData) => {
          let totalproposedBudget = 0;
          let totalpreviousApprovedBudget = 0;
          let totalcurrentExpenditures = 0;
          let totalpreviousExpenditures = 0;
          let totalcurrentApprovedAppropriation = 0;
          let totalactualExpenditureAsAt = 0;
          let totalproposedAppropriation = 0;
          let totalproposedExpense = 0;
          let totalprevApprovedExpense = 0;
          let totalcurrentExpenseAsAt = 0;
          let totalpreviousApprovedRevenue = 0;
          let totalcurrentRevenueAsAt = 0;
          let totalpreviousGeneratedRevenue = 0;
          let totalproposedRevenue = 0

          let totalRepayment = 0;
          pageData.forEach(
            ({
              proposedBudget,
              repayment,
              previousApprovedBudget,
              currentExpenditures,
              previousExpenditures,
              annualisedTotalExpenditure,
              currentApprovedAppropriation,
              actualExpenditureAsAt,
              proposedAppropriation,
              proposedExpense,
              prevApprovedExpense,
              currentExpenseAsAt,

              previousApprovedRevenue,
              currentRevenueAsAt,
              previousGeneratedRevenue,
              proposedRevenue,
            }) => {
              totalproposedBudget += proposedBudget || 0;
              totalpreviousApprovedBudget += previousApprovedBudget || 0;
              totalcurrentExpenditures += currentExpenditures || 0;
              totalpreviousExpenditures += previousExpenditures || 0;

              totalcurrentApprovedAppropriation +=
                currentApprovedAppropriation || 0;
              totalactualExpenditureAsAt += actualExpenditureAsAt || 0;
              totalproposedAppropriation += proposedAppropriation || 0;

              totalproposedExpense += proposedExpense || 0;
              totalprevApprovedExpense += prevApprovedExpense || 0;
              totalcurrentExpenseAsAt += currentExpenseAsAt || 0;

              totalpreviousApprovedRevenue += previousApprovedRevenue || 0;
              totalcurrentRevenueAsAt += currentRevenueAsAt || 0;
              totalpreviousGeneratedRevenue += previousGeneratedRevenue || 0;
              totalproposedRevenue += proposedRevenue || 0;
            }
          );
          return (
            <>
              {totalproposedBudget && (
                <Table.Summary.Row>
                  <Table.Summary.Cell index={0}>Total</Table.Summary.Cell>
                  <Table.Summary.Cell index={1}>
                    <Text type="danger"></Text>
                  </Table.Summary.Cell>
                  <Table.Summary.Cell index={1}>
                    <Text type="danger"></Text>
                  </Table.Summary.Cell>
                  <Table.Summary.Cell index={1}>
                    <Text type="danger">
                      {numberWithCommas(totalproposedBudget)}
                    </Text>
                  </Table.Summary.Cell>
                  <Table.Summary.Cell index={1}>
                    <Text type="danger">
                      {numberWithCommas(totalpreviousApprovedBudget)}
                    </Text>
                  </Table.Summary.Cell>
                  <Table.Summary.Cell index={1}>
                    <Text type="danger">
                      {numberWithCommas(totalcurrentExpenditures)}
                    </Text>
                  </Table.Summary.Cell>
                  <Table.Summary.Cell index={1}>
                    <Text type="danger">
                      {numberWithCommas(totalpreviousExpenditures)}
                    </Text>
                  </Table.Summary.Cell>
                </Table.Summary.Row>
              )}
              {totalproposedAppropriation && (
                <Table.Summary.Row>
                  <Table.Summary.Cell index={0}>Total</Table.Summary.Cell>
                  <Table.Summary.Cell index={0}></Table.Summary.Cell>
                  <Table.Summary.Cell index={0}></Table.Summary.Cell>
                  <Table.Summary.Cell index={0}></Table.Summary.Cell>
                  <Table.Summary.Cell index={1}>
                    <Text type="danger">
                      {/* {numberWithCommas(totalproposedBudget)} */}
                    </Text>
                  </Table.Summary.Cell>
                  <Table.Summary.Cell index={1}>
                    <Text type="danger">
                      {numberWithCommas(totalproposedAppropriation || 0)}
                    </Text>
                  </Table.Summary.Cell>
                  <Table.Summary.Cell index={1}>
                    <Text type="danger">
                      {numberWithCommas(totalcurrentApprovedAppropriation || 0)}
                    </Text>
                  </Table.Summary.Cell>
                  <Table.Summary.Cell index={1}>
                    <Text type="danger">
                      {numberWithCommas(totalactualExpenditureAsAt || 0)}
                    </Text>
                  </Table.Summary.Cell>
                  <Table.Summary.Cell index={1}>
                    <Text type="danger">
                      {numberWithCommas(totalpreviousExpenditures || 0)}
                    </Text>
                  </Table.Summary.Cell>
                </Table.Summary.Row>
              )}
              {totalproposedExpense && (
                <Table.Summary.Row>
                  <Table.Summary.Cell index={0}>Total</Table.Summary.Cell>
                  <Table.Summary.Cell index={0}></Table.Summary.Cell>
                  <Table.Summary.Cell index={0}></Table.Summary.Cell>
                  <Table.Summary.Cell index={0}></Table.Summary.Cell>
                  <Table.Summary.Cell index={1}>
                    <Text type="danger">
                      {/* {numberWithCommas(totalproposedBudget)} */}
                    </Text>
                  </Table.Summary.Cell>
                  <Table.Summary.Cell index={2}>
                    <Text type="danger">
                      {numberWithCommas(+totalproposedExpense)}
                    </Text>
                  </Table.Summary.Cell>
                  <Table.Summary.Cell index={3}>
                    <Text type="danger">
                      {numberWithCommas(totalprevApprovedExpense)}
                    </Text>
                  </Table.Summary.Cell>
                  <Table.Summary.Cell index={4}>
                    <Text type="danger">
                      {numberWithCommas(totalcurrentExpenseAsAt)}
                    </Text>
                  </Table.Summary.Cell>
                </Table.Summary.Row>
              )}
              {totalpreviousApprovedRevenue && (
                <Table.Summary.Row>
                  <Table.Summary.Cell index={0}>Total</Table.Summary.Cell>
                  <Table.Summary.Cell index={0}></Table.Summary.Cell>
                 
                  <Table.Summary.Cell index={2}>
                    <Text type="danger">
                      {numberWithCommas(+totalproposedRevenue)}
                    </Text>
                  </Table.Summary.Cell>
                  <Table.Summary.Cell index={3}>
                    <Text type="danger">
                      {numberWithCommas(totalpreviousGeneratedRevenue)}
                    </Text>
                  </Table.Summary.Cell>
                  <Table.Summary.Cell index={4}>
                    <Text type="danger">
                      {numberWithCommas(totalcurrentRevenueAsAt)}
                    </Text>
                  </Table.Summary.Cell>
                </Table.Summary.Row>
              )}
            </>
          );
        }}
      />
    </Form>
  );
};
export default EnvelopeTable;
