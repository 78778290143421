import { Form, Input, InputNumber, Popconfirm, Table, Typography } from "antd";
import moment from "moment";
import React, { useEffect, useState } from "react";

const originData = [];
for (let i = 0; i < 100; i++) {
  originData.push({
    key: i.toString(),
    name: `Edrward ${i}`,
    age: 32,
    address: `London Park no. ${i}`,
  });
}
const EditableCell = ({
  editing,
  dataIndex,
  title,
  inputType,
  record,
  index,
  children,
  ...restProps
}) => {
  const inputNode =
    inputType === "number" ? (
      <Input
      // formatter={(value) =>
      //   `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
      // }
      // parser={(value) => value?.replace(/\$\s?|(,*)/g, "")}
      />
    ) : (
      <Input />
    );
  return (
    <td {...restProps}>
      {editing ? (
        <Form.Item
          name={dataIndex}
          style={{
            margin: 0,
          }}
          //   rules={[
          //     {
          //       required: true,
          //       message: `Please Input ${title}!`,
          //     },
          //   ]}
        >
          {inputNode}
        </Form.Item>
      ) : (
        children
      )}
    </td>
  );
};
const EditableTableP = (props) => {
  const [form] = Form.useForm();
  const [data, setData] = useState([]);
  const [ongoingP, setOngoingP] = useState(originData);
  const [editingKey, setEditingKey] = useState("");
  const isEditing = (record) => record.key === editingKey;
  function numberWithCommas(x) {
    return x?.toString()?.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  }
  const edit = (record) => {
    form.setFieldsValue({
      deptBudgetId: "",
      administrativeCode: "",
      gl: "",
      noOfStaff: "",
      endDate: moment("2023-10-11T17:08:24.133").format("ll"),
      proposedExpense: "",
      prevNoOfStaff: "",
      prevApprovedExpense: "",
      currentExpenseAsAt: "",
      previousExpense: "",
      economicCodeMain: "",
      economicCodeDetails: "",
      functionCodeMain: "",
      functionCodeDetails: "",
      locationCode: "",
      fundCodeMain: "",
      programmeCode: "",

      ...record,
    });
    setEditingKey(record.key);
  };
  const cancel = () => {
    setEditingKey("");
  };
  const save = async (key, record) => {
    try {
      let row = await form.validateFields();
      row = {
        ...row,
        editedAmount: +row.availableBudget - +record?.proposedExpense,
        availableBudget: +row.availableBudget,
      };
      const newData = [...data];
      const index = newData.findIndex((item) => key === item.key);
      if (index > -1) {
        const item = newData[index];
        newData.splice(index, 1, {
          ...item,
          ...row,
        });
        console.log(newData);
        let kkk = { ...props.propzApproved, personnelCosts: newData };
        delete kkk["budgetDeptApprovers"];
        delete kkk["approvers"];
        props.saveAndRefreshApprovedMDAs(kkk);

        setData(newData);
        setEditingKey("");
      } else {
        newData.push(row);
        setData(newData);
        setEditingKey("");
        console.log(newData);
      }
    } catch (errInfo) {
      console.log("Validate Failed:", errInfo);
    }
  };

  //   const ongoingProjectsColumn = [
  //     {
  //       title: "Project",
  //       dataIndex: "project",
  //       editable: true,
  //       key: "name",
  //       render: (text) => <a>{text}</a>,
  //     },
  //     {
  //       title: "Status",
  //       dataIndex: "status",
  //       editable: true,
  //       key: "age",
  //     },
  //     {
  //       title: "Date Of Commencement",
  //       dataIndex: "startDate",
  //       key: "startDate",
  //       editable: true,
  //       width: "20%",
  //     },
  //     {
  //       title: "Amount Spent to date",
  //       dataIndex: "amountSpent",
  //       key: "amountSpent",
  //       editable: true,
  //       width: "20%",
  //     },
  //     {
  //       title: "Estimate to Conclusion",
  //       dataIndex: "conclusionEstimate",
  //       key: "conclusionEstimate",
  //       editable: true,
  //       width: "20%",
  //     },
  //     {
  //       title: "Time To Completion",
  //       dataIndex: "completionTime",
  //       key: "completionTime",
  //       editable: true,
  //       width: "20%",
  //     },
  //     {
  //       title: "operation",
  //       dataIndex: "operation",
  //       render: (_, record) => {
  //         const editable = isEditing(record);
  //         return editable ? (
  //           <span>
  //             <Typography.Link
  //               onClick={() => save(record.key)}
  //               style={{
  //                 marginRight: 8,
  //               }}
  //             >
  //               Save
  //             </Typography.Link>
  //             <Popconfirm title="Sure to cancel?" onConfirm={cancel}>
  //               <a>Cancel</a>
  //             </Popconfirm>
  //           </span>
  //         ) : (
  //           <Typography.Link
  //             disabled={editingKey !== ""}
  //             onClick={() => edit(record)}
  //           >
  //             Edit
  //           </Typography.Link>
  //         );
  //       },
  //     },
  //   ];

  const personnelCostsColumn = [
    {
      title: "Administrative Code",
      dataIndex: "administrativeCode",
      editable: true,
      key: "name",
      // render: (text) => <a>{text}</a>,
    },
    {
      title: "Economic Code Main",
      editable: true,
      dataIndex: "economicCodeMain",
      key: "currentExpenseAsAt",
      // width: "20%",
    },
    {
      title: "Economic Code Details",
      editable: true,
      dataIndex: "economicCodeDetails",
      key: "economicCodeDetails",
      // width: "20%",
    },
    {
      title: "Grade Level",
      editable: true,
      dataIndex: "gl",
      key: "age",
    },
    {
      title: "No. of Staff",
      dataIndex: "noOfStaff",
      key: "noOfStaff",
      editable: true,
      // width: "20%",
    },
    {
      title: "Proposed Expenditure",
      dataIndex: "proposedExpense",
      key: "proposedExpense",
      editable: false,
      render: (text, _) => numberWithCommas(+text),

      // width: "20%",
    },

    {
      title: "Available Budget",
      dataIndex: "availableBudget",
      key: "availableBudget",
      editable: true,
      render: (text, _) => numberWithCommas(+text),

      // width: "20%",
    },
    // {
    //   title: "Prev No. of Staff",
    //   dataIndex: "prevNoOfStaff",
    //   key: "prevNoOfStaff",
    //   // width: "20%",
    //   editable: true,
    // },
    {
      title: "Prev Approved Expenses",
      dataIndex: "prevApprovedExpense",
      editable: true,
      key: "prevApprovedExpense",
      render: (text, _) => numberWithCommas(+text),

      // width: "20%",
    },

    {
      title: "Expenses as at 30th Sept",
      editable: true,
      dataIndex: "currentExpenseAsAt",
      key: "currentExpenseAsAt",
      render: (text, _) => numberWithCommas(+text),

      // width: "20%",
    },

    {
      title: "Location",
      editable: true,
      dataIndex: "locationCode",
      key: "locationCode",
      // width: "20%",
    },
    {
      title: "Fund Code",
      editable: true,
      dataIndex: "fundCodeMain",
      key: "fundCodeMain",
      // width: "20%",
    },
    // {
    //   title: "Functional Code Details",
    //   editable: true,
    //   dataIndex: "functionCodeDetails",
    //   key: "functionCodeDetails",
    //   width: "20%",
    // },
    {
      title: "Functional Code",
      editable: true,
      dataIndex: "functionCodeMain",
      key: "functionCodeMain",
      // width: "20%",
    },

    {
      title: "Program Code",
      editable: true,
      dataIndex: "programmeCode",
      key: "programmeCode",
      // width: "20%",
    },

    // {
    //   editable: true,
    //   title: "End Date",
    //   dataIndex: "endDate",
    //   key: "endDate",
    //   width: "20%",
    // },
    {
      title: "operation",
      dataIndex: "operation",
      render: (_, record) => {
        const editable = isEditing(record);
        return editable ? (
          <span>
            <Typography.Link
              onClick={() => save(record.key, record)}
              style={{
                marginRight: 8,
              }}
            >
              Save
            </Typography.Link>
            <Popconfirm title="Sure to cancel?" onConfirm={cancel}>
              <a>Cancel</a>
            </Popconfirm>
          </span>
        ) : (
          <Typography.Link
            disabled={editingKey !== ""}
            onClick={() =>  props.isAdminEdit &&edit(record)}
          >
            Edit
          </Typography.Link>
        );
      },
    },
  ];
  useEffect(() => {
    createData();
  }, [props.propzApproved]);

  const createData = () => {
    console.log(props.propzApproved?.personnelCosts);
    const ongoingProjectsData = props.propzApproved?.personnelCosts?.map(
      (e, idx) => ({
        key: idx,
        id: idx,
        deptBudgetId: e.deptBudgetId,
        administrativeCode: e.administrativeCode,
        gl: e.gl,
        noOfStaff: e.noOfStaff,
        endDate: moment("2023-10-11T17:08:24.133").format("ll"),
        proposedExpense: e.proposedExpense,
        economicCodeMain: e.economicCodeMain,
        economicCodeDetails: e.economicCodeDetails,
        functionCodeMain: e.functionCodeMain,
        functionCodeDetails: e.functionCodeDetails,
        locationCode: e.locationCode,
        fundCodeMain: e.fundCodeMain,
        programmeCode: e.programmeCode,
        prevNoOfStaff: e.prevNoOfStaff,
        prevApprovedExpense: e.prevApprovedExpense,
        currentExpenseAsAt: e.currentExpenseAsAt,
        previousExpense: e.previousExpense,
        availableBudget: e.proposedExpense,
        editedAmount: +e?.editedAmount,

        ...e,
      })
    );

    console.log(ongoingProjectsData);
    setData(ongoingProjectsData);
  };

  //  console.log(props.propzApproved);
  const columns = [
    {
      title: "Project",
      dataIndex: "name",
      width: "25%",
      editable: true,
    },
    {
      title: "age",
      dataIndex: "age",
      width: "15%",
      editable: true,
    },
    {
      title: "address",
      dataIndex: "address",
      width: "40%",
      editable: true,
    },
    {
      title: "operation",
      dataIndex: "operation",
      render: (_, record) => {
        const editable = isEditing(record);
        return editable ? (
          <span>
            <Typography.Link
              onClick={() => save(record.key)}
              style={{
                marginRight: 8,
              }}
            >
              Save
            </Typography.Link>
            <Popconfirm title="Sure to cancel?" onConfirm={cancel}>
              <a>Cancel</a>
            </Popconfirm>
          </span>
        ) : (
          <Typography.Link
            disabled={editingKey !== ""}
            // onClick={() => edit(record)}
          >
            Edit
          </Typography.Link>
        );
      },
    },
  ];
  const mergedColumns = personnelCostsColumn
  ?.map((item) => {
    if (!props.switching && item?.dataIndex == "proposedExpense") {
      console.log({ ...item, editable: true })
      return { ...item, editable: true };
    } else return item;
  })
  ?.map((col) => {
    if (!col.editable) {
      return col;
    }
    return {
      ...col,
      onCell: (record) => ({
        record,
        inputType: col.dataIndex === "age" ? "number" : "text",
        dataIndex: col.dataIndex,
        title: col.title,
        editing: isEditing(record),
      }),
    };
  });
  return (
    <Form form={form} component={false}>
      <Table
        components={{
          body: {
            cell: EditableCell,
          },
        }}
        bordered
        dataSource={data}
        columns={mergedColumns}
        rowClassName="editable-row"
        pagination={{
          onChange: cancel,
        }}
      />
    </Form>
  );
};
export default EditableTableP;
