// import { Button, Checkbox, Form, Input } from "antd";
// import React from "react";

// const KPI = () => {
//   const onFinish = (values) => {
//     console.log("Success:", values);
//   };

//   const onFinishFailed = (errorInfo) => {
//     console.log("Failed:", errorInfo);
//   };

//   return (
//     <Form
//       name="basic"
//       labelCol={{
//         span: 8,
//       }}
//       wrapperCol={{
//         span: 16,
//       }}
//       initialValues={{
//         remember: true,
//       }}
//       onFinish={onFinish}
//       onFinishFailed={onFinishFailed}
//       autoComplete="off"
//     >
//       <Form.Item
//         label="Username"
//         name="username"
//         rules={[
//           {
//             required: true,
//             message: "Please input your username!",
//           },
//         ]}
//       >
//         <Input />
//       </Form.Item>

//       <Form.Item
//         label="Password"
//         name="password"
//         rules={[
//           {
//             required: true,
//             message: "Please input your password!",
//           },
//         ]}
//       >
//         <Input.Password />
//       </Form.Item>

//       <Form.Item
//         name="remember"
//         valuePropName="checked"
//         wrapperCol={{
//           offset: 8,
//           span: 16,
//         }}
//       >
//         <Checkbox>Remember me</Checkbox>
//       </Form.Item>

//       <Form.Item
//         wrapperCol={{
//           offset: 8,
//           span: 16,
//         }}
//       >
//         <Button type="primary" htmlType="submit">
//           Submit
//         </Button>
//       </Form.Item>
//     </Form>
//   )
// } export default KPI

import React, { useState, useEffect, useContext } from "react";
import {
  Button,
  Select,
  Form,
  Typography,
  Input,
  // Input,
  Table,
  Popconfirm,
} from "antd";
import { get, patch, post, del } from "../../api-services/fetch";
import axios from "axios";
import notification from "../../utility/notification";
import Cookies from "js-cookie";
import moment from "moment";
// import { CKEditor } from '@ckeditor/ckeditor5-react';
// import ClassicEditor from '@ckeditor/ckeditor5-build-classic';

// import ViewGovTable from './ViewGovTable';
import { decodeToken } from "../../utility/auth";
import EconomicSegment from "./EconomicSegment";
import EconomicType from "./EconomicType";
import EconomicSubType from "./EconomicSubType";
import EconomicSubSubType from "./EconomicSubSubType";
import EconomicItem from "./EconomicItem";
// import SearchDocument from '../layouts/SearchDocument';
const BASE_API_URL = "https://edogoverp.com/budget/api/v1";
const BASE_API_URLs = "https://edogoverp.com/newbudgetapi/api/v1";
// const BASE_API_URL = 'http://localhost:5000/api/v1';

// allMdaDrop: '/api/employee/mdas',
const originData = [];
for (let i = 0; i < 100; i++) {
  originData.push({
    key: i.toString(),
    name: `Edrward ${i}`,
    age: 32,
    address: `London Park no. ${i}`,
  });
}
const { Text } = Typography;
const { Option } = Select;

const { TextArea } = Input;
const EditableCell = ({
  editing,
  dataIndex,
  title,
  inputType,
  record,
  index,
  children,
  ...restProps
}) => {
  const inputNode =
    inputType === "number" ? (
      <Input
        formatter={(value) =>
          `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
        }
        parser={(value) => value?.replace(/\$\s?|(,*)/g, "")}
      />
    ) : (
      <TextArea rows={4} />
    );
  return (
    <td {...restProps}>
      {editing ? (
        <Form.Item
          name={dataIndex}
          style={{
            margin: 0,
          }}
          rules={[
            {
              required: true,
              message: `Please Input ${title}!`,
            },
          ]}
        >
          {inputNode}
        </Form.Item>
      ) : (
        children
      )}
    </td>
  );
};
const OverHeadCostMonitor = (props) => {
  const [commentsBudget, setCommentsBudget] = useState([]);
  const [economicSegment, setEconomicSegment] = useState([]);

  const [economicSegmentId, setEconomicSegmentId] = useState();
  const [economicTypeId, setEconomicTypeId] = useState();
  const [economicSubTypeId, setEconomicSubTypeId] = useState();
  const [economicItemId, setEconomicItemId] = useState();
  const [economicSubSubTypeId, setEconomicSubSubTypeId] = useState();

  const [tableKey, setTableKey] = useState(1);

  const [mdaId, setMdaId] = useState([]);

  const [userId, setUserId] = useState([]);

  const [data, setData] = useState([]);
  const [ongoingP, setOngoingP] = useState(originData);
  const [editingKey, setEditingKey] = useState("");
  const isEditing = (record) => record.key === editingKey;

  const { Option } = Select;

  const edit = (record) => {
    form.setFieldsValue({
      // label: "",
      // amount: "",
      // comment: "",

      ...record,
    });
    setEditingKey(record.key);
  };
  const cancel = () => {
    setEditingKey("");
  };
  const save = async (key) => {
    try {
      const row = await form.validateFields();
      const newData = [...data];
      const index = newData.findIndex((item) => key === item.key);
      if (index > -1) {
        const item = newData[index];
        newData.splice(index, 1, {
          ...item,
          ...row,
        });
        // props.setEnvelopeData(newData);
        console.log(newData);

        setData(newData);
        setEditingKey("");
      } else {
        newData.push(row);
        setData(newData);
        setEditingKey("");
        console.log(newData);
      }
    } catch (errInfo) {
      console.log("Validate Failed:", errInfo);
    }
  };
  const todelete = (key) => {
    const newData = [...data];

    const remAfterdelete = newData.filter((item) => key !== item.key);
    console.log(remAfterdelete);

    setData(remAfterdelete);
  };

  useEffect(() => {
    //  toGetAdministrativeSegment();
    toGetEconimicItems();

    //  const [mdaId, setMdaId] = useState([]);
    //  const [userId, setUserId] = useState([]);
    console.log(+Cookies.get("requestsMdaId"));

    setMdaId(+Cookies.get("requestsMdaId"));

    console.log(Cookies.get("signature"));
    console.log(Cookies.get("firstName"));
    console.log(Cookies.get("lastName"));
    // setsignature(Cookies.get("signature"));

    // let ui = localStorage.getItem("ui");
    // ui = decodeToken("ui").ui;
    // console.log(ui);
    // setUserId(ui);
  }, []);

  const toGetEconimicItems = () => {
    axios
      .get(`${BASE_API_URLs}/AdministrativeSegment`)
      // .post(`${BASE_API_URL}/budgetcodification`, { ...data })

      .then((res) => {
        //  setIsLoading(false);

        console.log(res);
        setEconomicSegment(res.data);
        //  notification({
        //    title: "Successful",
        //    message: "Projects have been successfully sent",
        //    type: "success",
        //  });
      })
      .catch((err) => {
        console.log(err?.response);
        //  setIsLoading(false);
        //  notification({
        //    title: "Sending Failed",
        //    message: `{err?.response?.data}`,
        //    type: "danger",
        //  });
      });
  };

  const opt = economicSegment.map((e) => (
    <Option key={e.administrativeSegmentName} value={e.code}>
      {e.code}
    </Option>
  ));

  const reccurentRevenueColumn = [
    {
      title: "Economic Code",
      dataIndex: "economicCode",
      key: "name",
      render: (text) => <a>{text}</a>,
    },
    {
      title: "details",
      dataIndex: "details",
      key: "details",
    },
    // {
    //   title: "frequency Per Month",
    //   dataIndex: "frequencyPerMonth",
    //   key: "frequencyPerMonth",
    //   //  width: "20%",
    // },
    // {
    //   title: "Rate",
    //   dataIndex: "rate",
    //   key: "rate",
    //   //  width: "20%",
    // },

    {
      title: "units ",
      dataIndex: "units",
      editable: true,
      key: "units",
      //  width: "20%",
    },

    // {
    //   title: "targets",
    //   dataIndex: "targets",
    //   key: "targets",
    //   //  width: "20%",
    // },
    {
      title: "Rate",
      dataIndex: "costPerMonth",
      editable: true,
      key: "costPerMonth",
      //   width: "20%",
    },
    {
      title: "Amount",
      dataIndex: "costPerAnnum",
      editable: true,
      key: "costPerAnnum",
      //   width: "20%",
    },
    // {
    //   title: " Remarks",
    //   dataIndex: "remarks",
    //   editable: true,
    //   key: "remarks",
    //   //   width: "20%",
    // },
    {
      title: "operation",
      dataIndex: "operation",
      render: (_, record) => {
        const editable = isEditing(record);
        return editable ? (
          <span>
            <Typography.Link
              onClick={() => save(record.key)}
              style={{
                marginRight: 8,
              }}
            >
              Save
            </Typography.Link>
            <Popconfirm title="Sure to cancel?" onConfirm={cancel}>
              <a>Cancel</a>
            </Popconfirm>
          </span>
        ) : (
          <div className="flex justify-between">
            <div style={{ marginRight: "10px" }}>
              <Typography.Link
                className="mr-10"
                disabled={editingKey !== ""}
                onClick={() => edit(record)}
              >
                Edit
              </Typography.Link>
            </div>

            <Typography.Link
              disabled={editingKey !== ""}
              onClick={() => todelete(record.key)}
            >
              Delete
            </Typography.Link>
          </div>
        );
      },
    },
  ];

  const recurrentRevenueData = commentsBudget?.map((e) => ({
    // id: "3fa85f64-5717-4562-b3fc-2c963f66afa6",
    dateCreated: "2023-10-23T12:23:22.628Z",
    enteredBy: "string",
    dateUpdated: "2023-10-23T12:23:22.628Z",
    updatedBy: "string",
    isDelete: false,
    dateDeleted: "2023-10-23T12:23:22.628Z",
    // deptBudgetId: "3fa85f64-5717-4562-b3fc-2c963f66afa6",
    economicCode: e.economicCode,
    details: e.details,
    frequencyPerMonth: e.frequencyPerMonth,
    units: e.units,
    costPerUnit: e.costPerUnit,
    costPerMonth: e.costPerMonth,
    costPerAnnum: e.costPerAnnum,
    remarks: e.remarks,
  }));

  const onFinish = (values) => {
    let payload = {
      key: tableKey,
      // id: "3fa85f64-5717-4562-b3fc-2c963f66afa6",
      dateCreated: "2023-10-23T12:23:22.628Z",
      enteredBy: "string",
      dateUpdated: "2023-10-23T12:23:22.628Z",
      updatedBy: "string",
      isDelete: false,
      dateDeleted: "2023-10-23T12:23:22.628Z",
      // deptBudgetId: "3fa85f64-5717-4562-b3fc-2c963f66afa6",
      economicCode: values.economicCode,
      details: values.details,
      frequencyPerMonth: +values.frequencyPerMonth || 0,
      units: +values.units || 0,
      costPerUnit: +values.costPerUnit || 0,
      costPerMonth: +values.costPerMonth || 0,
      costPerAnnum: +values.costPerAnnum || 0,
      remarks: values.remarks,
    };

    setTableKey((prev) => prev + 1);

    let payload2 = {
      // id: "3fa85f64-5717-4562-b3fc-2c963f66afa6",
      dateCreated: "2023-12-29T06:14:25.566Z",
      enteredBy: "string",
      dateUpdated: "2023-12-29T06:14:25.566Z",
      updatedBy: "string",
      isDelete: true,
      dateDeleted: "2023-12-29T06:14:25.566Z",
      // deptBudgetId: "3fa85f64-5717-4562-b3fc-2c963f66afa6",
      economicCode: values.economicCode.value,
      details: values.details,

      rate: +values.costPerUnit,
      units: +values.units,
      amount: +values.costPerAnnum,
    };
    console.log("Success:", values);

    setData([...data, payload]);
    // setCommentsBudget([...commentsBudget, payload]);

    props.overHead(payload);
    onReset();
  };

  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };

  //  "deptBudgetId": "3fa85f64-5717-4562-b3fc-2c963f66afa6",
  // "project": "string",
  // "status": "string",
  // "location": "string",
  // "startDate": "2023-10-11T17:08:24.133Z",
  // "amountSpent": 0,
  // "conclusionEstimate": 0,
  // "completionTime": 0,
  // "pId": 0,
  // "mdaId": 0
  const onReset = () => {
    form.resetFields();
  };

  const mergedColumns = reccurentRevenueColumn.map((col) => {
    if (!col.editable) {
      return col;
    }
    return {
      ...col,
      onCell: (record) => ({
        record,
        inputType: col.dataIndex === "comment" ? "text" : "number",
        dataIndex: col.dataIndex,
        title: col.title,
        editing: isEditing(record),
      }),
    };
  });

  const collectEconomicSegmentId = (id) => {
    form.setFieldsValue({ economicCode: id.key });
    setEconomicSegmentId(id.value);
  };
  const collectEconomicTypeId = (id) => {
    form.setFieldsValue({ economicCode: id.key });
    setEconomicTypeId(id.value);
  };
  const collectEconomicSubTypeId = (id) => {
    form.setFieldsValue({ economicCode: id.key });
    setEconomicSubTypeId(id.value);
  };
  const collectEconomicSubSubTypeId = (id) => {
    form.setFieldsValue({ economicCode: id.key });
    setEconomicSubSubTypeId(id.value);
  };
  const collectEconomicItem = (id) => {
    form.setFieldsValue({ economicCode: id.key });
    setEconomicItemId(id.value);
  };

  const [form] = Form.useForm();
  return (
    <div className="w-100">
      <h2>Recurrent Revenue Monitor</h2>

      <div
        className="w-full flex mb-10"
        style={{ gap: 10, marginBottom: "10px" }}
      >
        <EconomicSegment
          collectEconomicSegmentId={collectEconomicSegmentId}
          prefix={props.filter}
        />
        <EconomicType
          collectEconomicTypeId={collectEconomicTypeId}
          prefix={props.filter}
          id={economicSegmentId}
        />
        <EconomicSubType
          collectEconomicSubTypeId={collectEconomicSubTypeId}
          prefix={props.filter}
          id={economicTypeId}
        />
        <EconomicSubSubType
          collectEconomicSubSubTypeId={collectEconomicSubSubTypeId}
          prefix={props.filter}
          id={economicSubTypeId}
        />
        <EconomicItem
          collectEconomicItem={collectEconomicItem}
          prefix={props.filter}
          id={economicSubSubTypeId}
        />
      </div>

      <Form
        layout="vertical"
        form={form}
        // labelCol={{
        //   span: 8,
        // }}
        // wrapperCol={{
        //   span: 16,
        // }}
        // initialValues={{
        //   remember: true,
        // }}ber: true,
        // }}
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
        autoComplete="off"
      >
        {/* <Form.Item
          label="Economic Code"
          name="economicCode"
          rules={[
            {
              required: true,
              message: "Please input your username!",
            },
          ]}
        >
          <Input />
        </Form.Item> */}

        <div class="flex-between">
          <div className="w-100 m-r-10">
            <Form.Item label="Economic Code" name="economicCode">
              {/* <Select
                labelInValue
                showSearch
                style={{ width: "100%" }}
                placeholder="Search to Select"
                optionFilterProp="children"
                filterOption={(input, option) =>
                  option?.children
                    ?.toLowerCase()
                    ?.indexOf(input?.toLowerCase()) >= 0
                }
                filterSort={(optionA, optionB) =>
                  optionA?.children
                    ?.toLowerCase()
                    ?.localeCompare(optionB?.children?.toLowerCase())
                }
                // style={{ width: "100%" }}
                onChange={(e) => {
                  console.log(e);
                  form.setFieldsValue({
                    details: e.key,
                  });
                }}
                // defaultValue="lucy"
                // onChange={handleChange}
              >
                {opt}
              </Select> */}
              <Input readOnly />
            </Form.Item>
            <Form.Item label="details" name="details">
              <Input />
            </Form.Item>
            {/* <Form.Item label="frequency Per Month" name="frequencyPerMonth">
              <Input style={{ width: "100%" }} />
            </Form.Item> */}
            <Form.Item label="units" name="units">
              <Input style={{ width: "100%" }} />
            </Form.Item>
          </div>
          <div className="w-100 m-r-10">
            {/* <Form.Item label="cost Per Unit" name="costPerUnit">
              <Input style={{ width: "100%" }} />
            </Form.Item> */}
            <Form.Item label="Amount" name="costPerAnnum">
              <Input
                formatter={(value) =>
                  `₦ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                }
                parser={(value) => value?.replace(/\₦\s?|(,*)/g, "")}
                style={{ width: "100%" }}
              />
            </Form.Item>
          </div>
          <div className="w-100 m-r-10">
            <Form.Item label="Rate" name="costPerMonth">
              <Input
                parser={(value) => value?.replace(/\$\s?|(,*)/g, "")}
                style={{ width: "100%" }}
              />
            </Form.Item>
            {/* <Form.Item label="possible Impacts" name="possibleImpacts">
              <Input style={{ width: "100%" }} />
            </Form.Item> */}

            {/* <Form.Item label="ranking" name="ranking">
              <Input style={{ width: "100%" }} />
            </Form.Item> */}
            {/* <Form.Item label=" remarks" name="remarks">
              <Input style={{ width: "100%" }} />
            </Form.Item> */}
          </div>
        </div>
        <Form.Item
        //   wrapperCol={{
        //     offset: 8,
        //     span: 16,
        //   }}
        >
          <Button
            style={{ backgroundColor: "#1d811c" }}
            type="primary"
            htmlType="submit"
          >
            Save
          </Button>
        </Form.Item>
        <div style={{ overflowX: "scroll" }}>
          <div style={{ width: "120%" }}>
            <Table
              components={{
                body: {
                  cell: EditableCell,
                },
              }}
              pagination={{
                onChange: cancel,
              }}
              columns={mergedColumns}
              dataSource={data}
            />
          </div>
        </div>
      </Form>
    </div>
  );
};

export default OverHeadCostMonitor;
