import React, { useState, useEffect, useContext } from "react";
import {
  Table,
  Button,
  Input,
  Collapse,
  Select,
  Popconfirm,
  Form,
  Typography,
  Space,
} from "antd";
import { get, patch, post, put } from "../../api-services/fetch";
import axios from "axios";
import { SettingOutlined } from "@ant-design/icons";
import notification from "../../utility/notification";
import { SwapTableContext } from "../Contexts/SwapTableContext";
import BudgetInvitationTable from "./BudgetInvitationTable";
// import { PDFExport } from "@progress/kendo-react-pdf";
// import parse from 'html-react-parser';
import Cookies from "js-cookie";

import EdsgLogo from "../../assets/images/edo-logo-250.png";
import Festac from "../../assets/images/Festac-logo.jpg";
import { BrowserRouter, useLocation, Link } from "react-router-dom";
import { decodeToken } from "../../utility/auth";
import { CloseOutlined, SearchOutlined } from "@ant-design/icons";
import {
  CompareArrowsOutlined,
  ContactSupportOutlined,
} from "@material-ui/icons";
// import ApproverTable from '../layouts/ApproverTable';

const BASE_API_URL = "https://edogoverp.com/budget/api/v1";

const ref = React.createRef();

const InitiatorsReview = (prop) => {
  const pdfExportComponent = React.useRef(null);

  // const { handleImage, imageHolder, mFilesArray, handleMfilesArray, holdMfilesArray

  // } = useContext(SwapTableContext)
  const location = useLocation();
  const currentPath = location.pathname;

  const { TextArea } = Input;
  const { Option } = Select;
  const [preview, setPreview] = useState(false);
  // const [Approvers, setApprovers] = useState([])
  const [employee, setEmployee] = useState([]);
  const [subject, setSubject] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [tableDetails, settableDetails] = useState();
  const [assigneeId, setAssigneeId] = useState([]);
  const [envelope, setEnvelope] = useState([]);
  const [submits, setSubmit] = useState(false);
  const [viewCircular, setViewCircular] = useState(false);
  const [acceptedBudgetEnvelope, setAcceptedBudgetEnvelope] = useState();
  const [
    acceptedAssignedToEnterDetailsArray,
    setAcceptedAssignedToEnterDetailsArray,
  ] = useState([]);
  const [acceptedBudgetEnvelopeArray, setAcceptedBudgetEnvelopeArray] =
    useState([]);
  const [listOfMdas, setListOfMdas] = useState([]);
  const [saves, setSaves] = useState(false);
  const [show, setShow] = useState(false);
  const [approversComments, setApproversComments] = useState("");
  const [assignersComments, setAssignersComments] = useState("");
  const [hideApprovers, setHideApprovers] = useState(false);
  const [hideAction, setHideAction] = useState(true);
  const [hideAssign, setHideAssign] = useState(true);
  const [getRepliez, setGetRepliez] = useState([]);
  const [costImplication, setCostImplication] = useState();
  const [remove, setRemove] = useState(false);
  const [response, setResponse] = useState("");

  const [hideForSubmit, setHideForSubmit] = useState(false);
  const [pendingAssignedEnvelopeObj, setPendingAssignedEnvelopeObj] = useState(
    {}
  );
  const [pendingApprovals, setPendingApprovals] = useState([]);
  const [readRepliesToggle, setReadRepliesToggle] = useState([]);
  const [pdfName, setPdfName] = useState("");
  const [budPeriod, setBudgetPeriod] = useState([]);
  const [current, setCurrent] = useState();

  const [form] = Form.useForm();
  const [form2] = Form.useForm();
  const [form3] = Form.useForm();

  const { history } = prop;
  // const [form2] = Form.useForm();

  const { Panel } = Collapse;
  const BASE_API_URL = "https://edogoverp.com/budget/api/v1";
  const BASE_API_URLs = "https://edogoverp.com/newbudgetapi/api/v1";

  const genExtra = () => (
    <Link
      className="p-3"
      style={{ backgroundColor: "#1C811C", color: "white" }}
      to="/budget/budget-item-setup"
    >
      Go to Budget Set-Up
    </Link>
    // <SettingOutlined
    //   onClick={(event) => {
    //     // If you don't want click extra trigger collapse, you can prevent this:
    //     event.stopPropagation();
    //   }}
    // />
  );
  useEffect(() => {
    // onReset()
    console.log(prop?.contents?.approvers);
    getBudgetPeriod();
    toGetEnvelope();
    setPdfName(subject);
    getEmployeeList();
    getMdas();
    console.log(prop.contents);
    console.log(prop.payload);
    // let ui = localStorage.getItem("ui");
    // ui = decodeToken("ui").ui;
    // console.log(ui);
    // setuserId(ui);
  }, [
    prop?.contents?.title,
    prop?.contents?.id,
    prop.preview,
    prop?.previewValues,
    prop.payloads,
    subject,
  ]);

  function numberWithCommas(x) {
    return x?.toString()?.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  }

  console.log(prop?.contents?.memoRequestDocuments);

  console.log(currentPath);

  const getBudgetPeriod = async () => {
    // axios
    //   .get("https://edogoverp.com/newbudgetapi/api/Settings")

    const res = await get({ endpoint: "/api/Settings", auth: true });
    if (res.status == 200) {
      console.log(res.data.find((e) => e.isCurrent));
      setBudgetPeriod(res.data);

      getPendingAssignedEnvelope(res.data.find((e) => e.isCurrent));
      getAcceptedBudgetEnvelope(res.data.find((e) => e.isCurrent));
      getAcceptedInitiatorAssignment(res.data.find((e) => e.isCurrent));

      // this.setState({
      //     allBudgetCodification: response.data,
      //     loader: false,
      // });
    } else {
      // this.setState({
      //     message: nullcheck(err.response) == "" ? "server error" : err.response.data,
      //     loader: false,
      // });
    }
  };
  const getAcceptedBudgetEnvelope = async (budgetPeriod) => {
    const res = await get({
      endpoint: "/api/BudgetEnvelope/GetAcceptedBudgetItemInitiatorMemo",
      auth: true,
      // body: payload,
    });
    setAcceptedBudgetEnvelope(
      res?.data?.find((e) => e?.budgetPeriodId == budgetPeriod?.id)
    );
    setAcceptedBudgetEnvelopeArray(res?.data);

    // console.log(res.data);
  };

  const getAcceptedInitiatorAssignment = async (budgetPeriod) => {
    const res = await get({
      endpoint: "/api/BudgetEnvelope/GetAssignedBudgetItemInitiatorMemo",
      auth: true,
      // body: payload,
    });
    // setAcceptedBudgetEnvelope(
    //   res?.data?.find((e) => e?.budgetPeriodId == budgetPeriod?.id)
    // );
    setAcceptedAssignedToEnterDetailsArray(res?.data);

    // console.log(res.data);
  };
  const getPendingAssignedEnvelope = async (budgetPeriod) => {
    const res = await get({ endpoint: "pendingfromdirectoritem", auth: true });
    console.log(res);
    console.log(budgetPeriod);

    if (res && res.status === 200) {
      const { data } = res;
      console.log(data);
      if (res.status === 200) {
        console.log(data.find((e) => e?.budgetPeriodId == budgetPeriod?.id));
        let kk = data.find((e) => e?.budgetPeriodId == budgetPeriod?.id);

        console.log(kk);

        form3.setFieldsValue({
          to: kk?.assignee,
          from: "PS Budget",
          details: kk?.details,
          subject: kk?.title,
        });
        setPendingAssignedEnvelopeObj(
          data.find((e) => e?.budgetPeriodId == budgetPeriod?.id)
        );
        console.log(data[0]);
      } else {
        notification({
          title: "Error Getting List of Employees",
          message: data.message,
          type: "danger",
        });
      }
    } else {
      notification({
        title: "Network",
        message: "Something has gone wrong. Please, try again.",
        type: "danger",
      });
    }
  };
  // /api/BudgetEnvelope/ActionBudgetItemAssignment
  const toPostAcceptEnvelopeAssignment = async (status) => {
    setIsLoading(true);

    let payload = {
      id: pendingAssignedEnvelopeObj?.id,
      budgetPeriodId: pendingAssignedEnvelopeObj?.budgetPeriodId,
      envelopeId: pendingAssignedEnvelopeObj?.id,
      comment: response,
      status: status,
    };

    const res = await put({
      endpoint: "/api/BudgetEnvelope/ActionBudgetItemAssignment",
      auth: true,
      body: payload,
    });

    if (res && res.status == 200) {
      setIsLoading(false);
      notification({
        title: "Success",
        message: "Successfully Accepted",
        type: "success",
      });
      getBudgetPeriod();
    }
  };

  const onReset = (l) => {
    form.resetFields();
  };

  const toGet = async (payload) => {
    console.log(payload?.id);
    // setIsLoading(true)

    // axios
    //   .get("https://edogoverp.com/newbudgetapi/api/BudgetInvitation")
    //   // .post(`${BASE_API_URL}/budgetcodification`, { ...data })
    //   // axios.get(`${BASE_API_URLs}/BudgetInvitation/GetPendingBudgetInvitationsByMda?mdaId=${11}`, )
    //   //

    //   budgetperiod

    const res = await get({
      endpoint: "/api/BudgetInvitation",
      auth: true,
      // body: payload,
    });

    if (res && res.status == 200) {
      // setIsLoading(false)
      console.log(res.data);

      // setBudgetPeriod(res.data);

      // console.log(current);
      // console.log(budPeriod);
      let k = res.data.find(
        (e) => payload?.projectBatchId == e?.budgetPeriodId
      );

      settableDetails(k);
      //  const l = res.data.find((e) => e.isCurrent == true);

      // setGetRepliez(k);
      console.log(k);
      setBudgetPeriod(k?.budgetPeriodId);

      form.setFieldsValue({ from: "Budget Office" });
      form.setFieldsValue({ to: "All MDAs" });
      form.setFieldsValue({
        subject: k?.subject,
      });
      form.setFieldsValue({
        details: k?.details,
      });

      // console.log(res)
      //     notification({
      //       title: 'Successful',
      //       message: 'Projects have been successfully sent',
      //       type: 'success'
      // });
    } else {
      console.log(res);

      notification({
        title: "Sending Failed",
        message: `${res?.data}`,
        type: "danger",
      });
    }
  };

  const toAssign = async (payload) => {
    // settoClearTable(true);
    setIsLoading(true);
    //
    console.log(payload);
    const res = await post({
      endpoint: "assigntodirectorItem",
      auth: true,
      body: payload,
    });

    if (res && res.status == 200) {
      notification({
        title: "Sent",
        message: `${res?.data?.message || "Invitation Assignment Successful"}`,
        type: "success",
      });
      setIsLoading(true);
    } else {
      setIsLoading(true);
    }

    console.log(res);
  };

  const onFinish2 = (values) => {
    // setIsLoading(true);
    console.log(values);

    let assigner = {
      // id: "",
      dateCreated: "2024-10-15T16:04:07.478Z",
      enteredBy: "",
      dateUpdated: "2024-10-15T16:04:07.478Z",
      updatedBy: "",
      isDelete: false,
      dateDeleted: "2024-10-15T16:04:07.478Z",
      budgetPeriodId: budPeriod,
      title: values.subject,
      details: values.details,
      assignee: values.to.value,
      status: 3,
    };

    toAssign(assigner);
  };
  // const getMdas = () => {
  //   axios
  //     .get(`${BASE_API_URL}/Mda`)
  //     .then((response) => {
  //       console.log(response);
  //       setListOfMdas(response.data);

  //       // this.setState({
  //       //     allBudgetCodification: response.data,
  //       //     loader: false,
  //       // });
  //     })
  //     .catch((err) => {
  //       // this.setState({
  //       //     message: nullcheck(err.response) == "" ? "server error" : err.response.data,
  //       //     loader: false,
  //       // });
  //     });
  // };
  const getMdas = async () => {
    // axios
    //   .get(`${BASE_API_URLs}v1/Mda`)

    const res = await get({
      endpoint: `/api/v1/mda`,
      auth: true,
      // body: payload,
    });
    if (res && res.status == 200) {
      console.log(res);
      setListOfMdas(res.data);

      // this.setState({
      //     allBudgetCodification: response.data,
      //     loader: false,
      // });
    } else {
      // this.setState({
      //     message: nullcheck(err.response) == "" ? "server error" : err.response.data,
      //     loader: false,
      // });
    }
  };

  const toGetEnvelope = async () => {
    // console.log(payload.id);
    // setIsLoading(true)

    // axios
    //   .get(
    //     `https://edogoverp.com/newbudgetapi/api/BudgetEnvelope/GetBudgetEnvelopeByMda?mdaId=${+Cookies.get(
    //       "mdaId"
    //     )}`
    //   )
    //   .then((res) =>
    const res = await get({
      endpoint: `/api/BudgetEnvelope/GetBudgetEnvelopeByMdaPS?mdaId=${+Cookies.get(
        "mdaId"
      )}`,
      auth: true,
      // body: payload,
    });

    if (res && res.status == 200) {
      console.log(res?.data.projectBatchId);

      setEnvelope(res.data);
      toGet(res.data[0]);
    } else {
      console.log(res);
    }
  };

  const onFinish = (values) => {};

  const toRemove = () => {
    setRemove(true);
  };
  const getEmployeeList = async () => {
    const res = await get({
      endpoint: `employee`,
      auth: true,
      pQuery: { q: "a" },
    });
    console.log(res);

    if (res && res.status === 200) {
      const { data } = res;
      if (res.status === 200) {
        console.log(data);
        setEmployee(data);
      } else {
        notification({
          title: "Error Getting List of Employees",
          message: data.message,
          type: "danger",
        });
      }
    } else {
      notification({
        title: "Network",
        message: "Something has gone wrong. Please, try again.",
        type: "danger",
      });
    }
  };

  const handleEnployeeSearch = async (text) => {
    const res = await get({
      endpoint: "employee",
      auth: true,
      pQuery: { q: text || "e" },
    });
    console.log(res);

    if (res && res.status === 200) {
      const { data } = res;
      if (
        data
        // data.code === 1
      ) {
        console.log(data);
        setEmployee(data);
      } else {
        // notification({
        //   title: "Error Getting List of Employees",
        //   message: data.message,
        //   type: "danger",
        // });
      }
    } else {
      // notification({
      //   title: "Network",
      //   message: "Something has gone wrong. Please, try again.",
      //   type: "danger",
      // });
    }
  };
  const columnsForAccepted = [
    {
      title: "MDA",
      dataIndex: "mdaId",
      key: "title",
      render: (text, record) => listOfMdas?.find((e) => e.id == text)?.name,
    },
    {
      title: "Title",
      dataIndex: "title",
      key: "title",
    },
    {
      title: "Details",
      dataIndex: "details",
      key: "details",
    },
    {
      title: "Assignee Name",
      dataIndex: "assigneeName",
      key: "assigneeName",
    },
    {
      title: "Currently With",
      dataIndex: "currentlyWith",
      key: "currentlyWith",
    },
    {
      title: "Comment",
      dataIndex: "comment",
      key: "comment",
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      render: (_, record) =>
        record.status == 7 ? "Accepted" : record.status == 3 ? "Pending Acceptance" : "Rejected",
    },
    {
      title: "Action",
      key: "action",
      render: (_, record) => (
        <Space size="middle">
          <Button
            onClick={() => {
              Cookies.set("requestsMdaId", record?.mdaId);
              history.push("/budget/budget-item-setup");
              // genExtra()
              // alert(record.mdaId);
            }}
          >
            Set Up Budget Item
          </Button>
        </Space>
      ),
    },
  ];
  const columnsForAcceptedAssignment = [
    {
      title: "MDA",
      dataIndex: "mdaId",
      key: "title",
      render: (text, record) => listOfMdas?.find((e) => e.id == text)?.name,
    },
    {
      title: "Title",
      dataIndex: "title",
      key: "title",
    },
    {
      title: "Details",
      dataIndex: "details",
      key: "details",
    },
    {
      title: "Assignee Name",
      dataIndex: "assigneeName",
      key: "assigneeName",
    },
    {
      title: "Currently With",
      dataIndex: "currentlyWith",
      key: "currentlyWith",
    },
    {
      title: "Comment",
      dataIndex: "comment",
      key: "comment",
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      render: (_, record) =>
        record.status == 7 ? "Accepted" : record.status == 3 ? "Pending" : "Rejected",
    },
  ];
  return (
    <div className="w-100">
      <div
        className="p-10  bold-text m-b-20"
        style={{ fontSize: "20px", backgroundColor: "#D5iFFD5" }}
      >
        Budget Invitation
      </div>
      <Collapse>
        {((envelope && envelope?.length > 0) ||
          acceptedBudgetEnvelope?.status == 7) && (
          //  ||
          // Cookies.get("isBudgetPS") == "true"
          <Panel
            showArrow={false}
            header="View Budget Invitation"
            // extra={genExtra()}
          >
            <div
              className="w-100 bg-white m-t-5"
              style={{ display: "flex", justifyContent: "center" }}
            >
              <div className="bg-white" style={{ width: "70%" }}>
                {/* <div  style={{marginLLeft:'auto', justifyContent:"flex-start"}} className='mb5 w-100 flex  m-t-20'>
               <button style={{ maxWidth: '650px',marginLLeft:'auto' }} className='btn btn ' onClick={() => {
                     toRemove()
          
                   if (pdfExportComponent.current) {
                     setTimeout(() => {
                     pdfExportComponent.current.save();
                             setRemove(false);
                           }, 50);
                   }
          
          
                 }}>
                 Generate PDF
               </button>
          
             </div> */}

                <div className="flex-between flex-align p-20">
                  <div className="sidebar-header">
                    <img
                      src={EdsgLogo}
                      alt="logo"
                      className="brand"
                      width="150"
                    />
                  </div>
                  {prop?.contents ? (
                    <div style={{ textAlign: "center" }}>
                      <b>{prop?.contents?.mdaAddress?.name.toUpperCase()}</b>
                      <p className="no-margin">
                        {prop?.contents?.mdaAddress?.address}
                      </p>
                    </div>
                  ) : (
                    <div className="border"></div>
                  )}
                  <div>
                    <img src={Festac} width="40px" />
                  </div>
                </div>
                <div className=" w-100">
                  <div style={{ width: "100%" }} className=" w-60 m-r-10">
                    <div
                      style={{ fontSize: "", backgroundColor: "#D5FFD5" }}
                      className="p-10 center-text"
                    >
                      <p className="bold  "> BUDGET UPDATE</p>
                      {/* <p>
                       Memo Subject: <span className='bold-text'>{(prop?.contents?.subject || prop?.contents?.title)?.toUpperCase()}</span>
                     </p>
                     <p>
                       Memo Initiation Date: <span className='bold-text'>{prop?.contents?.createdAt ? moment(prop?.contents?.createdAt).format('LL') : ''}</span>
                     </p> */}
                    </div>
                    <Form
                      form={form}
                      onFinish={onFinish}
                      labelCol={{ span: 3 }}
                      // layout='vertical'
                      className=" w-100 m-r-10"
                      style={{
                        backgroundColor: "white",
                        padding: "20px 20px 20px 0",
                      }}
                    >
                      <Form.Item className="w-100" label="To" name="to">
                        <Input bordered={show} readOnly={!show} />
                      </Form.Item>
                      <Form.Item className="w-100" label="From" name="from">
                        <Input bordered={show} readOnly={!show} />
                      </Form.Item>
                      {prop?.contents?.approvers &&
                        prop?.contents?.approvers?.length > 1 && (
                          <Form.Item
                            className="w-100"
                            label="Through"
                            name="thru"
                            rules={[
                              {
                                required: true,
                                message: "Please input Approvers!",
                              },
                            ]}
                          >
                            <Select
                              disabled
                              readOnly
                              bordered={false}
                              showSearch
                              filterOption={(input, option) =>
                                option.children
                                  .toLowerCase()
                                  .indexOf(input.toLowerCase()) >= 0
                              }
                              filterSort={(optionA, optionB) =>
                                optionA.children
                                  .toLowerCase()
                                  .localeCompare(optionB.children.toLowerCase())
                              }
                              onSearch={(text, index) =>
                                handleEnployeeSearch(text)
                              }
                              mode="multiple"
                              labelInValue
                              allowClear
                              style={{ width: "100%", border: "" }}
                              placeholder="Please select"
                            >
                              {Array.isArray(employee) &&
                                employee?.map((obj) => (
                                  <Option value={obj?.id} key={obj?.id}>{`${
                                    obj.firstName
                                  } ${obj.lastName} ${
                                    obj.role ? obj.role : ""
                                  } ${obj.mda}`}</Option>
                                ))}
                            </Select>
                          </Form.Item>
                        )}

                      <Form.Item
                        className="w-100"
                        label="Subject"
                        name="subject"
                        rules={[
                          {
                            required: true,
                            message: "Please input Approvers!",
                          },
                        ]}
                      >
                        <Input
                          bordered={show}
                          readOnly={!show}
                          onChange={(e) => {
                            setSubject(e.target.value);
                          }}
                          style={{ width: "100%", border: "" }}
                        />
                      </Form.Item>

                      <Form.Item
                        className="w-100"
                        label="Details"
                        name="details"
                      >
                        <TextArea bordered={show} />
                      </Form.Item>

                      <BudgetInvitationTable
                        tableDetails={tableDetails}
                        listOfMdas={listOfMdas}
                        data={envelope}
                      />
                    </Form>
                  </div>
                </div>
                {/* </PDFExport> */}
              </div>
            </div>
          </Panel>
        )}

        <Panel
          showArrow={false}
          header={`${
            // Cookies.get("isBudgetPS") == "true" ||
            "Respond to"
          } Budget Item Creation Asignment`}
          // extra={genExtra()}
        >
          {envelope && envelope?.length > 0 && (
            <div
              className="w-100 bg-white m-t-5"
              style={{ display: "flex", justifyContent: "center" }}
            >
              {/* <div className="bg-white" style={{ width: "70%" }}>
               

                <div className="flex-between flex-align p-20">
                  <div className="sidebar-header">
                    <img
                      src={EdsgLogo}
                      alt="logo"
                      className="brand"
                      width="150"
                    />
                  </div>
                  {prop?.contents ? (
                    <div style={{ textAlign: "center" }}>
                      <b>{prop?.contents?.mdaAddress?.name.toUpperCase()}</b>
                      <p className="no-margin">
                        {prop?.contents?.mdaAddress?.address}
                      </p>
                    </div>
                  ) : (
                    <div className="border"></div>
                  )}
                  <div>
                    <img src={Festac} width="40px" />
                  </div>
                </div>
                <div className=" w-100">
                  <div style={{ width: "100%" }} className=" w-60 m-r-10">
                    <div
                      style={{ fontSize: "", backgroundColor: "#D5FFD5" }}
                      className="p-10 center-text"
                    >
                      <p className="bold  "> BUDGET UPDATE</p>
                     
                    </div>
                    <Form
                      form={form2}
                      onFinish={onFinish2}
                      labelCol={{ span: 3 }}
                      className=" w-100 m-r-10"
                      style={{
                        backgroundColor: "white",
                        padding: "20px 20px 20px 0",
                      }}
                    >
                      <Form.Item className="w-100" label="To" name="to">
                        <Select
                          showSearch
                          filterOption={(input, option) =>
                            option.children
                              .toLowerCase()
                              .indexOf(input.toLowerCase()) >= 0
                          }
                          filterSort={(optionA, optionB) =>
                            optionA.children
                              .toLowerCase()
                              .localeCompare(optionB.children.toLowerCase())
                          }
                          labelInValue
                          allowClear
                          style={{ width: "100%", border: "" }}
                          onSearch={(text, index) => handleEnployeeSearch(text)}
                          placeholder="Please select"
                        >
                          {Array.isArray(employee) &&
                            employee?.map((obj) => (
                              <Option value={obj?.id} key={obj?.id}>{`${
                                obj?.firstName
                              } ${obj?.lastName} (${
                                listOfMdas?.find((e) => e?.id == obj.mdaId)
                                  ?.name
                              })`}</Option>
                            ))}
                        </Select>
                      </Form.Item>

                      <Form.Item
                        className="w-100"
                        label="Subject"
                        name="subject"
                        rules={[
                          {
                            required: true,
                            message: "Please input Approvers!",
                          },
                        ]}
                      >
                        <Input
                          onChange={(e) => {
                            setSubject(e.target.value);
                          }}
                          style={{ width: "100%", border: "" }}
                        />
                      </Form.Item>
                      <Form.Item
                        className="w-100"
                        label="Budget"
                        name="ceilling"
                      >
                        <p className="m-l-20">{`₦ ${envelope.totalAmount}`}</p>
                      </Form.Item>

                      <Form.Item
                        className="w-100"
                        label="Details"
                        name="details"
                      >
                        <TextArea bordered />
                      </Form.Item>
                      <Form.Item className="flex-align flex-between p-20">
                        <Button
                          loading={isLoading}
                          block
                          className="m-r-10"
                          htmlType="submit"
                        >
                          Assign Budget Item Initiator
                        </Button>
                      </Form.Item>
                    </Form>
                  </div>
                </div>
              </div> */}
            </div>
          )}
          {pendingAssignedEnvelopeObj && (
            <div className=" w-100">
              <div style={{ width: "100%" }} className=" w-60 m-r-10">
                <div
                  style={{ fontSize: "", backgroundColor: "#D5FFD5" }}
                  className="p-10 center-text"
                >
                  <p className="bold  "> BUDGET PLANNING ASSIGNMENT RESPONSE</p>
                </div>
                <Form
                  form={form3}
                  onFinish={onFinish}
                  labelCol={{ span: 3 }}
                  // layout='vertical'
                  className=" w-100 m-r-10"
                  style={{
                    backgroundColor: "white",
                    padding: "20px 20px 20px 0",
                  }}
                >
                  {/* <Form.Item className="w-100" label="To" name="to">
                    <Input
                      bordered={false}
                      // value={pendingAssignedEnvelope?.assignee}
                      readOnly
                    />
                  </Form.Item>*/}
                  <Form.Item className="w-100" label="From" name="from">
                    <Input bordered={false} readOnly />
                  </Form.Item>
                  {/* {pendingAssignedEnvelopeObj?.details} */}
                  <Form.Item
                    className="w-100"
                    label="Subject"
                    name="subject"
                    // rules={[
                    //   {
                    //     required: true,
                    //     message: "Please input Approvers!",
                    //   },
                    // ]}
                  >
                    <Input
                      bordered={false}
                      readOnly
                      // value={pendingAssignedEnvelopeObj?.subject}
                      // onChange={(e) => {
                      //   setSubject(e.target.value);
                      // }}
                      style={{ width: "100%", border: "" }}
                    />
                  </Form.Item>

                  <Form.Item
                    className="w-100"
                    label="Details"
                    name="details"
                    // rules={[
                    //   {
                    //     required: true,
                    //     message: "Please input Approvers!",
                    //   },
                    // ]}
                  >
                    <Input
                      bordered={false}
                      readOnly
                      // value={pendingAssignedEnvelopeObj?.details}
                      // onChange={(e) => {
                      //   setSubject(e.target.value);
                      // }}
                      style={{ width: "100%", border: "" }}
                    />
                  </Form.Item>
                </Form>

                <div>
                  <label className="bold" style={{ fontSize: 20 }}>
                    Respond
                  </label>
                  <Form.Item className="w-100">
                    <TextArea onChange={(e) => setResponse(e?.target.value)} />
                  </Form.Item>
                </div>
                <div class="flex">
                  <Form.Item className="w-100">
                    <Button
                      loading={isLoading}
                      block
                      className="m-r-10"
                      // htmlType="submit"
                      onClick={() => toPostAcceptEnvelopeAssignment(7)}
                    >
                      Accept
                    </Button>

                    <Button
                      loading={isLoading}
                      block
                      className="m-r-10"
                      htmlType="submit"
                      // onClick={()=>toPostAcceptEnvelopeAssignment(3)}

                      // onClick={(text, index) => {
                      //   setSaves(true);
                      //   setSubmit(false);
                      //   setPropz("saving");
                      // }}
                    >
                      Reject
                    </Button>
                  </Form.Item>
                </div>
              </div>
            </div>
          )}
          <Table
            columns={columnsForAccepted}
            dataSource={acceptedBudgetEnvelopeArray}
          />
        </Panel>

        {acceptedAssignedToEnterDetailsArray.length > 0 && (
          <Panel
            showArrow={false}
            header="View Status of Envelopes You Assigned"
            // extra={genExtra()}
          >
            <div
              className="w-100 bg-white m-t-5"
              style={{ display: "flex", justifyContent: "center" }}
            >
              <Table
                columns={columnsForAcceptedAssignment}
                dataSource={acceptedAssignedToEnterDetailsArray}
              />
            </div>
          </Panel>
        )}
      </Collapse>
    </div>
  );
};

export default InitiatorsReview;
