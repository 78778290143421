import {
  Form,
  Input,
  InputNumber,
  Modal,
  Popconfirm,
  Table,
  Typography,
  Button,
} from "antd";
import moment from "moment";
import React, { useEffect, useState } from "react";
import AdditionTable from "./AdditionTableCapital";
import Cookies from "js-cookie";

const originData = [];
for (let i = 0; i < 100; i++) {
  originData.push({
    key: i.toString(),
    name: `Edrward ${i}`,
    age: 32,
    address: `London Park no. ${i}`,
  });
}
const EditableCell = ({
  editing,
  dataIndex,
  title,
  inputType,
  record,
  index,
  children,
  ...restProps
}) => {
  const inputNode =
    inputType === "number" ? (
      <InputNumber
        formatter={(value) =>
          `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
        }
        parser={(value) => value?.replace(/\$\s?|(,*)/g, "")}
      />
    ) : (
      <Input />
    );
  return (
    <td {...restProps}>
      {editing ? (
        <Form.Item
          name={dataIndex}
          style={{
            margin: 0,
          }}
          // rules={[
          //   {
          //     required: true,
          //     message: `Please Input ${title}!`,
          //   },
          // ]}
        >
          {inputNode}
        </Form.Item>
      ) : (
        children
      )}
    </td>
  );
};
const EditableTableCapital = (props) => {
  const [form] = Form.useForm();
  const [data, setData] = useState([]);
  const [ongoingP, setOngoingP] = useState(originData);
  const [editingKey, setEditingKey] = useState("");
  const isEditing = (record) => record.key === editingKey;
  const [showAddTable, setshowAddTable] = useState(false);

  const edit = (record) => {
    form.setFieldsValue({
      // id: "",
      // dateCreated: "",
      // enteredBy: "",
      // dateUpdated: "",
      // updatedBy: "",
      // isDelete: "",
      // dateDeleted: "",
      // deptBudgetId: "",
      // details: "",
      // stateOfCompletion: "",
      // status: "",
      // location: "",
      // currentApprovedAppropriation: "",
      // actualExpenditureAsAt: "",
      // proposedAppropriation: "",
      // targetsRemarks: "",
      // mdaId: "",

      // programmeCode: "",
      // economicMainHeadCode: "",
      // economicDetailsCode: "",
      // functionalMainHeadCode: "",
      // functionalDetailsCode: "",
      // locationCode: "",
      // fundMainCode: "",
      // fundDetailsCode: "",
      // fundMainCode2: "",
      // fundDetailsCode2: "",
      // fundMainCode3: "",
      // fundDetailsCode3: "",
      // fundAmount: "",
      // fundAmount2: "",
      // fundAmount3: "",

      ...record,
    });
    setEditingKey(record?.key);
    // console.log(record.key);
  };
  const cancel = () => {
    setEditingKey("");
  };
  const save = async (key, record) => {
    try {
      let row = await form.validateFields();

      if (+row.proposedAppropriation <= 0) {
        alert("cannot be zero or less than zero");
        return
      }
      row = {
        ...row,
        editedAmount:
          (+row.availableBudget || 0) - (+record?.proposedAppropriation || 0),
        availableBudget: +row.availableBudget,
        currentApprovedAppropriation: +row.currentApprovedAppropriation || 0,
        actualExpenditureAsAt: +row?.actualExpenditureAsAt || 0,
        proposedAppropriation: +row?.proposedAppropriation || 0,

        // ...row,
        // editedAmount: +row.availableBudget - +record?.proposedBudget,
        // currentExpenditures: +row?.currentExpenditures,
        // previousExpenditures: +row?.previousExpenditures,
      };
      const newData = [...data];
      const index = newData.findIndex((item) => key === item.key);
      if (index > -1) {
        const item = newData[index];
        newData.splice(index, 1, {
          ...item,
          ...row,
        });
        console.log(newData);
        let kkk = { ...props.propzApproved, capitalProjects: newData };
        delete kkk["budgetDeptApprovers"];
        delete kkk["approvers"];
        props.saveAndRefreshApprovedMDAs(kkk);

        setData(newData);
        setEditingKey("");
      } else {
        newData.push(row);
        setData(newData);
        setEditingKey("");
        console.log(newData);
      }
    } catch (errInfo) {
      console.log("Validate Failed:", errInfo);
    }
  };

  const handleAddReal = () => {
    const newData = {
      key: data?.length,
      // id: "",
      dateCreated: new Date(),
      enteredBy: Cookies.get("userId"),
      dateUpdated: new Date(),
      updatedBy: Cookies.get("userId"),
      isDelete: false,
      dateDeleted: new Date(),
      deptBudgetId: "3fa85f64-5717-4562-b3fc-2c963f66afa6",
      budgetPeriodId: "9f5ff7b5-a0cd-4768-9481-75d2ed785540",
      details: "",
      stateOfCompletion: "",
      status: "",
      location: "",
      currentApprovedAppropriation: 0,
      actualExpenditureAsAt: 0,
      proposedAppropriation: 0,
      targetsRemarks: "",
      mdaId: +props.propzApproved?.mdaId,
      fundAmount: 0,
      fundAmount2: 0,
      fundAmount3: 0,
      editedAmount: 0,
      availableBudget: 0,
      economicMainHeadCode: "",
      economicDetailsCode: "",
      functionalMainHeadCode: "",
      functionalDetailsCode: "",
      locationCode: "",
      fundMainCode: "",
      fundDetailsCode: "",
      fundMainCode2: "",
      fundDetailsCode2: "",
      fundMainCode3: "",
      fundDetailsCode3: "",
      programmeCode: "",

      adminCode: "",
    };
    setData([newData, ...data]);

    // edit()
    // setDataSource([...dataSource, newData]);
    // setCount(count + 1);
  };
  console.log(data);
  function numberWithCommas(x) {
    return x?.toString()?.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  }

  const ongoingProjectsColumn = [
    // {
    //   title: "Details of Expenditure",
    //   dataIndex: "details",
    //   key: "details",
    // },
    // {
    //   title: "State Of Completion",
    //   dataIndex: "stateOfCompletion",
    //   key: "stateOfCompletion",
    //   //  width: "20%",
    // },
    {
      title: "Administrative Code",
      dataIndex: "adminCode",
      editable: true,
      key: "adminCode",
      // render: (text) => <a>{numberWithCommas(+text)}</a>,
      editable: true,

      //  width: "20%",
    },
    {
      title: "Program Code",
      dataIndex: "programmeCode",
      key: "programmeCode",
      editable: true,
      //   width: "20%",
    },

    // {
    //   title: "Target Remarks",
    //   dataIndex: "targetsRemarks",
    //   key: "targetsRemarks",
    //   editable: true,
    //   //   width: "20%",
    // },

    // {
    //   title: "Economic Code",
    //   dataIndex: "economicMainHeadCode",
    //   editable: true,
    //   key: "economicMainHeadCode",
    //   render: (text) => <a>{text}</a>,
    // },
    {
      title: "Details",
      dataIndex: "details",
      key: "economicDetailsCode",
      editable: true,
      //   width: "20%",
    },
    {
      title: "Functional Code ",
      dataIndex: "functionalMainHeadCode",
      key: "functionalMainHeadCode",
      editable: true,
      //   width: "20%",
    },
    {
      title: "Current Approved Appropriation",
      dataIndex: "currentApprovedAppropriation",
      key: "currentApprovedAppropriation",
      render: (text) => <a>{numberWithCommas(+text)}</a>,
      editable: true,

      //  width: "20%",
    },

    {
      title: "Actual Expenditure At",
      dataIndex: "actualExpenditureAsAt",
      key: "actualExpenditureAsAt",
      render: (text) => <a>{numberWithCommas(+text)}</a>,
      editable: true,

      //  width: "20%",
    },
    {
      title: "Proposed Appropriation",
      dataIndex: "proposedAppropriation",
      key: "proposedAppropriation",
      render: (text) => <a>{numberWithCommas(+text)}</a>,
      editable: true,

      //   width: "20%",
    },
    {
      title: "Available Budget",
      dataIndex: "availableBudget",
      key: "availableBudget",
      render: (text) => <a>{numberWithCommas(+text)}</a>,
      editable: true,

      //   width: "20%",
    },
    {
      title: "Edited Amount",
      dataIndex: "editedAmount",
      key: "editedAmount",
      //  width: "20%",
    },
    // {
    //   title: "Functional Details Code ",
    //   dataIndex: "functionalDetailsCode",
    //   editable: true,
    //   key: "functionalDetailsCode",
    //   //   width: "20%",
    // },
    {
      title: "Location Code ",
      dataIndex: "locationCode",
      editable: true,
      key: "locationCode",
      //   width: "20%",
    },

    // {
    //   title: "Fund Main Code ",
    //   dataIndex: "fundMainCode",
    //   editable: true,
    //   key: "fundMainCode",
    //   //   width: "20%",
    // },
    // {
    //   title: "Fund Details Code ",
    //   dataIndex: "fundDetailsCode",
    //   editable: true,
    //   key: "fundDetailsCode",
    //   //   width: "20%",
    // },
    // {
    //   title: "Fund Main Code2 ",
    //   editable: true,
    //   dataIndex: "fundMainCode2",
    //   key: "fundMainCode2",
    //   //   width: "20%",
    // },
    // {
    //   title: "Fund Details Code2 ",
    //   dataIndex: "fundDetailsCode2",
    //   editable: true,
    //   key: "fundDetailsCode2",
    //   //   width: "20%",
    // },
    // {
    //   title: "Fund Main Code3 ",
    //   dataIndex: "fundMainCode3",
    //   key: "fundMainCode3",
    //   editable: true,
    //   //   width: "20%",
    // },

    // {
    //   title: "Fund Details Code3 ",
    //   dataIndex: "fundDetailsCode3",
    //   key: "fundDetailsCode3",
    //   editable: true,
    //   //   width: "20%",
    // },

    // {
    //   title: "Fund Amount ",
    //   dataIndex: "fundAmount",
    //   editable: true,
    //   key: "fundAmount",
    //   //   width: "20%",
    // },
    // {
    //   title: "Fund Amount 2",
    //   dataIndex: "fundAmount2",
    //   editable: true,
    //   key: "fundAmount2",
    //   //   width: "20%",
    // },

    // {
    //   title: "Fund Amount 3",
    //   dataIndex: "fundAmount3",
    //   editable: true,
    //   key: "fundAmount3",
    //   //
    // },

    {
      title: "operation",
      dataIndex: "operation",
      render: (_, record) => {
        const editable = isEditing(record);
        return editable ? (
          <span>
            <Typography.Link
              onClick={() => save(record.key)}
              style={{
                marginRight: 8,
              }}
            >
              Save
            </Typography.Link>
            <Popconfirm title="Sure to cancel?" onConfirm={cancel}>
              <a>Cancel</a>
            </Popconfirm>
          </span>
        ) : (
          <Typography.Link
            disabled={editingKey !== ""}
            onClick={() => props.isAdminEdit && edit(record)}
          >
            Edit
          </Typography.Link>
        );
      },
    },
  ];

  useEffect(() => {
    createData();
  }, [props.propzApproved]);

  const createData = () => {
    // console.log(props.propzApproved[0]?.ongoingProjects);
    const ongoingProjectsData = props.propzApproved?.capitalProjects?.map(
      (e, idx) => ({
        key: idx,
        // id: idx,
        // id: e.id,
        dateCreated: e.dateCreated,
        enteredBy: e.enteredBy,
        dateUpdated: e.dateUpdated,
        updatedBy: e.updatedBy,
        isDelete: e.isDelete,
        economicMainHeadCode: e?.economicMainHeadCode,
        functionalMainHeadCode: e?.functionalMainHeadCode,
        dateDeleted: e.dateDeleted,
        deptBudgetId: e.deptBudgetId,
        details: e.details,
        stateOfCompletion: e.stateOfCompletion,
        status: e.status,
        programmeCode: e.programmeCode,
        locationCode: e.locationCode,
        currentApprovedAppropriation: e.currentApprovedAppropriation,
        actualExpenditureAsAt: e.actualExpenditureAsAt,
        proposedAppropriation: e.proposedAppropriation,
        targetsRemarks: e.targetsRemarks,
        mdaId: +e.mdaId,
        availableBudget: e.proposedAppropriation,
        editedAmount: +e?.editedAmount,

        ...e,
      })
    );

    console.log(ongoingProjectsData);
    setData(ongoingProjectsData);
  };

  //  console.log(props.propzApproved);

  const mergedColumns = ongoingProjectsColumn
    ?.map((item) => {
      if (!props.switching && item?.dataIndex == "proposedAppropriation") {
        console.log({ ...item, editable: true });
        return { ...item, editable: true };
      } else return item;
    })

    ?.map((col) => {
      if (!col.editable) {
        return col;
      }
      return {
        ...col,
        onCell: (record) => ({
          record,
          inputType: col.dataIndex === "age" ? "number" : "text",
          dataIndex: col.dataIndex,
          title: col.title,
          editing: isEditing(record),
        }),
      };
    });
  const handleAdd = () => {
    // setshowAddTable((prev) => !prev);
    handleAddReal();
  };
  return (
    <Form form={form} component={false}>
      {props.isAdminEdit && (
        <Button onClick={handleAdd}>+ Add Capital Budget</Button>
      )}
      <Modal
        width={1000}
        title={`Add Capital Budget Form ${props.propzApproved.mda}`}
        visible={showAddTable}
        footer={false}
        // onOk={handleAdd}
        onCancel={handleAdd}
      >
        <AdditionTable
          refeshwithoutPost={props?.refeshwithoutPost}
          dataFromUp={props.propzApproved}
        />
      </Modal>
      <Table
        components={{
          body: {
            cell: EditableCell,
          },
        }}
        bordered
        dataSource={data}
        columns={mergedColumns}
        rowClassName="editable-row"
        pagination={{
          onChange: cancel,
        }}
      />
    </Form>
  );
};
export default EditableTableCapital;
