// import React, {useState} from 'react'
// import { Collapse, Button } from 'antd';
// import Tablez from '../layouts/Tablez';
// import BudgetDetailsSummary from '../layouts/BudgetDetailsSummary';
// import BudgetItemSetUp from './BudgetItemSetUp';

// const { Panel } = Collapse;
// const InitiateBudget = ()=>{

//     const  [show, setShow] = useState(false)

//   const callback = ()=>{
// setShow(!show)
//   }
//     return(
//         <div style={{width:'100%'}}>

// <Collapse  defaultActiveKey={['1']} onChange={callback}
// // expandIconPosition={'right'}
// >
//     <Panel
//     header={<div className=' w-100 flex-between'  >
//         <p classname='no-margin'>Budget Details</p>
//         <div classname='flex' style={{display:show?'flex':'none'}}>
//             <p classname='no-margin' >Status</p>
//         <Button >View Apporoved Roles</Button>
//         </div>
//     </div>} key="1">
//       <p><Tablez initiateBudget= {true}/></p>
//     </Panel>

//   </Collapse>,

//   {/* <BudgetItemSetUp/> */}

//         </div>
//     )
// }
// export default InitiateBudget

import React, { Component } from "react";
import { Button } from "antd";
import UploadFile from "../layouts/UploadFile";
// import {Nav} from "../Components/Nav";
// import InitiateBudgetDetailsC from "../layouts/InitiateBudgetDetailsC";
import { Input } from "antd";
import Select from "react-select";
import axios from "axios";
import { Preloader } from "../../Utils/Preloader";
import ReactNotifications from "react-notifications-component";
import ValidationMessageComponent from "../layouts/ValidationMessage";
import budgetInformationSchema from "../../Validation/ValidateBudgetInformation";
import Greeting from "../common/Greeting";
import LocationDetails from "../layouts/LocationDetails";

import cookies from "js-cookie";
// const BASE_API_URL = 'http://localhost:5000/api/v1';
const BASE_API_URL = "https://edogoverp.com/newbudgetapi/api/v1";

// const BASE_API_URL = process.env.REACT_APP_BASE_API_URL;
const BASE_MODULE_URL = process.env.REACT_APP_BASE_MODULE_URL;
// const BASE_API_URL = 'https://edogoverp.com/budget/api/v1'

export default class InitiateBudget extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showModal: false,
      allCurrencyData: [],
      allDepartmentBudget: [],
      allBudgetInformationData: [],
      departmentBudget: "",
      currency: "",
      allProgramme: [],
      programme: "",
      showApprovalModal: false,
      janVal: "0",
      febVal: "0",
      marVal: "0",
      aprVal: "0",
      mayVal: "0",
      junVal: "0",
      julVal: "0",
      augVal: "0",
      sepVal: "0",
      octVal: "0",
      novVal: "0",
      decVal: "0",
      total: 0,
      message: "",
      isalreadyadded: false,
      showNotification: false,
      notificationSuccess: false,
      notificationMessage: "Successfully saved!",
      loader: false,
      fuillName: "",
      employeeId: "",
      role: "",
      time: "",
      ministry: "",
      mdaId: 0,
      authorized: false,
      auhtorizedMdaId: 0,
      profilePicture: "",
      informations: [],
    };
  }

  handleChange = (ev) => {
    const re = /^[0-9\b]+$/;
    if (ev.target.value === "" || re.test(ev.target.value)) {
      let targetvalue = ev.target.value == "" ? "0" : ev.target.value;
      let total = this.state.total;
      let prevSate = this.state?.[ev.target.name];
      if (parseFloat(prevSate) != 0) {
        total = total - parseFloat(prevSate);
        total = total + parseFloat(targetvalue);
      } else {
        total = parseFloat(targetvalue) + total;
      }

      this.setState({
        [ev.target.name]: targetvalue,
        total: total,
      });
    } else {
      this.setState({
        [ev.target.name]: "0",
      });
    }
  };

  componentDidMount() {
    this.getCurrency();
    this.getAuthorisedUser();
  }

  getAuthorisedUser = () => {
    // const {cookies} = this.props;

    if (typeof cookies.get("userId") !== "undefined") {
      let authUser = cookies.get("userId");
      console.log(authUser);
      // let time = authUser.lastLoginTime.split("T")[1]
      this.setState({
        fullName: authUser.firstName + " " + authUser.lastName,
        role: authUser.role,
        // time: time,
        //ministry: authUser.mda.name,
        employeeId: authUser,
        //mdaId: authUser.mdaId
        profilePicture: `${BASE_MODULE_URL}/${authUser.picture}`,
      });
      this.checkUserCanInitiate(authUser);
    }
  };
  clearEntry = () => {
    this.setState({
      janVal: 0,
      febVal: 0,
      marVal: 0,
      aprVal: 0,
      mayVal: 0,
      junVal: 0,
      julVal: 0,
      augVal: 0,
      sepVal: 0,
      octVal: 0,
      novVal: 0,
      decVal: 0,
    });
  };
  handleSubmit = () => {
    let janVal = this.state.janVal;
    let febVal = this.state.febVal;
    let marVal = this.state.marVal;
    let aprVal = this.state.aprVal;
    let mayVal = this.state.mayVal;
    let junVal = this.state.junVal;
    let julVal = this.state.julVal;

    let augVal = this.state.augVal;
    let sepVal = this.state.sepVal;
    let octVal = this.state.octVal;
    let novVal = this.state.novVal;
    let decVal = this.state.decVal;
    let departmentBudgetId = this.state.departmentBudget.value;
    let currencyId = this.state.currency.value;
    let enteredBy = this.state.employeeId.toString();

    let data = {
      currencyId,
      departmentBudgetId,
      janVal,
      febVal,
      marVal,
      aprVal,
      mayVal,
      junVal,
      julVal,
      augVal,
      sepVal,
      octVal,
      novVal,
      decVal,
      enteredBy,
    };

    budgetInformationSchema
      .validate(data)
      .then((message) => {
        this.setState({
          loader: true,
          message: "",
          showModal: false,
        });
        axios
          .post(`${BASE_API_URL}/budgetInformation/addBudgetInformations`, {
            ...data,
          })
          .then((response) => {
            this.getBudgetInformation(this.state.auhtorizedMdaId);
            this.setState({
              showModal: false,
              janVal: "0",
              febVal: "0",
              marVal: "0",
              aprVal: "0",
              mayVal: "0",
              junVal: "0",
              julVal: "0",
              augVal: "0",
              sepVal: "0",
              octVal: "0",
              novVal: "0",
              decVal: "0",
              loader: false,
              messsage: "",
              total: "0",
            });
          })
          .catch((err) => {
            this.setState({
              loader: false,
              message: err.response.data,
              showModal: true,
            });
          });
      })
      .catch((err) => {
        this.setState({
          loader: false,
          message: err.errors,
          showModal: true,
        });
      });
  };
  handleSelectedCurrencyChange = (selectedCurrency) => {
    this.setState({ selectedCurrency, currency: selectedCurrency }, () =>
      console.log(`Option selected:`, selectedCurrency)
    );
  };
  handleSelectedProgrammeChange = (selectedProgramme) => {
    this.setState({ selectedProgramme, programme: selectedProgramme }, () =>
      console.log(`Option selected:`, selectedProgramme)
    );
    this.getDepartmentBudgetByProgrammeID(selectedProgramme.value);
  };
  handleSelectedDepartmentBudgetChange = (selectedDepartmentBudget) => {
    this.setState(
      { selectedDepartmentBudget, departmentBudget: selectedDepartmentBudget },
      () => console.log(`Option selected:`, selectedDepartmentBudget)
    );
  };
  checkUserCanInitiate = (id) => {
    axios
      .get(
        `${BASE_API_URL}/userprevilege/getuserprevilegebyemployeeId?employeeId=${243}`
      )
      .then((response) => {
        console.log(response.data);
        if (response.data.length != 0) {
          this.getBudgetInformation(response.data[0].mdaId);
          this.setState({
            auhtorizedMdaId: response.data[0].mdaId,
            informations: response.data,
          });
        } else {
          this.setState({
            allBudgetInformationData: response.data,
            authorized: false,
          });
        }
      })
      .catch((err) => {
        console.log(err.message);
      });
  };
  getCurrency = () => {
    axios
      .get(`${BASE_API_URL}/currency/getcurrency`)
      .then((response) => {
        console.log(response.data);
        this.setState({
          allCurrencyData: response.data,
        });
      })
      .catch((err) => {
        console.log(err);
      });
  };
  getProgramme = () => {
    this.setState({
      loader: true,
      message: "",
    });
    axios
      .get(`${BASE_API_URL}/programme/getprogram`)
      .then((response) => {
        console.log(response.data);
        this.setState({
          allProgramme: response.data,
          programme: response.data.length == 0 ? "" : response.data[0],
          loader: false,
          message: "",
        });
      })
      .catch((err) => {
        this.setState({
          loader: false,
          message: err.message,
        });
      });
  };
  getBudgetInformation = (mdaId) => {
    this.setState({
      loader: true,
      message: "",
    });
    axios
      .get(
        `${BASE_API_URL}/budgetinformation/GetBudgetmentInformationByMdaId?mdaId=${mdaId}`
      )
      .then((response) => {
        if (response.data.length != 0) {
          let departmentBudgetID = response.data.map(
            (a) => a.departmentBudgetID
          );
          console.log(response.data);
          this.checkIfBudgetHaveAlreadyBeenAddded(departmentBudgetID);
        }

        console.log(response.data);

        this.setState({
          allBudgetInformationData: response.data,
          loader: false,
          message: "",
          authorized: true,
        });
      })
      .catch((err) => {
        this.setState({
          loader: false,
          message: err.message,
        });
      });
  };
  checkIfBudgetHaveAlreadyBeenAddded = (departmentBudgetId) => {
    //let newMdaId = mdaId.toString();

    axios
      .post(
        `${BASE_API_URL}/ApprovalProcess/CheckIfBudgetIsAddedForReview`,
        departmentBudgetId
      )
      .then((response) => {
        this.setState({
          isalreadyadded: response.data,
        });
      })
      .catch((err) => {
        console.log(err);
      });
  };

  getDepartmentBudgetByProgrammeID = (programmeId) => {
    this.setState({
      showModal: false,
      loader: true,
      message: "",
    });
    let mdaId = this.state.auhtorizedMdaId;
    axios
      .get(
        `${BASE_API_URL}/departmentBudget/GetDepartmentBudgetByProgrammeIDAndMda?programmeId=${programmeId}&mdaId=${mdaId}`
      )
      .then((response) => {
        console.log(response.data);
        this.setState({
          allDepartmentBudget: response.data,
          departmentBudget: response.data.length == 0 ? "" : response.data[0],
          loader: false,
          message: "",
          showModal: true,
        });
      })
      .catch((err) => {
        this.setState({
          loader: false,
          message: err.message,
          showModal: true,
        });
      });
  };

  render() {
    return (
      <React.Fragment>
        <ReactNotifications />
        {this.state.loader === true ? <Preloader /> : ""}

        <main
          class="flex-1 w-100 relative overflow-y-auto focus:outline-none"
          tabindex="0"
          style={{ outline: "none" }}
        >
          <div className="w-100 flex space-between flex-v-baseline">
            <Greeting />
            <LocationDetails />
          </div>
          <div class="py-10 max-w-full mx-auto px-2 sm:px-4 md:px-8">
            <div class="flex">
              <div className="flex-1 flex">
                <h1 class=" text-2xl font-medium text-color mr-10">
                  {this.state.ministry}
                </h1>
                {this.state.authorized === true ? (
                  <>
                    {this.state.isalreadyadded === false ? (
                      <>
                        <button
                          type="submit"
                          className="m-r-5 m-b-10 inline-flex py-2 px-4 border border-transparent shadow-sm text-sm font-bold text-white primary-bg-green focus:outline-none"
                          onClick={() =>
                            this.setState({ showModal: !this.state.showModal })
                          }
                        >
                          Enter Budget Item
                        </button>
                      </>
                    ) : null}
                  </>
                ) : null}
              </div>
            </div>
            <div className="py-6">
              {/* <InitiateBudgetDetailsC
                informations={this.state.informations}
                allBudgetInformationData={this.state.allBudgetInformationData}
                authorized={this.state.authorized}
                isalreadyadded={this.state.isalreadyadded}
                {...this.props}
              /> */}
              {this.state.authorized === true ? (
                <>
                  {this.state.isalreadyadded === false ? (
                    <>
                      <div className="m-t-10">
                        <UploadFile
                          {...this.props}
                          employeeId={this.state.employeeId}
                        />
                      </div>
                    </>
                  ) : null}
                </>
              ) : null}
            </div>
          </div>
          {this.state.showModal ? (
            <>
              <div className="justify-center overlay w-100 items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
                <div className="relative w-60 w-5/12 my-6 mx-auto">
                  {/*content*/}
                  <div className="flex bg-card-header py-4 px-4 shadow-sm overflow-hidden cursor-pointer">
                    <p className="flex-1 mt-2 text font-medium font-avenir-black text-color">
                      Budget Information
                    </p>
                    <div
                      style={{
                        width: 38,
                        height: 38,
                        backgroundColor: "#FFB831",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      <img
                        alt="profile picture"
                        src={this.state.profilePicture}
                        style={{
                          width: 15,
                          position: "absolute",
                          top: 28,
                          right: 28,
                        }}
                      />
                    </div>
                  </div>

                  <div className="bg-white py-4 px-4 shadow-sm overflow-hidden">
                    {this.state.message !== "" ? (
                      <ValidationMessageComponent
                        message={this.state.message}
                      ></ValidationMessageComponent>
                    ) : (
                      // <p></p>
                      ""
                    )}
                    <div class="mt-3 grid grid-cols-1 gap-y-6 gap-x-4 sm:grid-cols-12">
                      <div className="flex-align">
                        <div class="col-span-4">
                          <label
                            for="economicSegment"
                            class="block text-sm font-medium text-gray-700"
                          >
                            Programme
                          </label>
                          <div class="m-r-10">
                            <Select
                              name="economicSegment"
                              id="economicSegment"
                              value={this.state.programme}
                              options={this.state.allProgramme}
                              class="shadow-sm focus:outline-none block w-full sm:text-sm"
                              onChange={this.handleSelectedProgrammeChange}
                            />
                          </div>
                        </div>
                        <div class="col-span-4">
                          <label
                            for="economicType"
                            class="block text-sm font-medium text-gray-700"
                          >
                            Item
                          </label>
                          <div class="m-r-10">
                            <Select
                              name="economicType"
                              id="economicType"
                              value={this.state.departmentBudget}
                              options={this.state.allDepartmentBudget}
                              class="shadow-sm focus:outline-none block w-full sm:text-sm"
                              onChange={
                                this.handleSelectedDepartmentBudgetChange
                              }
                            />
                          </div>
                        </div>
                        <div class="col-span-4">
                          <label
                            for="economicSegment"
                            class="block text-sm font-medium text-gray-700"
                          >
                            Currency
                          </label>
                          <div class="m-r-10">
                            <Select
                              name="currency"
                              id="currency"
                              value={this.state.currency}
                              options={this.state.allCurrencyData}
                              class="shadow-sm focus:outline-none block w-full sm:text-sm"
                              onChange={this.handleSelectedCurrencyChange}
                            />
                          </div>
                        </div>
                      </div>

                      <div className="w-100 m-t-10">
                        <div className="flex-align w-100">
                          <div class="col-span-4 w-100 ">
                            <label
                              for="economicType"
                              class="block text-sm font-medium text-gray-700"
                            >
                              Jan
                            </label>
                            <div class="m-r-10">
                              <Input
                                type="text"
                                name="janVal"
                                id="janVal"
                                value={this.state.janVal}
                                class="shadow-sm focus:outline-none block w-full sm:text-sm"
                                onChange={this.handleChange}
                              />
                            </div>
                          </div>
                          <div class="col-span-4">
                            <label
                              for="economicType"
                              class="block text-sm font-medium text-gray-700"
                            >
                              Feb
                            </label>
                            <div class="m-r-10">
                              <Input
                                type="text"
                                name="febVal"
                                id="febVal"
                                value={this.state.febVal}
                                class="shadow-sm focus:outline-none block w-full text-sm"
                                onChange={this.handleChange}
                              />
                            </div>
                          </div>
                          <div class="col-span-4">
                            <label
                              for="economicType"
                              class="block text-sm font-medium text-gray-700"
                            >
                              Mar
                            </label>
                            <div class="m-r-10">
                              <Input
                                type="text"
                                name="marVal"
                                id="marVal"
                                value={this.state.marVal}
                                class="shadow-sm focus:outline-none block w-full text-sm"
                                onChange={this.handleChange}
                              />
                            </div>
                          </div>
                        </div>
                        <div className="flex-align w-100">
                          <div class="col-span-4">
                            <label
                              for="economicType"
                              class="block text-sm font-medium text-gray-700"
                            >
                              Apr
                            </label>
                            <div class="m-r-10">
                              <Input
                                type="text"
                                name="aprVal"
                                id="aprVal"
                                value={this.state.aprVal}
                                class="shadow-sm focus:outline-none block w-full text-sm"
                                onChange={this.handleChange}
                              />
                            </div>
                          </div>

                          <div class="col-span-4">
                            <label
                              for="economicType"
                              class="block text-sm font-medium text-gray-700"
                            >
                              May
                            </label>
                            <div class="m-r-10">
                              <Input
                                type="text"
                                name="mayVal"
                                id="mayVal"
                                value={this.state.mayVal}
                                class="shadow-sm focus:outline-none block w-full text-sm"
                                onChange={this.handleChange}
                              />
                            </div>
                          </div>
                          <div class="col-span-4">
                            <label
                              for="economicType"
                              class="block text-sm font-medium text-gray-700"
                            >
                              Jun
                            </label>
                            <div class="m-r-10">
                              <Input
                                type="text"
                                name="junVal"
                                id="junVal"
                                value={this.state.junVal}
                                class="shadow-sm focus:outline-none block w-full text-sm"
                                onChange={this.handleChange}
                              />
                            </div>
                          </div>
                        </div>
                        <div className="flex-align w-100">
                          <div class="col-span-4">
                            <label
                              for="economicType"
                              class="block text-sm font-medium text-gray-700"
                            >
                              Jul
                            </label>
                            <div class="m-r-10">
                              <Input
                                type="text"
                                name="julVal"
                                id="julVal"
                                value={this.state.julVal}
                                class="shadow-sm focus:outline-none block w-full text-sm"
                                onChange={this.handleChange}
                              />
                            </div>
                          </div>
                          <div class="col-span-4">
                            <label
                              for="economicType"
                              class="block text-sm font-medium text-gray-700"
                            >
                              Aug
                            </label>
                            <div class="m-r-10">
                              <Input
                                type="text"
                                name="augVal"
                                id="augVal"
                                value={this.state.augVal}
                                class="shadow-sm focus:outline-none block w-full text-sm"
                                onChange={this.handleChange}
                              />
                            </div>
                          </div>

                          <div class="col-span-4">
                            <label
                              for="economicType"
                              class="block text-sm font-medium text-gray-700"
                            >
                              Sep
                            </label>
                            <div class="m-r-10">
                              <Input
                                type="text"
                                name="sepVal"
                                id="sepVal"
                                value={this.state.sepVal}
                                class="shadow-sm focus:outline-none block w-full text-sm"
                                onChange={this.handleChange}
                              />
                            </div>
                          </div>
                        </div>

                        <div className="flex-align">
                          <div class="col-span-4">
                            <label
                              for="economicType"
                              class="block text-sm font-medium text-gray-700"
                            >
                              Oct
                            </label>
                            <div class="m-r-10">
                              <Input
                                type="text"
                                name="octVal"
                                id="octVal"
                                value={this.state.octVal}
                                class="shadow-sm focus:outline-none block w-full text-sm"
                                onChange={this.handleChange}
                              />
                            </div>
                          </div>
                          <div class="col-span-4">
                            <label
                              for="economicType"
                              class="block text-sm font-medium text-gray-700"
                            >
                              Nov
                            </label>
                            <div class="m-r-10">
                              <Input
                                type="text"
                                name="novVal"
                                id="novVal"
                                value={this.state.novVal}
                                class="shadow-sm focus:outline-none block w-full text-sm"
                                onChange={this.handleChange}
                              />
                            </div>
                          </div>
                          <div class="col-span-4">
                            <label
                              for="economicType"
                              class="block text-sm font-medium text-gray-700"
                            >
                              Dec
                            </label>
                            <div class="m-r-10">
                              <Input
                                type="text"
                                name="decVal"
                                id="decVal"
                                value={this.state.decVal}
                                class="shadow-sm focus:outline-none block w-full text-sm"
                                onChange={this.handleChange}
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="col-span-4 m-b-10">
                        <div style={{ width: "33.3%" }}>
                          <label
                            for="economicType"
                            class="block text-sm font-medium text-gray-700"
                          >
                            Total
                          </label>
                          <div class="m-r-10">
                            <Input
                              type="text"
                              name="code"
                              id="code"
                              value={this.state.total}
                              class="shadow-sm focus:outline-none block w-full sm:text-sm"
                              readOnly
                            />
                          </div>
                        </div>
                      </div>
                      <div
                        class="pt-5 w-100 flex sm:col-span-12 grid place-content-end"
                        style={{ justifyContent: "flex-end" }}
                      >
                        <div class="flex-between w-40">
                          <Button
                            onClick={this.handleSubmit}
                            type="submit"
                            class="mr-5 w-32 inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-bold text-white primary-bg-green focus:outline-none"
                          >
                            Save Record
                          </Button>
                          <Button
                            onClick={this.clearEntry}
                            type="button"
                            class="mr-5 w-32 bg-clear-entry py-2 px-4 border border-gray-300 shadow-sm text-sm font-bold text-color bg-gray-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                          >
                            Clear Entry
                          </Button>
                          <Button
                            type="button"
                            class="w-32 bg-white py-2 px-4 border border-gray-300 shadow-sm text-sm font-bold text-color bg-gray-200 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                            onClick={() =>
                              this.setState({
                                showModal: !this.state.showModal,
                              })
                            }
                          >
                            Close
                          </Button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
            </>
          ) : null}
        </main>
      </React.Fragment>
    );
  }
}
