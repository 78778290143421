/* eslint-disable operator-linebreak */
/* eslint-disable object-curly-newline */
/* eslint-disable comma-dangle */
/* eslint-disable quotes */
/* eslint-disable indent */
/* eslint-disable linebreak-style */
/* eslint-disable array-bracket-spacing */
/* eslint-disable no-empty */
/* eslint-disable keyword-spacing */
/* eslint-disable brace-style */
/* eslint-disable import/order */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable max-len */
/* eslint-disable no-unused-vars */
import React, { useEffect, useState, useRef, useCallback } from "react";
import "../../assets/css/Dashboard.css";
// import AutoComplete from "../inputs/AutoComplete";
// import CommentTable from "../layouts/CommentTable";
import BudgetReviewTable from "./BudgetReviewTable";
// import { TiPencil } from "react-icons/ti";

// import { IoMdClose } from "react-icons/io";
// import { AiOutlineRedEnvelope } from 'react-icons/ai';
// import { BiCalendar } from 'react-icons/bi';
// import { HiOutlineUserGroup } from 'react-icons/hi';
// import Calendar from "react-calendar";
// import CardGiftcardIcon from "@material-ui/icons/CardGiftcard";
// import EventNoteIcon from "@material-ui/icons/EventNote";
// import SearchInput from "../inputs/SearchInput";
// import RequestDetails from "../layouts/RequestDetails";
import { get, post, put } from "../../api-services/fetch";
// import { newget } from "../../api-services/newfetch";
// import IsLoading from "../common/IsLoading";
// import {
//   formatSimpleDate,
//   formatDate,
//   formatDateforPicker,
// } from "../../utility/dateTime";
import axios from "axios";

// import BudgetTeamCorrespondenceuestForm from "../layouts/BudgetTeamCorrespondenceuestForm";
// import Greeting from "../common/Greeting";
import LocationDetails from "../layouts/LocationDetails";
// import { formatStatus } from "../../utility/general";
import notification from "../../utility/notification";
import "../../assets/css/calendar.css";
// import useClickOutsideBox from "../hooks/useClickOutsideBox";
// import SelectInputColored from "../inputs/SelectInputColored";
// // import CreateIcon from "@material-ui/icons/Create";
// import DeleteIcon from "@material-ui/icons/Delete";
// import { decodeToken } from "../../utility/auth";
// // import RequestQueryForm from "../layouts/QueryForm";
// // import QueryThread from "../layouts/QueryThread";
// // import Massive from "../layouts/massive";
// import GovernorsVIew from "./GovernorsView";
import {
  Table,
  Button,
  Collapse,
  Modal,
  Select,
  Tooltip,
  Form,
  Card,
  Typography,
  Space,
  Tag,
} from "antd";
import moment from "moment";
import Cookies from "js-cookie";

const BudgetTeamCorrespondence = ({ history }) => {
  const [name, setName] = useState("");
  const [amount, setAmount] = useState("");
  // const [isLoading, setIsLoading] = useState("");
  const [arrayHolder, setArrayHolder] = useState([]);
  const [envelopHolder, setenvelopHolder] = useState([]);
  const [id, setId] = useState(1);
  const [title, setTile] = useState("");
  const [totalCost, setTotalCost] = useState("");
  const [dateCreated, setDateCreated] = useState("");
  const [onShow, setOnShow] = useState(false);
  const [refresh, setRefresh] = useState();

  const [isLoading, setIsLoading] = useState(false);
  const [details, setDetails] = useState([]);
  const [requestCounts, setRequestCounts] = useState({});
  const [meetingCounts, setMeetingCounts] = useState({});
  const [errorMsg, setErrorMsg] = useState("No Request Yet");
  const [Mda, setMda] = useState([]);
  const [mdaId, setMdaId] = useState([]);
  const [employees, setEmployees] = useState([]);
  const [employeesId, setEmployeesId] = useState([]);
  const [ApproverId, setApproverId] = useState([]);
  const [mailDisplay, setMailDisplay] = useState(true);
  const [mdaDisplay, setMdaDisplay] = useState(false);
  const [category, setCategory] = useState([]);
  const [searchText, setSearchText] = useState("");
  //   const [status, setStatus] = useState("actioned");
  const [ourUrl, setOurUrl] = useState("");
  const [username, setUsername] = useState("");
  const [userId, setUserId] = useState("");
  const [summary, setSummaary] = useState("");
  const [batchId, setBatchId] = useState("");
  const [projectInitiative, setProjectInitiative] = useState("");
  const [onAction, setOnAction] = useState(false);
  const [sectorId, setSectorId] = useState();

  const [budgetArrays, setBudgetArrays] = useState([]);
  const [commentsBudget, setCommentsBudget] = useState([]);
  const [commentsBudgetA, setCommentsBudgetA] = useState([]);
  const [budgetPending, setBudgetPending] = useState([]);
  const [budgetDeptId, setBudgetDeptId] = useState();

  const [showCalendar, setShowCalendar] = useState(false);
  const [calendarFor, setCalendarFor] = useState("");
  const [calendar, setCalendar] = useState(null);
  const [requestCalendar, setRequestCalendar] = useState(null);
  const [meetingCalendar, setMeetingCalendar] = useState(null);
  const [showQueryForm, setShowQueryForm] = useState(false);
  const [showQueryThread, setShowQueryThread] = useState(false);
  const token =
    "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJVc2VySUQiOiIxIiwiRmlyc3ROYW1lIjoiRWRvIEdvdnYiLCJMYXN0TmFtZSI6IkFkbWluIiwiZXhwIjoxNjIxNzg3NTQ1LCJpc3MiOiJzZXJ2ZXIiLCJhdWQiOiJjbGllbnQifQ.JpkTA-250nWIkIxrOyaLZcbw33fJNXuV7z1tPE9zz0w";
  const wrapperRef = useRef(null);
  const focusRef = useRef();
  const [current, setCurrent] = useState("");
  const [currentId, setCurrentId] = useState("");
  console.log(details);
  console.log(errorMsg);

  const BASE_API_URL = "https://edogoverp.com/budget/api/v1";
  const BASE_API_URLs = "https://edogoverp.com/newbudgetapi/api/v1";
  // const BASE_API_URL = 'http://localhost:5000/api/v1';

  const { Panel } = Collapse;

  let fn;
  let ln;
  let ui;
  let mi;
  useEffect(() => {
    //   getMdas()
    //   getEmployees()

    const r = localStorage.getItem("r");
    fn = localStorage.getItem("fn");
    ln = localStorage.getItem("ln");
    ui = localStorage.getItem("ui");
    mi = localStorage.getItem("mi");

    // r && setRole(decodeToken('r').r);
    // if (fn && ln) {
    //   fn = decodeToken("fn").fn;
    //   ln = decodeToken("ln").ln;
    //   ui = decodeToken("ui").ui;
    //   mi = decodeToken("mi").mi;
    //   setUsername(`${fn} ${ln}`);
    //   // setUsername(`${fn} ${ln}`);
    //   // getPending(ui)
    getBudgetPlanningSummary(+Cookies.get("userId"));
    // getBudgetPendingComents(+Cookies.get("userId"));
    // getPendingBudgetSectors(+Cookies.get("userId"),)

    console.log(+Cookies.get("userId"));
    //   setUserId(ui);
    //   setMdaId(mi);
    // }
  }, [refresh]);

  const headers = {
    Authorization: Cookies.get("token"), // Replace with your actual token or header
    "Content-Type": "application/json", // Adjust content type as needed
    // Add any other headers as necessary
  };

  const callRefresh = () => {
    setRefresh(refresh + 1);
    getBudgetPlanningSummary(ui);
    // getBudgetPendingComents(ui);
    // alert('go go')
  };

  const catchProjectInitiative = (lob) => {
    console.log(lob);
    setProjectInitiative(lob);
  };

  // const removeItem = (id) => {
  //   console.log(id);
  //   const temp = arrayHolder.filter((item) => item.idz !== id);

  //   setArrayHolder(temp);
  // };

  // const callBatchId = (id) => {
  //   console.log(id);
  //   setBatchId(id);

  //   axios
  //     .get(`${BASE_API_URL}/BudgetPlanning/GetInitiativesByBatchId`, {
  //       params: { id },
  //     })
  //     .then((response) => {
  //       console.log(response.data);
  //       setEnvelopeHolder(response.data);
  //       // setProjectInitiative(response.data[0].projectInitiative)
  //       // setEmployees( response.data)
  //     });
  // };

  const columnsBudgetItems = [
    {
      title: "Project",
      dataIndex: "project",
      key: "name",
      render: (text) => <a>{text}</a>,
    },
    {
      title: "Title",
      dataIndex: "title",
      key: "age",
    },
    {
      title: "Proposed Budget",
      dataIndex: "proposedBudget",
      editable: true,
      key: "address",
      width: "20%",
    },
    // {
    //   title: "Tags",
    //   key: "tags",
    //   dataIndex: "tags",
    //   render: (_, { tags }) => (
    //     <>
    //       {tags?.map((tag) => {
    //         let color = tag.length > 5 ? "geekblue" : "green";

    //         if (tag === "loser") {
    //           color = "volcano";
    //         }

    //         return (
    //           <Tag color={color} key={tag}>
    //             {tag?.toUpperCase()}
    //           </Tag>
    //         );
    //       })}
    //     </>
    //   ),
    // },
    // {
    //   title: "Action",
    //   key: "action",
    //   render: (_, record) => (
    //     <Space size="middle">
    //       <a>Invite {record.name}</a>
    //       <a>Delete</a>
    //     </Space>
    //   ),
    // },
  ];

  const budgetPlanningzz = [
    {
      deptBudgetId: "string",
      mda: "string",
      budgetItems: [
        {
          budgetPlanId: "3fa85f64-5717-4562-b3fc-2c963f66afa6",
          budgetPeriodId: "3fa85f64-5717-4562-b3fc-2c963f66afa6",
          adminSectorCode: 0,
          adminSector: "string",
          project: "string",
          title: "string",
          programmeCode: "string",
          programmeSegment: "string",
          administrativeCode: "string",
          economicCode: "string",
          economicSegment: "string",
          fundCode: "string",
          fundSegment: "string",
          functionalCode: "string",
          functionalSegment: "string",
          geoCode: "string",
          geoArea: "string",
          status: "string",
          projectLocation: "string",
          proposedBudget: 0,
          previousApprovedBudget: 0,
          currentExpenditures: 0,
          previousExpenditures: 0,
          performance: "string",
          actuals: "string",
          mdaId: 0,
          approvalStatus: 0,
          stage: 0,
        },
      ],
      approvers: [
        {
          // id: "3fa85f64-5717-4562-b3fc-2c963f66afa6",
          dateCreated: "2023-10-23T20:14:02.980Z",
          enteredBy: "string",
          dateUpdated: "2023-10-23T20:14:02.980Z",
          updatedBy: "string",
          isDelete: false,
          dateDeleted: "2023-10-23T20:14:02.980Z",
          batchId: "3fa85f64-5717-4562-b3fc-2c963f66afa6",
          mdaId: 0,
          role: "string",
          roleId: 0,
          signature64: "string",
          comment: "string",
          status: "string",
          intray: "2023-10-23T20:14:02.980Z",
          outTray: "2023-10-23T20:14:02.980Z",
          approverId: 0,
          approverName: "string",
        },
      ],
      ongoingProjects: [
        {
          deptBudgetId: "3fa85f64-5717-4562-b3fc-2c963f66afa6",
          project: "string",
          status: "string",
          location: "string",
          startDate: "2023-10-23T20:14:02.980Z",
          amountSpent: 0,
          conclusionEstimate: 0,
          completionTime: 0,
          mdaId: 0,
        },
      ],
      personnelCosts: [
        {
          deptBudgetId: "3fa85f64-5717-4562-b3fc-2c963f66afa6",
          administrativeCode: "string",
          gl: "string",
          noOfStaff: 0,
          endDate: "2023-10-23T20:14:02.980Z",
          proposedExpense: 0,
          prevNoOfStaff: 0,
          prevApprovedExpense: 0,
          currentExpenseAsAt: 0,
          previousExpense: 0,
        },
      ],
      recurrentRevenues: [
        {
          deptBudgetId: "3fa85f64-5717-4562-b3fc-2c963f66afa6",
          economicCode: "string",
          details: "string",
          proposedRevenue: 0,
          previousApprovedRevenue: 0,
          currentRevenueAsAt: 0,
          forecastOfCurrentRevenue: 0,
          previousGeneratedRevenue: 0,
        },
      ],
      capitalProjects: [
        {
          id: "3fa85f64-5717-4562-b3fc-2c963f66afa6",
          dateCreated: "2023-10-23T20:14:02.980Z",
          enteredBy: "string",
          dateUpdated: "2023-10-23T20:14:02.980Z",
          updatedBy: "string",
          isDelete: false,
          dateDeleted: "2023-10-23T20:14:02.980Z",
          deptBudgetId: "3fa85f64-5717-4562-b3fc-2c963f66afa6",
          details: "string",
          stateOfCompletion: "string",
          status: "string",
          location: "string",
          currentApprovedAppropriation: 0,
          actualExpenditureAsAt: 0,
          proposedAppropriation: 0,
          targetsRemarks: "string",
          mdaId: 0,
        },
      ],
      kpi: [
        {
          id: "3fa85f64-5717-4562-b3fc-2c963f66afa6",
          dateCreated: "2023-10-23T20:14:02.980Z",
          enteredBy: "string",
          dateUpdated: "2023-10-23T20:14:02.980Z",
          updatedBy: "string",
          isDelete: false,
          dateDeleted: "2023-10-23T20:14:02.980Z",
          deptBudgetId: "3fa85f64-5717-4562-b3fc-2c963f66afa6",
          economicCode: "string",
          projectTitle: "string",
          projectObjectives: "string",
          projectDuration: 0,
          projectOutcome: "string",
          keyPerformanceIndicators: "string",
          targets: "string",
          possibleImpacts: "string",
          ranking: "string",
          remarks: "string",
        },
      ],
      rankings: [
        {
          id: "3fa85f64-5717-4562-b3fc-2c963f66afa6",
          dateCreated: "2023-10-23T20:14:02.980Z",
          enteredBy: "string",
          dateUpdated: "2023-10-23T20:14:02.980Z",
          updatedBy: "string",
          isDelete: false,
          dateDeleted: "2023-10-23T20:14:02.980Z",
          deptBudgetId: "3fa85f64-5717-4562-b3fc-2c963f66afa6",
          economicCode: "string",
          projectTitle: "string",
          projectObjectives: "string",
          projectActivities: "string",
          possibleImpacts: "string",
          ranking: "string",
          remarks: "string",
        },
      ],
      overHeadCostMonitor: [
        {
          id: "3fa85f64-5717-4562-b3fc-2c963f66afa6",
          dateCreated: "2023-10-23T20:14:02.980Z",
          enteredBy: "string",
          dateUpdated: "2023-10-23T20:14:02.980Z",
          updatedBy: "string",
          isDelete: false,
          dateDeleted: "2023-10-23T20:14:02.980Z",
          deptBudgetId: "3fa85f64-5717-4562-b3fc-2c963f66afa6",
          economicCode: "string",
          details: "string",
          frequencyPerMonth: 0,
          units: 0,
          costPerUnit: 0,
          costPerMonth: 0,
          costPerAnnum: 0,
          remarks: "string",
        },
      ],
      revenueMonitor: [
        {
          id: "3fa85f64-5717-4562-b3fc-2c963f66afa6",
          dateCreated: "2023-10-23T20:14:02.980Z",
          enteredBy: "string",
          dateUpdated: "2023-10-23T20:14:02.980Z",
          updatedBy: "string",
          isDelete: false,
          dateDeleted: "2023-10-23T20:14:02.980Z",
          deptBudgetId: "3fa85f64-5717-4562-b3fc-2c963f66afa6",
          economicCode: "string",
          details: "string",
          rate: 0,
          units: 0,
          amount: 0,
        },
      ],
    },
  ];

  const budgetItemsData = commentsBudget?.budgetItems?.map((e) => ({
    ...e,
    // dateCreated: "2023-09-17T13:22:34.007Z",
    enteredBy: userId + "",
    // dateUpdated: "2023-09-17T13:22:34.007Z",
    // updatedBy: userId + "",
    // isDelete: false,
    // dateDeleted: "2023-09-17T13:22:34.007Z",
    // budgetPlanId: "3fa85f64-5717-4562-b3fc-2c963f66afa6",
    // budgetPeriodId: "3fa85f64-5717-4562-b3fc-2c963f66afa6",
    // adminSectorCode: 0,
    // project: "string",
    // title: "",
    // programmeCode: "111111111111",
    // administrativeCode: e.administrativeCode,
    // economicCode: e.economicCode,
    // //   administrativeCode: e.administrativeCode,
    // fundCode: "444444444444",
    // functionalCode: "555555555555",
    // geoCode: "666666666666",
    // status: "string",
    // projectLocation: "string",
    proposedBudget: +e.proposedBudget,
    previousApprovedBudget: +e.previousApprovedBudget || 0,
    currentExpenditures: +e.currentExpenditures || 0,
    previousExpenditures: +e.previousExpenditures || 0,
    annualisedTotalExpenditure: +e.annualisedTotalExpenditure || 0,
    // performance: "string",
    // actuals: "string",
    mdaId: +mdaId,
    // approvalStatus: 0,
    // stage: 0,
  }));

  const budgetItemsColumn = [
    {
      title: "Administrative Code",
      dataIndex: "administrativeCode",
      key: "name",
      render: (text) => <a>{text}</a>,
    },
    {
      title: "Economic code",
      dataIndex: "economicCode",
      // editable: true,
      key: "economicCode",
      //  width: "20%",
    },
    {
      title: "Details Of expenditure",
      dataIndex: "economicDetails",
      key: "economicCode",
      //  width: "20%",
    },
    {
      title: "Proposed Estimates 2024",
      editable: true,
      dataIndex: "proposedBudget",
      key: "proposedBudget",
      render: (text) => <a>{numberWithCommas(text)}</a>,

      //  width: "20%",
    },
    {
      title: "Approved Estimates 2023",
      dataIndex: "previousApprovedBudget",
      editable: true,
      key: "previousApprovedBudget",
      render: (text) => <a>{numberWithCommas(text)}</a>,

      //  width: "20%",
    },
    {
      title: "Actual Expenditure At SEPT 30th 2023",
      dataIndex: "currentExpenditures",
      editable: true,
      key: "currentExpenditures",
      render: (text) => <a>{numberWithCommas(text)}</a>,

      //  width: "20%",
    },
    {
      title: "Actual Expenditure 2022",
      dataIndex: "previousExpenditures",
      editable: true,
      key: "previousExpenditures",
      render: (text) => <a>{numberWithCommas(text)}</a>,

      //  width: "20%",
    },

    // {
    //   title: "Expenses as at 30th Sept",
    //   dataIndex: "currentExpenseAsAt",
    //   key: "address",
    //   //  width: "20%",
    // },
    // {
    //   title: "Annualised Total Expenditure 2023",
    //   editable: true,
    //   dataIndex: "annualisedTotalExpenditure",
    //   key: "address",
    //   //  width: "20%",
    // },
  ];

  const capitalProjetsColumn = [
    {
      title: "Economic Code",
      dataIndex: "economicCode",
      key: "name",
      render: (text) => <a>{text}</a>,
    },
    {
      title: "Details of Expenditure",
      dataIndex: "details",
      key: "details",
    },
    {
      title: "State Of Completion",
      dataIndex: "stateOfCompletion",
      editable: true,
      key: "stateOfCompletion",
      //  width: "20%",
    },
    {
      title: "Status",
      dataIndex: "",
      editable: true,
      key: "status",
      //  width: "20%",
    },
    {
      title: "Location",
      dataIndex: "location",
      editable: true,
      key: "location",
      //  width: "20%",
    },
    {
      title: "Current Approved Appropriation",
      dataIndex: "currentApprovedAppropriation",
      editable: true,
      key: "currentApprovedAppropriation",
      //  width: "20%",
    },

    {
      title: "Actual Expenditure At",
      dataIndex: "actualExpenditureAsAt",
      editable: true,
      key: "actualExpenditureAsAt",
      //  width: "20%",
    },
    {
      title: "Proposed Appropriation 2024",
      dataIndex: "proposedAppropriation",
      editable: true,
      key: "proposedAppropriation",
      //   width: "20%",
    },
    {
      title: "Target Remarks",
      dataIndex: "targetsRemarks",
      editable: true,
      key: "targetsRemarks",
      //   width: "20%",
    },
  ];

  const rankingsColumn = [
    {
      title: "Economic Code",
      dataIndex: "economicCode",
      key: "name",
      render: (text) => <a>{text}</a>,
    },
    {
      title: "Project Title",
      dataIndex: "projectTitle",
      key: "projectTitle",
    },
    {
      title: "Project Objectives",
      dataIndex: "projectObjectives",
      editable: true,
      key: "projectObjectives",
      //  width: "20%",
    },
    {
      title: "project Activities",
      editable: true,
      dataIndex: "projectActivities",
      key: "projectActivities",
      //  width: "20%",
    },
    // {
    //   title: "project Outcome",
    //   dataIndex: "projectOutcome",
    //   key: "projectOutcome",
    //   //  width: "20%",
    // },
    // {
    //   title: "key Performance Indicators",
    //   dataIndex: "keyPerformanceIndicators",
    //   key: "keyPerformanceIndicators",
    //   //  width: "20%",
    // },

    // {
    //   title: "targets",
    //   dataIndex: "targets",
    //   key: "targets",
    //   //  width: "20%",
    // },
    {
      title: "possible Impacts",
      dataIndex: "possibleImpacts",
      editable: true,
      key: "possibleImpacts",
      //   width: "20%",
    },
    {
      title: "ranking",
      dataIndex: "ranking",
      editable: true,
      key: "ranking",
      //   width: "20%",
    },
    {
      title: "Target Remarks",
      dataIndex: "targetsRemarks",
      editable: true,
      key: "remarks",
      //   width: "20%",
    },
  ];

  const capitalReceiptsColumn = [
    {
      title: "Economic Code",
      dataIndex: "economicDetailsCode",
      key: "economicDetailsCode",
      render: (text) => <a>{text}</a>,
    },
    {
      title: "Project Details",
      dataIndex: "details",
      key: "details",
    },
    // {
    //   title: "Project Objectives",
    //   dataIndex: "projectObjectives",
    //   editable: true,
    //   key: "projectObjectives",
    //   //  width: "20%",
    // },
    // {
    //   title: "project Activities",
    //   editable: true,
    //   dataIndex: "projectActivities",
    //   key: "projectActivities",
    //   //  width: "20%",
    // },
    // {
    //   title: "project Outcome",
    //   dataIndex: "projectOutcome",
    //   key: "projectOutcome",
    //   //  width: "20%",
    // },
    // {
    //   title: "key Performance Indicators",
    //   dataIndex: "keyPerformanceIndicators",
    //   key: "keyPerformanceIndicators",
    //   //  width: "20%",
    // },

    // {
    //   title: "targets",
    //   dataIndex: "targets",
    //   key: "targets",
    //   //  width: "20%",
    // },
    {
      title: "Proposed Budget 2024",
      dataIndex: "proposedAppropriation",
      editable: true,
      render: (text) => <a>{numberWithCommas(text)}</a>,

      key: "proposedAppropriation",
      //   width: "20%",
    },
    {
      title: "Current Appropriation",
      dataIndex: "currentApprovedAppropriation",
      editable: true,
      render: (text) => <a>{numberWithCommas(text)}</a>,

      key: "currentApprovedAppropriation",
      //   width: "20%",
    },
    {
      title: "Actual Expenditure at September 2023",
      dataIndex: "actualExpenditureAsAt",
      editable: true,
      render: (text) => <a>{numberWithCommas(text)}</a>,

      key: "actualExpenditureAsAt",
      //   width: "20%",
    },
  ];
  const capitalReceiptsData = commentsBudget?.capitalReceipts?.map((e) => ({
    ...e,
    deptBudgetId: e?.deptBudgetId,
    adminCode: e?.adminCode,
    details: e?.details,
    programmeCode: e?.programmeCode,
    economicMainHeadCode: e?.economicMainHeadCode,
    economicDetailsCode: e?.economicDetailsCode,
    functionalMainHeadCode: e?.functionalMainHeadCode,
    functionalDetailsCode: e?.functionalDetailsCode,
    locationCode: e?.locationCode,
    fundMainCode: e?.fundMainCode,
    fundDetailsCode: e?.fundDetailsCode,
    fundMainCode2: e?.fundMainCode2,
    fundDetailsCode2: e?.fundDetailsCode2,
    fundMainCode3: e?.fundMainCode3,
    fundDetailsCode3: e?.fundDetailsCode3,
    fundAmount: e?.fundAmount,
    fundAmount2: e?.fundAmount2,
    fundAmount3: e?.fundAmount3,
    currentApprovedAppropriation: e?.currentApprovedAppropriation || 0,
    actualExpenditureAsAt: e?.actualExpenditureAsAt || 0,
    proposedAppropriation: e?.proposedAppropriation || 0,
    mdaId: e?.mdaId,
    id: e?.id,
    dateCreated: e?.dateCreated,
    enteredBy: e?.enteredBy,
    dateUpdated: e?.dateUpdated,
    updatedBy: e?.updatedBy,
    isDelete: false,
    dateDeleted: e?.dateDeleted,
  }));

  const rankingsData = commentsBudget?.rankings?.map((e) => ({
    id: "3fa85f64-5717-4562-b3fc-2c963f66afa6",
    dateCreated: "2023-10-23T12:23:22.628Z",
    enteredBy: "string",
    dateUpdated: "2023-10-23T12:23:22.628Z",
    updatedBy: "string",
    isDelete: false,
    dateDeleted: "2023-10-23T12:23:22.628Z",
    deptBudgetId: "3fa85f64-5717-4562-b3fc-2c963f66afa6",
    economicCode: e.economicCode,
    projectTitle: e.projectTitle,
    projectObjectives: e.projectObjectives,
    projectActivities: e.projectActivities,
    // projectOutcome: e.projectOutcome,
    // keyPerformanceIndicators: e.keyPerformanceIndicators,
    // targets: e.targets,
    possibleImpacts: e.possibleImpacts,
    ranking: e.ranking,
    remarks: e.remarks,
  }));

  const overHeadCostMonitorColumn = [
    {
      title: "Economic Code",
      dataIndex: "economicCode",
      key: "name",
      render: (text) => <a>{text}</a>,
    },
    {
      title: "details",
      dataIndex: "details",
      key: "details",
    },
    {
      title: "frequency Per Month",
      dataIndex: "frequencyPerMonth",
      editable: true,
      key: "frequencyPerMonth",
      //  width: "20%",
    },

    {
      title: "units ",
      dataIndex: "units",
      editable: true,
      key: "units",
      //  width: "20%",
    },
    {
      title: "cost Per Unit",
      dataIndex: "costPerUnit",
      editable: true,
      key: "costPerUnit",
      //  width: "20%",
    },

    // {
    //   title: "targets",
    //   dataIndex: "targets",
    //   key: "targets",
    //   //  width: "20%",
    // },
    {
      title: "cost Per Month",
      editable: true,
      dataIndex: "costPerMonth",
      key: "costPerMonth",
      //   width: "20%",
    },
    {
      title: "cost Per Annum",
      dataIndex: "costPerAnnum",
      editable: true,
      key: "costPerAnnum",
      //   width: "20%",
    },
    {
      title: " Remarks",
      dataIndex: "remarks",
      key: "remarks",
      //   width: "20%",
    },
  ];
  const overHeadCostMonitorData = commentsBudget?.overHeadCostMonitor?.map(
    (e) => ({
      // id: "3fa85f64-5717-4562-b3fc-2c963f66afa6",
      dateCreated: "2023-10-23T12:23:22.628Z",
      enteredBy: "string",
      dateUpdated: "2023-10-23T12:23:22.628Z",
      updatedBy: "string",
      isDelete: false,
      dateDeleted: "2023-10-23T12:23:22.628Z",
      // deptBudgetId: "3fa85f64-5717-4562-b3fc-2c963f66afa6",
      economicCode: e.economicCode,
      details: e.details,
      frequencyPerMonth: e.frequencyPerMonth,
      units: e.units,
      costPerUnit: e.costPerUnit || 0,
      costPerMonth: e.costPerMonth || 0,
      costPerAnnum: e.costPerAnnum || 0,
      remarks: e.remarks,
    })
  );

  const ongoingProjectsColumn = [
    {
      title: "Project",
      dataIndex: "project",
      key: "name",
      render: (text) => <a>{text}</a>,
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "age",
    },
    {
      title: "Details ",
      dataIndex: "details",
      key: "age",
    },
    {
      title: "Date Of Commencement",
      dataIndex: "startDate",
      key: "address",
      width: "20%",
    },
    {
      title: "Amount Spent to date",
      dataIndex: "amountSpent",
      editable: true,
      key: "address",
      width: "20%",
    },
    {
      title: "Estimate to Conclusion",
      dataIndex: "conclusionEstimate",
      editable: true,
      key: "address",
      width: "20%",
    },
    {
      title: "Time To Completion",
      dataIndex: "completionTime",
      editable: true,
      key: "address",
      width: "20%",
    },
  ];

  const ongoingProjectsData = commentsBudget?.ongoingProjects?.map((e) => ({
    // deptBudgetId: "fd9de34e-86f6-43c1-98ce-998a4812c289",
    project: e.project,
    status: e.status ? e.status : "Ongoing",
    location: e.location,
    startDate: moment("2023-10-11T17:08:24.133").format("ll"),
    amountSpent: e.amountSpent,
    conclusionEstimate: e.conclusionEstimate,
    completionTime: e.completionTime,
    mdaId: 11,
  }));

  const personnelCostsColumn = [
    {
      title: "Administrative Code",
      dataIndex: "administrativeCode",
      key: "name",
      render: (text) => <a>{text}</a>,
    },
    {
      title: "Details of Revenue",
      dataIndex: "details",
      key: "age",
    },
    {
      title: "Grade Level",
      dataIndex: "gl",
      editable: true,
      key: "age",
    },
    {
      title: "No. of Staff",
      dataIndex: "noOfStaff",
      editable: true,
      key: "address",
      width: "20%",
    },
    {
      title: "Prev No. of Staff",
      dataIndex: "prevNoOfStaff",
      editable: true,
      key: "address",
      width: "20%",
    },
    {
      title: "Proposed Expenditure 2024",
      dataIndex: "proposedExpense",
      editable: true,
      key: "address",
      render: (text) => <a>{numberWithCommas(text)}</a>,
      width: "20%",
    },

    {
      title: "Prev Approved Expenses 2023",
      dataIndex: "prevApprovedExpense",
      key: "address",
      editable: true,
      render: (text) => <a>{numberWithCommas(text)}</a>,
      width: "20%",
    },

    {
      title: "Expenses as at 30th Sept 2023",
      dataIndex: "currentExpenseAsAt",
      editable: true,
      key: "address",
      render: (text) => <a>{numberWithCommas(text)}</a>,
      width: "20%",
    },
    // {
    //   title: "End Date",
    //   dataIndex: "endDate",
    //   editable: true,
    //   key: "address",
    //   width: "20%",
    // },
  ];

  const personnelCostsData = commentsBudget?.personnelCosts?.map((e) => ({
    ...e,
    // deptBudgetId: "fd9de34e-86f6-43c1-98ce-998a4812c289",
    // administrativeCode:
    //   e.administrativeCode == "string" ? "23099" : e.administrativeCode,
    // gl: e.gl,
    // noOfStaff: e.noOfStaff,
    // endDate: moment("2023-10-11T17:08:24.133").format("ll"),
    // proposedExpense: e.proposedExpense,
    // prevNoOfStaff: e.prevNoOfStaff,
    // prevApprovedExpense: e.prevApprovedExpense || 0,
    // currentExpenseAsAt: e.currentExpenseAsAt || 0,
    // previousExpense: e.previousExpense || 0,
    details: e.economicCodeDetails,
  }));

  const reccurentRevenueColumn = [
    {
      title: "Administrative/Economic Code",
      dataIndex: "economicCode",
      key: "name",
      render: (text) => <a>{text}</a>,
    },
    {
      title: "Details of Revenue",
      dataIndex: "details",
      key: "details",
    },
    {
      title: "Proposed Revenue Estimates 2024",
      dataIndex: "proposedRevenue",
      editable: true,
      key: "proposedRevenue",
      render: (text) => <a>{numberWithCommas(text)}</a>,

      width: "20%",
    },
    {
      title: "Approved Revenue Estimates 2023",
      dataIndex: "previousApprovedRevenue",
      editable: true,
      render: (text) => <a>{numberWithCommas(text)}</a>,

      key: "address",
      width: "20%",
    },
    {
      title: "Actual Revenue as at 30th September, 2023",
      dataIndex: "currentRevenueAsAt",
      editable: true,
      key: "address",
      render: (text) => <a>{numberWithCommas(text)}</a>,

      width: "20%",
    },
    // {
    //   title: "Forecast of Actual Revenue up to 31st December (Annualised)",
    //   dataIndex: "forecastOfCurrentRevenue",
    //   editable: true,
    //   key: "address",
    //   width: "20%",
    // },

    {
      title: "Actual Revenue 2022",
      dataIndex: "previousGeneratedRevenue",
      editable: true,
      key: "address",
      width: "20%",
      render: (text) => <a>{numberWithCommas(text)}</a>,
    },
    // {
    //   title: "End Date",
    //   dataIndex: "endDate",
    //   key: "address",
    //   width: "20%",
    // },
  ];
  function numberWithCommas(x) {
    return x?.toString()?.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  }

  const recurrentRevenueData = commentsBudget?.recurrentRevenues?.map((e) => ({
    // deptBudgetId: "fd9de34e-86f6-43c1-98ce-998a4812c289",
    ...e,
    // economicCode: e.economicCode,
    // // details: e.economicCodeDetails,
    // proposedRevenue: e.proposedRevenue,
    // previousApprovedRevenue: e.previousApprovedRevenue || 0,
    // currentRevenueAsAt: e.currentRevenueAsAt || 0,
    // forecastOfCurrentRevenue: e.forecastOfCurrentRevenue || 0,
    // previousGeneratedRevenue: e.previousGeneratedRevenue || 0,
  }));

  const KPIColumn = [
    {
      title: "Economic Code",
      dataIndex: "economicCode",
      key: "name",
      render: (text) => <a>{text}</a>,
    },
    {
      title: "Project Title",
      dataIndex: "projectTitle",
      key: "projectTitle",
    },
    {
      title: "Project Objectives",
      dataIndex: "projectObjectives",
      key: "projectObjectives",
      //  width: "20%",
    },
    {
      title: "project Duration",
      dataIndex: "projectDuration",
      key: "projectDuration",
      //  width: "20%",
    },
    {
      title: "project Outcome",
      dataIndex: "projectOutcome",
      key: "projectOutcome",
      //  width: "20%",
    },
    {
      title: "key Performance Indicators",
      dataIndex: "keyPerformanceIndicators",
      key: "keyPerformanceIndicators",
      //  width: "20%",
    },

    {
      title: "targets",
      dataIndex: "targets",
      key: "targets",
      //  width: "20%",
    },
    {
      title: "possible Impacts",
      dataIndex: "possibleImpacts",
      key: "possibleImpacts",
      //   width: "20%",
    },
    {
      title: "ranking",
      dataIndex: "ranking",
      key: "ranking",
      //   width: "20%",
    },
    {
      title: "Target Remarks",
      dataIndex: "targetsRemarks",
      key: "targetsRemarks",
      //   width: "20%",
    },
  ];
  const KPIData = commentsBudget?.kpi?.map((e) => ({
    id: "3fa85f64-5717-4562-b3fc-2c963f66afa6",
    dateCreated: "2023-10-23T12:23:22.628Z",
    enteredBy: "string",
    dateUpdated: "2023-10-23T12:23:22.628Z",
    updatedBy: "string",
    isDelete: false,
    dateDeleted: "2023-10-23T12:23:22.628Z",
    deptBudgetId: "3fa85f64-5717-4562-b3fc-2c963f66afa6",
    economicCode: e.economicCode,
    projectTitle: e.projectTitle,
    projectObjectives: e.projectObjectives,
    projectDuration: e.projectDuration,
    projectOutcome: e.projectOutcome,
    keyPerformanceIndicators: e.keyPerformanceIndicators,
    targets: e.targets,
    possibleImpacts: e.possibleImpacts,
    ranking: e.ranking,
    remarks: e.remarks,
  }));

  const CapitalProjectColumn = [
    {
      title: "Administrative Code",
      dataIndex: "adminCode",
      editable: true,
      key: "adminCode",
      //  width: "20%",
    },
    {
      title: "Economic Code",
      dataIndex: "economicCode",
      key: "name",
      render: (text) => <a>{text}</a>,
    },
    {
      title: "Details of Expenditure",
      dataIndex: "details",
      key: "details",
    },

    {
      title: "Status",
      dataIndex: "status",
      editable: true,
      key: "status",
      render: (text) => (text === "3" ? "Ongoing" : "New"),

      //  width: "20%",
    },
    {
      title: "Location Code",
      dataIndex: "location",
      key: "location",
      editable: true,
      //  width: "20%",
    },

    {
      title: "Proposed Appropriation 2024",
      dataIndex: "proposedAppropriation",
      editable: true,
      key: "proposedAppropriation",
      render: (text) => <a>{numberWithCommas(text)}</a>,

      //   width: "20%",
    },
    {
      title: "Current Approved Appropriation 2023",
      dataIndex: "currentApprovedAppropriation",
      editable: true,
      key: "currentApprovedAppropriation",
      render: (text) => <a>{numberWithCommas(text)}</a>,

      //  width: "20%",
    },

    {
      title: "Actual Expenditure At September 2023",
      dataIndex: "actualExpenditureAsAt",
      editable: true,
      key: "actualExpenditureAsAt",
      render: (text) => <a>{numberWithCommas(text)}</a>,

      //  width: "20%",
    },

    {
      title: "Target Remarks",
      dataIndex: "targetsRemarks",
      editable: true,
      key: "targetsRemarks",
      //   width: "20%",
    },
  ];
  const CapitalProjectData = commentsBudget?.capitalProjects?.map((e) => ({
    ...e,
    id: e.id,
    dateCreated: e.dateCreated,
    enteredBy: e.enteredBy,
    dateUpdated: e.dateUpdated,
    updatedBy: e.updatedBy,
    economicCode: e.economicMainHeadCode,
    isDelete: e.isDelete,
    dateDeleted: e.dateDeleted,
    deptBudgetId: e.deptBudgetId,
    details: e.details,
    stateOfCompletion: e.stateOfCompletion,
    status: e.status,
    location: e.locationCode,
    currentApprovedAppropriation: e.currentApprovedAppropriation || 0,
    actualExpenditureAsAt: e.actualExpenditureAsAt || 0,
    proposedAppropriation: e.proposedAppropriation || 0,
    targetsRemarks: e.targetsRemarks,
    mdaId: e.mdaId,
  }));

  console.log(budgetDeptId);

  const toApproveBudgetItem = async (status, mdaId) => {
    setIsLoading(true);
    let payload = {
      comments: "reviewed",
      approverId: +Cookies.get("userId"),
      // deptBudgetId: budgetDeptId,
      mdaId: mdaId,
      approverStatus: status,
    };

    const res = await put({
      endpoint: "/api/ApprovalProcess/action_plan",
      auth: true,
      body: payload,
    });

    if (res && res.status == 200) {
      status == 7
        ? notification({
            title: "Successful",
            message: "Allocated Mda budget has been Approved successfully",
            type: "success",
          })
        : notification({
            title: "Successful",
            message: " Mda budget has been Declined successfully",
            type: "success",
          });
      setIsLoading(false);
    }
  };

  // const toPostEnvelope = () => {
  //   setIsLoading(true);
  //   // let poster = arrayHolder.map((a)=>())
  //   axios
  //     .post(`${BASE_API_URL}/BudgetPlanning/BudgetEnvelope`, {
  //       id: batchId,
  //       dateCreated: dateCreated,
  //       enteredBy: userId + "",
  //       dateUpdated: "2023-02-09T05:56:40.680Z",
  //       updatedBy: userId + "",
  //       isDelete: false,
  //       dateDeleted: "2023-02-09T05:56:40.680Z",
  //       projectBatchId: batchId,
  //       totalAmount: +totalCost,
  //       mdaId: +mdaId,
  //       // pId: 0
  //     })
  //     .then((res) => {
  //       setIsLoading(false);
  //       setOnAction(false);
  //       getBudgetPlanningSummary();
  //       notification({
  //         title: "Successful",
  //         message: "Allocated Mda budget has been sent successfully",
  //         type: "success",
  //       });
  //     });
  // };

  const getPending = (id) => {
    axios
      .get(
        `${BASE_API_URL}/BudgetPlanning/GetPendingInitiativesSummaryByUserId`,
        { params: { id } }
      )
      .then((response) => {
        console.log(response.data);
        setArrayHolder(response.data);
      });
  };
  // const getBudgetPendingComents = (id) => {
  //   axios
  //     .get(
  //       `${BASE_API_URL}/CommentPlan/GetPendingCommentsSummaryByApproverId`,
  //       { params: { id } }
  //     )
  //     .then((response) => {
  //       console.log(response);
  //       setBudgetPending(response.data);
  //       // console.log(response.data[0].budgetPlanning)
  //       // setCommentsBudget(response.data)
  //       // setCommentsBudgetA(response.data[0]?.budgetPlanning)
  //     });
  // };

  // const getPendingBudgetSectors = (userId) => {
  //   axios
  //     .get(`${BASE_API_URL}/BudgetPlanning/GetPendingBudgetSectors`, {
  //       params: { userId },
  //     })
  //     .then((response) => {
  //       console.log(response);
  //       setSectorId(response.data);

  //       // console.log(response.data[0].budgetPlanning)
  //       // setCommentsBudget(response.data)
  //       // setCommentsBudgetA(response.data[0]?.budgetPlanning)
  //     });
  // };

  // console.log(commentsBudget.map((e)=>e.budgetItems))
  // console.log(commentsBudget[0]?.budgetItems);

  const getBudgetPlanningSummary = async (id) => {
    // axios
    // .get(
    //   `https://edogoverp.com/newbudgetapi/api/v1/BudgetPlanning/GetBudgetPlanningByUserId?userId=${+id}`
    // )
    // .then((response) => {
    const response = await get({
      endpoint: `/api/v1/BudgetPlanning/GetBudgetPlanningByUserId?userId=${+id}`,
      auth: true,
      //  body: payload,
    });

    if (response && response.status == 200) {
      setBudgetArrays(response?.data);
      console.log(response);
      console.log(response?.data.length != 0 && response?.data[0]?.budgetItems);
      // console.log(response.data[0].budgetPlanning)
      response?.data.length != 0 &&
        setCommentsBudget(response?.data[response?.data?.length - 1]);
      response?.data.length != 0 &&
        setBudgetDeptId(
          response?.data[response?.data?.length - 1].deptBudgetId
        );
      // setCommentsBudgetA(response.data[0]?.budgetPlanning)
    }
  };

  return (
    <div className="w-100">
      {budgetArrays.map((budgetContent) => (
        <div className="w-100">
          <h1
            className="w-100 flex space-between text-center m-b-50 flex-v-baseline"
            style={{}}
          >
            {budgetContent?.mda}
            {/* <Greeting /> */}
            {/* <LocationDetails /> */}
          </h1>
          <h2 className="m-b-10 m-l-10">Budget Review</h2>
          <Collapse className="w-100" accordion>
            <Panel className="w-100" header={<h4>Overhead</h4>} key="1">
              <div style={{ width: "100%" }}>
                <BudgetReviewTable
                  columns={budgetItemsColumn}
                  tableData={budgetContent?.budgetItems?.map((e) => ({
                    ...e,
                    // dateCreated: "2023-09-17T13:22:34.007Z",
                    enteredBy: userId + "",
                    // dateUpdated: "2023-09-17T13:22:34.007Z",
                    // updatedBy: userId + "",
                    // isDelete: false,
                    // dateDeleted: "2023-09-17T13:22:34.007Z",
                    // budgetPlanId: "3fa85f64-5717-4562-b3fc-2c963f66afa6",
                    // budgetPeriodId: "3fa85f64-5717-4562-b3fc-2c963f66afa6",
                    // adminSectorCode: 0,
                    // project: "string",
                    // title: "",
                    // programmeCode: "111111111111",
                    // administrativeCode: e.administrativeCode,
                    // economicCode: e.economicCode,
                    // //   administrativeCode: e.administrativeCode,
                    // fundCode: "444444444444",
                    // functionalCode: "555555555555",
                    // geoCode: "666666666666",
                    // status: "string",
                    // projectLocation: "string",
                    proposedBudget: +e.proposedBudget,
                    previousApprovedBudget: +e.previousApprovedBudget || 0,
                    currentExpenditures: +e.currentExpenditures || 0,
                    previousExpenditures: +e.previousExpenditures || 0,
                    annualisedTotalExpenditure:
                      +e.annualisedTotalExpenditure || 0,
                    // performance: "string",
                    // actuals: "string",
                    mdaId: +mdaId,
                    // approvalStatus: 0,
                    // stage: 0,
                  }))}
                />
                {/* <Table columns={budgetItemsColumn} dataSource={budgetItemsData} /> */}
              </div>
            </Panel>
            {/* <Panel header={<h4>Ongoing Projects</h4>} key="2">
       <div style={{}}>
         <BudgetReviewTable
           columns={ongoingProjectsColumn}
           tableData={ongoingProjectsData}
         />
       </div>
     </Panel> */}
            <Panel className="w-100" header={<h4>Personel Costs</h4>} key="3">
              <div>
                <BudgetReviewTable
                  columns={personnelCostsColumn}
                  tableData={budgetContent?.personnelCosts?.map((e) => ({
                    ...e,
                    // deptBudgetId: "fd9de34e-86f6-43c1-98ce-998a4812c289",
                    // administrativeCode:
                    //   e.administrativeCode == "string" ? "23099" : e.administrativeCode,
                    // gl: e.gl,
                    // noOfStaff: e.noOfStaff,
                    // endDate: moment("2023-10-11T17:08:24.133").format("ll"),
                    // proposedExpense: e.proposedExpense,
                    // prevNoOfStaff: e.prevNoOfStaff,
                    // prevApprovedExpense: e.prevApprovedExpense || 0,
                    // currentExpenseAsAt: e.currentExpenseAsAt || 0,
                    // previousExpense: e.previousExpense || 0,
                    details: e.economicCodeDetails,
                  }))}
                />
              </div>
            </Panel>
            {/* <Panel header={<h4>KPI</h4>} key="4">
       <div>
         <BudgetReviewTable columns={KPIColumn} tableData={KPIData} />
       </div>
     </Panel> */}
            <Panel
              className="w-100"
              header={<h4>Capital Expenditure</h4>}
              key="5"
            >
              <div>
                <BudgetReviewTable
                  columns={CapitalProjectColumn}
                  tableData={budgetContent?.capitalProjects?.map((e) => ({
                    ...e,
                    id: e.id,
                    dateCreated: e.dateCreated,
                    enteredBy: e.enteredBy,
                    dateUpdated: e.dateUpdated,
                    updatedBy: e.updatedBy,
                    economicCode: e.economicMainHeadCode,
                    isDelete: e.isDelete,
                    dateDeleted: e.dateDeleted,
                    deptBudgetId: e.deptBudgetId,
                    details: e.details,
                    stateOfCompletion: e.stateOfCompletion,
                    status: e.status,
                    location: e.locationCode,
                    currentApprovedAppropriation:
                      e.currentApprovedAppropriation || 0,
                    actualExpenditureAsAt: e.actualExpenditureAsAt || 0,
                    proposedAppropriation: e.proposedAppropriation || 0,
                    targetsRemarks: e.targetsRemarks,
                    mdaId: e.mdaId,
                  }))}
                />
              </div>
            </Panel>
            <Panel
              className="w-100"
              header={<h4>Recurrent Revenue</h4>}
              key="8"
            >
              <div>
                <BudgetReviewTable
                  columns={reccurentRevenueColumn}
                  tableData={budgetContent?.recurrentRevenues?.map((e) => ({
                    // deptBudgetId: "fd9de34e-86f6-43c1-98ce-998a4812c289",
                    ...e,
                    // economicCode: e.economicCode,
                    // // details: e.economicCodeDetails,
                    // proposedRevenue: e.proposedRevenue,
                    // previousApprovedRevenue: e.previousApprovedRevenue || 0,
                    // currentRevenueAsAt: e.currentRevenueAsAt || 0,
                    // forecastOfCurrentRevenue: e.forecastOfCurrentRevenue || 0,
                    // previousGeneratedRevenue: e.previousGeneratedRevenue || 0,
                  }))}
                />
              </div>
            </Panel>
            <Panel className="w-100" header={<h4>Capital Receipts</h4>} key="9">
              <div>
                <BudgetReviewTable
                  columns={capitalReceiptsColumn}
                  tableData={budgetContent?.capitalReceipts?.map((e) => ({
                    ...e,
                    deptBudgetId: e?.deptBudgetId,
                    adminCode: e?.adminCode,
                    details: e?.details,
                    programmeCode: e?.programmeCode,
                    economicMainHeadCode: e?.economicMainHeadCode,
                    economicDetailsCode: e?.economicDetailsCode,
                    functionalMainHeadCode: e?.functionalMainHeadCode,
                    functionalDetailsCode: e?.functionalDetailsCode,
                    locationCode: e?.locationCode,
                    fundMainCode: e?.fundMainCode,
                    fundDetailsCode: e?.fundDetailsCode,
                    fundMainCode2: e?.fundMainCode2,
                    fundDetailsCode2: e?.fundDetailsCode2,
                    fundMainCode3: e?.fundMainCode3,
                    fundDetailsCode3: e?.fundDetailsCode3,
                    fundAmount: e?.fundAmount,
                    fundAmount2: e?.fundAmount2,
                    fundAmount3: e?.fundAmount3,
                    currentApprovedAppropriation:
                      e?.currentApprovedAppropriation || 0,
                    actualExpenditureAsAt: e?.actualExpenditureAsAt || 0,
                    proposedAppropriation: e?.proposedAppropriation || 0,
                    mdaId: e?.mdaId,
                    id: e?.id,
                    dateCreated: e?.dateCreated,
                    enteredBy: e?.enteredBy,
                    dateUpdated: e?.dateUpdated,
                    updatedBy: e?.updatedBy,
                    isDelete: false,
                    dateDeleted: e?.dateDeleted,
                  }))}
                />
              </div>
            </Panel>
            {/* <Panel header={<h4>Recurrent Revenue</h4>} key="8">
       <div>
         <BudgetReviewTable
           columns={reccurentRevenueColumn}
           tableData={recurrentRevenueData}
         />
       </div>
     </Panel> */}
            {/* <Panel header={<h4>Ranking</h4>} key="6">
       <div>
         <Table columns={rankingsColumn} dataSource={rankingsData} />
       </div>
     </Panel> */}
            {/* <Panel header={<h4>Over Head Cost Monitor</h4>} key="7">
       <div>
         <Table
           columns={overHeadCostMonitorColumn}
           dataSource={overHeadCostMonitorData}
         />
       </div>
     </Panel> */}
          </Collapse>
          <div>
            <div
              className=""
              style={{
                width: "20%",
                marginTop: "20px",
                display: "flex",
                marginLeft: "auto",
              }}
            >
              <Button
                loading={isLoading}
                style={{
                  backgroundColor: "#1d811c",
                  marginRight: "10px",
                  color: "white",
                }}
                onClick={() => toApproveBudgetItem(7, budgetContent?.mdaId)}
              >
                Approve
              </Button>
              <Button
                loading={isLoading}
                style={{ backgroundColor: "red", color: "white" }}
                onClick={() => toApproveBudgetItem(8, budgetContent?.mdaId)}
              >
                Decline
              </Button>
            </div>
          </div>
        </div>
      ))}
    </div>
  );
};

// const ListItem3 = ({
//   toApproveBudgetItem,
//   detail,
//   userId,
//   callRefresh,
//   higherApprover,
//   callBatchId,
//   setOnAction,
//   setDateCreated,
//   batchId,
//   projectInitiative,
//   handleDetails,
//   current,
//   removeItem,
// }) => {
//   const [itemDetails, setItemDetails] = useState(detail || {});
//   const [showDetails, setShowDetails] = useState(false);
//   const [comentTable, setCommentTable] = useState(false);

//   //   const updateInfo = (newItem) => {
//   //     setItemDetails(newItem || {});
//   //   };
//   const closeModal = (close) => {
//     console.log(close);
//     setCommentTable(close);
//     callRefresh();
//   };

//   console.log(detail);

//   return (
//     <>
//       {comentTable && (
//         <div className="overlay w-60">
//           <CommentTable
//             higherApprover={higherApprover}
//             closeModal={closeModal}
//             userIdz={userId}
//             commentsz={detail.comments}
//             project={detail.budgetPlanning}
//             projectParams={detail}
//           />
//         </div>
//       )}

//       <tr
//         className={`${showDetails && "active"}  ${
//           itemDetails.id
//         } table-row pointer`}
//         // onClick={() => {
//         //     setShowDetails(!showDetails)
//         //     callBatchId(detail.batchId)

//         // }}
//         role="presentation"
//       >
//         <td className="td p-r" style={{ padding: "20px 10px" }}>
//           {itemDetails.title || itemDetails.title}
//           {/* {showDetails && (
//             <IoMdClose
//               className="dropdown-close-btn pointer"
//               onClick={() => setShowDetails(!showDetails)}
//             />
//           )} */}
//         </td>

//         {/* <td className="td" style={{ padding: "20px 10px" }}>
//           {itemDetails.vendor}
//         </td> */}
//         {/* <td className="td p-r" style={{ padding: "20px 10px" }}>
//           <div className={`status ${detail.status}-status`}>
//             {(detail.status||'Pending')}
//           </div>
//         </td> */}

//         <td className="td p-r" style={{ padding: "20px 10px" }}>
//           {/* {formatDate(itemDetails?.dateCreated)} */}
//           <p> &#8358; {itemDetails?.proposedBudget}</p>
//         </td>
//         {/* <td className="td" style={{ padding: "20px 10px" }}>
//           {(detail.amount)}
//         </td> */}
//         <td
//           style={{
//             display: "flex",
//             right: "0",
//             justifyContent: "flex-end",
//             padding: "20px 10px",
//           }}
//         >
//           <span
//             style={{
//               border: "1px solid white",
//               // height: "30px",
//               // width: "30px",
//               display: "flex",
//               justifyContent: "center",
//               alignItems: "center",
//               backgroundColor: "#ff8566",
//             }}
//             // onClick={() => { setCommentTable(true) }}
//           >
//             {/* <TiPencil style={{backgroundColor: "#66ff99", fontSize:"30px", padding:"5px"}}/> */}
//             <div>
//               <Button onClick={() => toApproveBudgetItem(7)}>Approve</Button>
//               <Button onClick={() => toApproveBudgetItem(8)}>Decline</Button>
//             </div>
//             {/* View <DeleteIcon style={{ color: "green" }} /> */}
//           </span>
//         </td>
//       </tr>
//     </>
//   );
// };

export default BudgetTeamCorrespondence;
