import React, { useEffect, useState } from "react";
import { Table, Modal, Select, Button } from "antd";
import "antd/dist/antd.css";

import moment from "moment";
import notification from "../../utility/notification";
import { CloseOutlined } from "@ant-design/icons";
import { get, post, put } from "../../api-services/fetch";
import Cookies from "js-cookie";

const { Option } = Select;

const AntdTable = (props) => {
  const [approver, setApprover] = useState();
  const [mdaDetails, setmdaDetails] = useState({});
  const [employeeModal, setEmployeeModal] = useState(false);
  const [isLoading, setisLoading] = useState(false);
  const [listOfEmployees, setListOfEmployees] = useState([]);

  useEffect(() => {
    console.log(props.listOfMdas);
    getEmployeeList();
  }, []);

  const columns = [
    {
      title: "Total Amount",
      dataIndex: "totalAmount",
      key: "totalAmount",
      render: (text) => `₦ ${text.toLocaleString()}`,
    },
    {
      title: "Overhead Cost",
      dataIndex: "overheadCost",
      key: "overheadCost",
      render: (text) => `₦ ${text ? text.toLocaleString() : 0}`,
    },
    {
      title: "Personel Cost",
      dataIndex: "personelCost",
      key: "personelCost",
      render: (text) => `₦ ${text ? text.toLocaleString() : 0}`,
    },
    {
      title: "Capital Cost",
      dataIndex: "capitalCost",
      key: "capitalCost",
      render: (text) => (text ? `₦ ${text.toLocaleString()}` : "N/A"),
    },
    {
      title: "MDA ID",
      dataIndex: "mdaId",
      key: "mdaId",
      render: (text, record) =>
        props.listOfMdas?.find((e) => e.id == record.mdaId)?.name,
    },
    {
      title: "operation",
      dataIndex: "operation",
      render: (_, record) => {
        // console.log(record);
        return (
          <div style={{ display: "flex", gap: 4, alignItems: "center" }}>
            <Button
              style={{ backgroundColor: "#1C811C", color: "white" }}
              onClick={() => {
                handleCancel();
                setmdaDetails(record);
                console.log(record)
                // console.log(props.listOfMdas?.find((e) => e.id == record.mdaId).name)
              }}
            >
              Assign to Budget Item Unit
            </Button>
          </div>
        );
      },
    },
    // Add more columns as needed
  ];
  const getEmployeeList = async () => {
    const res = await get({
      endpoint: `employee`,
      auth: true,
      pQuery: { q: "a" },
    });
    console.log(res);

    if (res && res.status === 200) {
      const { data } = res;
      if (res.status === 200) {
        console.log(data);
        setListOfEmployees(data);
      } else {
        notification({
          title: "Error Getting List of Employees",
          message: data.message,
          type: "danger",
        });
      }
    } else {
      notification({
        title: "Network",
        message: "Something has gone wrong. Please, try again.",
        type: "danger",
      });
    }
  };
  const toSendToPSBudget = async () => {
    // setisLoading(true);
    // let payload = {
    //   mdaId: mdaDetails?.mdaId,
    //   periodId: mdaDetails?.projectBatchId,
    //   approvalStatus: 1,
    //   approverId: approver.value,
    // };
    let payload = {
      // id: "",
      dateCreated: new Date(),
      enteredBy: Cookies.get("userId"),
      dateUpdated: new Date(),
      envelopeId: mdaDetails?.id,
      updatedBy:  Cookies.get("userId"),
      isDelete: false,
      dateDeleted: new Date(),
      budgetPeriodId: props?.tableDetails?.budgetPeriodId,
      title: props?.tableDetails?.subject,
      details: props?.tableDetails?.details,
      assignee: approver.value,
      status: 3,
    };

    //   toAssign(assigner);

    const res = await post({
      endpoint: "assigntodirectorItem",
      auth: true,
      body: payload,
    });

    if (res && res.status == 200) {
      setisLoading(false);
      notification({
        title: "Success",
        message: "Successfully Assigned",
        type: "success",
      });
      //   props?.getPendingConfirmationBudgetFunction();
      handleCancel();
    } else {
      setisLoading(false);
      notification({
        title: "Error",
        message: "Something went Wrong",
        // message: data.message,
        type: "danger",
      });
    }
  };
  const handleCancel = () => {
    setEmployeeModal(!employeeModal);
  };

  const handleEnployeeSearch = async (text) => {
    const res = await get({
      endpoint: "employee",
      auth: true,
      pQuery: { q: text || "e" },
    });
    console.log(res);

    if (res && res.status === 200) {
      const { data } = res;
      if (
        data
        // data.code === 1
      ) {
        console.log(data);
        setListOfEmployees(data);
      } else {
        // notification({
        //   title: "Error Getting List of Employees",
        //   message: data.message,
        //   type: "danger",
        // });
      }
    } else {
      // notification({
      //   title: "Network",
      //   message: "Something has gone wrong. Please, try again.",
      //   type: "danger",
      // });
    }
  };
  return (
    <div>
      <Table
        dataSource={props.data}
        columns={columns}
        pagination={false} // Remove if you want pagination
      />
      <Modal
        width="40%"
        title={props.listOfMdas?.find((e) => e.id == mdaDetails.mdaId)?.name}
        footer={false}
        // okText={<p style={{ maxWidth: "100px" }}>Boss</p>}
        closeIcon={
          <CloseOutlined
            style={{
              fontSize: "25px",
              backgroundColor: "red",
              color: "white",
              padding: "10px",
              marginLeft: "60px",
            }}
          />
        }
        visible={employeeModal}
        onCancel={handleCancel}
      >
        <div>
          {" "}
          <Select
            onChange={(text, index) => {
              setApprover(index);
            }}
            // mode="multiple"
            showSearch
            filterOption={(input, option) =>
              option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
            }
            filterSort={(optionA, optionB) =>
              optionA.children
                .toLowerCase()
                .localeCompare(optionB.children.toLowerCase())
            }
            //   mode="multiple"
            labelInValue
            allowClear
            style={{ width: "100%", border: "" }}
            //   onChange={e => this.setState({ details: e.target.value })}
            placeholder="Please select"
            //   defaultValue={['a10', 'c12']}
            //   onChange={handleChange}
            onSearch={(text, index) => handleEnployeeSearch(text)}
          >
            {Array.isArray(listOfEmployees) &&
              listOfEmployees?.map((obj) => (
                <Option value={obj?.id} key={obj?.id}>{`${obj?.firstName} ${
                  obj?.lastName
                } (${
                  props.listOfMdas?.find((e) => e?.id == obj.mdaId)?.name
                })`}</Option>
              ))}
            {/* {children} */}
          </Select>
          <Button
            loading={isLoading}
            style={{
              backgroundColor: "#1C811C",
              color: "white",
              marginTop: "20px",
            }}
            onClick={toSendToPSBudget}
          >
            Assign
          </Button>
        </div>
      </Modal>
    </div>
  );
};

export default AntdTable;
